import React from "react";
import config from "../../../../config";
import config1 from "../../../../coreFIles/config";
import {
  Container,
  Nav,
  Navbar,
  Dropdown,
  Offcanvas,
  Button,
} from "react-bootstrap";

import { useEffect, useState } from "react";
import Cookies from "js-cookie";

import { useNavigate } from "react-router-dom";

let urlParameter = window.location.pathname.split("/").pop();

const Header = () => {
  const [userProfile, setUserProfile] = useState([]);
  const navigate = useNavigate();
  const [navData, setNavData] = useState("");

  const loginData = !Cookies.get("loginSuccessPropertyOwner")
    ? []
    : JSON.parse(Cookies.get("loginSuccessPropertyOwner"));
  if (!loginData || loginData == "") {
    window.location.href = `${config.baseurl}`;
  }




  const redirectUrl = async (page) => {
    window.location.href = page;
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const logout = async () => {
    // await logoutAction();
    Cookies.remove("loginSuccessPropertyOwner");
    window.location.href = `${config.baseurl}`;
  };

  const naviagteToUrl = async (data) => {
    setNavData(data);
    navigate(
      `${config.baseurl}${config.loginurl}/${data === "prop" ? "calender" : "CalenderVehicle"
      } `,
      { state: data }
    );
    setTimeout(() => {
      window.location.reload();
    });
  };
  return (
    <>
      {["xl"].map((expand) => (
        <Navbar key={expand} expand={expand} className="bg-white pt-2 pb-2 ">
          <Container>
            <Navbar.Brand href={`${config.baseurl}staffdashboard`}>
              <img src="assets/images/logo/logo.webp" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  <img src="assets/images/logo/logo.webp" />
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                    <Nav className="justify-content-end flex-grow-1 align-items-xl-center">
                      <Nav.Link
                        className={urlParameter == "dashboard" ? "active" : ""}
                        href={`${config.baseurl}staffdashboard`}
                      >
                        Today
                      </Nav.Link>
                      <Nav.Link
                        className={
                          urlParameter == "reservation" ? "active" : ""
                        }
                        href={`${config.baseurl}staffdashboard`}
                      >
                        Reservation
                      </Nav.Link>
                      <Nav.Link
                        className={urlParameter == "chat" ? "active" : ""}
                        href={`${config.baseurl}staffdashboard`}
                        style={{display:"flex", flexDirection:"row", gap:"5px"}}
                      >
                        Inbox
                        {userProfile?.totalSupportHostUnreadMsg > 0 ? <div className='inboxmsg'>{userProfile?.totalSupportHostUnreadMsg}</div> : ""}
                      </Nav.Link>
                      <Nav.Link
                        className={urlParameter == "calender" ? "active" : ""}
                        href={`${config.baseurl}staffdashboard`}
                      >
                        Calendar
                      </Nav.Link>
                      <Nav.Link
                        className={
                          urlParameter == "listingproperty" ? "active" : ""
                        }
                        href={`${config.baseurl}staffdashboard`}
                      >
                        Listings
                      </Nav.Link>
                      <Nav.Link
                        className={
                          urlParameter == "transaction" ? "active" : ""
                        }
                        href={`${config.baseurl}staffdashboard`}
                      >
                        Transaction
                      </Nav.Link>
                      {/* {/ <Nav.Link><Button onClick={() => redirectUrl('userdashboard/yourbooking')} variant='primary' className='rounded-5 px-3'>Switch To Hosting</Button></Nav.Link> /} */}
                    </Nav>
                <Nav className="justify-content-end flex-grow-1">
                  <Dropdown className="profileD">
                    <Dropdown.Toggle
                      variant="default"
                      id="dropdown-basic"
                      className="h-auto border-0 profileDropdown"
                    >
                        <img
                          src="images/p1.png"
                          width="30px"
                          height="30px"
                          style={{ objectFit: "cover", borderRadius: "50%" }}
                          className=""
                        />
              
                      &nbsp;{" "}
                      Shubham
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item  className="">
                        Log Out
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
};

export default Header;
