import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Row, Col, Button } from "react-bootstrap";
import Cookies from "js-cookie";
import '../../css/listMenu.css';
import "../../../commanComponents/componentCSS/reservation.css";
import config from '../../../../config';
import { getUserProfileAction } from "../../../../Action/user.action";

const loginData = !Cookies.get("loginSuccessPropertyOwner")
    ? []
    : JSON.parse(Cookies.get("loginSuccessPropertyOwner"));

const UserNameAndTab = () => {
    const [userProfile, setUserProfile] = useState([]);
    const [activeTab, setActiveTab] = useState('');
    const location = useLocation();

    useEffect(() => {
        getProfileAPI();
        setActiveTabBasedOnPath(location.pathname);
    }, [location.pathname]);

    const getProfileAPI = async () => {
        let res = await getUserProfileAction();
        if (res.success) {
            setUserProfile(res.data);
        }
    };

    const setActiveTabBasedOnPath = (path) => {
        const vehicleDashboardPaths = [
            'financevehicledashboard',
            'vehicleexpense',
            'vehiclereceivable',
            'vehiclesupplier',
            'vehicleemployee',
            'vehiclejournalentry'
        ];

        const isVehicleDashboard = vehicleDashboardPaths.some((route) => path.includes(route));

        if (isVehicleDashboard) {
            setActiveTab('vehicleDashboard');
        } else {
            setActiveTab('financeDashboard');
        }
    };

    return (
        <div>
            <Row className="">
                <Col lg={5} sm={12} xs={12} className="mb-4">
                    <div className="d-flex profileMobile align-items-center">
                        {!userProfile?.profile ? (
                            <img src="assets/images/profile-img.png" width="32px" />
                        ) : (
                            <img
                                src={userProfile?.profile}
                                width="40px"
                                height="40px"
                                style={{ objectFit: "cover", borderRadius: "50%" }}
                                className=""
                            />
                        )}
                        &nbsp;&nbsp;{" "}
                        <div className="profileContent d-flex align-items-center">
                            <div>
                                <h5 className="mb-0 me-2 fw-bold">Hey {loginData.firstName} {loginData.lastName}</h5>
                            </div>
                            <img src="images/icon/hand.svg" width={`37px`} />
                        </div>
                    </div>
                </Col>
                <Col lg={7} sm={12} xs={12} className="text-right">
                    {activeTab === 'financeDashboard' && (
                        <Link to={`${config.baseurl}financevehicledashboard`}>
                        <Button
                            variant="primary"
                            className="rounded-5 me-2 px-4 px-xs-1 mb-4 mobileBtn"
                        >
                            Vehicle Dashboard
                        </Button>
                    </Link>
                    )}
                    {activeTab === 'vehicleDashboard' && (
                       <Link to={`${config.baseurl}financedashboard`}>
                       <Button
                           variant="primary"
                           className="rounded-5 me-2 px-4 px-xs-1 mb-4 mobileBtn"
                       >
                           Finance Dashboard
                       </Button>
                   </Link>
                    )}
                </Col>
            </Row>
        </div>
    );
};

export default UserNameAndTab;
