import React, { useState, useEffect } from "react";
import Header from "./directives/header";
import Footer from "./directives/footer";
import "./generalCSS/home.css";
import "./generalCSS/generalnavbar.css";
import "./generalCSS/blog.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { getAboutUsAction } from "../Action/user.action";
import moment from "moment";
import configCore from "../coreFIles/config";

import { Link } from "react-router-dom";
import config from "../config";

const OurMission = () => {
  const { id } = useParams();

  const [aboutUs, setAboutUs] = useState([]);
  const [desc, setDesc] = useState("");

  useEffect(() => {
    getAboutUsAPI();
  }, []);

  const getAboutUsAPI = async () => {
    let res = await getAboutUsAction();
    if (res.success) {
      setAboutUs(res.data);
    }
  };

  return (
    <>
  
      <section className="bg-light py-4">
        <Container>
          <Row className="align-items-center">
            <Col lg={12} md={12} className=" text-center">
              <div className="">
                <h1 className="text-primary mb-0 py-4">
                  <b>Our Mission  </b>
                </h1>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="blog">
        <Container>
          <Row className="justify-content-center">
            <Row>
              <Col lg={12} className=" mb-5">
                <Row>
                  <Col lg={12} className="mb-5">
                    <Card className="overflow-hidden border-0">
                      <div className="post-image-card overflow-hidden">
                        <Card.Img
                          variant="top"
                          src={aboutUs[2]?.image}
                          // style={{ width: "720px", height: "405px" }}
                        />
                      </div>

                      <Card.Body>
                        <h5 className="fw-bold">{aboutUs[2]?.title}</h5>
                        <Card.Text>
                          <p>{aboutUs[2]?.shortDesc}</p>{" "}
                        </Card.Text>
                        <Card.Text>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: aboutUs[2]?.description,
                            }}
                          ></p>{" "}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Row>
        </Container>
      </section>

   
    </>
  );
};

export default OurMission;
