import React, { useState, useEffect } from "react";
import Header from "./directives/header";
import Footer from "./directives/footer";
import "./generalCSS/home.css";
import "./generalCSS/generalnavbar.css";
import "./generalCSS/blog.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { ShimmerThumbnail } from "react-shimmer-effects";
import config from "../config";


import configCore from "../coreFIles/config";
import { Helmet } from "react-helmet";
import {
  getBlogPostDetailsAction,
  getBlogImagesPostDetailsAction,
} from "../Action/user.action";
import moment from "moment";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Link } from "react-router-dom";
import trimText from "./trimText";

const Blog2 = () => {
  const [blogList, setBlogList] = useState([]);
  const [blogPost1, setBlogPost1] = useState({});
  const [blogPost2, setBlogPost2] = useState({});
  const [Images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getListingDetails();
    getImages();
    setTimeout(() => {
      setLoading(false);
    }, 1500);

  }, []);

  const getListingDetails = async () => {
    let res = await getBlogPostDetailsAction();
    if (res.success) {
      setBlogList(res.data);

      if (res.data.length > 1) {
        let blog1Data = res.data[0];
        blog1Data.description = trimText(
          res.data[0].description,
          700,
          700,
          700
        )[0];
        setBlogPost1(blog1Data);

        let blog2Data = res.data[1];
        blog2Data.description = trimText(
          res.data[1].description,
          4000,
          4000,
          4000
        )[0];
        setBlogPost2(blog2Data);
      }
    } else {
      setBlogList([]);
    }
  };
  const getImages = async () => {
    let res = await getBlogImagesPostDetailsAction();
    if (res.success) {
      setImages(res.data);
    } else {
      setImages([]);
    }
  };
  return (
    <>
      <Helmet>
        <title>
          Propertynu Guyana- Boost Your Bookings Build Your Business
        </title>
        <meta
          title="Propertynu Guyana- Boost Your Bookings Build Your Business"
          content="IE=edge"
        />
        <meta
          name="description"
          content="PropertyNU is the easiest way to list property and vehicles for rentals in Guyana. We'll take care of all the marketing and guest management, so you can just relax and enjoy the extra income."
        />
      </Helmet>
      {loading ? (
        <div className="loader-container">
          <img src="assets/images/loader-home.gif" style={{height:'100px', width:'100px'}}/>
        </div>
      ) :(
      <div>
      <section className="blogBanner py-0">
        <Container fluid className="">
          <Row className="align-items-center">
            <Col lg={12} md={12} className="px-0 text-center">
              <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
              >
                {Images.map((item) => (
                  <SwiperSlide>
                    <div className="blogContent">
                      <img src={item.image} />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="blog">
        <Container>
          <Row className="justify-content-center">
            <Row>
              <Col lg={12} className="">
                <Row className="align-items-center">
                  <Col lg={6} className="mb-4">
                    {loading ? (
                      <ShimmerThumbnail height={300} rounded />
                    ) : (
                      <div>
                        <div className="date-heading mb-3">
                          <span>
                            {" "}
                            {moment(blogPost1.dateTime).format("MMM DD, yyyy")}
                          </span>
                        </div>

                        <h1 className="fw-bold  mb-3 text-primary">
                          {blogPost1.title}
                        </h1>
                        <p
                          className=" mb-4"
                          dangerouslySetInnerHTML={{
                            __html: blogPost1.description,
                          }}
                        ></p>

                        <a
                          className="rounded-5 px-4 blogsection"
                          variant="primary"
                          href={`${config.baseurl}blog_detail/${blogPost1.id}`}
                        >
                          CONTINUE READING
                        </a>
                      </div>
                    )}
                  </Col>
                  <Col lg={6} className="">
                    <div className="px-md-4">
                      <img
                        src={blogPost1.image}
                        height="470px"
                        width="100%"
                        style={{ objectFit: "cover" }}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          {loading ? (
            <ShimmerThumbnail height={300} rounded />
          ) : (
            <Row className="align-items-center">
              <Col lg={6} className="mb-4">
                <div className="px-md-4">
                  <img
                    src={blogPost2.image}
                    height="470px"
                    width="100%"
                    style={{ objectFit: "cover" }}
                  />
                </div>
              </Col>
              <Col lg={6} className="mb-4">
                <div>
                  <div className="date-heading mb-3">
                    <span>
                      {" "}
                      {moment(blogPost2.dateTime).format("MMM DD, yyyy")}
                    </span>
                  </div>
                  <h1 className="fw-bold mb-3 text-primary">
                    {blogPost2.title}
                  </h1>
                  <p
                    className=" mb-4"
                    dangerouslySetInnerHTML={{
                      __html: blogPost2.description,
                    }}
                  ></p>

                  <a
                    className="rounded-5 px-4 blogsection"
                    variant="primary"
                    href={`${config.baseurl}blog_detail/${blogPost2.id}`}
                  >
                    CONTINUE READING
                  </a>
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </section>

      <section className="explore">
        <Container>
          <div className="mb-5">
            <div class="text-center">
              <h2 class="">
                <b>Explore More...</b>
              </h2>
              <div class="section-title-line"> </div>
            </div>
          </div>
          <Row className="">
            <Col lg={12} className="mb-4">
              <Swiper
                slidesPerView={1}
                spaceBetween={10}
                loop={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                navigation={{
                  clickable: true,
                }}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 50,
                  },
                }}
                modules={[Autoplay, Navigation]}
                className="mySwiper h-auto"
              >
                {blogList &&
                  blogList.map((item) => (
                    <SwiperSlide>
                      <Card className="border-0 mb-2">
                        <div class="post-image-card position-relative bg-black">
                          <div class="overflow-hidden">
                            <img
                              class="card-img-top opblogdetailacity-75"
                              src={item.image}
                              style={{ width: "100%", height: "250px" }}
                            />
                          </div>
                          <div className="innerHeading image-h ">
                            <h5 className="fw-bold">{item.title}</h5>
                          </div>
                        </div>
                        <Card.Body>
                          <a
                            className="rounded-5 px-4 blogsection"
                            variant="primary"
                            href={`${config.baseurl}blog_detail/${item.id}`}
                          >
                            CONTINUE READING
                          </a>
                        </Card.Body>
                      </Card>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </Col>
          </Row>
        </Container>
      </section>
      </div>
      )}
    </>
  );
};

export default Blog2;
