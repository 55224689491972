import React, { useState, useEffect } from "react";
import Header from "../generalPages/directives/header";
import Footer from "../generalPages/directives/footer";
import "./generalCSS/home.css";
import "./generalCSS/generalnavbar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Vortex } from "react-loader-spinner";

import { IoClose } from "react-icons/io5";
import {
  getPropertyAllDetaillistAction,
  getVehicleAllDetailListAction,
  getTopDestinationAction,
  getLocationByMoodAction,
  getLocationByMoodPropertiesAction,
  getMoreSuggestionAction,
  addWishlistAction,
  removeFromWishlistAction,
  getWeeklyPropertyAction,
  getHomePageDetailsAction,
} from "../Action/user.action";
import {
  Container,
  Row,
  Col,
  InputGroup,
  Button,
  Form,
  Dropdown,
  Tab,
  Nav,
  Card,
  Toast,
} from "react-bootstrap";
import { ShimmerThumbnail } from "react-shimmer-effects";
import DatePicker, { DateObject } from "react-multi-date-picker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import config from "../coreFIles/config";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import Cookies from "js-cookie";
import toast, { Toaster } from "react-hot-toast";
const loginData = !Cookies.get("loginSuccessPropertyOwner")
  ? []
  : JSON.parse(Cookies.get("loginSuccessPropertyOwner"));

const Home = () => {
  const navigate = useNavigate();
  const [guestsCount, setguestCount] = useState({
    adults: 0,
    children: 0,
    infants: 0,
    pets: 0,
  });
  const [allListing, setallListing] = useState([]);
  const [propertyPurchaseTypeId, setpropertyPurchaseTypeId] = useState(2); //1 = Sell ,2=Rent , 3=Lease,4=vehicle
  const [vehicleList, setvehicleList] = useState([]);
  const [homepage, sethomepage] = useState([]);
  const [AllDestinationData, setAllDestinationData] = useState([]);
  const [searchDestinationData, setsearchDestinationData] = useState([]);
  const [locationText, setlocationText] = useState("");
  const [searchEnable, setsearchEnable] = useState(true);
  const [searchIcon, setSearchIcon] = useState(false);
  const [imageloading, setImageLoading] = useState(true);
  // const [checkInDate, setCheckInDate] = useState(moment().toDate());
  // const [checkOutDate, setCheckOutDate] = useState(moment().toDate());
  const [checkInDate, setCheckInDate] = useState(new DateObject());
  const [checkOutDate, setCheckOutDate] = useState(
    new DateObject().add(1, "days")
  );
  const [vehiclecheckInDate, setvehicleCheckInDate] = useState();
  const [vehiclecheckOutDate, setvehicleCheckOutDate] = useState();
  const [topDestination, setTopDestination] = useState([]);
  const [someMoreSuggestion, setSomeMoreSuggestion] = useState([]);
  const [locationByMood, setLocationByMood] = useState([]);
  const [propertylocationByMood, setPropertyLocationByMood] = useState([]);
  const [isActiveStep, setIsActiveStep] = useState(0);
  const [isActiveStepId, setIsActiveStepId] = useState(0);
  const [weeklyProperty, setWeeklyProperty] = useState([]);
  const [loading, setLoading] = useState(false);
  const [bookingDates, setBookingDates] = useState({
    startDate: "",
    endDate: "",
  });
  const [values, setValues] = useState([new DateObject(), new DateObject()]);
  useEffect(() => {
    getListingDetails(2);
    getVehicleList();
    getTopDestinationAPI();
    getLocationByMoodAPI();
    getMoreSuggestionAPI();
    getWeeklyProperty();
    clearCacheData();
    getHomePage();
    setTimeout(() => {
      setImageLoading(false);
    }, 1000);
  }, []);

  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };

  const getVehicleList = async () => {
    let res = await getVehicleAllDetailListAction();
    if (res.success) {
      setvehicleList(res.data);
    }
  };
  const getHomePage = async () => {
    setLoading(true);
    let res = await getHomePageDetailsAction();
    if (res.success) {
      setLoading(false);
      sethomepage(res.data[0]);
    } else {
      setLoading(false);
    }
  };
  const getWeeklyProperty = async () => {
    let res = await getWeeklyPropertyAction();
    if (res.success) {
      setWeeklyProperty(res.data[0]);
    } else {
      setWeeklyProperty([]);
    }
  };

  const changeTab = async (id, index) => {
    getLocationByMoodPropertiesAPI(id);
    setIsActiveStepId(id);
    setIsActiveStep(index == 0 ? 0 : id);
  };

  const getTopDestinationAPI = async () => {
    let res = await getTopDestinationAction({ id: 0 });
    if (res.success) {
      setTopDestination(res.data);
    }
  };

  const getMoreSuggestionAPI = async () => {
    let res = await getMoreSuggestionAction({ id: 0 });
    if (res.success) {
      setSomeMoreSuggestion(res.data);
    }
  };

  const getLocationByMoodAPI = async () => {
    try {
      let res = await getLocationByMoodAction();
      if (res.success) {
        getLocationByMoodPropertiesAPI(res.data[0].id);
        setIsActiveStepId(res.data[0].id);
        let filteredData = res.data.filter((item) => item.status === 1);
        setLocationByMood(filteredData);
      }
    } catch (error) {
      // Handle the error (e.g., log it or show an error message)
      console.error("Error fetching Property More  details:", error);
    }
  };

  const getLocationByMoodPropertiesAPI = async (id) => {
    let res = await getLocationByMoodPropertiesAction({
      id: id,
      user_id: loginData?.id ? loginData?.id : 0,
    });
    if (res.success) {
      setPropertyLocationByMood(res.data);
    } else {
      setPropertyLocationByMood([]);
    }
  };

  const getListingDetails = async (propertyPurchaseTypeIds) => {
    let res = await getPropertyAllDetaillistAction({
      propertyPurchaseTypeId: propertyPurchaseTypeIds,
      checkInDate: checkInDate ? checkInDate : new DateObject(),
      checkOutDate: new DateObject().add(1, "day"),
    });
    if (res.success) {
      // setlisting(res.data);
      setallListing(res.data);
    }
  };

  const selectDestination = (e, value) => {
    const filter = AllDestinationData.filter((item) =>
      item.location.toUpperCase().match(value.toUpperCase())
    );
    setsearchEnable(false);
    setlocationText(value);
    setAllDestinationData([]);
    setsearchDestinationData([]);
  };

  const searchHeader = (e) => {
    if (
      checkInDate &&
      checkOutDate &&
      checkInDate.format() !== checkOutDate.format()
    ) {
      // window.location.href = `${config.baseUrl}propertylist`
      window.open(
        `${config.baseUrl}propertylist/${
          locationText.length > 0 ? locationText : "Search"
        }/${guestsCount.adults}/${moment(checkInDate.format()).format(
          "YYYY-MM-DD"
        )}/${moment(checkOutDate.format()).format("YYYY-MM-DD")}/${
          guestsCount.children
        }/${guestsCount.infants}/${guestsCount.pets}/${propertyPurchaseTypeId}`
      );
      // navigate(`${config.baseUrl}propertylist/${locationText.length > 0 ? locationText : 'Search'}/${guestsCount.adults}/${moment(checkInDate.format()).format('YYYY-MM-DD')}/${moment(checkOutDate.format()).format('YYYY-MM-DD')}/${guestsCount.children}/${guestsCount.infants}/${guestsCount.pets}`)
    } else if (
      checkInDate &&
      checkOutDate &&
      checkInDate.format() == checkOutDate.format()
    ) {
      toast.error("Check-In Date and Check-out Date Cannot be same");
      return;
      // window.location.href = `${config.baseUrl}propertylist`
      window.open(
        `${config.baseUrl}propertylist/${
          locationText.length > 0 ? locationText : "Search"
        }/${guestsCount.adults}/${moment(checkInDate.format()).format(
          "YYYY-MM-DD"
        )}/${moment(checkOutDate.format())
          .add(1, "days")
          .format("YYYY-MM-DD")}/${guestsCount.children}/${
          guestsCount.infants
        }/${guestsCount.pets}/${propertyPurchaseTypeId}`
      );
      // navigate(`${config.baseUrl}propertylist/${locationText.length > 0 ? locationText : 'Search'}/${guestsCount.adults}/${moment(checkInDate.format()).format('YYYY-MM-DD')}/${moment(checkOutDate.format()).format('YYYY-MM-DD')}/${guestsCount.children}/${guestsCount.infants}/${guestsCount.pets}`)
    } else {
      window.open(
        `${config.baseUrl}propertylist/${
          locationText.length > 0 ? locationText : "Search"
        }/${guestsCount.adults}/${moment().format("YYYY-MM-DD")}/${moment()
          .add(1, "days")
          .format("YYYY-MM-DD")}/${guestsCount.children}/${
          guestsCount.infants
        }/${guestsCount.pets}/${propertyPurchaseTypeId}`
      );
    }
  };

  const clickviewMore = (e) => {
    if (
      checkInDate &&
      checkOutDate &&
      moment(checkInDate?.format()) &&
      moment(checkOutDate?.format())
    ) {
      window.open(
        `${config.baseUrl}propertylist/${"Search"}/${
          guestsCount.adults
        }/${moment(checkInDate.format()).format("YYYY-MM-DD")}/${moment(
          checkOutDate.format()
        ).format("YYYY-MM-DD")}/${guestsCount.children}/${
          guestsCount.infants
        }/${guestsCount.pets}/${propertyPurchaseTypeId}`
      );
    } else {
      window.open(
        `${config.baseUrl}propertylist/${"Search"}/${
          guestsCount.adults
        }/${moment().format("YYYY-MM-DD")}/${moment()
          .add(1, "days")
          .format("YYYY-MM-DD")}/${guestsCount.children}/${
          guestsCount.infants
        }/${guestsCount.pets}/${propertyPurchaseTypeId}`
      );
    }
  };

  const searchDestHandler = (e) => {
    const { name, value } = e.target;
    setsearchEnable(true);
    setSearchIcon(true);

    if (e.target.value === "") {
      setSearchIcon(false);
    }
    let filter = [];
    let arr1 = [];
    if (guestsCount.adults > 0 && value && propertyPurchaseTypeId == 2) {
      filter = allListing.filter(
        (item) =>
          item.location.toUpperCase().match(value.toUpperCase()) &&
          parseFloat(item.numberOfBedrooms) >=
            parseFloat(guestsCount.adults / 2) &&
          item.propertyPurchaseTypeId == 2
      );
      arr1 = filter.filter(
        (v, i, a) =>
          a.findIndex(
            (v2) => v2.location.toUpperCase() === v.location.toUpperCase()
          ) === i
      );
    } else if (value && propertyPurchaseTypeId == 2) {
        
      filter = allListing.filter((item) =>
        item.location.toUpperCase().includes(value.toUpperCase())
      );
      arr1 = filter.filter(
        (v, i, a) =>
          a.findIndex(
            (v2) => v2.location.toUpperCase() === v.location.toUpperCase()
          ) === i
      );
    } else if (value && propertyPurchaseTypeId == 1) {
      filter = allListing.filter(
        (item) =>
          item.location.toUpperCase().match(value.toUpperCase()) &&
          item.propertyPurchaseTypeId == 1
      );
      arr1 = filter.filter(
        (v, i, a) =>
          a.findIndex(
            (v2) => v2.location.toUpperCase() === v.location.toUpperCase()
          ) === i
      );
    } else if (value && propertyPurchaseTypeId == 3) {
      filter = allListing.filter(
        (item) =>
          item.location.toUpperCase().match(value.toUpperCase()) &&
          item.propertyPurchaseTypeId == 3
      );
      arr1 = filter.filter(
        (v, i, a) =>
          a.findIndex(
            (v2) => v2.location.toUpperCase() === v.location.toUpperCase()
          ) === i
      );
    } else if (value && propertyPurchaseTypeId == 4) {
      filter = vehicleList.filter((item) =>
        item.location.toUpperCase().match(value.toUpperCase())
      );
      arr1 = filter.filter(
        (v, i, a) =>
          a.findIndex(
            (v2) => v2.location.toUpperCase() === v.location.toUpperCase()
          ) === i
      );
    }

    setlocationText(value);

    setAllDestinationData(filter); //set  duplicate locations data which is related to match
    setsearchDestinationData(arr1); //set only unic location data remove duplicate locations data
  };

  const loginMsg = async () => {
    toast.error("Please login first");
  };

  const addToWishList = async (id, type, status) => {
    let res =
      status == 1
        ? await addWishlistAction({ id: id, type: type })
        : await removeFromWishlistAction({ id: id, type: type });
    if (res.success) {
      toast.success(res.msg);
      getLocationByMoodPropertiesAPI(isActiveStepId);
    } else {
      toast.error(res.msg);
    }
  };

  const changeTabs = (value) => {
    setpropertyPurchaseTypeId(value);
    getListingDetails(value);
    setlocationText("");
    setAllDestinationData([]);
    setsearchDestinationData([]);
  };

  /*-------------------------------------------------------------Vehicle Module--------------------------------------------*/

  useEffect(() => {
    if (values[0].format()) {
      setBookingDates((old) => {
        return { ...old, ["startDate"]: values[0].format() };
      });

      setvehicleCheckInDate(values[0].format());
    }

    if (values.length > 1) {
      setBookingDates((old) => {
        return { ...old, ["endDate"]: values[1].format() };
      });
      setvehicleCheckOutDate(values[1].format());
      // var start = moment(values[0].format(), "YYYY-MM-DD");
      // var end = moment(values[1].format(), "YYYY-MM-DD");

      // //Difference in number of days
      // const dif = moment.duration(start.diff(end)).asDays();
      // settotalBookingDay(dif * -1);
    }
  }, [values]);

  const searchVehicleHeader = (e) => {
    if (vehiclecheckInDate && vehiclecheckOutDate) {
      // window.location.href = `${config.baseUrl}propertylist`
      window.open(
        `${config.baseUrl}propertylist/${
          locationText.length > 0 ? locationText : "Search"
        }/${guestsCount.adults}/${moment(vehiclecheckInDate).format(
          "YYYY-MM-DD"
        )}/${moment(vehiclecheckOutDate).format("YYYY-MM-DD")}/${
          guestsCount.children
        }/${guestsCount.infants}/${guestsCount.pets}/${propertyPurchaseTypeId}`
      );
      // navigate(`${config.baseUrl}propertylist/${locationText.length > 0 ? locationText : 'Search'}/${guestsCount.adults}/${moment(checkInDate.format()).format('YYYY-MM-DD')}/${moment(checkOutDate.format()).format('YYYY-MM-DD')}/${guestsCount.children}/${guestsCount.infants}/${guestsCount.pets}`)
    } else {
      window.open(
        `${config.baseUrl}propertylist/${
          locationText.length > 0 ? locationText : "Search"
        }/${guestsCount.adults}/${moment().format("YYYY-MM-DD")}/${moment()
          .add(1, "days")
          .format("YYYY-MM-DD")}/${guestsCount.children}/${
          guestsCount.infants
        }/${guestsCount.pets}/${propertyPurchaseTypeId}`
      );
    }
  };

  return (
    <>
      <Header />
      <Toaster />

      {imageloading ? (
        <div className="loader-container">
          <img
            src="assets/images/loader-home.gif"
            style={{ height: "100px", width: "100px" }}
          />
        </div>
      ) : (
        <div>
          <section
            className="banner-area banner-area-two banner-bg bannerHome d-flex justify-content-center align-items-center"
            style={{
              backgroundImage: `url(${
                homepage && homepage.image ? homepage.image : ""
              })`,
            }}
          >
            <Container>
              <Row className="d-flex justify-content-center align-items-center">
                <Col xl={9} lg={11} md={12}>
                  <div className="text-center">
                    <h1 className="text-white">
                      {homepage?.description !== null ||
                      homepage?.description !== " " ||
                      homepage?.description !== undefined ? (
                        <b
                          dangerouslySetInnerHTML={{
                            __html: homepage?.description,
                          }}
                        ></b>
                      ) : (
                        <b>
                          Unlock the door to <br /> unforgettable experiences.
                        </b>
                      )}
                    </h1>
                  </div>
                  <div className="banner-location bg-white rounded-5 mt-5 pr-0 position-relative mainBanner">
                    <Container>
                      <Row>
                        <Col lg={12}>
                          <div className="tab_style2">
                            <Tab.Container
                              id="left-tabs-example"
                              defaultActiveKey="booking"
                            >
                              <Row className="border-bottom align-items-end">
                                <Col lg={12} md={12} className="">
                                  <Nav
                                    variant="pills"
                                    className=" mt-2 mb-0 d-flex justify-content-evenly"
                                  >
                                    <Nav.Item onClick={(e) => changeTabs(2)}>
                                      <Nav.Link
                                        eventKey="booking"
                                        className="mb-0"
                                      >
                                        STAYS
                                      </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item onClick={(e) => changeTabs(1)}>
                                      <Nav.Link eventKey="buy" className="mb-0">
                                        BUY
                                      </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item onClick={(e) => changeTabs(3)}>
                                      <Nav.Link
                                        eventKey="lease"
                                        className="mb-0"
                                      >
                                        LEASE
                                      </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item onClick={(e) => changeTabs(4)}>
                                      <Nav.Link
                                        eventKey="vehicle"
                                        className="mb-0"
                                      >
                                        VEHICLE
                                      </Nav.Link>
                                    </Nav.Item>
                                  </Nav>
                                </Col>
                              </Row>

                              <Tab.Content>
                                <Tab.Pane eventKey="booking">
                                  <div className="list p-2">
                                    <Row>
                                      <Col lg={3} md={3} xs={12}>
                                        <InputGroup className="bannerInput">
                                          {/* {searchIcon === true ? '' : */}
                                          <Button
                                            variant="default"
                                            id="button-addon1"
                                            className="border-0"
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="24"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                            >
                                              <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M4.06176 7.66933C5.48796 4.24469 8.82961 2.01004 12.5393 2.00013C16.5375 1.97907 20.0926 4.54006 21.339 8.3391C22.5853 12.1381 21.2381 16.3074 18.0044 18.6588C14.7707 21.0103 10.3893 21.0068 7.15934 18.6501L4.27934 21.5301C3.98652 21.8226 3.51216 21.8226 3.21934 21.5301C2.92689 21.2373 2.92689 20.7629 3.21934 20.4701L6.00934 17.6801C3.40324 15.04 2.63556 11.094 4.06176 7.66933ZM5.39891 14.1691C6.59638 17.0547 9.41508 18.9342 12.5393 18.9301V18.8901C16.7796 18.8847 20.2219 15.4603 20.2493 11.2201C20.2534 8.09586 18.374 5.27717 15.4883 4.0797C12.6026 2.88223 9.27967 3.54208 7.07048 5.75127C4.86129 7.96046 4.20144 11.2834 5.39891 14.1691Z"
                                                fill="black"
                                              />
                                            </svg>
                                          </Button>

                                          <Form.Control
                                            aria-label="Example text with button addon"
                                            onChange={(e) =>
                                              searchDestHandler(e)
                                            }
                                            value={locationText}
                                            aria-describedby="basic-addon1"
                                            placeholder="Destination"
                                            type="search"
                                          />
                                          {/* {locationText !== "Search" &&
                                        locationText.length > 0 ? (
                                        <Button
                                          variant="default"
                                          id="button-addon1"
                                          className="border-0"
                                          onClick={(e) => setlocationText("")}
                                        >
                                          <IoClose fill="#838383" />
                                        </Button>
                                      ) : (
                                        ""
                                      )} */}
                                        </InputGroup>

                                        {searchDestinationData.length > 0 ? (
                                          <div className="search_destination rounded-5">
                                            <div className="list h-auto w-auto">
                                              <ul>
                                                {searchDestinationData.map(
                                                  (item) => {
                                                    return (
                                                      <>
                                                        <li
                                                          onClick={(e) =>
                                                            selectDestination(
                                                              e,
                                                              item.location
                                                            )
                                                          }
                                                          className="border-0"
                                                        >
                                                          <div>
                                                            <h6 className="mb-0">
                                                              {/* <b>{item.propertyName}</b> */}
                                                            </h6>
                                                            <p className="mb-0 text-grey">
                                                              {item.location}
                                                            </p>
                                                          </div>
                                                        </li>
                                                      </>
                                                    );
                                                  }
                                                )}
                                              </ul>
                                            </div>{" "}
                                          </div>
                                        ) : searchDestinationData.length == 0 &&
                                          locationText.length > 0 &&
                                          searchEnable == true ? (
                                          <div className="search_destination rounded-5">
                                            <div className="list h-auto text-center">
                                              <ul>
                                                <li className="border-0">
                                                  <div>
                                                    <h6 className="mb-0">
                                                      {/* <b>{item.propertyName}</b> */}
                                                    </h6>
                                                    <p className="mb-0 text-grey">
                                                      No Data Found
                                                    </p>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>{" "}
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </Col>
                                      <Col lg={9} md={9} xs={12}>
                                        <Row>
                                          <Col lg={4} md={4} xs={6}>
                                            <InputGroup className="bannerInput">
                                              <Button
                                                variant="default"
                                                id="button-addon1"
                                              >
                                                <img src="assets/images/icon/calender.svg" />
                                              </Button>
                                            </InputGroup>
                                            <DatePicker
                                              // placeholder="Check-in"
                                              // value={checkInDate}
                                              // onChange={setCheckInDate}
                                              // minDate={moment().toDate()}
                                              // numberOfMonths={2}
                                              // // selected={checkInDate}
                                              // selected={checkInDate || moment()}
                                              // selectsStart
                                              // startDate={checkInDate}
                                              // endDate={checkOutDate}
                                              // // value={checkInDate ?checkInDate : }
                                              // value={checkInDate ? checkInDate.format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')}
                                              // onChange={(date) =>
                                              //   setCheckInDate(date)
                                              // }
                                              placeholder="Check-in"
                                              value={checkInDate || moment()}
                                              minDate={moment().toDate()}
                                              onChange={setCheckInDate}
                                              numberOfMonths={2}
                                            />
                                          </Col>
                                          <Col lg={4} md={4} xs={6}>
                                            <InputGroup className="bannerInput">
                                              <Button
                                                variant="default"
                                                id="button-addon1"
                                              >
                                                <img src="assets/images/icon/calender.svg" />
                                              </Button>

                                              <DatePicker
                                                placeholder="Check-out"
                                                selected={checkOutDate}
                                                value={checkOutDate}
                                                selectsEnd
                                                startDate={checkInDate}
                                                endDate={checkOutDate}
                                                minDate={checkInDate}
                                                onChange={setCheckOutDate}
                                                numberOfMonths={2}
                                              />
                                            </InputGroup>
                                          </Col>
                                          <Col lg={4} md={4}>
                                            <div className="d-flex justify-content-between ">
                                              <div className="guest_dropdown">
                                                <Dropdown>
                                                  <Dropdown.Toggle
                                                    variant="default"
                                                    id="dropdown-basic"
                                                    className="border-0"
                                                  >
                                                    <div className="d-flex align-items-center">
                                                      <div>
                                                        <img src="assets/images/icon/user.svg" />
                                                      </div>
                                                      <div
                                                        className={
                                                          guestsCount.adults > 0
                                                            ? "add_guest text-left"
                                                            : "add_guest1 text-left"
                                                        }
                                                      >
                                                        <span className="">
                                                          {parseInt(
                                                            guestsCount.adults
                                                          ) == 0
                                                            ? "Add Guests"
                                                            : "Guests"}
                                                          &nbsp;
                                                          {parseInt(
                                                            guestsCount.adults
                                                          ) > 0
                                                            ? parseInt(
                                                                guestsCount.adults
                                                              )
                                                            : ""}
                                                          {parseInt(
                                                            guestsCount.adults
                                                          ) > 0 &&
                                                          parseInt(
                                                            guestsCount.infants
                                                          ) +
                                                            parseInt(
                                                              guestsCount.children
                                                            ) +
                                                            parseInt(
                                                              guestsCount.pets
                                                            ) >
                                                            0
                                                            ? " "
                                                            : ""}
                                                          <div
                                                            className={
                                                              parseInt(
                                                                guestsCount.infants
                                                              ) +
                                                                parseInt(
                                                                  guestsCount.children
                                                                ) +
                                                                parseInt(
                                                                  guestsCount.pets
                                                                ) ==
                                                              0
                                                                ? "d-none"
                                                                : ""
                                                            }
                                                          >
                                                            {parseInt(
                                                              guestsCount.infants
                                                            ) +
                                                              parseInt(
                                                                guestsCount.children
                                                              ) +
                                                              parseInt(
                                                                guestsCount.pets
                                                              ) ==
                                                            0
                                                              ? ""
                                                              : "Others"}
                                                            &nbsp;
                                                            {parseInt(
                                                              parseInt(
                                                                guestsCount.infants
                                                              ) +
                                                                parseInt(
                                                                  guestsCount.children
                                                                ) +
                                                                parseInt(
                                                                  guestsCount.pets
                                                                )
                                                            ) > 0
                                                              ? parseInt(
                                                                  parseInt(
                                                                    guestsCount.infants
                                                                  ) +
                                                                    parseInt(
                                                                      guestsCount.children
                                                                    ) +
                                                                    parseInt(
                                                                      guestsCount.pets
                                                                    )
                                                                )
                                                              : ""}{" "}
                                                          </div>
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </Dropdown.Toggle>

                                                  <Dropdown.Menu>
                                                    <div className="px-2">
                                                      <div className="d-flex justify-content-between align-items-center">
                                                        <div>Adults</div>
                                                        <div>
                                                          <div class="input-group">
                                                            <span class="input-group-btn">
                                                              <button
                                                                name="adults"
                                                                onClick={(e) =>
                                                                  setguestCount(
                                                                    (old) => {
                                                                      return {
                                                                        ...old,
                                                                        ["adults"]:
                                                                          parseInt(
                                                                            guestsCount.adults
                                                                          ) -
                                                                            1 <
                                                                          0
                                                                            ? 0
                                                                            : parseInt(
                                                                                guestsCount.adults
                                                                              ) -
                                                                              1,
                                                                      };
                                                                    }
                                                                  )
                                                                }
                                                                type="button"
                                                                class="btn   btn-sm btn-number rounded-5 border border-secondary"
                                                              >
                                                                <span class="">
                                                                  <FontAwesomeIcon
                                                                    icon={
                                                                      faMinus
                                                                    }
                                                                  />
                                                                </span>
                                                              </button>
                                                            </span>
                                                            <input
                                                              type="text"
                                                              name="guestQuantity"
                                                              class="form-control  input-number border-0"
                                                              min="1"
                                                              max="10"
                                                              value={
                                                                guestsCount.adults
                                                              }
                                                            />
                                                            <span class="input-group-btn">
                                                              <button
                                                                onClick={(e) =>
                                                                  setguestCount(
                                                                    (old) => {
                                                                      return {
                                                                        ...old,
                                                                        ["adults"]:
                                                                          parseInt(
                                                                            guestsCount.adults
                                                                          ) + 1,
                                                                      };
                                                                    }
                                                                  )
                                                                }
                                                                type="button"
                                                                class="btn  btn-sm btn-number rounded-5 border border-secondary"
                                                              >
                                                                <span class="">
                                                                  <FontAwesomeIcon
                                                                    icon={
                                                                      faPlus
                                                                    }
                                                                  />
                                                                </span>
                                                              </button>
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="d-flex justify-content-between align-items-center">
                                                        <div>Children</div>
                                                        <div>
                                                          <div class="input-group">
                                                            <span class="input-group-btn">
                                                              <button
                                                                type="button"
                                                                name="children"
                                                                onClick={(e) =>
                                                                  setguestCount(
                                                                    (old) => {
                                                                      return {
                                                                        ...old,
                                                                        ["children"]:
                                                                          parseInt(
                                                                            guestsCount.children
                                                                          ) -
                                                                            1 <
                                                                          0
                                                                            ? 0
                                                                            : parseInt(
                                                                                guestsCount.children
                                                                              ) -
                                                                              1,
                                                                      };
                                                                    }
                                                                  )
                                                                }
                                                                class="btn   btn-sm btn-number rounded-5 border border-secondary"
                                                                data-type="plus"
                                                                data-field="quant[1]"
                                                              >
                                                                <span class="">
                                                                  <FontAwesomeIcon
                                                                    icon={
                                                                      faMinus
                                                                    }
                                                                  />
                                                                </span>
                                                              </button>
                                                            </span>
                                                            <input
                                                              type="text"
                                                              name="guestQuantity"
                                                              class="form-control  input-number border-0"
                                                              min="1"
                                                              max="10"
                                                              value={
                                                                guestsCount.children
                                                              }
                                                            />
                                                            <span class="input-group-btn">
                                                              <button
                                                                type="button"
                                                                name="children"
                                                                onClick={(e) =>
                                                                  setguestCount(
                                                                    (old) => {
                                                                      return {
                                                                        ...old,
                                                                        ["children"]:
                                                                          parseInt(
                                                                            guestsCount.children
                                                                          ) +
                                                                            1 <
                                                                          0
                                                                            ? 0
                                                                            : parseInt(
                                                                                guestsCount.children
                                                                              ) +
                                                                              1,
                                                                      };
                                                                    }
                                                                  )
                                                                }
                                                                class="btn  btn-sm btn-number rounded-5 border border-secondary"
                                                                data-type="minus"
                                                                data-field="quant[1]"
                                                              >
                                                                <span class="">
                                                                  <FontAwesomeIcon
                                                                    icon={
                                                                      faPlus
                                                                    }
                                                                  />
                                                                </span>
                                                              </button>
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="d-flex justify-content-between align-items-center">
                                                        <div>Infants</div>
                                                        <div>
                                                          <div class="input-group">
                                                            <span class="input-group-btn">
                                                              <button
                                                                name="infants"
                                                                onClick={(e) =>
                                                                  setguestCount(
                                                                    (old) => {
                                                                      return {
                                                                        ...old,
                                                                        ["infants"]:
                                                                          parseInt(
                                                                            guestsCount.infants
                                                                          ) -
                                                                            1 <
                                                                          0
                                                                            ? 0
                                                                            : parseInt(
                                                                                guestsCount.infants
                                                                              ) -
                                                                              1,
                                                                      };
                                                                    }
                                                                  )
                                                                }
                                                                type="button"
                                                                class="btn   btn-sm btn-number rounded-5 border border-secondary"
                                                                data-type="plus"
                                                                data-field="quant[1]"
                                                              >
                                                                <span class="">
                                                                  <FontAwesomeIcon
                                                                    icon={
                                                                      faMinus
                                                                    }
                                                                  />
                                                                </span>
                                                              </button>
                                                            </span>
                                                            <input
                                                              type="text"
                                                              name="guestQuantity"
                                                              class="form-control  input-number border-0"
                                                              min="1"
                                                              max="10"
                                                              value={
                                                                guestsCount.infants
                                                              }
                                                            />
                                                            <span class="input-group-btn">
                                                              <button
                                                                name="infants"
                                                                onClick={(e) =>
                                                                  setguestCount(
                                                                    (old) => {
                                                                      return {
                                                                        ...old,
                                                                        ["infants"]:
                                                                          parseInt(
                                                                            guestsCount.infants
                                                                          ) + 1,
                                                                      };
                                                                    }
                                                                  )
                                                                }
                                                                type="button"
                                                                class="btn  btn-sm btn-number rounded-5 border border-secondary"
                                                                data-type="minus"
                                                                data-field="quant[1]"
                                                              >
                                                                <span class="">
                                                                  <FontAwesomeIcon
                                                                    icon={
                                                                      faPlus
                                                                    }
                                                                  />
                                                                </span>
                                                              </button>
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="d-flex justify-content-between align-items-center">
                                                        <div>Pets</div>
                                                        <div>
                                                          <div class="input-group">
                                                            <span class="input-group-btn">
                                                              <button
                                                                type="button"
                                                                name="pets"
                                                                onClick={(e) =>
                                                                  setguestCount(
                                                                    (old) => {
                                                                      return {
                                                                        ...old,
                                                                        ["pets"]:
                                                                          parseInt(
                                                                            guestsCount.pets
                                                                          ) -
                                                                            1 <
                                                                          0
                                                                            ? 0
                                                                            : parseInt(
                                                                                guestsCount.pets
                                                                              ) -
                                                                              1,
                                                                      };
                                                                    }
                                                                  )
                                                                }
                                                                class="btn   btn-sm btn-number rounded-5 border border-secondary"
                                                                data-type="plus"
                                                                data-field="quant[1]"
                                                              >
                                                                <span class="">
                                                                  <FontAwesomeIcon
                                                                    icon={
                                                                      faMinus
                                                                    }
                                                                  />
                                                                </span>
                                                              </button>
                                                            </span>
                                                            <input
                                                              type="text"
                                                              name="guestQuantity"
                                                              class="form-control  input-number border-0"
                                                              min="1"
                                                              max="10"
                                                              value={
                                                                guestsCount.pets
                                                              }
                                                            />
                                                            <span class="input-group-btn">
                                                              <button
                                                                type="button"
                                                                class="btn  btn-sm btn-number rounded-5 border border-secondary"
                                                                data-type="minus"
                                                                name="pets"
                                                                onClick={(e) =>
                                                                  setguestCount(
                                                                    (old) => {
                                                                      return {
                                                                        ...old,
                                                                        ["pets"]:
                                                                          parseInt(
                                                                            guestsCount.pets
                                                                          ) + 1,
                                                                      };
                                                                    }
                                                                  )
                                                                }
                                                                data-field="quant[1]"
                                                              >
                                                                <span class="">
                                                                  <FontAwesomeIcon
                                                                    icon={
                                                                      faPlus
                                                                    }
                                                                  />
                                                                </span>
                                                              </button>
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </Dropdown.Menu>
                                                </Dropdown>
                                              </div>
                                              <div>
                                                <Button
                                                  onClick={(e) =>
                                                    searchHeader(e)
                                                  }
                                                  variant="primary"
                                                  className="rounded-5 position-absolute btnsearch"
                                                  style={{ right: "12px" }}
                                                >
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 25 25"
                                                    fill="none"
                                                  >
                                                    <circle
                                                      cx="10.8333"
                                                      cy="10.8333"
                                                      r="9.33333"
                                                      stroke="white"
                                                      stroke-width="2"
                                                      stroke-linecap="round"
                                                      stroke-linejoin="round"
                                                    />
                                                    <path
                                                      d="M17.25 17.7841L23.0833 23.6175"
                                                      stroke="white"
                                                      stroke-width="2"
                                                      stroke-linecap="round"
                                                      stroke-linejoin="round"
                                                    />
                                                  </svg>
                                                </Button>
                                              </div>
                                            </div>
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                  </div>
                                </Tab.Pane>

                                <Tab.Pane eventKey="buy">
                                  <div className="list p-2">
                                    <Row>
                                      <Col lg={10} md={10} xs={10}>
                                        <InputGroup className="bannerInput">
                                          <Button
                                            variant="default"
                                            id="button-addon1"
                                            className="border-0"
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="24"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                            >
                                              <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M4.06176 7.66933C5.48796 4.24469 8.82961 2.01004 12.5393 2.00013C16.5375 1.97907 20.0926 4.54006 21.339 8.3391C22.5853 12.1381 21.2381 16.3074 18.0044 18.6588C14.7707 21.0103 10.3893 21.0068 7.15934 18.6501L4.27934 21.5301C3.98652 21.8226 3.51216 21.8226 3.21934 21.5301C2.92689 21.2373 2.92689 20.7629 3.21934 20.4701L6.00934 17.6801C3.40324 15.04 2.63556 11.094 4.06176 7.66933ZM5.39891 14.1691C6.59638 17.0547 9.41508 18.9342 12.5393 18.9301V18.8901C16.7796 18.8847 20.2219 15.4603 20.2493 11.2201C20.2534 8.09586 18.374 5.27717 15.4883 4.0797C12.6026 2.88223 9.27967 3.54208 7.07048 5.75127C4.86129 7.96046 4.20144 11.2834 5.39891 14.1691Z"
                                                fill="black"
                                              />
                                            </svg>
                                          </Button>
                                          <Form.Control
                                            aria-label="Example text with button addon"
                                            onChange={(e) =>
                                              searchDestHandler(e)
                                            }
                                            value={locationText}
                                            aria-describedby="basic-addon1"
                                            placeholder="Destination"
                                            className="border-0"
                                            type="search"
                                          />
                                          {/* 
                                      {locationText !== "Search" &&
                                        locationText.length > 0 ? (
                                        <Button
                                          variant="default"
                                          id="button-addon1"
                                          className="border-0"
                                          onClick={(e) => setlocationText("")}
                                        >
                                          <IoClose fill="#838383" />
                                        </Button>
                                      ) : (
                                        ""
                                      )} */}
                                        </InputGroup>

                                        <div id="destination">
                                          {searchDestinationData.length > 0 ? (
                                            <div className="search_destination rounded-5">
                                              <div className="list h-auto w-auto">
                                                <ul>
                                                  {searchDestinationData.map(
                                                    (item) => {
                                                      return (
                                                        <>
                                                          <li
                                                            onClick={(e) =>
                                                              selectDestination(
                                                                e,
                                                                item.location
                                                              )
                                                            }
                                                            className="border-0"
                                                          >
                                                            <div>
                                                              <h6 className="mb-0">
                                                                {/* <b>{item.propertyName}</b> */}
                                                              </h6>
                                                              <p className="mb-0 text-grey">
                                                                {item.location}
                                                              </p>
                                                            </div>
                                                          </li>
                                                        </>
                                                      );
                                                    }
                                                  )}
                                                </ul>
                                              </div>{" "}
                                            </div>
                                          ) : searchDestinationData.length ==
                                              0 &&
                                            locationText.length > 0 &&
                                            searchEnable == true ? (
                                            <div className="search_destination rounded-5">
                                              <div className="list h-auto text-center">
                                                <ul>
                                                  <li className="border-0">
                                                    <div>
                                                      <h6 className="mb-0">
                                                        {/* <b>{item.propertyName}</b> */}
                                                      </h6>
                                                      <p className="mb-0 text-grey">
                                                        No Data Found
                                                      </p>
                                                    </div>
                                                  </li>
                                                </ul>
                                              </div>{" "}
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </Col>
                                      <Col lg={2} md={2} xs={2}>
                                        <div>
                                          <Button
                                            onClick={(e) => searchHeader(e)}
                                            variant="primary"
                                            className="rounded-5 position-absolute btnsearch"
                                            style={{ right: "12px" }}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="20"
                                              height="20"
                                              viewBox="0 0 25 25"
                                              fill="none"
                                            >
                                              <circle
                                                cx="10.8333"
                                                cy="10.8333"
                                                r="9.33333"
                                                stroke="white"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              />
                                              <path
                                                d="M17.25 17.7841L23.0833 23.6175"
                                                stroke="white"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              />
                                            </svg>
                                          </Button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </Tab.Pane>

                                <Tab.Pane eventKey="lease">
                                  <div className="list p-2">
                                    <Row>
                                      <Col lg={10} md={10} xs={10}>
                                        <InputGroup className="bannerInput">
                                          <Button
                                            variant="default"
                                            id="button-addon1"
                                            className="border-0"
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="24"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                            >
                                              <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M4.06176 7.66933C5.48796 4.24469 8.82961 2.01004 12.5393 2.00013C16.5375 1.97907 20.0926 4.54006 21.339 8.3391C22.5853 12.1381 21.2381 16.3074 18.0044 18.6588C14.7707 21.0103 10.3893 21.0068 7.15934 18.6501L4.27934 21.5301C3.98652 21.8226 3.51216 21.8226 3.21934 21.5301C2.92689 21.2373 2.92689 20.7629 3.21934 20.4701L6.00934 17.6801C3.40324 15.04 2.63556 11.094 4.06176 7.66933ZM5.39891 14.1691C6.59638 17.0547 9.41508 18.9342 12.5393 18.9301V18.8901C16.7796 18.8847 20.2219 15.4603 20.2493 11.2201C20.2534 8.09586 18.374 5.27717 15.4883 4.0797C12.6026 2.88223 9.27967 3.54208 7.07048 5.75127C4.86129 7.96046 4.20144 11.2834 5.39891 14.1691Z"
                                                fill="black"
                                              />
                                            </svg>
                                          </Button>
                                          <Form.Control
                                            aria-label="Example text with button addon"
                                            onChange={(e) =>
                                              searchDestHandler(e)
                                            }
                                            value={locationText}
                                            aria-describedby="basic-addon1"
                                            placeholder="Destination"
                                            type="search"
                                            className="border-0"
                                          />
                                          {/* {locationText !== "Search" &&
                                        locationText.length > 0 ? (
                                        <Button
                                          variant="default"
                                          id="button-addon1"
                                          className="border-0"
                                          onClick={(e) => setlocationText("")}
                                        >
                                          <IoClose fill="#838383" />
                                        </Button>
                                      ) : (
                                        ""
                                      )} */}
                                        </InputGroup>

                                        {searchDestinationData.length > 0 ? (
                                          <div className="search_destination rounded-5">
                                            <div className="list h-auto w-auto">
                                              <ul>
                                                {searchDestinationData.map(
                                                  (item) => {
                                                    return (
                                                      <>
                                                        <li
                                                          onClick={(e) =>
                                                            selectDestination(
                                                              e,
                                                              item.location
                                                            )
                                                          }
                                                          className="border-0"
                                                        >
                                                          <div>
                                                            <h6 className="mb-0">
                                                              {/* <b>{item.propertyName}</b> */}
                                                            </h6>
                                                            <p className="mb-0 text-grey">
                                                              {item.location}
                                                            </p>
                                                          </div>
                                                        </li>
                                                      </>
                                                    );
                                                  }
                                                )}
                                              </ul>
                                            </div>{" "}
                                          </div>
                                        ) : searchDestinationData.length == 0 &&
                                          locationText.length > 0 &&
                                          searchEnable == true ? (
                                          <div className="search_destination rounded-5">
                                            <div className="list h-auto text-center">
                                              <ul>
                                                <li className="border-0">
                                                  <div>
                                                    <h6 className="mb-0">
                                                      {/* <b>{item.propertyName}</b> */}
                                                    </h6>
                                                    <p className="mb-0 text-grey">
                                                      No Data Found
                                                    </p>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>{" "}
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </Col>
                                      <Col lg={2} md={2} xs={2}>
                                        <div>
                                          <Button
                                            onClick={(e) => searchHeader(e)}
                                            variant="primary"
                                            className="rounded-5 position-absolute btnsearch"
                                            style={{ right: "12px" }}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="20"
                                              height="20"
                                              viewBox="0 0 25 25"
                                              fill="none"
                                            >
                                              <circle
                                                cx="10.8333"
                                                cy="10.8333"
                                                r="9.33333"
                                                stroke="white"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              />
                                              <path
                                                d="M17.25 17.7841L23.0833 23.6175"
                                                stroke="white"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              />
                                            </svg>
                                          </Button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </Tab.Pane>

                                <Tab.Pane eventKey="vehicle">
                                  <div className="list p-2">
                                    <Row>
                                      <Col lg={4} md={4} xs={12}>
                                        <InputGroup className="bannerInput">
                                          <Button
                                            variant="default"
                                            id="button-addon1"
                                            className="border-0"
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="24"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                            >
                                              <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M4.06176 7.66933C5.48796 4.24469 8.82961 2.01004 12.5393 2.00013C16.5375 1.97907 20.0926 4.54006 21.339 8.3391C22.5853 12.1381 21.2381 16.3074 18.0044 18.6588C14.7707 21.0103 10.3893 21.0068 7.15934 18.6501L4.27934 21.5301C3.98652 21.8226 3.51216 21.8226 3.21934 21.5301C2.92689 21.2373 2.92689 20.7629 3.21934 20.4701L6.00934 17.6801C3.40324 15.04 2.63556 11.094 4.06176 7.66933ZM5.39891 14.1691C6.59638 17.0547 9.41508 18.9342 12.5393 18.9301V18.8901C16.7796 18.8847 20.2219 15.4603 20.2493 11.2201C20.2534 8.09586 18.374 5.27717 15.4883 4.0797C12.6026 2.88223 9.27967 3.54208 7.07048 5.75127C4.86129 7.96046 4.20144 11.2834 5.39891 14.1691Z"
                                                fill="black"
                                              />
                                            </svg>
                                          </Button>
                                          <Form.Control
                                            aria-label="Example text with button addon"
                                            onChange={(e) =>
                                              searchDestHandler(e)
                                            }
                                            value={locationText}
                                            aria-describedby="basic-addon1"
                                            placeholder="Destination"
                                            type="search"
                                          />
                                          {/* {locationText !== "Search" &&
                                        locationText.length > 0 ? (
                                        <Button
                                          variant="default"
                                          id="button-addon1"
                                          className="border-0"
                                          onClick={(e) => setlocationText("")}
                                        >
                                          <IoClose fill="#838383" />
                                        </Button>
                                      ) : (
                                        ""
                                      )} */}
                                        </InputGroup>

                                        {searchDestinationData.length > 0 ? (
                                          <div className="search_destination rounded-5">
                                            <div className="list h-auto w-auto">
                                              <ul>
                                                {searchDestinationData.map(
                                                  (item) => {
                                                    return (
                                                      <>
                                                        <li
                                                          onClick={(e) =>
                                                            selectDestination(
                                                              e,
                                                              item.location
                                                            )
                                                          }
                                                          className="border-0"
                                                        >
                                                          <div>
                                                            <h6 className="mb-0">
                                                              {/* <b>{item.propertyName}</b> */}
                                                            </h6>
                                                            <p className="mb-0 text-grey">
                                                              {item.location}
                                                            </p>
                                                          </div>
                                                        </li>
                                                      </>
                                                    );
                                                  }
                                                )}
                                              </ul>
                                            </div>{" "}
                                          </div>
                                        ) : searchDestinationData.length == 0 &&
                                          locationText.length > 0 &&
                                          searchEnable == true ? (
                                          <div className="search_destination rounded-5">
                                            <div className="list h-auto text-center">
                                              <ul>
                                                <li className="border-0">
                                                  <div>
                                                    <h6 className="mb-0">
                                                      {/* <b>{item.propertyName}</b> */}
                                                    </h6>
                                                    <p className="mb-0 text-grey">
                                                      No Data Found
                                                    </p>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>{" "}
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </Col>
                                      <Col lg={5} md={5} xs={12}>
                                        <Row>
                                          <Col
                                            lg={10}
                                            md={10}
                                            xs={9}
                                            className="input-border-none"
                                          >
                                            <InputGroup className="bannerInput">
                                              <Button
                                                variant="default"
                                                id="button-addon1"
                                              >
                                                <img src="assets/images/icon/calender.svg" />
                                              </Button>
                                            </InputGroup>
                                            {/* <DatePicker
                                          placeholder="Check-in"
                                          value={checkInDate}
                                          minDate={moment().toDate()}
                                          onChange={setCheckInDate}
                                          numberOfMonths={2}
                                        /> */}

                                            <DatePicker
                                              className="two_month_date"
                                              value={values}
                                              minDate={moment().toDate()}
                                              onChange={setValues}
                                              numberOfMonths={2}
                                              range
                                            />
                                          </Col>

                                          <Col lg={2} md={2} xs={3}>
                                            <div className="d-flex justify-content-between ">
                                              <div>
                                                <Button
                                                  onClick={(e) =>
                                                    searchVehicleHeader(e)
                                                  }
                                                  variant="primary"
                                                  className="rounded-5 position-absolute btnsearch"
                                                  style={{ right: "12px" }}
                                                >
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 25 25"
                                                    fill="none"
                                                  >
                                                    <circle
                                                      cx="10.8333"
                                                      cy="10.8333"
                                                      r="9.33333"
                                                      stroke="white"
                                                      stroke-width="2"
                                                      stroke-linecap="round"
                                                      stroke-linejoin="round"
                                                    />
                                                    <path
                                                      d="M17.25 17.7841L23.0833 23.6175"
                                                      stroke="white"
                                                      stroke-width="2"
                                                      stroke-linecap="round"
                                                      stroke-linejoin="round"
                                                    />
                                                  </svg>
                                                </Button>
                                              </div>
                                            </div>
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                  </div>
                                </Tab.Pane>
                              </Tab.Content>
                            </Tab.Container>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="location-area">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="text-center">
                    <h2 className="">
                      <b>Choose Your Location By Mood</b>
                    </h2>
                    <div class="section-title-line"> </div>
                  </div>
                </Col>
              </Row>
              <Row className="mt-5 tab-style1">
                <Col lg={12}>
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey={isActiveStep}
                  >
                    <Nav variant="pills" className="justify-content-center">
                      {locationByMood.map((data, i) => (
                        <Nav.Item>
                          <Nav.Link
                            onClick={() => changeTab(data.id, i)}
                            eventKey={i == 0 ? 0 : data.id}
                          >
                            {data.name}
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                    </Nav>
                    <br />

                    <Tab.Content>
                      <Tab.Pane eventKey={isActiveStep}>
                        <Row className="justify-content-center align-items-center missionflex">
                          {propertylocationByMood.length > 0 ? (
                            propertylocationByMood.map((data) => (
                              <Col lg={3} md={4} className="mb-4">
                                <div className="hotel-view position-relative">
                                  <div className="hotel-img overflow-hidden">
                                    <Link
                                      to={`${config.baseUrl}propertydetail?id=${data.id}&numberOfAdults=2&checkin=${moment().format("YYYY-MM-DD")}&checkout=${moment().add(1,"days").format("YYYY-MM-DD")}&numberOfChildren=0&numberOfInfants=0&numberOfPets=0&propertyPurchaseTypeId=${data.propertyPurchaseTypeId}&roomType=0`}
                                      target="_blank"
                                    >
                                      {!data.image ||
                                      data.image == null ||
                                      data.image == undefined ? (
                                        <img
                                          src="assets/images/hand-holding-house.jpg"
                                          style={{
                                            height: "170px",
                                            objectFit: "cover",
                                            objectPosition: "top center",
                                          }}
                                        />
                                      ) : (
                                        <img
                                          style={{
                                            height: "170px",
                                            objectFit: "cover",
                                          }}
                                          src={data.image}
                                        />
                                      )}
                                    </Link>
                                  </div>
                                  <span className="like">
                                    {loginData?.id ? (
                                      data.isWishList >= 1 ? (
                                        <svg
                                          onClick={(e) =>
                                            addToWishList(data.id, 1, 0)
                                          }
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="22"
                                          height="19"
                                          viewBox="0 0 22 19"
                                          fill="none"
                                        >
                                          <path
                                            d="M2.67587 11.7115C2.63949 11.6795 2.60746 11.6514 2.5796 11.6269V11.5344L2.43198 11.3878C1.20735 10.1713 0.5 8.55715 0.5 6.84979V6.64011C0.605106 3.33023 3.44217 0.608727 6.78607 0.608727C7.34205 0.608727 8.02744 0.796121 8.68457 1.14762C9.32278 1.48899 9.89783 1.96574 10.2814 2.51826C10.5791 3.20646 11.5587 3.19766 11.839 2.49186C12.1669 1.90463 12.7333 1.40534 13.3766 1.04896C14.0334 0.685115 14.7165 0.5 15.2139 0.5C18.6571 0.5 21.3947 3.21142 21.5 6.63986V6.84979C21.5 8.67408 20.7874 10.2768 19.5838 11.3728L19.4204 11.5215V11.6052C19.3844 11.6348 19.3445 11.6679 19.3013 11.7041C19.132 11.8459 18.9014 12.0432 18.6256 12.2815C18.0735 12.7584 17.3335 13.405 16.5264 14.1116C16.2678 14.338 16.0024 14.5705 15.734 14.8056C14.3264 16.0387 12.8366 17.3438 11.8273 18.2097C11.3566 18.5968 10.6434 18.5968 10.1727 18.2097C8.96965 17.1776 7.05737 15.5222 5.43102 14.1103C4.61714 13.4037 3.8752 12.7584 3.32999 12.283C3.05735 12.0453 2.83416 11.8503 2.67587 11.7115Z"
                                            fill="#FF0000"
                                            fill-opacity="0.7"
                                            stroke="white"
                                          />
                                        </svg>
                                      ) : (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          onClick={(e) =>
                                            addToWishList(data.id, 1, 1)
                                          }
                                          width="22"
                                          height="19"
                                          viewBox="0 0 22 19"
                                          fill="none"
                                        >
                                          <path
                                            d="M22 6.63233C21.8905 2.93562 18.9353 0 15.2139 0C14.0099 0 12.1493 0.869814 11.3831 2.28326C11.2736 2.60944 10.8358 2.60944 10.7264 2.28326C9.85075 0.978541 8.0995 0.108727 6.78607 0.108727C3.17413 0.108727 0.109453 3.04435 0 6.63233V6.84979C0 8.69814 0.766169 10.4378 2.0796 11.7425C2.0796 11.7425 2.0796 11.7425 2.0796 11.8512C2.18905 11.9599 7.44279 16.5265 9.85075 18.5923C10.5075 19.1359 11.4925 19.1359 12.1493 18.5923C14.5572 16.5265 19.7015 11.9599 19.9204 11.8512C19.9204 11.8512 19.9204 11.8512 19.9204 11.7425C21.2338 10.5465 22 8.80687 22 6.84979V6.63233Z"
                                            fill="white"
                                            fill-opacity="0.7"
                                          />
                                        </svg>
                                      )
                                    ) : (
                                      <img
                                        onClick={loginMsg}
                                        style={{ width: 30, height: 30 }}
                                        src="assets/images/icon/heart.svg"
                                      />
                                    )}
                                  </span>
                                  <div className="hotel-view-content mt-2">
                                    <p className="mb-0">
                                      <b>{data.propertyName}</b>
                                    </p>
                                    <p className="text-grey mb-0">
                                      {data?.location?.length > 30
                                        ? `${data?.location.slice(0, 30)}...`
                                        : data?.location}
                                    </p>
                                  </div>
                                </div>
                              </Col>
                            ))
                          ) : (
                            <Col lg={12} className="mb-5">
                              <Card className="py-5 border-0 text-center">
                                <div>
                                  <img
                                    width="150"
                                    src="assets/images/listing_img.svg"
                                  />
                                  <h5 className="fs-6 mb-0 mt-3">
                                    No Data Found!
                                  </h5>
                                </div>
                              </Card>
                            </Col>
                          )}
                        </Row>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </Col>
              </Row>
              <Row className="text-center">
                <div>
                  <Button
                    variant="outline-primary"
                    className="viewmore rounded-5 px-4 "
                    onClick={clickviewMore}
                  >
                    View More
                  </Button>
                </div>
              </Row>
            </Container>
          </section>
          <section className="destination-area">
            <Container>
              <Row>
                <Col lg={12} className="mb-5">
                  <div className="text-center">
                    <h2 className="">
                      <b>Top Destinations</b>
                    </h2>
                    <div class="section-title-line"> </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={6} className="mb-4">
                  <Link
                    to={`${config.baseUrl}destinationDetails/${
                      topDestination[0]?.id ? topDestination[0]?.id : ""
                    }`}
                  >
                    <div className="boximage overflow-hidden">
                      {topDestination[0]?.image ? (
                        <img src={topDestination[0]?.image} width="100%" />
                      ) : (
                        ""
                        // <img src="assets/images/georgetown.svg" width="100%" />
                      )}
                      <div className="overlay"></div>
                      <h4 className="text-uppercase text-white text-center">
                        <b>
                          {topDestination[0]?.name
                            ? topDestination[0]?.name
                            : ""}
                        </b>
                      </h4>
                    </div>
                  </Link>
                </Col>

                <Col lg={6} md={6} className="mb-4">
                  <Link
                    to={`${config.baseUrl}destinationDetails/${
                      topDestination[1]?.id ? topDestination[1]?.id : ""
                    }`}
                  >
                    <div className="boximage overflow-hidden">
                      {topDestination[1]?.image ? (
                        <img src={topDestination[1]?.image} width="100%" />
                      ) : (
                        ""
                        // <img src="assets/images/liden.svg" width="100%" />
                      )}
                      <div className="overlay"></div>
                      <h4 className="text-uppercase text-white text-center">
                        <b>
                          {topDestination[1]?.name
                            ? topDestination[1]?.name
                            : ""}
                        </b>
                      </h4>
                    </div>
                  </Link>
                </Col>

                <Col lg={4} md={4} className="mb-3">
                  <Link
                    to={`${config.baseUrl}destinationDetails/${
                      topDestination[2]?.id ? topDestination[2]?.id : ""
                    }`}
                  >
                    <div className="boximage overflow-hidden">
                      {topDestination[2]?.image ? (
                        <img src={topDestination[2]?.image} width="100%" />
                      ) : (
                        // <img src="assets/images/newamsterdam.svg" width="100%" />
                        ""
                      )}
                      <div className="overlay"></div>
                      <h4 className="text-uppercase text-white text-center">
                        <b>
                          {topDestination[2]?.name
                            ? topDestination[2]?.name
                            : ""}
                        </b>
                      </h4>
                    </div>
                  </Link>
                </Col>

                <Col lg={4} md={4} className="mb-3">
                  <Link
                    to={`${config.baseUrl}destinationDetails/${
                      topDestination[3]?.id ? topDestination[3]?.id : ""
                    }`}
                  >
                    <div className="boximage overflow-hidden">
                      {topDestination[3]?.image ? (
                        <img src={topDestination[3]?.image} width="100%" />
                      ) : (
                        // <img src="assets/images/annaregina.svg" width="100%" />
                        ""
                      )}
                      <div className="overlay"></div>
                      <h4 className="text-uppercase text-white text-center">
                        <b>
                          {topDestination[3]?.name
                            ? topDestination[3]?.name
                            : ""}
                        </b>
                      </h4>
                    </div>
                  </Link>
                </Col>

                <Col lg={4} md={4} className="mb-3">
                  <Link
                    to={`${config.baseUrl}destinationDetails/${
                      topDestination[4]?.id ? topDestination[4]?.id : ""
                    }`}
                  >
                    <div className="boximage overflow-hidden">
                      {topDestination[4]?.image ? (
                        <img src={topDestination[4]?.image} width="100%" />
                      ) : (
                        // <img src="assets/images/bartica.svg" width="100%" />
                        ""
                      )}
                      <div className="overlay"></div>
                      <h4 className="text-uppercase text-white text-center">
                        <b>
                          {topDestination[4]?.name
                            ? topDestination[4]?.name
                            : ""}
                        </b>
                      </h4>
                    </div>
                  </Link>
                </Col>
              </Row>
            </Container>
          </section>

          {weeklyProperty?.propertyId === null ||
          weeklyProperty?.propertyId === undefined ||
          weeklyProperty?.propertyId === "" ? (
            ""
          ) : (

            <Link
              target="_blank"
              to={`${config.baseUrl}propertydetail?id=${weeklyProperty.propertyId}&numberOfAdults=2&checkin=${moment().format("YYYY-MM-DD")}&checkout=${moment().add(1, "days").format("YYYY-MM-DD")}&numberOfChildren=0&numberOfInfants=0&numberOfPets=0&propertyPurchaseTypeId=${weeklyProperty.propertyPurchaseTypeId}&roomType=0`}
              className="text-decoration-none "
            >
              <section
                className="property-area abc position-relative d-flex justify-content-center align-items-center flex-column"
                style={{
                  background:
                    weeklyProperty.fileName === "" ||
                    weeklyProperty.fileName === null ||
                    weeklyProperty.fileName === undefined
                      ? "url(assets/images/banner/property.svg) center center no-repeat , "
                      : `url(${weeklyProperty.fileName}) center center no-repeat`,
                }}
              >
                <div class="overlay"></div>
                <Container className="position-relative ">
                  <Row>
                    <Col lg={12}>
                      <div className="text-center">
                        <h2 className="text-white fw-bold">
                          Property Of The Week
                        </h2>
                      </div>
                    </Col>
                  </Row>

                  <Row className="bottom_text">
                    <Col lg={12}>
                      <div className="mb-0 text-white text-center">
                        <b>{weeklyProperty.location}</b>
                      </div>
                      <div className="text-white text-center">
                        <b>{weeklyProperty.propertyName}</b>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </section>
            </Link>
          )}

          <section className="suggestion-area">
            <Container>
              <Row>
                <Col lg={12} className="mb-5">
                  <div className="text-center">
                    <h2 className="">
                      <b>Some More Suggestions</b>
                    </h2>
                    <div class="section-title-line"> </div>
                  </div>
                </Col>
              </Row>
              <Row className="suggestionlist">
                <Col lg={12}>
                  <Row className="px-5 px-sm-0">
                    {someMoreSuggestion.length > 0 ? (
                      someMoreSuggestion.map((data) => (
                        <Col lg={6} md={12} className="mb-3">
                          <Card className="p-4 list">
                            <Row>
                              <Col lg={7} md={7}>
                                <h5 className="text-primary">{data.name}</h5>
                                <p
                                  className="mb-0"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      data.description.length > 100
                                        ? data.description.slice(0, 100) +
                                          "......."
                                        : data.description,
                                  }}
                                ></p>

                                <Link
                                  to={`${config.baseUrl}suggestionsDetails/${data.id}`}
                                >
                                  <button className="pl-0 btn btn-default">
                                    {" "}
                                    Learn More&nbsp;
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      viewBox="0 0 20 20"
                                      fill="none"
                                    >
                                      <path
                                        d="M10.4492 2.94922L17.4999 9.99994L10.4492 17.0507"
                                        stroke="black"
                                        stroke-width="1.4"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M17.4999 10L2.5 10"
                                        stroke="black"
                                        stroke-width="1.4"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                  </button>
                                </Link>
                              </Col>
                              <Col lg={5} md={5}>
                                <div className="text-center">
                                  {!data.image ||
                                  data.image == null ||
                                  data.image == undefined ? (
                                    <img
                                      src="assets/images/pet.svg"
                                      width="100%"
                                    />
                                  ) : (
                                    <img src={data.image} width="100%" />
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      ))
                    ) : (
                      <Col lg={12} className="mb-5">
                        <Card className="py-5 border-0 text-center">
                          <div>
                            <img
                              width="150"
                              src="assets/images/listing_img.svg"
                            />
                            <h5 className="fs-6 mb-0 mt-3">No Data Found!</h5>
                          </div>
                        </Card>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      )}
      <Footer />
    </>
  );
};

export default Home;
