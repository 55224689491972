import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import config from "../../config";
import { Formik } from "formik";
import "../generalCSS/userlogin.css";
import { SignupSchema } from "../../validations/signup";
import Select from "react-select";
import Config from "../../coreFIles/config";
import { createSvgIcon } from "react-social-login-buttons";
import { Container, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import {
  RegisterAction,
  SmsVerificationAction,
  getCountryCodeAction,
  googleLoginAction,
} from "../../Action/propertyOwner.action";
import { createButton } from "react-social-login-buttons";
import jwt_decode from "jwt-decode";
import { toast, Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import { LoginSocialFacebook, LoginSocialGoogle } from "reactjs-social-login";
import {
  FacebookLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";
const UserSignup = () => {
  const navigate = useNavigate();
  const [isStep, setIsStep] = useState(1);
  const [countryList, setCountryList] = useState([]);
  const [otp, setOtp] = useState(null);
  const [loginData, setLoginData] = useState(null);
  const [countryCode, setcountryCode] = useState({
    label: "+592",
    value: "592",
  });
  const [countryoptions, setcountryoptions] = useState([]);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    password: "",
    confirmPassword: "",
    tos: false,
    userType: "2",
    countryCode: countryCode.value,
  });

  // Use useRef to create a reference for each input field
  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const input4Ref = useRef(null);
  const input5Ref = useRef(null);
  const [fieldsDisabled, setFieldsDisabled] = useState(false);

  useEffect(() => {
    // input1Ref.current.focus();
    getCountryCodeAPI();
  }, []);

  // Function to move focus to the next input field
  const handleInput = (e, nextRef) => {
    // Move to the next input only if a digit is entered
    if (e.target.value.length === 1 && nextRef.current) {
      nextRef.current.focus();
    }
  };

  const responseGoogle = async (response) => {
    try {
      const res = await googleLoginAction({
        firstName: response.given_name,
        email: response.email,
        mobileNumber: null,
        registrationType: 2,
        isEmailVerify: 1,
        userType: 2,
      });

      if (res.success == true) {
        Cookies.set("loginSuccessPropertyOwner", JSON.stringify(res.data));
        setLoginData(res.data);
        setTimeout(() => {
          navigate(`${config.baseurl}`);
        }, 1000);
        toast.success(res.msg);
      }
    } catch (err) {
      toast.error(err.response);
    }
  };
  const responseFacebook = async (response) => {
    try {
      const res = await googleLoginAction({
        firstName: response.data.first_name,
        email: response.data.email,
        mobileNumber: null,
        registrationType: 3,
        isEmailVerify: 1,
        userType: 2,
      });

      if (res.success === true) {
        if (res.success === true) {
          Cookies.set("loginSuccessPropertyOwner", JSON.stringify(res.data));
          setLoginData(res.data);
          setTimeout(() => {
            navigate(`${config.baseurl}`);
          }, 1000);
          toast.success(res.msg);
        }
      }
    } catch (err) {
      toast.error(err.response);
    }
  };

  const handleSignup = async (values) => {
    const { mobileNumber, email } = values;
    const res = await SmsVerificationAction(countryCode.value, mobileNumber, email);
    setFormData(values);
    if (res.success) {
      toast.success(res.msg);
      setOtp(res.data);
      setIsStep(2);

      setTimeout(() => {
        input1Ref.current.focus();
      }, 200);
    } else {
      toast.error(res.msg);
    }
  };

  const resendOTP = async () => {
    const res = await SmsVerificationAction(countryCode.value, formData.mobileNumber);
    if (res.success) {
      toast.success(res.msg);
    } else {
      toast.error(res.msg);
    }
  };

  const handleRegistration = async (values) => {
    try {
      const enteredOTP =
        values.otp1 + values.otp2 + values.otp3 + values.otp4 + values.otp5;
      formData.otp = enteredOTP;
      formData.countryCode = countryCode.value;
      formData.userType = 2;
      let res = await RegisterAction(formData);
      if (res.success) {
        toast.success(res.msg);
        setTimeout(() => {
          navigate(`${config.baseurl}userlogin`);
        }, 2000);
      } else {
        toast.error(res.msg);
      }
    } catch (error) {
      toast.error("An error occurred during registration.");
    }
  };

  const getCountryCodeAPI = async () => {
    let res = await getCountryCodeAction();
    let arry = [];
    if (res.success) {
      for (let x in res.data) {
        let obj = {};
        obj.label = `+${res.data[x].phonecode}`;
        obj.value = res.data[x].phonecode;
        arry.push(obj);
      }
      setcountryoptions(arry);
    }
  };
  const SelectStyles = {
    option: (defaultStyles, state) => ({
      ...defaultStyles,
      color: state.isSelected ? "#fff" : "#000",
      backgroundColor: state.isSelected ? "#cf5416" : "#fff",
      minWidth: "63px",
    }),

    control: (defaultStyles, state) => ({
      ...defaultStyles,
      height: "20px",
      minHeight: "30px",
      borderColor: "#E9E9E9",
      minWidth: "63px",
      borderRadius: "0",
      backgroundColor: "#E9E9E9",
    }),
    singleValue: (defaultStyles) => ({
      ...defaultStyles,
      color: "#000",
      height: "30px",
      margin: "0px",
    }),
  };

  return (
    <>
      <section className="login-section pt-0 pb-0 overflow-hidden vh-80">
        <Toaster />
        {isStep == 1 ? (
          <Container fluid>
            <Formik
              initialValues={formData}
              validationSchema={SignupSchema}
              onSubmit={(values) => {
                handleSignup(values);
              }}
            >
              {({
                errors,
                handleSubmit,
                handleBlur,
                handleChange,
                touched,
                values,
              }) => (
                <Row className="justify-content-center ">
                  <Col xl={7} lg={7} className="p-0">
                    <img
                      src="assets/images/background/login2.jpg"
                      className="userbg"
                    />
                  </Col>
                  <Col xl={5} lg={5}>
                    <div className="login-container px-3 pt-5">
                      <div className="login-form">
                        <div className="login-logo">
                          <Link to={`${config.baseurl}`}>
                            <img
                              src="assets/images/logo/logo.webp"
                              alt="logo"
                            />
                          </Link>
                          <h3 className="pt-4 pb-4">
                            <b>CREATE AN ACCOUNT</b>
                          </h3>
                        </div>

                        <Form onSubmit={handleSubmit}>
                          <Row>
                            <Col xl={6} lg={6}>
                              <Form.Group
                                className="mb-3"
                                controlId="FIRST NAME*"
                              >
                                <Form.Control
                                  type="text"
                                  placeholder="FIRST NAME*"
                                  name="firstName"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.firstName}
                                />
                                <Form.Text
                                  id="passwordHelpBlock"
                                  className="text-danger"
                                >
                                  {errors.firstName && touched.firstName && (
                                    <div className="text-danger">
                                      {errors.firstName}
                                    </div>
                                  )}
                                </Form.Text>
                              </Form.Group>
                            </Col>
                            <Col xl={6} lg={6}>
                              <Form.Group
                                className="mb-3"
                                controlId="LAST NAME*"
                              >
                                <Form.Control
                                  type="text"
                                  placeholder="LAST NAME*"
                                  name="lastName"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.lastName}
                                />
                                <Form.Text
                                  id="passwordHelpBlock"
                                  className="text-danger"
                                >
                                  {errors.lastName && touched.lastName && (
                                    <div className="text-danger">
                                      {errors.lastName}
                                    </div>
                                  )}
                                </Form.Text>
                              </Form.Group>
                            </Col>
                            <Col xl={12} lg={12}>
                              <Form.Group className="mb-3" controlId="EMAIL">
                                <Form.Control
                                  type="email"
                                  placeholder="EMAIL"
                                  name="email"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.email}
                                />
                                <Form.Text
                                  id="passwordHelpBlock"
                                  className="text-danger"
                                >
                                  {errors.email && touched.email && (
                                    <div className="text-danger">
                                      {errors.email}
                                    </div>
                                  )}
                                </Form.Text>
                              </Form.Group>
                            </Col>
                            <Col xl={12} lg={12}>
                              <div className="mb-3">
                                <InputGroup className="reactSelect position-relative">
                                  <Select
                                    aria-label="countryCode"
                                    name="countryCode"
                                    onBlur={handleBlur}
                                    options={countryoptions}
                                    value={countryCode}
                                    onChange={setcountryCode}
                                    labelledBy="Country Code"
                                    styles={SelectStyles}
                                  />
                                  <Form.Control
                                    type="text"
                                    aria-label="Text input with dropdown button"
                                    onKeyPress={(event) => {
                                      if (
                                        !/^\d*[]?\d{0,1}$/.test(event.key)
                                      ) {
                                        event.preventDefault();
                                      }
                                    }}
                                    placeholder="PHONE NUMBER*"
                                    name="mobileNumber"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.mobileNumber}
                                  />
                                </InputGroup>

                                <Form.Text
                                  id="passwordHelpBlock"
                                  className="text-danger"

                                >
                                  {errors.mobileNumber &&
                                    touched.mobileNumber && (
                                      <div className="text-danger">
                                        {errors.mobileNumber}
                                      </div>
                                    )}
                                </Form.Text>
                              </div>
                            </Col>

                            <Col xl={6} lg={6}>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicPassword"
                              >
                                <Form.Control
                                  type="password"
                                  placeholder="PASSWORD"
                                  name="password"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.password}
                                />
                                <Form.Text
                                  id="passwordHelpBlock"
                                  className="text-danger"
                                >
                                  {errors.password && touched.password && (
                                    <div className="text-danger">
                                      {errors.password}
                                    </div>
                                  )}
                                </Form.Text>
                              </Form.Group>
                            </Col>
                            <Col xl={6} lg={6}>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicPassword"
                              >
                                <Form.Control
                                  type="password"
                                  placeholder="CONFIRM PASSWORD"
                                  name="confirmPassword"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.confirmPassword}
                                />
                                <Form.Text
                                  id="passwordHelpBlock"
                                  className="text-danger"
                                >
                                  {errors.confirmPassword &&
                                    touched.confirmPassword && (
                                      <div className="text-danger">
                                        {errors.confirmPassword}
                                      </div>
                                    )}
                                </Form.Text>
                              </Form.Group>
                            </Col>
                            <Col xl={12} lg={12}>
                              <Form.Group
                                className="mb-3 agreebox"
                                controlId="formBasicCheckbox"
                              >
                                <Form.Check
                                  type="checkbox"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  name="tos"
                                  value={values.tos}
                                />
                                <span className="ms-2">You agree to our <Link target="_blank" to={`${config.baseurl}termsConditions`}> terms & conditions </Link></span>
                                <Form.Text
                                  id="passwordHelpBlock"
                                  className="text-danger"
                                >
                                  {errors.tos && touched.tos && (
                                    <div className="text-danger">
                                      {errors.tos}
                                    </div>
                                  )}
                                </Form.Text>
                              </Form.Group>
                            </Col>
                            <Col xl={12} lg={12}>
                              <Button
                                variant="primary"
                                type="submit"
                                className="rounded-5 px-4 btn btn-primary w-100"
                              >
                                SIGN UP
                              </Button>
                            </Col>

                            <Col xl={12} lg={12} className="text-center">
                              <div className="social-button text-center mt-4">
                                {/* <GoogleOAuthProvider clientId="1018403538313-1u3n5j591qoqcq9pvt0fuvopd9irj0p0.apps.googleusercontent.com"> */}
                                <GoogleOAuthProvider clientId={Config.GOOGLE_CLIENT_ID}>
                                  <GoogleLogin
                                    onSuccess={(credentialResponse) => {
                                      const credentialResponseDecode =
                                        jwt_decode(
                                          credentialResponse.credential
                                        );
                                      console.log(credentialResponseDecode);
                                      responseGoogle(credentialResponseDecode);
                                    }}
                                    onError={() => {
                                      console.log("Login Failed");
                                    }}
                                  />
                                </GoogleOAuthProvider>

                                <LoginSocialFacebook
                                  appId={Config.REACT_APP_FB_APP_ID}
                                  onResolve={(response) => {
                                    console.log("response", response);
                                    setLoginData(response.data);
                                    responseFacebook(response);
                                  }}
                                  onReject={(error) => {
                                    console.log(error, "errt");
                                  }}
                                >
                                  <FacebookLoginButton>
                                    {" "}
                                    <span>Continue with facebook</span>{" "}
                                  </FacebookLoginButton>
                                </LoginSocialFacebook>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                        <div>
                          Already have an account?{" "}
                          <Link
                            to={`${config.baseurl}userlogin`}
                            className=" text-primary"
                          >
                            Login{" "}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
            </Formik>
          </Container>
        ) : (
          <Container fluid>
            <Row className="justify-content-center ">
              <Col xl={7} lg={7} className="p-0">

                <img
                  src="assets/images/background/login2.jpg"
                  className="userbg"
                />

              </Col>
              <Col xl={5} lg={5}>
                <div className="login-container px-3 pt-5">

                  <Button
                    style={{
                      marginBottom: "7px"
                    }}
                    variant="default"
                    className="border-1 rounded-1 border-secondary h-auto arrow_left" onClick={() => setIsStep(1)}
                  >
                    <img
                      src="assets/images/icon/right_arrow.svg"
                      width="7px"
                      style={{ marginTop: "-3px" }}
                    />{" "}
                  </Button>

                  <div className="login-form">
                    <div className="login-logo">
                      <Link to={`${config.baseurl}`}>
                        <img src="assets/images/logo/logo.webp" alt="logo" />
                      </Link>
                      <div className="pt-4 pb-4">
                        <h3 className="mb-3">
                          <b>VERIFY YOUR OTP</b>
                        </h3>
                        {/* <span>
                          {" "}
                          your otp is <b>{otp}</b>
                        </span> */}
                        <p>We have sent OTP on your mobile number</p>
                      </div>
                    </div>
                    <Formik
                      initialValues={{
                        otp1: "",
                        otp2: "",
                        otp3: "",
                        otp4: "",
                        otp5: "",
                      }}
                      onSubmit={(values) => {
                        handleRegistration(values);
                      }}
                    >
                      {({ handleChange, handleSubmit }) => (
                        <>
                          <Form onSubmit={handleSubmit}>
                            <Row>
                              <Col xl={12} lg={12}>
                                <Form.Group
                                  className="mb-4 d-flex m-verification"
                                  controlId="formBasicPassword"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="-"
                                    autoComplete="off"
                                    name="otp1"
                                    className="me-3"
                                    maxLength="1"
                                    pattern="[0-9]"
                                    ref={input1Ref}
                                    onInput={(e) => handleInput(e, input2Ref)}
                                    onChange={handleChange}
                                  />
                                  <Form.Control
                                    type="text"
                                    placeholder="-"
                                    autoComplete="off"
                                    name="otp2"
                                    className="me-3"
                                    ref={input2Ref}
                                    onInput={(e) => handleInput(e, input3Ref)}
                                    onChange={handleChange}
                                    maxLength="1"
                                    pattern="[0-9]"
                                  />
                                  <Form.Control
                                    type="text"
                                    placeholder="-"
                                    autoComplete="off"
                                    name="otp3"
                                    className="me-3"
                                    onChange={handleChange}
                                    ref={input3Ref}
                                    onInput={(e) => handleInput(e, input4Ref)}
                                    maxLength="1"
                                    pattern="[0-9]"
                                  />
                                  <Form.Control
                                    type="text"
                                    placeholder="-"
                                    autoComplete="off"
                                    name="otp4"
                                    className="me-3"
                                    ref={input4Ref}
                                    onInput={(e) => handleInput(e, input5Ref)}
                                    onChange={handleChange}
                                    maxLength="1"
                                    pattern="[0-9]"
                                  />
                                  <Form.Control
                                    type="text"
                                    placeholder="-"
                                    autoComplete="off"
                                    name="otp5"
                                    className=""
                                    ref={input5Ref}
                                    // onInput={(e) => handleInput(e, null)}
                                    onChange={handleChange}
                                    maxLength="1"
                                    pattern="[0-9]"
                                  />
                                </Form.Group>
                              </Col>

                              <Col xl={12} lg={12}>
                                <div className="text-center mt-4 pb-5">
                                  <span
                                    onClick={resendOTP}
                                    style={{ cursor: "pointer" }}
                                    className="text-decoration-none"
                                  >
                                    Didn’t receive code? <span style={{ color: "#E46422" }}>Send Again</span>
                                  </span>
                                </div>
                              </Col>

                              <Col xl={12} lg={12}>
                                <Button
                                  variant="primary"
                                  type="submit"
                                  className="w-100"
                                >
                                  VERIFY OTP
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        </>
                      )}
                    </Formik>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        )}
      </section>
    </>
  );
};

export default UserSignup;
