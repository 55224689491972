import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { FaAngleDown } from "react-icons/fa";

// eslint-disable-next-line react/prop-types
const CustomMaterialMenu = ({ row, onDeleteRow, size }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };
    const deleteRow = () => {
      if (onDeleteRow) {
        onDeleteRow(row);
      }
    };

    return (
      <div className="action-button">
        <IconButton

          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
          size={size}
          className="cbtn-red text-primary"
          style={{ fontSize: 13, color: "black" }}
        >
          Run Report <FaAngleDown className="ms-1" />
        </IconButton>
        <Menu
          id="menu"
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >

      
            <MenuItem
              className="recruiter-menu-item"
            >
              Shortlist
            </MenuItem>
          <MenuItem className="recruiter-menu-item">
            Interview Schedule
          </MenuItem>
            <MenuItem className="recruiter-menu-item">
              Hire
            </MenuItem>
        </Menu>
      </div>
    );
  };

  export default CustomMaterialMenu;