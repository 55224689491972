import React, { useState } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
  useLoadScript,
} from "@react-google-maps/api";
import Config from "../coreFIles/config";
function Maps({ selectedLocation }) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: Config.googleMapsApiKey,
  });

  const [selectedMarker, setSelectedMarker] = useState(null);

  const mapRef = React.useRef();

  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };

  const handleMarkerClose = () => {
    setSelectedMarker(null);
  };

  if (loadError) return "Error";
  if (!isLoaded) return "Maps";
  const generateGoogleMapsLink = (lat, lng) => {
    return `https://www.google.com/maps?q=${lat},${lng}`;
  };
  return (
    <div>
      <GoogleMap
        zoom={13}
        mapContainerStyle={{ height: "500px" }}
        center={selectedLocation}
        onLoad={onMapLoad}
      >
        <Marker
          position={selectedLocation}
          icon={"https://maps.gstatic.com/mapfiles/ms2/micons/red-dot.png"}
          onClick={() => handleMarkerClick(selectedLocation)}
        />

        {selectedMarker && (
          <InfoWindow
            position={selectedMarker}
            onCloseClick={handleMarkerClose}
            onClick={() => handleMarkerClick(selectedLocation)}
          >
            <div>
              <p>
                {" "}
                <b>{selectedLocation.location} </b>
              </p>

              <a
                href={generateGoogleMapsLink(
                  selectedMarker.lat,
                  selectedMarker.lng
                )}
                target="_blank"
                rel="noopener noreferrer"
              >
                View on Google Maps
              </a>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </div>
  );
}

export default Maps;
