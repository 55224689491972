import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button, Form, Table, Dropdown, Modal } from "react-bootstrap";
import DataTable, { createTheme } from 'react-data-table-component';
import { Link, useNavigate } from "react-router-dom";
import config from "../../../config";
import Header from "../../commanComponents/usernavbar"
import "../css/staffdashboard.css"
import "../css/finance.css"
import { getSupplierList, getCountryCodeAction, updateSupplier, addSupplier, getSupplierStatics, getExpenseCategoryList } from '../../../Action/propertyOwner.action'
import { GoPencil } from "react-icons/go";
import { toast, Toaster } from "react-hot-toast";
import Select from "react-select";
import ListMenuButton from "./VehicleListMenu";
import UserNameAndTab from "../Dashboard/Finance/UserNameAndTab";


const VehicleSupplier = () => {


    useEffect(() => {
        // Add the class to the body tag
        document.body.classList.add('staffbody');

        // Cleanup function to remove the class when the component is unmounted
        return () => {
            document.body.classList.remove('staffbody');
        };
    }, []);


    createTheme('solarized', {
        text: {
            primary: '#000',
            secondary: '#2aa198',
        },
        background: {
            default: '',
        },
        context: {
            background: '#cb4b16',
            text: '#FFFFFF',
        },
        divider: {
            default: '#EBEBEB',
        },
        action: {
            button: '#E46422',
            hover: 'rgba(0,0,0,.08)',
            disabled: 'rgba(0,0,0,.12)',
        },
    }, 'dark');

    const customStyles = {
        rows: {
            style: {
                minHeight: '70px', // override the row height

            },
        },
        headCells: {

            style: {
                minHeight: '60px',
                paddingLeft: '20px', // override the cell padding for head cells
                paddingRight: '20px',
                background: '#FFF8F4',

            },
        },
        cells: {
            style: {
                paddingLeft: '20px', // override the cell padding for data cells
                paddingRight: '20px',
            },
        },
    };

    const navigate = useNavigate();


    const [supplierList, setSupplierList] = useState([]);
    const [isModelOpen, setIsModelOpen] = useState(false);
    const [countryCode, setcountryCode] = useState({ label: "+592", value: "592" });
    const [countryoptions, setcountryoptions] = useState([]);
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [filterText, setFilterText] = useState("");
    const [allSupplierList, setAllSupplierList] = useState([]);
    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')
    const [statistics, setStatistics] = useState([])
    const [expenseCategory, setExpenseCategory] = useState([])



    useEffect(() => {
        fetchSupplierList();
        getCountryCodeAPI();
        fetchStatistics()
        fetchExpenseCategory()
    }, []);

    useEffect(() => {
        let filteredData = allSupplierList;
        if (fromDate && toDate) {
            filteredData = filteredData.filter(item => {
                const fromdate = new Date(fromDate);
                const todate = new Date(toDate);
                const dateTime = new Date(item.dateTime);
                return dateTime >= fromdate && dateTime <= todate
            });
        }

        if (filterText) {
            filteredData = filteredData.filter(item => {
                const lowerCaseFilterText = filterText.toLowerCase();
                return (
                    item.supplierName.toLowerCase().includes(lowerCaseFilterText) ||
                    item.companyName.toLowerCase().includes(lowerCaseFilterText)
                );
            });
        }

        setSupplierList(filteredData)
    }, [(fromDate && toDate), filterText]);

    const fetchExpenseCategory = async () => {
        let res = await getExpenseCategoryList({ type: 2 })
        if (res.success) {
            setExpenseCategory(res.data || [])
        }
    }

    const fetchSupplierList = async () => {
        let res = await getSupplierList({ supplierFor: 2 });
        if (res.success) {
            setSupplierList(res.data || []);
            setAllSupplierList(res.data || [])
        }
    }



    const fetchStatistics = async () => {
        let res = await getSupplierStatics({ supplierFor: 2 });
        if (res.success) {
            setStatistics(res.data || []);
        }
    }

    const getCountryCodeAPI = async () => {
        let arry = []
        let res = await getCountryCodeAction();
        if (res.success) {
            for (let x in res.data) {
                let obj = {}
                obj.label = `+${res.data[x].phonecode}`
                obj.value = res.data[x].phonecode
                arry.push(obj)
            }
            setcountryoptions(arry)
        }
    };


    const modelClose = () => {
        setIsModelOpen(false);
        setIsEdit(false)
        setFormData({})
    }

    const inputHandler = (e) => {
        const { name, value } = e.target;
        setFormData((old) => {
            return { ...old, [name]: value };
        });
        if (value.trim() !== "") {
            setErrors((oldErrors) => ({ ...oldErrors, [name]: "" }));
        }
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.supplierName) newErrors.supplierName = "Supplier name is required.";
        if (!formData.companyName) newErrors.companyName = "Company name is required.";
        if (!formData.email) newErrors.email = "Email is required.";
        if (!formData.mobileNumber) newErrors.mobileNumber = "Mobile number is required.";
        if (!formData.streetAddress) newErrors.streetAddress = "Address is required.";
        if (!formData.city) newErrors.city = "City is required.";
        if (!formData.postalCode) newErrors.postalCode = "Postal code is required.";
        if (!formData.country) newErrors.country = "Country is required.";
        // if (!formData.supplierFor) newErrors.supplierFor = "Select supplier";
        if (!formData.terms) newErrors.terms = "Term is required.";
        if (!formData.expenseCategoryId) newErrors.expenseCategoryId = "Expense Category is required.";
        if (!formData.openingBalance) newErrors.openingBalance = "Opening balance is required.";
        if (!formData.asOf) newErrors.asOf = "As of date is required.";


        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const columns = [
        {
            name: "#",
            selector: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: 'Supplier',
            selector: row => row.supplierName,
        },
        {
            name: 'Company',
            selector: row => row.companyName,
        },
        {
            name: 'Phone',
            selector: row => `+${row.countryCode} ${row.mobileNumber}`,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
        },
        {
            name: "Status",
            selector: (row) => {
                let statusColor;
                if (row.isBlock == "0") {
                    statusColor = "green";
                } else if (row.isBlock == "1") {
                    statusColor = "red";
                }
                return (
                    <div style={{ color: statusColor }}>{row.isBlock == 0 ? 'Active' : 'Blocked'}</div>
                );
            },
        },

        {
            name: 'Edit',
            selector: row => (
                <Button variant={`primary`}
                    className="btn-sm h-auto  rounded-5 " onClick={() => handleEdit(row)}>
                    <GoPencil />
                    &nbsp;Edit
                </Button>
            )
        },
        {
            name: 'Action',
            selector: row => (
              <>
                <div className="d-inline-block">
                    <span onClick={() => navigate(`${config.baseurl}vehiclemakepayment/${row.id}`)}>Make Payment</span>
                  <Dropdown>
                    <Dropdown.Toggle variant="primary" id="dropdown-basic" className="btn-sm h-auto rounded-5">
                
                    </Dropdown.Toggle>
        
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => navigate(`${config.baseurl}vehicleexpense`)}>
                        Create Expense
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => navigate(`${config.baseurl}vehiclecreatebill/${row.id}`)}>
                        Create Bill
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
          }
          

    ];




    const handleSubmit = async () => {
        if (!validateForm()) return;
        try {
            setIsLoading(true)
            let data = {
                ...formData,
                countryCode: countryCode.value,
                supplierFor: 2
            };

            if (isEdit) {
                let res = await updateSupplier(data);
                if (res.success) {
                    toast.success(res.msg);
                    fetchSupplierList();
                    modelClose();
                    setFormData({})
                } else {
                    toast.error(res.msg);
                }
                setIsLoading(false)
            } else {
                let res = await addSupplier(data);
                if (res.success) {
                    toast.success(res.msg);
                    fetchSupplierList();
                    modelClose();
                    setFormData({})
                } else {
                    toast.error(res.msg);
                }
                setIsLoading(false)
            }
        } catch (error) {
            toast.error('Something went wrong please try again after some time.');
        }
    };

    const handleEdit = (supplier) => {
        setFormData(supplier);
        setcountryCode({ label: `+${supplier.countryCode}`, value: supplier.countryCode });
        setIsEdit(true);
        setIsModelOpen(true);
    };


    // const filteredData = supplierList.filter(item => {
    //     const lowerCaseFilterText = filterText.toLowerCase();
    //     return (
    //         item.supplierName.toLowerCase().includes(lowerCaseFilterText) ||
    //         item.companyName.toLowerCase().includes(lowerCaseFilterText)
    //     );
    // });




    return (
        <div className="staffdashboard bg-pink">
            <Header />
            <Toaster />
            <section className="listing pt-5 pb-5 overflow-hidden vh-80">
                <Container>

                    <UserNameAndTab />
                    <div className="listMenuButton">
                        <Col xl={12} lg={12} className="mb-3">
                            <Card className="border-0 tablistfinance">
                                <Card.Body>
                                    <ListMenuButton />
                                </Card.Body>
                            </Card>
                        </Col>
                    </div>
                    <div className="">
                        <Col lg={12} className="mb-3">
                            <Card className="border-0 bg-white p-3">
                                <Card.Header className="border-0 mb-0 pb-0">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className=""><h4 className="fw-bolder mb-4">Supplier  </h4></div>
                                        <div className="text-end">
                                            <Button
                                                variant="primary"
                                                className=" rounded-5  px-4 px-xs-1 mb-4 mobileBtn"
                                                onClick={setIsModelOpen}
                                            >
                                                New Supplier
                                            </Button>
                                        </div>

                                    </div>
                                    <div className="">
                                        <Row >
                                            <Col xl={3} className="mb-2">
                                                <Card className="bg-light-orange p-sm-3 PriceBox">

                                                    <Card.Body>
                                                        <div>
                                                            <h4 className="text-primary mb-0">${statistics[0]?.totalExpenseAmount}</h4>
                                                            <small>Total Expense</small>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </Col>

                                            <Col xl={2} className="mb-2">
                                                <Card className="bg-light-orange p-sm-3 PriceBox">
                                                    <Card.Body>
                                                        <div>
                                                            <h4 className="text-primary mb-0">${statistics[0]?.totalPendingAmount}</h4>
                                                            <small>{statistics[0]?.pendingCount} Pending</small>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </Col>

                                            <Col xl={2} className="mb-2">
                                                <Card className="bg-light-orange p-sm-3 PriceBox">
                                                    <Card.Body>
                                                        <div>
                                                            <h4 className="text-primary mb-0">${statistics[0]?.totalPaidAmount}</h4>
                                                            <small>{statistics[0]?.paidCount} Paid</small>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </Col>

                                            <Col xl={2} className="mb-2">
                                                <Card className="bg-light-orange p-sm-3 PriceBox">
                                                    <Card.Body>
                                                        <div>
                                                            <h4 className="text-primary mb-0">${statistics[0]?.totalUnpaidAmount}</h4>
                                                            <small>{statistics[0]?.unpaidCount} Unpaid</small>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </Col>

                                            <Col xl={3} className="mb-2">
                                                <Card className="bg-light-orange p-sm-3 PriceBox">
                                                    <Card.Body>
                                                        <div>
                                                            <h4 className="text-primary mb-0">${statistics[0]?.totalOverdueAmount}</h4>
                                                            <small>{statistics[0]?.overdueCount} Overdue</small>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </Col>

                                            <Col lg={12}>
                                                <Form.Group
                                                    className="me-2"
                                                    controlId="formBasicPassword"
                                                >
                                                    <h6 className=" fw-normal">Search here</h6>
                                                    <Row className="mt-2">

                                                        <Col lg={3} className="mb-4">
                                                            <div className="position-relative">
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Search..."
                                                                    value={filterText}
                                                                    onChange={(e) => setFilterText(e.target.value)}
                                                                />
                                                                <span className="text-light-grey inner-field small"></span>
                                                            </div>
                                                        </Col>

                                                        <Col lg={3} className="mb-4">
                                                            <div className="position-relative">
                                                                <Form.Control
                                                                    type="date"
                                                                    placeholder="Date"
                                                                    aria-label="firstName"
                                                                    name="Date"
                                                                    onChange={(e) => setFromDate(e.target.value)}

                                                                />
                                                                <span className="text-light-grey inner-field small">From</span>
                                                            </div>
                                                        </Col>
                                                        <Col lg={3} className="mb-4">
                                                            <div className="position-relative">
                                                                <Form.Control
                                                                    type="date"
                                                                    placeholder="Date"
                                                                    aria-label="firstName"
                                                                    name="Date"
                                                                    onChange={(e) => setToDate(e.target.value)}
                                                                />
                                                                <span className="text-light-grey inner-field small">To</span>
                                                            </div>
                                                        </Col>



                                                        <Col lg={3}>

                                                        </Col>
                                                        <Col lg={12}>
                                                            <hr className="mb-4 mt-0" style={{ color: "#EBEBEB", opacity: "1" }} />
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </div>

                                </Card.Header>
                                <Card.Body className="p-0">
                                    <div className="">
                                        {supplierList.length > 0 ?
                                            <Row className="">
                                                <Col lg={12} xs={12}>
                                                    <div className="mb-4">
                                                        <DataTable
                                                            columns={columns}
                                                            data={supplierList}
                                                            customStyles={customStyles}
                                                            theme="solarized"

                                                        />
                                                    </div>
                                                </Col>

                                            </Row>
                                            :
                                            <div className="text-center">
                                                <div>
                                                    <img src="images/nodatafound.svg" />
                                                </div>
                                                <h5 className="mb-1">Payable Report</h5>
                                                <div>This report doesn’t have any data</div>
                                            </div>
                                        }

                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </div>

                    <Modal
                        show={isModelOpen}
                        onHide={() => modelClose(false)}
                        dialogClassName="modal-90w modal-xl"
                        aria-labelledby="example-custom-modal-styling-title"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <b>{isEdit ? 'Edit Supplier' : 'Add Supplier'}</b>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="">
                                <Col lg={12} className="mb-3">
                                    <Card className="border-0 bg-white p-3">
                                        <Card.Header className="border-0 mb-0">
                                            <div className="">
                                                <h4 className="mb-0 fw-bolder">Name & Contact </h4>
                                            </div>

                                        </Card.Header>
                                        <Card.Body className="p-0 mb-3">
                                            <div className="">
                                                <Row className="mt-2">

                                                    <Col lg={6} className="mb-4">
                                                        <Form.Group className="" controlId="formBasicEmail">
                                                            <Form.Label className="text-light-grey mb-1">Supplier Name</Form.Label>
                                                            <Form.Control type="text" name="supplierName" onChange={inputHandler} value={formData.supplierName || ''} />
                                                            {errors.supplierName && <small className="text-danger">{errors.supplierName}</small>}
                                                        </Form.Group>
                                                    </Col>

                                                    <Col lg={6} className="mb-4">
                                                        <Form.Group className="" controlId="formBasicEmail">
                                                            <Form.Label className="text-light-grey mb-1">Company Name</Form.Label>
                                                            <Form.Control type="text" name="companyName" onChange={inputHandler} value={formData.companyName || ''} />
                                                            {errors.companyName && <small className="text-danger">{errors.companyName}</small>}
                                                        </Form.Group>
                                                    </Col>


                                                    <Col lg={6} className="mb-4">
                                                        <Form.Group className="" controlId="formBasicEmail">
                                                            <Form.Label className="text-light-grey mb-1">Country Code Number </Form.Label>
                                                            <Select
                                                                aria-label="countryCode"
                                                                name="countryCode"
                                                                options={countryoptions}
                                                                value={countryCode}
                                                                onChange={setcountryCode}
                                                                labelledBy="Country Code"
                                                            />
                                                        </Form.Group>
                                                    </Col>

                                                    <Col lg={6} className="mb-4">
                                                        <Form.Group className="" controlId="formBasicEmail">
                                                            <Form.Label className="text-light-grey mb-1">Mobile Number </Form.Label>
                                                            <Form.Control type="text" name="mobileNumber" onChange={inputHandler} value={formData.mobileNumber || ''} onKeyPress={(event) => {
                                                                if (
                                                                    !/^\d*[]?\d{0,1}$/.test(event.key)
                                                                ) {
                                                                    event.preventDefault();
                                                                }
                                                            }} />
                                                            {errors.mobileNumber && <small className="text-danger">{errors.mobileNumber}</small>}
                                                        </Form.Group>
                                                    </Col>

                                                    <Col lg={6} className="mb-4">
                                                        <Form.Group className="" controlId="formBasicEmail">
                                                            <Form.Label className="text-light-grey mb-1">Email </Form.Label>
                                                            <Form.Control type="text" name="email" onChange={inputHandler} value={formData.email || ''} />
                                                            {errors.email && <small className="text-danger">{errors.email}</small>}
                                                        </Form.Group>
                                                    </Col>

                                                    {/* <Col lg={6} className="mb-4">
                                                        <Form.Group controlId="formBasicEmail">
                                                            <Form.Label className="text-light-grey mb-1">Supplier</Form.Label>
                                                            <Form.Control
                                                                as="select"
                                                                name="supplierFor"
                                                                value={formData.supplierFor}
                                                                onChange={inputHandler}
                                                            >
                                                                <option value="">Select Supplier</option>
                                                                <option value="1">Property</option>
                                                                <option value="2">Vehicle</option>
                                                            </Form.Control>
                                                            {errors.supplierFor && <small className="text-danger">{errors.supplierFor}</small>}
                                                        </Form.Group>
                                                    </Col> */}

                                                </Row>
                                            </div>

                                        </Card.Body>
                                        <Card className="border-0 bg-white p-0 mb-3">
                                            <Card.Header className="border-0 mb-0">
                                                <div className="">
                                                    <h4 className="mb-0 fw-bolder">Address </h4>
                                                </div>

                                            </Card.Header>
                                            <Card.Body className="p-0">
                                                <div className="">
                                                    <Row className="mt-2">
                                                        <Col lg={6} className="mb-4">
                                                            <Form.Group className="" controlId="formBasicEmail">
                                                                <Form.Label className="text-light-grey mb-1">Street Address</Form.Label>
                                                                <Form.Control type="text" name="streetAddress" onChange={inputHandler} value={formData.streetAddress || ''} />
                                                                {errors.streetAddress && <small className="text-danger">{errors.streetAddress}</small>}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={6} className="mb-4">
                                                            <Form.Group className="" controlId="formBasicEmail">
                                                                <Form.Label className="text-light-grey mb-1">City </Form.Label>
                                                                <Form.Control type="text" name="city" onChange={inputHandler} value={formData.city || ''} />
                                                                {errors.city && <small className="text-danger">{errors.city}</small>}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={6} className="mb-4">
                                                            <Form.Group className="" controlId="formBasicEmail">
                                                                <Form.Label className="text-light-grey mb-1">Postal Code </Form.Label>
                                                                <Form.Control type="text" name="postalCode" onChange={inputHandler} value={formData.postalCode || ''} />
                                                                {errors.postalCode && <small className="text-danger">{errors.postalCode}</small>}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={6} className="mb-4">
                                                            <Form.Group className="" controlId="formBasicEmail">
                                                                <Form.Label className="text-light-grey mb-1">Country</Form.Label>
                                                                <Form.Control type="text" name="country" onChange={inputHandler} value={formData.country || ''} />
                                                                {errors.country && <small className="text-danger">{errors.country}</small>}
                                                            </Form.Group>
                                                        </Col>

                                                        <Card className="border-0 bg-white p-0 mb-3">
                                                            <Card.Header className="border-0 mb-0">
                                                                <div className="">
                                                                    <h4 className="mb-0 fw-bolder">Payment</h4>
                                                                </div>

                                                            </Card.Header>
                                                            <Card.Body className="p-0">
                                                                <div className="">
                                                                    <Row className="mt-2">
                                                                        <Col lg={6} className="mb-4">
                                                                            <Form.Group className="" controlId="formBasicEmail">
                                                                                <Form.Label className="text-light-grey mb-1">Terms </Form.Label>
                                                                                <Form.Select aria-label="Default select example" onChange={inputHandler} name="terms" value={formData.terms || ''} >
                                                                                    <option value="0">Select Terms</option>
                                                                                    <option value="15">15</option>
                                                                                    <option value="30">30</option>
                                                                                    <option value="60">60</option>
                                                                                </Form.Select>
                                                                                {errors.terms && <small className="text-danger">{errors.terms}</small>}
                                                                            </Form.Group>
                                                                        </Col>

                                                                        <Col lg={6} className="mb-4">
                                                                            <Form.Group controlId="formBasicEmail">
                                                                                <Form.Label className="text-light-grey mb-1">Expense Category</Form.Label>
                                                                                <Form.Select
                                                                                    aria-label="Default select example"
                                                                                    onChange={inputHandler}
                                                                                    name="expenseCategoryId"
                                                                                    value={formData.expenseCategoryId || ''}
                                                                                >
                                                                                    {expenseCategory.map((item) => (
                                                                                        <option key={item.id} value={item.id}>
                                                                                            {item.expenseCategory}
                                                                                        </option>
                                                                                    ))}
                                                                                </Form.Select>
                                                                                {errors.expenseCategoryId && <small className="text-danger">{errors.expenseCategoryId}</small>}
                                                                            </Form.Group>
                                                                        </Col>


                                                                        <Col lg={6} className="mb-4">
                                                                            <Form.Group className="" controlId="formBasicEmail">
                                                                                <Form.Label className="text-light-grey mb-1">Opening Balance </Form.Label>
                                                                                <Form.Control type="text" name="openingBalance" onChange={inputHandler} value={formData.openingBalance || ''} />
                                                                                {errors.openingBalance && <small className="text-danger">{errors.openingBalance}</small>}
                                                                            </Form.Group>
                                                                        </Col>



                                                                        <Col lg={6} className="mb-4">
                                                                            <Form.Group className="" controlId="formBasicEmail">
                                                                                {console.log('formData?.asOf', formData?.asOf)}
                                                                                <Form.Label className="text-light-grey mb-1">As of </Form.Label>
                                                                                <Form.Control type="date" name="asOf" onChange={inputHandler} value={formData?.asOf} />
                                                                                {errors.asOf && <small className="text-danger">{errors.asOf}</small>}
                                                                            </Form.Group>
                                                                        </Col>


                                                                        {isEdit && (
                                                                            <div className="col-md-12 mt-2">
                                                                                <label htmlFor="status">Status:</label>&nbsp;
                                                                                <select
                                                                                    name="isBlock"
                                                                                    className="form-control"
                                                                                    value={formData.isBlock || ''}
                                                                                    onChange={inputHandler}
                                                                                >
                                                                                    <option value="0">Active</option>
                                                                                    <option value="1">Block</option>
                                                                                </select>
                                                                            </div>
                                                                        )}

                                                                    </Row>
                                                                </div>

                                                            </Card.Body>
                                                        </Card>

                                                       

                                                    </Row>
                                                </div>

                                            </Card.Body>
                                        </Card>

                                        <Col lg={12} className="text-center">
                                            <div>
                                                <Link to={`#`}>
                                                    {isLoading ?
                                                        <button className="rounded-5 me-2 px-4 px-xs-1 mb-3 btn btn-primary" disabled>
                                                            Processing...
                                                        </button>
                                                        :
                                                        <button className="rounded-5 me-2 px-4 px-xs-1 mb-3 btn btn-primary" onClick={handleSubmit}>
                                                            {isEdit ? 'Update Supplier' : 'Save Supplier'}
                                                        </button>
                                                    }
                                                </Link>
                                            </div>
                                        </Col>
                                    </Card>
                                </Col>
                            </div>
                        </Modal.Body>
                    </Modal>

                </Container>
            </section>
        </div >
    );
};

export default VehicleSupplier;
