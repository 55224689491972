import React, { useState, useEffect } from "react";
import "./generalCSS/home.css";
import "./generalCSS/generalnavbar.css";
import "./generalCSS/faq.css"
import "bootstrap/dist/css/bootstrap.min.css";
import { getFaqAction } from "../Action/user.action";
import { Container, Row, Col } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
const Faq = () => {
  const [faq, setFaq] = useState([]);

  useEffect(() => {
    getFaq();
  }, []);

  const getFaq = async () => {
    let res = await getFaqAction();
    console.log(res.data);
    if (res.success) {
      setFaq(res.data);
    }
  };
  return (
    <>
      <section className="bg-light py-4  mb-4">
        <Container>
          <Row className="align-items-center">
            <Col lg={12} md={12} className=" text-center">

              <div className="">
                <h1 className="text-black mb-0 py-4">
                  <b>FAQs</b>
                  <div class="section-title-line"> </div>
                </h1>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="destination-area pt-0">
        <Container>
          {faq.map((item, i) => (
            <Accordion>
              <Accordion.Item eventKey={i} className="mb-4">
                <Accordion.Header>{item.question}</Accordion.Header>
                <Accordion.Body>{item.answer}</Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ))}
        </Container>
      </section>
    </>
  );
};

export default Faq;
