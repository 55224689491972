
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import config from '../config'

import './generalCSS/login.css';
import './generalCSS/mobileverification.css'
import { Container, Row, Col, Form, Button, InputGroup, DropdownButton, Dropdown } from 'react-bootstrap';

const LoadingPopup = () => {
    return (
        <div className="loading-popup-container">
            <div className="loading-popup">
                <p>Please wait</p>
                <h1>Loggin in . . .</h1>
            </div>
        </div>
    );
};

const Passwordsuccesfully = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleLogin = (e) => {
        e.preventDefault();
        setIsLoading(true);
        console.log('Logged in with username:', username, 'and password:', password);
        setTimeout(() => {
            navigate(`${config.baseurl}${config.loginurl}/`);
        }, 3000);
    };

    return (
        <>

            <section className="login-section pt-5 pb-5 overflow-hidden vh-80">
                <Container>
                    <Row className='justify-content-center '>
                        <Col xl={7} lg={7}>
                            <div className="login-container">
                                <div className="login-form">
                                    <div className="login-logo">
                                        <img
                                            src="assets/images/logo/logo.webp"
                                            alt="logo"
                                        />
                                         <div className='pt-5 pb-5'>
                                         <img
                                            src="assets/images/success-check.svg"
                                            alt="logo"
                                        />
                                        </div>
                                        <div className='pt-4 pb-4'>
                                            <h3 className='mb-3'><b>PASSWORD HAS BEEN SUCCESSFULLY RESET!</b></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                      
                    </Row>

                </Container>

            </section>
            <footer>
            <div className='banner-bottom'>
                <img src='assets/images/background/bottom_banner.webp' width="100%" />

            </div>
            </footer>
            {isLoading && <LoadingPopup />}

        </>
    )
}

export default Passwordsuccesfully;
