import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getPlanPurchaseDetailsAction } from "../../Action/user.action";
import Cookies from "js-cookie";
import { toast } from "react-hot-toast";
import config from "../../config"; // Adjust the path based on your project structure

const CheckUserSubscription = ({ type, page }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkSubscription = async () => {
      const loginData = Cookies.get("loginSuccessPropertyOwner")
        ? JSON.parse(Cookies.get("loginSuccessPropertyOwner"))
        : null;

      if (!loginData) {
        window.location.href = `${config.baseurl}`;
        return;
      }

      if (loginData.loginType === 1) {
        try {
          const res = await getPlanPurchaseDetailsAction({ type });
          if (res.success) {
            if (
              parseInt(res.data.totalPlanListing.totalListing) >
              parseInt(res.data.getTotalListed.totalListed)
            ) {
              navigate(`${config.baseurl}${page}`);
            } else {
              navigate(`${config.baseurl}subscriptionplan`);
            }
          } else {
            toast.error(res.msg);
          }
        } catch (error) {
          toast.error("An error occurred while checking the subscription.");
        }
      } else {
        navigate(`${config.baseurl}${page}`);
      }
    };

    checkSubscription();
  }, [navigate, page, type]);

  return null; // Component does not render anything visually
};

export default CheckUserSubscription;
