import React, { useState, useEffect, useMemo } from "react";
import config from "../../coreFIles/config";
import { toast, Toaster } from "react-hot-toast";
import CryptoJS from 'crypto-js';
import {
  getVehicleDetailActionbyid,
  getFygaroJwtForApp
} from "../../Action/user.action";
import {
  paymentIntentAction,
} from "../../Action/propertyOwner.action";
import {
  Container,
  Row,
  Col,
  Card,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Buffer } from "buffer";

const AppVehiclepayment = () => {
  const navigate = useNavigate();

  const url = window.location.href;
  const urlParams = new URL(url);
  const params = new URLSearchParams(urlParams.search);
  const id = params.get('id');
  const checkin = params.get('checkin');
  const checkout = params.get('checkout');
  const passenger = params.get('passenger');
  const auth = params.get('JWT');

  const [vehicleDetail, setvehicleDetail] = useState({
    PropertyName: "",
    engineArr: [],
    bikeParking: "",
    carParking: "",
    categoryName: "",
    checkIn: "",
    checkOut: "",
    datetime: "",
    description: "",
    documentArr: [],
    email: "",
    guestCount: "",
    id: "",
    imageArr: [],
    isDeleted: "",
    location: "",
    numberOfBathroom: "",
    numberOfBedrooms: "",
    numberOfBeds: "",
    petsAllowed: "",
    price: "",
    propertyName: "",
    propertyPurchaseType: "",
    propertyPurchaseTypeId: "",
    status: "",
    terms: "",
  });
  const [imagesArr, setimagesArr] = useState([]);
  const [engineArr, setengineArr] = useState([]);
  const [totalBookingHour, settotalBookingHour] = useState(0);
  const [paymentType, setpaymentType] = useState(1); //1=Card ,2=Bank Transfer ,3=Paypal,4=Stripe,5=MMG
  const [clientSecret, setClientSecret] = useState("");
  const [fygaroToken, setFygaroToken] = useState('');

  useEffect(() => {
    getListingDetails();
  }, []);

  const getFygaroPayloadAPI = async (data) => {
    const encryptionKey = config.fygaroJWTEncryptionKey;
    const [checkinDate, checkinTime] = checkin.split(" ");

    const [checkoutDate, checkoutTime] = checkout.split(" ");


    let bookingHours;

    if (checkin && checkout) {
      var date1 = new Date(checkin);
      var date2 = new Date(checkout);

      const getHoursDiffBetweenDates = (dateInitial, dateFinal) =>
        (dateFinal - dateInitial) / (1000 * 3600);
      const hour = parseFloat(getHoursDiffBetweenDates(date1, date2)).toFixed(
        0
      );
      bookingHours = hour;
    }

    let amount = data.perHourRate * bookingHours

    let fygaroPayload = {
      vehicleId: id,
      passengers: passenger,
      checkInDate: checkinDate,
      checkInTime: checkinTime,
      checkOutDate: checkoutDate,
      checkOutTime: checkoutTime,
      amount: amount,
      type: 2
    };

    const encryptedPayload = CryptoJS.AES.encrypt(
      JSON.stringify(fygaroPayload),
      encryptionKey
    ).toString();

    let jwtReceived = await getFygaroJwtForApp({ jwt: encryptedPayload, auth: auth });

    if (jwtReceived.success) {
      setFygaroToken(jwtReceived.data);
    }
  };

  const getListingDetails = async () => {
    let res = await getVehicleDetailActionbyid({ id: id });
    if (res.success) {
      setvehicleDetail(res.data);
      setimagesArr(JSON.parse(res.data.imageArr));
      setengineArr(res.data.engineArr);
      getFygaroPayloadAPI(res.data)
    }
    if (checkin && checkout) {
      //Difference in number of days
      var date1 = new Date(checkin);
      var date2 = new Date(checkout);

      const getHoursDiffBetweenDates = (dateInitial, dateFinal) =>
        (dateFinal - dateInitial) / (1000 * 3600);
      const hour = parseFloat(getHoursDiffBetweenDates(date1, date2)).toFixed(
        0
      );
      settotalBookingHour(hour);
    }
  };

  const paymentIntent = async () => {
    let amount =
      parseInt(vehicleDetail.perHourRate) * parseInt(totalBookingHour);

    let res = await paymentIntentAction({ amount: amount });
    if (res.success) {
      setClientSecret({
        clientSecret: Buffer.from(res.client_secret, "base64").toString(
          "ascii"
        ),
      });
    }
  };

  useMemo(() => {
    if (paymentType == 2) {
      paymentIntent();
    }
  }, [paymentType]);

  const callFygaro = () => {
    if (fygaroToken) {
      let fygaroString = `https://www.fygaro.com/en/pb/8ef036d5-3406-4b0d-858f-8d814667d469?jwt=${fygaroToken}`;
      window.open(fygaroString, '_blank');
    } else {
      toast.error('Something went wrong please try again after some time.');
    }
  };

  return (
    <>
      <Toaster />
      <section className="yourbooking pt-5 pb-5 overflow-hidden vh-80">
        <Container>
          <div className="">
            <Row className=" ">
              <Col lg={12} className="mb-4">
                <Card>
                  <div className="paymentImg">
                    {imagesArr.length > 0 ? (
                      <img
                        src={`${imagesArr[0].fileNameImage}`}
                      />
                    ) : (
                      ""
                    )}

                  </div>
                  <div className="px-2">
                    <div>
                      <div className="mt-4">
                        <div className="">
                          <h5 className="mb-2">
                            <b>{vehicleDetail.vehicleName}</b>
                          </h5>
                          <div className="d-flex justify-content-between">
                            <div>
                              <h6>{vehicleDetail.location}</h6>
                            </div>
                            <h5>
                              {" "}
                              <b>
                                $
                                {parseInt(vehicleDetail.perHourRate) *
                                  parseInt(totalBookingHour)}
                              </b>
                            </h5>
                          </div>
                        </div>
                      </div>
                      <hr />

                      <div className="">
                        <Row>
                          <Col lg={4} className="">
                            <div>
                              <div>
                                <b>Start Date</b>
                              </div>
                              <p className="text-grey mb-0">{checkin}</p>
                            </div>
                          </Col>
                          <Col lg={4} className="">
                            <div>
                              <div>
                                <b>End Date</b>
                              </div>
                              <p className="text-grey mb-0">{checkout}</p>
                            </div>
                          </Col>
                          <Col lg={4} className="">
                            <div>
                              <div>
                                <b>Hours</b>
                              </div>
                              <p className="text-grey mb-0">
                                {totalBookingHour} Hours
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <hr />
                      <div className="mt-4 mb-4">
                        <Row>
                          <Col lg={6} sm={6} xs={6} className="mb-2">
                            <h6>
                              <td>
                                {" "}
                                ${parseInt(vehicleDetail.perHourRate)} X{" "}
                                {parseInt(totalBookingHour)} hours
                              </td>
                            </h6>
                          </Col>
                          <Col lg={6} sm={6} xs={6} className="mb-2 text-right">
                            <span className="text-grey">
                              $
                              {parseInt(vehicleDetail.perHourRate) *
                                parseInt(totalBookingHour)}
                            </span>
                          </Col>
                          <Col lg={6} sm={6} xs={6} className="mb-2">
                            <h6>Tax Fees</h6>
                          </Col>
                          <Col lg={6} sm={6} xs={6} className="mb-2 text-right">
                            <span className="text-grey">$0</span>
                          </Col>
                          <Col lg={6} sm={6} xs={6} className="mb-2 ">
                            <h5>
                              <b>Total Cost:</b>
                            </h5>
                          </Col>
                          <Col lg={6} sm={6} xs={6} className="mb-2 text-right">
                            <h5 className="text-grey">
                              $
                              {parseInt(vehicleDetail.perHourRate) *
                                parseInt(totalBookingHour)}
                            </h5>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </Card>

                {/* <button className="btn paypal-button w-100 mt-2" onClick={callFygaro}>Pay Now</button> */}
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};

export default AppVehiclepayment;
