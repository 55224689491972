import React, { useEffect, useState, useRef } from "react";
import config from "../config";
import { Formik } from "formik";
import "./generalCSS/login.css";
import "./generalCSS/generalnavbar.css";
import Select from "react-select";
import {
  ForgetPasswordSchema,
  ForgetPasswordOTPSchema,
  ResetPasswordOTPSchema,
} from "../validations/signup";
import { Container, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import {
  ForgotPasswordAction,
  getCountryCodeAction,
  verifyForgetPasswordOTPAction,
  ResetPasswordAction,
} from "../Action/propertyOwner.action";
import { toast, Toaster } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";

const Forgetpassword = () => {
  const navigate = useNavigate();
  const [isStep, setIsStep] = useState(1);
  const [mobileNumber, setMobileNumber] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [countryCode, setcountryCode] = useState({
    label: "592",
    value: "592",
  });
  const [countryoptions, setcountryoptions] = useState([]);
  const [otp, setOtp] = useState(null);
  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const input4Ref = useRef(null);
  const input5Ref = useRef(null);
  useEffect(() => {
    getCountryCodeAPI();
  }, []);

  // Function to move focus to the next input field
  const handleInput = (e, nextRef) => {
    // Move to the next input only if a digit is entered
    if (e.target.value.length === 1 && nextRef.current) {
      nextRef.current.focus();
    }
  };

  const getCountryCodeAPI = async () => {
    let res = await getCountryCodeAction();
    let arry = [];

    if (res.success) {
      for (let x in res.data) {
        let obj = {};
        obj.label = `+${res.data[x].phonecode}`;
        obj.value = res.data[x].phonecode;
        arry.push(obj);
      }
      setcountryoptions(arry);
    }
  };

  const SubmitForm = async (formData) => {
    setisLoading(true);
    formData.countryCode = countryCode.value;
    setMobileNumber(formData.emailormobile);
    let res = await ForgotPasswordAction(formData);
    if (res.success) {
      toast.success(res.msg);
      setOtp(res.data);
      // toast.success(res.data);
      setIsStep(2);
      setTimeout(() => {
        input1Ref.current.focus();
      }, 200);
    } else {
      toast.error(res.msg);
      setisLoading(false);
    }
  };

  const resendOTP = async () => {
    let formData = { emailormobile: mobileNumber };
    let res = await ForgotPasswordAction(formData);
    if (res.success) {
      toast.success(res.msg);
    } else {
      toast.error(res.msg);
    }
  };

  const verifyOTPForm = async (values) => {
    let enteredOTP =
      values.otp1 + values.otp2 + values.otp3 + values.otp4 + values.otp5;
    let formData = {
      mobileNumber: mobileNumber,
      otp: enteredOTP,
      countryCode: countryCode.value,
    };
    let res = await verifyForgetPasswordOTPAction(formData);
    if (res.success) {
      setIsStep(3);
    } else {
      toast.error(res.msg);
      setisLoading(false);
    }
  };

  const resetPasswordForm = async (formData) => {
    formData.emailormobile = mobileNumber;
    let res = await ResetPasswordAction(formData);
    if (res.success) {
      toast.success(res.msg);
      setTimeout(() => {
        navigate(`${config.baseurl}login`);
      }, 2000);
    } else {
      toast.error(res.msg);
    }
  };
  const SelectStyles = {
    option: (defaultStyles, state) => ({
      ...defaultStyles,
      color: state.isSelected ? "#fff" : "#000",
      backgroundColor: state.isSelected ? "#cf5416" : "#fff",
      minWidth: "63px",
    }),

    control: (defaultStyles, state) => ({
      ...defaultStyles,
      height: "20px",
      minHeight: "30px",
      borderColor: "#E9E9E9",
      minWidth: "63px",
      borderRadius: "0",
      backgroundColor: "#E9E9E9",
    }),
    singleValue: (defaultStyles) => ({
      ...defaultStyles,
      color: "#000",
      height: "30px",
      margin: "0px",
    }),
  };

  return (
    <>
      <Toaster />
      <section className="login-section pt-5 pb-5 overflow-hidden vh-80">
        {isStep == 1 ? (
          <Container>
            <Row className="justify-content-center ">
              <Col xl={6} lg={6}>
                <div className="login-container">
                  <div className="login-form">
                    <div className="login-logo">
                      <img src="assets/images/logo/logo.webp" alt="logo" />
                      <div className="pt-4 pb-4">
                        <h3 className="mb-3">
                          <b>FORGOT PASSWORD</b>
                        </h3>
                        <p>Enter your registered mobile number</p>
                      </div>
                    </div>

                    <Formik
                      initialValues={{
                        emailormobile: "",
                        countryCode: countryCode.value,
                      }}
                      validationSchema={ForgetPasswordSchema}
                      onSubmit={(values) => {
                        SubmitForm(values);
                      }}
                    >
                      {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        touched,
                        values,
                      }) => (
                        <>
                          <Form onSubmit={handleSubmit} autoComplete="off">
                            <Row>
                              <Col xl={12} lg={12}>
                                <div className="mb-3">
                                  <InputGroup
                                    className="reactSelect position-relative"
                                    onKeyPress={(event) => {
                                      if (!/^\d*[]?\d{0,1}$/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                  >
                                    {/* <Form.Select aria-label="Default select example" name="countryCode" value={values.countryCode} onChange={handleChange}>
                                                                            {countryList.map(item => (
                                                                                <option value={item.phonecode}>+{item.phonecode}</option>
                                                                            ))}
                                                                        </Form.Select> */}
                                    <Select
                                      aria-label="countryCode"
                                      name="countryCode"
                                      onBlur={handleBlur}
                                      options={countryoptions}
                                      value={countryCode}
                                      onChange={setcountryCode}
                                      labelledBy="Country Code"
                                      styles={SelectStyles}
                                    />
                                    <Form.Control
                                      placeholder="Enter phone number"
                                      aria-label="emailormobile"
                                      name="emailormobile"
                                      onKeyPress={(event) => {
                                        if (
                                          !/^\d*[]?\d{0,1}$/.test(event.key)
                                        ) {
                                          event.preventDefault();
                                        }
                                      }}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      value={values.emailormobile}
                                    />
                                  </InputGroup>
                                  <Form.Text
                                    id="passwordHelpBlock"
                                    className="text-danger"
                                  >
                                    {errors.emailormobile &&
                                      touched.emailormobile && (
                                        <div className="text-danger">
                                          {errors.emailormobile}
                                        </div>
                                      )}
                                  </Form.Text>
                                </div>
                              </Col>

                              <Col xl={12} lg={12} className="mt-2">
                                {isLoading ? (
                                  <Button
                                    variant="primary"
                                    type="button"
                                    className="w-100"
                                  >
                                    Processing
                                  </Button>
                                ) : (
                                  <Button
                                    variant="primary"
                                    type="submit"
                                    className="w-100"
                                  >
                                    Forget Password
                                  </Button>
                                )}
                              </Col>
                              <Col xl={12} lg={12} className="mt-2">
                                <div className="text-center mt-2">
                                  <div>
                                    <Link
                                      to={`${config.baseurl}login`}
                                      className="text-decoration-none"
                                    >
                                      <u>Back To Login</u>
                                    </Link>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        </>
                      )}
                    </Formik>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        ) : isStep == 2 ? (
          <Container>
            <Row className="justify-content-center ">
              <Col xl={6} lg={6}>
                <div className="login-container">
                  <div className="login-form">
                    <div className="login-logo">
                      <img src="assets/images/logo/logo.webp" alt="logo" />
                      <div className="pt-4 pb-4">
                        <h3 className="mb-3">
                          <b>FORGET PASSWORD</b>
                        </h3>
                        {/* <span>your OTP is <b>{otp}</b></span> */}
                        <p>
                          “If your Mobile is registered by us you will be
                          receiving an email/SMS to reset your password
                        </p>
                      </div>
                    </div>

                    <Formik
                      initialValues={{
                        otp1: "",
                        otp2: "",
                        otp3: "",
                        otp4: "",
                        otp5: "",
                      }}
                      validationSchema={ForgetPasswordOTPSchema}
                      onSubmit={(values) => {
                        verifyOTPForm(values);
                      }}
                    >
                      {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        touched,
                        values,
                      }) => (
                        <>
                          <Form onSubmit={handleSubmit}>
                            <Row>
                              <Col xl={12} lg={12}>
                                <Form.Group
                                  className="mb-4 d-flex m-verification"
                                  controlId="formBasicPassword"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="5"
                                    autoComplete="off"
                                    name="otp1"
                                    className="me-3"
                                    ref={input1Ref}
                                    onInput={(e) => handleInput(e, input2Ref)}
                                    maxLength="1"
                                    pattern="[0-9]"
                                    onChange={handleChange}
                                  />
                                  <Form.Control
                                    type="text"
                                    ref={input2Ref}
                                    onInput={(e) => handleInput(e, input3Ref)}
                                    placeholder="5"
                                    autoComplete="off"
                                    name="otp2"
                                    className="me-3"
                                    onChange={handleChange}
                                    maxLength="1"
                                    pattern="[0-9]"
                                  />
                                  <Form.Control
                                    type="text"
                                    ref={input3Ref}
                                    onInput={(e) => handleInput(e, input4Ref)}
                                    placeholder="5"
                                    autoComplete="off"
                                    name="otp3"
                                    className="me-3"
                                    onChange={handleChange}
                                    maxLength="1"
                                    pattern="[0-9]"
                                  />
                                  <Form.Control
                                    type="text"
                                    ref={input4Ref}
                                    onInput={(e) => handleInput(e, input5Ref)}
                                    placeholder="5"
                                    autoComplete="off"
                                    name="otp4"
                                    className="me-3"
                                    onChange={handleChange}
                                    maxLength="1"
                                    pattern="[0-9]"
                                  />
                                  <Form.Control
                                    type="text"
                                    ref={input5Ref}
                                    placeholder="5"
                                    autoComplete="off"
                                    name="otp5"
                                    className=""
                                    onChange={handleChange}
                                    maxLength="1"
                                    pattern="[0-9]"
                                  />
                                </Form.Group>
                              </Col>

                              <Col xl={12} lg={12}>
                                <div className="text-center mt-4 pb-5">
                                  <span
                                    onClick={resendOTP}
                                    style={{ cursor: "pointer" }}
                                    className="text-decoration-none"
                                  >
                                    Didn’t receive code?{" "}
                                    <span style={{ color: "#E46422" }}>
                                      Send Again
                                    </span>
                                  </span>
                                </div>
                              </Col>

                              <Col xl={12} lg={12}>
                                <Button
                                  variant="primary"
                                  type="submit"
                                  className="w-100"
                                >
                                  VERIFY OTP
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        </>
                      )}
                    </Formik>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        ) : (
          <Container>
            <Row className="justify-content-center ">
              <Col xl={6} lg={6}>
                <div className="login-container">
                  <div className="login-form">
                    <div className="login-logo">
                      <img src="assets/images/logo/logo.webp" alt="logo" />
                      <div className="pt-4 pb-4">
                        <h3 className="mb-3">
                          <b>RESET PASSWORD</b>
                        </h3>
                        <p>Enter new password</p>
                      </div>
                    </div>
                    <Formik
                      initialValues={{
                        password: "",
                        confirmPassword: "",
                      }}
                      validationSchema={ResetPasswordOTPSchema}
                      onSubmit={(values) => {
                        resetPasswordForm(values);
                      }}
                    >
                      {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        touched,
                        values,
                      }) => (
                        <>
                          <Form onSubmit={handleSubmit}>
                            <Row>
                              <Col xl={12} lg={12}>
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicPassword"
                                >
                                  <Form.Control
                                    type="password"
                                    placeholder="New Password"
                                    aria-label="password"
                                    name="password"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.password}
                                  />
                                </Form.Group>
                                {errors.password && touched.password && (
                                  <div className="text-danger">
                                    {errors.password}
                                  </div>
                                )}
                              </Col>

                              <Col xl={12} lg={12}>
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicPassword"
                                >
                                  <Form.Control
                                    type="password"
                                    placeholder="Confirm Password"
                                    aria-label="password"
                                    name="confirmPassword"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.confirmPassword}
                                  />
                                </Form.Group>
                                {errors.confirmPassword &&
                                  touched.confirmPassword && (
                                    <div className="text-danger">
                                      {errors.confirmPassword}
                                    </div>
                                  )}
                              </Col>

                              <Col xl={12} lg={12}>
                                <Button
                                  variant="primary"
                                  type="submit"
                                  className="w-100"
                                >
                                  RESET PASSWORD
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        </>
                      )}
                    </Formik>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        )}
      </section>
      <footer>
        <div className="banner-bottom">
          <img src="assets/images/background/bottom_banner.webp" width="100%" />
        </div>
      </footer>
    </>
  );
};

export default Forgetpassword;
