import React, { useState, useEffect } from "react";
import "./generalCSS/home.css";
import "./generalCSS/generalnavbar.css";
import "./generalCSS/faq.css"
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Card } from "react-bootstrap";
const Faq = () => {
  return (
    <>
      <section className="bg-light py-4  mb-4">
        <Container>
          <Row>
            <Col lg={12} className="mb-5">
              <div className="text-center">
                <h2 className="">
                  <b>Site Map Links</b>
                </h2>
                <div class="section-title-line"> </div>
              </div>
            </Col>
          </Row>
          <Row className="suggestionlist">
            <Col lg={12}>
              <Row className="px-5 px-sm-0">
                <Col lg={12} md={12} className="mb-3">
                  <Card className="p-4 list">
                    <Row>
                      <Col lg={12} md={12}>
                        <h5 className="text-primary">Home</h5>
                        <p>https://www.propertynu.com</p>
                      </Col>
                      <Col lg={12} md={12}>
                        <h5 className="text-primary">About Us</h5>
                        <p>https://www.propertynu.com/aboutUs</p>
                      </Col>
                      <Col lg={12} md={12}>
                        <h5 className="text-primary">Blog</h5>
                        <p>https://www.propertynu.com/blog</p>
                      </Col>
                      <Col lg={12} md={12}>
                        <h5 className="text-primary">Blog Details</h5>
                        <p>https://www.propertynu.com/blog_detail/20</p>
                      </Col>
                      <Col lg={12} md={12}>
                        <h5 className="text-primary">Contact Us</h5>
                        <p>https://www.propertynu.com/contactus</p>
                      </Col>
                      <Col lg={12} md={12}>
                        <h5 className="text-primary">Privacy Policy</h5>
                        <p>https://www.propertynu.com/privacyPolicy</p>
                      </Col>
                      <Col lg={12} md={12}>
                        <h5 className="text-primary">Terms & Conditions</h5>
                        <p>https://www.propertynu.com/termsConditions</p>
                      </Col>
                      <Col lg={12} md={12}>
                        <h5 className="text-primary">FAQs</h5>
                        <p>https://www.propertynu.com/faq</p>
                      </Col>
                    </Row>
                  </Card>
                </Col>

              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Faq;
