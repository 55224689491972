import React, { useState, useEffect } from "react";
import Header from "../generalPages/directives/header";
import Footer from "../generalPages/directives/footer";
import "./generalCSS/home.css";
import "./generalCSS/generalnavbar.css";
import "./generalCSS/faq.css"
import "bootstrap/dist/css/bootstrap.min.css";
import { getFaqAction } from "../Action/user.action";
import { Container, Row, Col } from "react-bootstrap";
import config from "../coreFIles/config";
import Accordion from "react-bootstrap/Accordion";
import { useNavigate } from "react-router-dom";
const Faq = () => {
  const navigate = useNavigate();
  const [faq, setFaq] = useState([]);

  useEffect(() => {
    getFaq();
  }, []);

  const getFaq = async () => {
    let res = await getFaqAction();
    console.log(res.data);
    if (res.success) {
      setFaq(res.data);
    }
  };
  const handleBack = () => {
    navigate(`${config.baseUrl}`)
  };
  return (
    <>
      <Header />
      <section className="bg-light py-4  mb-4">
        <Container>
          <Row className="align-items-center">
            <Col lg={12} md={12} className=" text-center">

              <div className="">
                <h1 className="text-black mb-0 py-4">
                  <b>FAQs</b>
                  <div class="section-title-line"> </div>
                </h1>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="destination-area pt-0">
        <Container>
          <div className="mb-5">
            <h5 className=" d-flex align-items-center">
              <button
                color="#000"
                onClick={handleBack}
                className="border-1 rounded-1 border-secondary h-auto arrow_left btn btn-default"
                sx={{ mr: 1, color: "#000" }}
              >
                <img
                  src="assets/images/icon/right_arrow.svg"
                  width="7px"
                  style={{ marginTop: "-3px" }}
                />
              </button>
              &nbsp;
              <b>Back</b>
            </h5>
          </div>
          {faq.map((item, i) => (
            <Accordion>
              <Accordion.Item eventKey={i} className="mb-4">
                <Accordion.Header>{item.question}</Accordion.Header>
                <Accordion.Body>{item.answer}</Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ))}
        </Container>
      </section>

      <Footer />
    </>
  );
};

export default Faq;
