import React, { useState } from "react";
import Header from "../generalPages/directives/header";
import Footer from "../generalPages/directives/footer";
import "./generalCSS/home.css";
import "./generalCSS/generalnavbar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { FaArrowRight } from "react-icons/fa6";
import { ContactFormAction } from "../Action/propertyOwner.action";
import { toast, Toaster } from "react-hot-toast";
import Maps from "../afterLoggedin/Maps";

const Contactus = () => {
  const [form, setForm] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });
  const [validatioError, setvalidatioError] = useState({
    nameError: "",
    emailError: "",
    phoneError: "",
    subjectError: "",
    messageError: "",
  });

  const inputHandler = (e) => {
    const { name, value, id } = e.target;
    if (value != "") {
      setvalidatioError((old) => {
        return { ...old, [id]: "" };
      });
    }

    setForm((old) => {
      return { ...old, [name]: value };
    });
  };

  function validate() {
    let nameError = "";
    let emailError = "";
    let phoneError = "";
    let subjectError = "";
    let messageError = "";

    if (form.name === "") {
      nameError = "Name is required.";
    }
    if (form.email === "") {
      emailError = "Email is required.";
    }
    if (form.phone === "") {
      phoneError = "Phone number is required.";
    }
    if (form.subject === "") {
      subjectError = "Subject is required.";
    }
    if (form.message === "") {
      messageError = "Message is required.";
    }
    if (nameError || emailError || phoneError || subjectError || messageError) {
      setvalidatioError({
        nameError,
        emailError,
        phoneError,
        subjectError,
        messageError,
      });
      return false;
    } else {
      return true;
    }
  }

  const SubmitForm = async (e) => {
    e.preventDefault();
    const isValid = validate();
    if (!isValid) {
    } else {
      let res = await ContactFormAction(form);
      if (res.success) {
        toast.success(res.msg);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.error(res.msg);
      }
    }
  };

  const selectedLocation = {
    lat: 6.8137173,
    lng: -58.1474597,
    location: '91 Republic Street Newtown, Kitty, Georgetown, Guyana',
  };

  return (
    <>
      <Header />
      <Toaster />
      <section className="destination-area">
        <Container>
          <Row className="justify-content-center mb-5">
            <Col lg={6} md={6} className=" text-center">
              <div className="">
                <div className='text-center'>
                  <h2 className='text-black'><b>Get In Touch Today</b></h2>
                  <div class="section-title-line"> </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={12}>
              <Row>
                <Col lg={8} className=" mb-5">
                  <Form onSubmit={SubmitForm}>
                    <Row>
                      <Col lg={6} className="mb-3">
                        <Form.Group>
                          <Form.Label className="e fw-bold">
                            Name
                          </Form.Label>
                          <Form.Control
                            onChange={inputHandler}
                            type="text"
                            name="name"
                            placeholder="Name"
                          />
                          <span className="validationErr">
                            {validatioError.nameError}
                          </span>
                        </Form.Group>
                      </Col>
                      <Col lg={6} className="mb-3">
                        <Form.Group controlId="exampleForm.ControlInput1">
                          <Form.Label className=" fw-bold">
                            Email
                          </Form.Label>
                          <Form.Control
                            onChange={inputHandler}
                            type="email"
                            name="email"
                            placeholder="example@email.com"
                          />
                          <span className="validationErr">
                            {validatioError.emailError}
                          </span>
                        </Form.Group>
                      </Col>
                      <Col lg={6} className="mb-3">
                        <Form.Group controlId="exampleForm.ControlInput1">
                          <Form.Label className=" fw-bold">
                            Phone
                          </Form.Label>
                          <Form.Control
                            type="number"
                            onChange={inputHandler}
                            name="phone"
                            placeholder="(123) 456 - 789"
                          />
                          <span className="validationErr">
                            {validatioError.phoneError}
                          </span>
                        </Form.Group>
                      </Col>
                      <Col lg={6} className="mb-3">
                        <Form.Group controlId="exampleForm.ControlInput1">
                          <Form.Label className=" fw-bold">
                            Subject
                          </Form.Label>
                          <Form.Control
                            onChange={inputHandler}
                            type="text"
                            name="subject"
                            placeholder="Subject"
                          />
                          <span className="validationErr">
                            {validatioError.subjectError}
                          </span>
                        </Form.Group>
                      </Col>
                      <Col lg={12} className="mb-3">
                        <Form.Group controlId="formPlaintextPassword">
                          <Form.Label className=" fw-bold">
                            Message
                          </Form.Label>
                          <Form.Control
                            name="message"
                            onChange={inputHandler}
                            as="textarea"
                            rows={5}
                            className="h-auto"
                            placeholder="Please type your message here..."
                          />
                          <span className="validationErr">
                            {validatioError.messageError}
                          </span>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Button
                      variant="primary"
                      type="submit"
                      className="rounded-5 px-4 btn btn-primary"
                    >
                      Send message&nbsp;&nbsp;
                      <FaArrowRight />
                    </Button>
                  </Form>
                </Col>

                <Col lg={4} className=" mb-5">
                  <div className="contact-address bg-light px-4 py-3">
                    <div className="mb-5">
                      <div className="text-uppercase maincolor1 fw-bolder mb-2">
                        Our location
                      </div>
                      <p className="fw-bolder ">
                        91 Republic Street Newtown, Kitty, Georgetown, Guyana
                      </p>
                    </div>
                    <div className="mb-5">
                      <div className="text-uppercase maincolor1 fw-bolder mb-2">
                        MAILING ADDRESS
                      </div>
                      <p className="fw-bolder ">
                        8605 E BAYPOINT CIR PARKLAND, FLORIDA 33076
                      </p>
                    </div>
                    <div className="mb-5">
                      <div className="text-uppercase maincolor1 fw-bolder mb-2">
                        Call us
                      </div>
                      <p className="fw-bolder ">(592)725-7002 <br /> (592)677-2854 <br />(1)754-207-3727</p>
                    </div>
                    <div className="">
                      <div className="text-uppercase maincolor1 fw-bolder mb-2">
                        Email us
                      </div>
                      <p className="fw-bolder  mb-0">admin@propertynu.com</p>
                    </div>
                  </div>
                </Col>
              </Row>

              {/* <Maps selectedLocation={selectedLocation} /> */}
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3198.1992371480997!2d-58.14880083090852!3d6.813525578120249!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8dafef01e697660d%3A0xcb45e24e907b7b24!2s91%20Republic%20St%2C%20Georgetown%2C%20Guyana!5e1!3m2!1sen!2sin!4v1731418154410!5m2!1sen!2sin" width="100%" height="450" 
              style={{
                border : '0',
                laoding:"lazy",
                referrerPolicy :  "no-referrer-when-downgrade"
              }}></iframe>

            </Col>
          </Row>
        </Container>
      </section>

      <Footer />
    </>
  );
};

export default Contactus;
