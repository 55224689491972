import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Card, Modal } from "react-bootstrap";
import Header from '../afterLoggedin/commanComponents/usernavbar';
import "../../src/afterLoggedin/commanComponents/componentCSS/addlisting.css";
import "../../src/afterLoggedin/commanComponents/componentCSS/listingproperty.css";
import {
  getPropertyDetailAction,
  changePropertyStatusAction,
} from "../Action/user.action";
import { toast, Toaster } from "react-hot-toast";
import { Swiper, SwiperSlide } from "swiper/react";
import { useParams } from "react-router-dom";
import { Pagination, Navigation } from "swiper/modules";
import Swal from "sweetalert2";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../../src/afterLoggedin/commanComponents/componentCSS/swiper.css";
import {
  FaWifi,
  FaUtensils,
  FaSwimmingPool,
  FaChair,
  FaParking,
  FaChargingStation,
  FaDog,
  FaCameraRetro,
  FaBlenderPhone,
  FaNetworkWired,
  FaFan,
  FaHandsWash,
  FaHome,
  FaBed,
  FaBath,
  FaUsers,
  FaCar,
  FaClock,
  FaSmoking,
  FaRegCalendarAlt,
} from "react-icons/fa";
import { } from "react-icons/lu";
import { BsAlarm, BsFire, BsClipboard2Plus } from "react-icons/bs";
import { LuBadgeCheck } from "react-icons/lu";
import config from "../coreFIles/config";
import Cookies from 'js-cookie';
const loginData = (!Cookies.get('loginSuccessPropertyOwner')) ? [] : JSON.parse(Cookies.get('loginSuccessPropertyOwner'));

const PropertyDetails = () => {
  const { id } = useParams();
  const [listing, setlisting] = useState([]);
  const [imagesArr, setimagesArr] = useState([]);
  const [amenityArr, setAmenityArr] = useState([]);
  const [standoutAmenityArr, setStandoutAmenityArr] = useState([]);
  const [safetyHygieneArr, setSafetyHygieneArr] = useState([]);
  const [cancellationPolicies, setCancellationPolicies] = useState([]);
  const [roomTypeImages, setRoomTypeImages] = useState([]);
  const [isViewOpenImages, setisViewOpenImages] = useState(false);
  const [roomName, setRoomName] = useState('');

  useEffect(() => {
    getListingDetails();
  }, []);

  const getListingDetails = async () => {
    let res = await getPropertyDetailAction({ id: id });
    if (res.success) {

      if (parseInt(loginData?.id) != parseInt(res.data?.ownerId)) {
        window.location.href = `${config.baseUrl}staffdashboard/listingproperty`
      }

      setlisting(res.data);
      if (res.data.imageArr) {
        setimagesArr(JSON.parse(res.data.imageArr));
      }
      setAmenityArr(res.data.amenityArr);
      setStandoutAmenityArr(res.data.standoutPropertyAmenity);
      setSafetyHygieneArr(res.data.safetyHygieneArr);
      setCancellationPolicies(res.data.cancellationPolicies);
    }
  };

  const handleOpenviewImages = (imageArr, roomName) => {
    let images = JSON.parse(imageArr.roomsImages)
    setRoomTypeImages(images)
    setisViewOpenImages(true);
    setRoomName(roomName);
  }

  const handleCloseview = () => {
    setisViewOpenImages(false);
  };

  const changePropertyStatus = async (status) => {
    if (listing?.status == 0) {
      toast.error(
        "Your property is under review. Please wait for platform approval"
      );
      return;
    } else if (listing?.status == 2) {
      toast.error("Your property has been rejected from the platform.");
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await changePropertyStatusAction({ id: id, status: status });
        if (res.success) {
          toast.success(
            status == 1
              ? "Property activated successfully"
              : "Property deactivated successfully"
          );
          getListingDetails([]);
        } else {
          toast.error(res.msg);
        }
      }
    });
  };

  return (
    <div>
      <Header />
      <section className="propertydetail  pt-0 pb-0 overflow-hidden">
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          loop={true}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          {imagesArr.length > 0
            ? imagesArr.map((data) => (
              <SwiperSlide>
                <div>
                  <img src={data.fileNameImage} />
                </div>
              </SwiperSlide>
            ))
            : ""}
        </Swiper>
        <Container className="mt-5">
          <Toaster />
          <Row className="justify-space-between">
            <Col xl={9} lg={8} mb={8} sm={8}>
              <h3 className="mb-3">
                <b>{listing.propertyName}</b>
              </h3>
              <p className="mb-3">Location : {listing.location}</p>
              <p className="mb-3">Type Of Property: {listing.categoryName}</p>
            </Col>
            <Col xl={3} lg={4} mb={4} sm={4} className="">
              {listing?.isDeleted == 1 ? (
                <Button
                  onClick={() => changePropertyStatus(0)}
                  variant="primary"
                  className="rounded-5 h-auto mb-3 px-3"
                >
                  Deactivate Property
                </Button>
              ) : (
                <Button
                  onClick={() => changePropertyStatus(1)}
                  variant="primary"
                  className="rounded-2 h-auto mb-3"
                >
                  Activate Property
                </Button>
              )}

              <h6 className="text-success mb-3">
                <b>
                  {listing?.status == 1
                    ? "Listed"
                    : listing?.status == 2
                      ? "Rejected"
                      : "Under Review"}
                </b>
              </h6>
              <h6 className="text-grey mb-3">
                Price: ${listing.price}{" "}
                {listing.propertyPurchaseTypeId == 1 ||
                  listing.propertyPurchaseTypeId == 3
                  ? "Lump-sum"
                  : "Stay Cost Per Night"}{" "}
              </h6>

              <h6 className="text-grey mb-3">
                Cleaning & maintenance fee: ${listing.cleaningAndMaintenanceFee ? listing.cleaningAndMaintenanceFee : 0}
              </h6>

              {listing.cancellationPercentage ?
                <h6 className="text-grey mb-3">
                  Booking Cancellation Charges: {listing.cancellationPercentage ? listing.cancellationPercentage : 0}%
                </h6>
                : ""}

              <h6 className="text-grey mb-3">
                Property Listed For: {listing.propertyPurchaseType}
              </h6>
            </Col>
          </Row>
        </Container>
        <hr />
        <div className="amenities_list mb-4">
          <Container>
            <br />
            <Row>
              <Col lg={3} md={4} xs={6}>
                <div className="text-center">
                  <FaBed style={{ color: "#f25e2e" }} />
                  <p className="mt-2">{listing.numberOfBedrooms} Bedrooms</p>
                </div>
              </Col>
              <Col lg={3} md={4} xs={6}>
                <div className="text-center">
                  <FaBath style={{ color: "#f25e2e" }} />
                  <p className="mt-2">{listing.numberOfBathroom} Bathroom</p>
                </div>
              </Col>
              <Col lg={3} md={4} xs={6}>
                <div className="text-center">
                  <FaUsers style={{ color: "#f25e2e" }} />
                  <p className="mt-2">{listing.guestCount} Guests/per room</p>
                </div>
              </Col>
              <Col lg={3} md={4} xs={6}>
                <div className="text-center">
                  <FaCar style={{ color: "#f25e2e" }} />
                  <p className="mt-2">
                    {listing.carParking} Cars/{listing.bikeParking} Bikes
                  </p>
                </div>
              </Col>
              <Col lg={3} md={4} xs={6}>
                <div className="text-center">
                  <FaClock style={{ color: "#f25e2e" }} />
                  <p className="mt-2">
                    {listing.checkIn}{" "}
                    {parseInt(listing.checkIn) < 12 ? "AM" : "PM"} to{" "}
                    {listing.checkOut}{" "}
                    {parseInt(listing.checkOut) < 12 ? "AM" : "PM"}
                  </p>
                </div>
              </Col>
              <Col lg={3} md={4} xs={6}>
                <div className="text-center">
                  <FaDog style={{ color: "#f25e2e" }} />
                  <p className="mt-2">
                    {listing.petsAllowed ? listing.petsAllowed : 0} Pets Allowed
                  </p>
                </div>
              </Col>
              <Col lg={3} md={4} xs={6}>
                <div className="text-center">
                  <FaSmoking style={{ color: "#f25e2e" }} />
                  <p className="mt-2">
                    {!listing.isSmokingAllowed ||
                      listing.isSmokingAllowed == null
                      ? "Smoking Not Allowed"
                      : "Smoking Allowed"}
                  </p>
                </div>
              </Col>
              <Col lg={3} md={4} xs={6}>
                <div className="text-center">
                  <FaRegCalendarAlt style={{ color: "#f25e2e" }} />
                  <p className="mt-2">
                    {!listing.isEventAllowed || listing.isEventAllowed == null
                      ? "Event Not Allowed"
                      : "Event Allowed"}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {listing.description ?
          <>
            <div className="property-content mt-3">
              <Container>
                <Row>
                  <Col lg={12} className="mb-3">
                    <div>
                      <h5 className="mb-3">
                        <b>Property Description</b>
                      </h5>
                      <p>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: listing.description
                              ? listing.description
                              : "Not filled!!",
                          }}
                        />
                      </p>
                    </div>
                  </Col>

                  {listing.terms ?
                    <Col lg={12} className="mb-3">
                      <div>
                        <h5 className="mb-3">
                          <b>Property Policies</b>
                        </h5>
                        <p>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: listing.terms ? listing.terms : "Not filled!!",
                            }}
                          />
                        </p>
                      </div>
                    </Col>
                    : ""}

                  {listing?.cancellationAdditionalRules ?
                    <Col lg={12} className="mb-3">
                      <div>
                        <h5 className="mb-3">
                          <b>Booking Cancellation Policies</b>
                        </h5>
                        <p>
                          {listing?.cancellationAdditionalRules}
                        </p>
                      </div>
                    </Col>
                    : ""}
                </Row>
              </Container>
            </div>
            <hr />
          </>
          : ""}

        {amenityArr.length > 0 ?
          <div className="offered_amenities mt-4 mb-3">
            <Container>
              <Row>
                <Col lg={12} className="mb-3">
                  <div>
                    <h5 className="mb-3">
                      <b>Offered Amenities</b>
                    </h5>
                    <Row>
                      {amenityArr.length > 0 ? (
                        amenityArr.map((data) => (
                          <Col lg={4}>
                            <div className="">
                              <p>
                                {data.icon == "wifi" ? (
                                  <FaWifi />
                                ) : data.icon == "Workspace" ? (
                                  <FaChair />
                                ) : data.icon == "Parking" ? (
                                  <FaParking />
                                ) : data.icon == "Pool" ? (
                                  <FaSwimmingPool />
                                ) : data.icon == "Charger" ? (
                                  <FaChargingStation />
                                ) : data.icon == "Pets" ? (
                                  <FaDog />
                                ) : data.icon == "Cameras" ? (
                                  <FaCameraRetro />
                                ) : data.icon == "Kitchen" ? (
                                  <FaUtensils />
                                ) : data.icon == "Television" ? (
                                  <FaBlenderPhone />
                                ) : data.icon == "Internet" ? (
                                  <FaNetworkWired />
                                ) : data.icon == "ac" ? (
                                  <FaFan />
                                ) : data.icon == "Washer" ? (
                                  <FaHandsWash />
                                ) : data.icon == "Balcony" ? (
                                  <FaHome />
                                ) : data.icon == "Cleaning" ? (
                                  <FaHandsWash />
                                ) : (
                                  <LuBadgeCheck />
                                )}
                                &nbsp;{data.name}
                              </p>
                            </div>
                          </Col>
                        ))
                      ) : (
                        <span className="mb-3">Not filled!!</span>
                      )}
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          : ""}

        {standoutAmenityArr.length > 0 ?
          <div className="offered_amenities mt-4 mb-3">
            <hr />
            <Container>
              <Row>
                <Col lg={12} className="mb-3">
                  <div>
                    <h5 className="mb-3">
                      <b>Standout Amenities</b>
                    </h5>
                    <Row>
                      {standoutAmenityArr.length > 0 ? (
                        standoutAmenityArr.map((data) => (
                          <Col lg={4}>
                            <div className="">
                              <p>&nbsp;{data.name}</p>
                            </div>
                          </Col>
                        ))
                      ) : (
                        <span className="mb-3">Not filled!!</span>
                      )}
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          : ""}

        {standoutAmenityArr.length > 0 ? <hr /> : ""}
        <div className="safety_and_hygiene mt-4 mb-3">
          <Container>
            {safetyHygieneArr.length > 0 ? (
              <Row>
                <Col lg={12} className="mb-3">
                  <div>
                    <h5 className="mb-3">
                      <b>Safety and Hygiene</b>
                    </h5>
                    <Row>
                      {safetyHygieneArr.length > 0 ? (
                        safetyHygieneArr.map((data) => (
                          <Col lg={4}>
                            <p>
                              {data.safetyId == 1 ? (
                                <BsAlarm />
                              ) : data.safetyId == 2 ? (
                                <BsClipboard2Plus />
                              ) : data.safetyId == 3 ? (
                                <BsFire />
                              ) : data.safetyId == 4 ? (
                                <BsAlarm />
                              ) : (
                                ""
                              )}
                              &nbsp;{data.name}
                            </p>
                          </Col>
                        ))
                      ) : (
                        <span className="mb-3">Not filled!!</span>
                      )}
                    </Row>
                  </div>
                </Col>
              </Row>
            ) : (
              ""
            )}
          </Container>
        </div>

        {/* {safetyHygieneArr.length > 0 ? <hr /> : ""}
        <div className="safety_and_hygiene mt-4 mb-3">
          <Container>
            <Row>
              <Col lg={12} className="mb-3">
                <div>
                  <h5 className="mb-3">
                    <b>Rooms & Spaces</b>
                  </h5>
                  <Row>
                    <Col lg={3}>Gym : {listing.gym ? listing.gym : "0"}</Col>
                    <Col lg={3}>Beds : {listing.beds ? listing.beds : "0"}</Col>
                    <Col lg={3}>
                      Kitchen : {listing.kitchen ? listing.kitchen : "0"}
                    </Col>
                    <Col lg={3}>
                      Utility Room :{" "}
                      {listing.utilityRoom ? listing.utilityRoom : "0"}
                    </Col>

                    <Col lg={3}>
                      Living Room :{" "}
                      {listing.livingRoom ? listing.livingRoom : "0"}
                    </Col>
                    <Col lg={3}>
                      Dining Area :{" "}
                      {listing.diningArea ? listing.diningArea : "0"}
                    </Col>
                    <Col lg={3}>
                      Office : {listing.office ? listing.office : "0"}
                    </Col>
                    <Col lg={3}>
                      Garden : {listing.garden ? listing.garden : "0"}
                    </Col>

                    <Col lg={3}>Pool : {listing.pool ? listing.pool : "0"}</Col>
                    <Col lg={3}>
                      HotTub : {listing.hotTub ? listing.hotTub : "0"}
                    </Col>
                    <Col lg={3}>
                      Garage : {listing.garage ? listing.garage : "0"}
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <hr />
            {cancellationPolicies.length > 0 ? (
              <Col lg={12} className="mb-3">
                <div>
                  <h5 className="mb-3">
                    <b>Cancellation Policy</b>
                  </h5>
                  {cancellationPolicies.length > 0 ? (
                    cancellationPolicies.map((data) => (
                      <p>
                        <h6>
                          <b>{data.name}</b>
                        </h6>
                        {data.description}
                      </p>
                    ))
                  ) : (
                    <p>Not filled!!</p>
                  )}
                </div>
              </Col>
            ) : (
              ""
            )}
            {cancellationPolicies.length > 0 ? <hr /> : ""}
            {listing.additionalRules ? (
              <Col lg={12} className="mb-3">
                <div>
                  <h5 className="mb-3">
                    <b>Additional Rules</b>
                  </h5>
                  {listing.additionalRules ? (
                    listing.additionalRules
                  ) : (
                    <p>Not filled!!</p>
                  )}
                </div>
              </Col>
            ) : (
              ""
            )}
          </Container>
        </div> */}

        {/* {cancellationPolicies.length > 0 ? <hr /> : ""} */}

        <div className="safety_and_hygiene mt-4 mb-3">
          <Container>
            <Row>
              {listing?.getPropertyRoomTypeImages == "" || listing?.getPropertyRoomTypeImages == null || listing?.getPropertyRoomTypeImages == undefined ?
                "" :
                <Col lg={12} className="">
                  <h5 className="mb-3">
                    <b>Room Types</b>
                  </h5>
                  <div>
                    <Row>
                      {listing?.getPropertyRoomTypeImages.map(roomImg => (
                        <Col lg={4} className="my-3">
                          <h6 className="mb-3">
                            <b>{roomImg.roomName} Room</b>
                          </h6>
                          <Card className="border-0">
                            <div class="post-image-card position-relative">
                              <div class="rounded-2 bg-black overflow-hidden">
                                <img
                                  class="card-img-top opacity-75"
                                  src={roomImg.fileName}
                                  style={{ width: "100%", height: "250px" }}
                                />
                              </div>
                              <div className="image-h ">
                                <button type="button" class="viewmore position-absolute rounded-2 btn-sm h-auto text-white border btn btn-default" onClick={() => handleOpenviewImages(roomImg, roomImg.roomName)}>View More</button>
                              </div>
                            </div>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </div>
                </Col>
              }
              <hr />

              {!listing.propertyVideo ||
                listing.propertyVideo == null ||
                listing.propertyVideo == undefined ?
                "" :
                <Col lg={12} className="mb-3">
                  <div>
                    <h5 className="mb-3">
                      <b>Video</b>
                    </h5>
                    <Row>
                      <div className="propertyVideo">
                        <video width="100px" controls>
                          <source src={listing.propertyVideo} />
                        </video>
                      </div>
                      <br />
                      <br />
                      <a href={listing.propertyVideo} target="_blank">
                        <Button
                          variant="primary"
                          className="rounded-2 h-auto mb-3"
                        >
                          View Video
                        </Button>
                      </a>
                    </Row>
                  </div>
                </Col>
              }
            </Row>
          </Container>
        </div>

        <Modal
          show={isViewOpenImages}
          onHide={() => handleCloseview(false)}
          dialogClassName="modal-90w modal-right "
          aria-labelledby="example-custom-modal-styling-title"
          className="hotelImages"
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <b>{roomName} Room</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              <Swiper
                pagination={{
                  type: "fraction",
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="mySwiper"
              >
                {roomTypeImages && roomTypeImages.length > 0
                  ? roomTypeImages.map((obj) => {
                    return (
                      <SwiperSlide>
                        <img src={obj.fileNameImage} />
                      </SwiperSlide>
                    );
                  })
                  : ""}
              </Swiper>
            </>
          </Modal.Body>
        </Modal>
      </section>
    </div>
  );
};

export default PropertyDetails;
