import React, { useState, useEffect } from "react";
import Header from '../generalPages/directives/header';
import Footer from '../generalPages/directives/footer';
import './generalCSS/home.css'
import './generalCSS/generalnavbar.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { getPrivacyPolicyAction } from "../Action/user.action";
import { Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import config from "../coreFIles/config";

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const [privacyPolicy, setPrivacyPolicy] = useState([])

  useEffect(() => {
    getPrivacyPolicyAPI();
  }, []);

  const getPrivacyPolicyAPI = async () => {
    let res = await getPrivacyPolicyAction();
    if (res.success) {
      setPrivacyPolicy(res.data)
    }
  };
  const handleBack = () => {
    navigate(`${config.baseUrl}`)
  };
  return (
    <>
    
      <section className='destination-area'>
        <Container>
          <Row>
            <Col lg={12} className='mb-5'>
     
             
               
              <div className='text-center'>
                <h2 className='text-black'><b>Privacy Policy</b></h2>
                <div class="section-title-line"> </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} className='mb-3'>
              <div className="mt-4">
                {privacyPolicy ?
                  <>
                    <div dangerouslySetInnerHTML={{ __html: privacyPolicy?.description }} />
                  </>
                  : 'No data found'}
              </div>
            </Col>
          </Row>
        </Container>

      </section >

    

    </>
  )
}

export default PrivacyPolicy
