import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Card,
    Button,
    Form,
} from "react-bootstrap";
import Header from "../../../../commanComponents/usernavbar";
import { addPaymentDetail } from "../../../../../Action/propertyOwner.action";
import "../../../css/staffdashboard.css";
import "../../../css/finance.css";
import { toast, Toaster } from "react-hot-toast";
import config from "../../../../../config";
import { FaPlus, FaArrowLeftLong } from "react-icons/fa6";


const AddPayment = () => {
    const { id } = useParams();

    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        employeeId: "",
        amount: "",
        paymentDate: "",
        paymentMode: "",
    });

    const [errors, setErrors] = useState({});

    useEffect(() => {
        document.body.classList.add("staffbody");
        return () => {
            document.body.classList.remove("staffbody");
        };
    }, []);

    const validateForm = () => {
        let formErrors = {};
        if (!formData.amount) formErrors.amount = "Amount is required";
        if (!formData.paymentDate)
            formErrors.paymentDate = "Payment Date is required";
        if (!formData.paymentMode)
            formErrors.paymentMode = "Payment Mode is required";
        return formErrors;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async () => {
        const formValidationErrors = validateForm();
        if (Object.keys(formValidationErrors).length === 0) {
            const reqData = {
                employeeId: id,
                amount: parseFloat(formData.amount).toFixed(2),
                paymentDate: formData.paymentDate,
                paymentMode: formData.paymentMode,
            };

            try {
                const res = await addPaymentDetail(reqData);
                if (res.success) {
                    toast.success(res.msg);
                    navigate(`/paymentlist/${id}`);
                } else {
                    toast.error(res.msg);
                }
            } catch (error) {
                console.error("Error adding payment:", error);
                alert("Internal Server Error");
            }
        } else {
            setErrors(formValidationErrors);
        }
    };

    return (
        <div className="staffdashboard bg-pink">
            <Header />
            <Toaster />

            <section className="listing pt-5 pb-5 overflow-hidden vh-80">
                <Container>
                    <Row>
                        <Col lg={5} sm={12} xs={12} className="mb-4">
                            <Link to={`${config.baseurl}paymentlist/${id}`}>
                                <Button variant="default" className="p-0 h-auto">
                                    <FaArrowLeftLong size={`25px`} />
                                </Button>
                            </Link>
                        </Col>
                    </Row>

                    <Col lg={12} className="mb-3">
                        <Card className="border-0 bg-white p-3 pt-sm-4 px-sm-5">
                            <Card.Header className="border-0 mb-0 px-sm-5">
                                <h4 className="mb-0 fw-bolder">Add Payment</h4>
                            </Card.Header>
                            <Card.Body className="p-0 px-sm-5">
                                <Row className="mt-2">

                                    <Col lg={6} className="mb-4">
                                        <Form.Group>
                                            <Form.Label className="text-light-grey mb-1">
                                                Amount
                                            </Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="amount"
                                                value={formData.amount}
                                                onChange={handleChange}
                                                className="rounded-5"
                                            />
                                            {errors.amount && (
                                                <p className="text-danger">{errors.amount}</p>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6} className="mb-4">
                                        <Form.Group>
                                            <Form.Label className="text-light-grey mb-1">
                                                Payment Date
                                            </Form.Label>
                                            <Form.Control
                                                type="date"
                                                name="paymentDate"
                                                value={formData.paymentDate}
                                                onChange={handleChange}
                                                className="rounded-5"
                                            />
                                            {errors.paymentDate && (
                                                <p className="text-danger">{errors.paymentDate}</p>
                                            )}
                                        </Form.Group>
                                    </Col>

                                    <Col lg={6} className="mb-4">
                                        <Form.Group>
                                            <Form.Label className="text-light-grey mb-1">
                                                Payment Method
                                            </Form.Label>
                                            <Form.Select
                                                name="paymentMode"
                                                value={formData.paymentMode}
                                                onChange={handleChange}
                                                className="rounded-5"
                                            >
                                                <option value="">Select Payment Method</option>
                                                <option value="1">Cash</option>
                                                <option value="2">Card</option>
                                                <option value="3">Bank Transfer</option>
                                            </Form.Select>
                                            {errors.paymentMode && (
                                                <p className="text-danger">{errors.paymentMode}</p>
                                            )}
                                        </Form.Group>
                                    </Col>

                                </Row>

                                <Button
                                    variant="primary"
                                    onClick={handleSubmit}
                                    className="rounded-5"
                                >
                                    Add Payment
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                </Container>
            </section>
        </div>
    );
};

export default AddPayment;
