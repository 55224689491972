import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import "./generalCSS/login.css";
import "./generalCSS/mobileverification.css";
import "./generalCSS/subscriptionplan.css";
import CryptoJS from 'crypto-js';
import {
  getSubscriptionPlanActionForApp
} from "../Action/propertyOwner.action";
import { getFygaroJwtForApp } from "../Action/user.action";
import { Container, Row, Col, Card } from "react-bootstrap";
import config from "../coreFIles/config";
import { toast, Toaster } from "react-hot-toast";
import Cookies from "js-cookie";
const loginData = !Cookies.get("loginSuccessPropertyOwner")
  ? []
  : JSON.parse(Cookies.get("loginSuccessPropertyOwner"));

const Subscriptionplan = () => {
  const [chooseplanDetails, setChoosePlanDetails] = useState({});
  const [fygaroToken, setFygaroToken] = useState('');
  const url = window.location.href;
  const urlParams = new URL(url);
  const params = new URLSearchParams(urlParams.search);
  const id = params.get('id');
  const JWT = params.get('JWT');

  useEffect(() => {
    getSubscriptionplan(id);
  }, [id]);


  const getSubscriptionplan = async () => {
    let res = await getSubscriptionPlanActionForApp({auth: JWT});
    if (res.success) {
      getFygaroPayloadAPI()
      let planData = res.data.filter((item) => item.id == id);
      console.log('planData', planData[0]);
      setChoosePlanDetails(planData[0])
    }
  };

  // const choosePlan = async (id) => {
  //   let res = await checkPlanPurchaseAction({ id: id });
  //   if (res.success) {
  //     setChoosePlanDetails(data);
  //     getFygaroPayloadAPI(data)
  //   } else {
  //     toast.error(res.msg);
  //   }
  // };

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>   Get fygaro JWT token >>>>>>>>>>>>>>>>>>>>>>>>>>>>.

  const getFygaroPayloadAPI = async () => {
    const encryptionKey = config.fygaroJWTEncryptionKey;
    let fygaroPayload = {
      subscriptionId: id,
      type: 3
    };
    console.log('fygaroPayload', fygaroPayload);
    const encryptedPayload = CryptoJS.AES.encrypt(
      JSON.stringify(fygaroPayload),
      encryptionKey
    ).toString();

    let jwtReceived = await getFygaroJwtForApp({ jwt: encryptedPayload, auth: JWT });
    if (jwtReceived.success) {
      setFygaroToken(jwtReceived.data);
    }
  };

  const callFygaro = () => {
    if (fygaroToken) {
      let fygaroString = `https://www.fygaro.com/en/pb/8ef036d5-3406-4b0d-858f-8d814667d469?jwt=${fygaroToken}`;
      window.open(fygaroString, '_blank');
    } else {
      toast.error('Something went wrong please try again after some time.');
    }
  };

  return (
    <>
      <section className="login-section pt-5 pb-5 overflow-hidden vh-80">
        <Container>
          <Toaster />
          <Row className="justify-content-center ">
            <Col xl={12} lg={12}>
              <div className="login-container">
                <div className="login-form">
                  <div className="login-logo ">
                    <Link to={`${config.baseUrl}`} className="">
                      <img
                        src="assets/images/logo/logo.webp"
                        alt="logo"
                        className=""
                      />
                    </Link>
                    <div className="pt-4">
                      <Row className="align-items-center">
                        <Col xl={2} lg={2} sm={2}>

                        </Col>
                        <Col xl={8} lg={8} sm={8}>
                          <div className="text-center">
                            <h3 className="mb-4 position-relative">
                              <b>PLAN DETAILS</b>
                            </h3>
                          </div>
                        </Col>
                        <Col xl={2}></Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={12} lg={12}>
              <div className="subscriptionplanDetails">
                <Row>
                  <Col xl={3} lg={3} className="mb-4"></Col>
                  <Col xl={6} lg={6} className="mb-4">
                    <Card>
                      <div className="d-flex align-items-center">
                        <Card.Img
                          variant="top"
                          src="assets/images/plana.png"
                          width="60px"
                          className="me-2"
                        />
                        <h5 className="mb-0">
                          <b>{chooseplanDetails.subscriptionType}</b> (
                          {chooseplanDetails.name}){" "}
                        </h5>
                      </div>
                      <Card.Body className="pb-0">
                        <p>{chooseplanDetails.description} </p>
                        <div className="d-flex align-items-center pb-3">
                          <h2>
                            <b>${chooseplanDetails.amount}</b>
                          </h2>
                          <span>
                            / {chooseplanDetails.validity}{" "}
                            {chooseplanDetails.validity == 1
                              ? "Month"
                              : "Months"}
                          </span>
                        </div>
                        <Card.Text>
                          <h6>
                            <b>What’s included</b>
                          </h6>
                          <ul>
                            {chooseplanDetails.IncludedDetails &&
                              chooseplanDetails.IncludedDetails.map(
                                (data) => (
                                  <li>
                                    <img
                                      src="assets/images/icon/check_circle.svg"
                                      className="me-1"
                                    />
                                    {data?.name}
                                  </li>
                                )
                              )}
                          </ul>
                        </Card.Text>

                        {/* <button className="btn paypal-button w-100 mt-2" onClick={callFygaro}>Pay Now</button> */}
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col xl={3} lg={3} className="mb-4"></Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Subscriptionplan;