import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, Button, Dropdown, Form } from "react-bootstrap";
import DataTable, { createTheme } from 'react-data-table-component';
import { Link } from "react-router-dom";
import config from "../../../../config";
import config1 from "../../../../coreFIles/config";
import Header from "../../../commanComponents/usernavbar"
import RevenueChart from "../RevenueChart"
import Testimonial from "./VihicleTestimonal"
import Browseservice from "../browseservice";
import CanvasJSReact from '@canvasjs/react-charts';
import "../../css/staffdashboard.css"
import Revenue from "../../../commanComponents/revenue";
import CheckUserSubscription from "../../../commanComponents/CheckUserSubscription";


import {
    getUserProfileAction,
    getVehicleBookingListAction,
    getVehicleListAction,
    getVehicleDashboardStatisticsAction,
} from '../../../../Action/user.action';



const Vehicle = () => {

    const CanvasJSChart = CanvasJSReact.CanvasJSChart;
    const navigate = useNavigate();

    useEffect(() => {
        // Add the class to the body tag
        document.body.classList.add('staffbody');

        // Cleanup function to remove the class when the component is unmounted
        return () => {
            document.body.classList.remove('staffbody');
        };
    }, []);


    createTheme('solarized', {
        text: {
            primary: '#000',
            secondary: '#2aa198',
        },
        background: {
            default: '',
        },
        context: {
            background: '#cb4b16',
            text: '#FFFFFF',
        },
        divider: {
            default: '#EBEBEB',
        },
        action: {
            button: 'rgba(0,0,0,.54)',
            hover: 'rgba(0,0,0,.08)',
            disabled: 'rgba(0,0,0,.12)',
        },
    }, 'dark');

    const customStyles = {
        rows: {
            style: {
                minHeight: '70px', // override the row height

            },
        },
        headCells: {

            style: {
                minHeight: '60px',
                paddingLeft: '20px', // override the cell padding for head cells
                paddingRight: '20px',
                background: '#FFF8F4',

            },
        },
        cells: {
            style: {
                paddingLeft: '20px', // override the cell padding for data cells
                paddingRight: '20px',
            },
        },
    };




    const [bookingList, setBookingList] = useState([]);
    const [userProfile, setUserProfile] = useState([]);
    const [listing, setlisting] = useState([]);
    const [dashboardStatistics, setDashboardStatistics] = useState({});

    useEffect(() => {
        getBookingListAPI(1);
        getProfileAPI();
        getListingDetails();
        getVehicleDashboardStatisticsAPI();
    }, []);

    const columns = [
        {
            name: 'Vehicle Name',
            selector: row => row.vehicleName,
        },
        {
            name: 'Vehicle Type',
            selector: row => row.vehicleType,
        },
        {
            name: 'Passenger Name',
            selector: row => row.userName,
        },
        {
            name: 'Pickup Location',
            selector: row => row.location,
        },
        {
            name: 'Pick Up - Drop Off',
            selector: row => `${row.checkInDate} ${row.checkInTime} - ${row.checkOutDate} ${row.checkOutTime}`,
        },

        {
            name: 'Booked',
            selector: row => row.dateTime,
        },
        {
            name: 'Payment',
            selector: row => row.amount ? '$' + row.amount : '$0',
        },

        {
            name: "Status",
            selector: (row) =>
                row.status == 1 ? (
                    <>
                        {" "}
                        <span style={{ color: "#10A330" }}>Confirmed</span>
                    </>
                ) : row.status == 2 ? (
                    <>
                        {" "}
                        <span style={{ color: "#F93232" }}>Cancelled</span>{" "}
                    </>
                ) : row.status == 3 ? (
                    <>
                        {" "}
                        <span style={{ color: "#10A330" }}>Completed</span>{" "}
                    </>
                ) : row.status == 4 ? (
                    <>
                        {" "}
                        <span style={{ color: "#F93232" }}>Rejected</span>{" "}
                    </>
                ) : (
                    <>
                        {" "}
                        <span style={{ color: "#10A330" }}>Ongoing</span>{" "}
                    </>
                ),
            sortable: true,
        },

    ];

    const getBookingListAPI = async (filterType) => {
        console.log(filterType, "checking filter type");
        let res = await getVehicleBookingListAction({ filterType: filterType });
        if (res.success) {
            setBookingList(res.data);
        } else {
            setBookingList([]);
        }
    };
    const getProfileAPI = async () => {
        let res = await getUserProfileAction();
        if (res.success) {
            setUserProfile(res.data);
        }
    };
    const getListingDetails = async (filterType = 0) => {
        let res = await getVehicleListAction({ filterType: filterType });
        if (res) {
            setlisting(res.data);
        } else {
            setlisting([]);
        }
    };

    const getVehicleDashboardStatisticsAPI = async () => {
        let res = await getVehicleDashboardStatisticsAction();
        if (res.success) {
            setDashboardStatistics(res.data);
        }
    };

    const navigateRoute = () => {
        setTimeout(() => {
            navigate(`${config.baseurl}${config.loginurl}`, { state: "prop" });
            window.location.reload();
        });
    };


    const data = [
        {
            id: 1,
            name: 'Toyota Fortuner',
            vehicletype: 'SUV',
            passengername: 'Mark Smith',
            pickuplocation: 'Manhattan, NYC',
            dropoff: 'Time Squire',
            booked: '2 Person',
            payment: 'Paid',
            status: 'Booked',
        },
        {
            id: 2,
            name: 'Toyota Fortuner',
            vehicletype: 'SUV',
            passengername: 'Mark Smith',
            pickuplocation: 'Manhattan, NYC',
            dropoff: 'Time Squire',
            booked: '2 Person',
            payment: 'Paid',
            status: 'Booked',
        },
        {
            id: 3,
            name: 'Toyota Fortuner',
            vehicletype: 'SUV',
            passengername: 'Mark Smith',
            pickuplocation: 'Manhattan, NYC',
            dropoff: 'Time Squire',
            booked: '2 Person',
            payment: 'Paid',
            status: 'Booked',
        },
    ]


    return (
        <div className="staffdashboard bg-pink">
            <Header />
            <CheckUserSubscription type={2} page="vehicleDashboard" />
            <section className="listing pt-5 pb-5 overflow-hidden vh-80">
                <Container>
                    <Row className="">
                        <Col lg={5} sm={12} xs={12} className="mb-4">
                            <div className="d-flex profileMobile align-items-center">
                                {!userProfile?.profile ||
                                    userProfile?.profile == undefined ||
                                    userProfile?.profile == null ? (
                                    <img
                                        src="assets/images/profile-img.png"
                                        width="50px"
                                        height="50px"
                                        style={{ objectFit: "cover", borderRadius: "50%" }}
                                    />
                                ) : (
                                    <img
                                        // src={`${config1.imageUrl + userProfile?.profile}`}
                                        // height="50px"
                                        // width="50px"
                                        // style={{ objectFit: "cover", borderRadius: "50%" }}
                                        // className=""
                                        src={userProfile?.profile}
                                        height="50px"
                                        width="50px"
                                        style={{ objectFit: "cover", borderRadius: "50%" }}
                                        className=""
                                    />
                                )}

                                &nbsp;&nbsp;{" "}
                                <div className="profileContent d-flex align-items-center">
                                    <div className="">
                                        {/* <p className="mb-0 text-lighter-grey d-md-none">Hi John Doe,</p> */}
                                        <div>
                                            <h5 className="mb-0 ms-2 me-3 fw-bold">Hi, {userProfile?.firstName
                                                ? userProfile?.firstName
                                                : "NA"}{" "}
                                                {userProfile?.lastName ? userProfile?.lastName : ""}{" "}</h5>
                                        </div>
                                    </div>

                                    <img src="images/icon/hand.svg" width={`37px`} />
                                </div>
                            </div>

                        </Col>
                        <Col lg={7} sm={12} xs={12} className=" text-right">
                            {/* <Link to={`${config.baseurl}servicedashboard`}>
                                <Button
                                    variant="outline-primary"
                                    className=" rounded-5 me-2 px-4 px-xs-1 mb-4 mobileBtn"
                                >
                                    Service Dashboard
                                </Button>
                            </Link> */}
                            <a href={`${config.baseurl}staffdashboard`}>
                                <Button
                                    variant="primary"
                                    className=" rounded-5 me-2 px-4 px-xs-1 mb-4 mobileBtn"
                                >
                                    Property Dashboard
                                </Button>
                            </a>
                        </Col>


                        <Col xl={12} lg={12}>
                            <div className="">
                                <Row>
                                    <Col xl={3} lg={6} md={6} sm={6} className="mb-4">
                                        <Card className="border-0">
                                            <Link
                                                className="dashboardLink text-black"
                                                to={`${config.baseurl}staffdashboard/listingVehicle`}

                                            >
                                                <Card.Body className="">
                                                    <div className="d-flex align-items-center ">
                                                        <div className="me-xxl-4 me-xl-3 me-4 circleIcon">
                                                            <img
                                                                src="images/icon/car.svg"
                                                                width="36px"
                                                            />
                                                        </div>
                                                        <div>
                                                            <p className="mb-1 text-light-grey">Listed Vehicles</p>
                                                            <h4>
                                                                <b>
                                                                    {parseInt(dashboardStatistics?.totalVehicle) >
                                                                        0
                                                                        ? parseInt(
                                                                            dashboardStatistics?.totalVehicle
                                                                        )
                                                                        : 0}
                                                                </b>
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Link>
                                        </Card>
                                    </Col>
                                    <Col xl={3} lg={6} md={6} sm={6} className="mb-4">
                                        <Card className="border-0">
                                            <Link
                                                className="dashboardLink text-black"
                                                to={`${config.baseurl}staffdashboard/listingVehicle`}
                                            >
                                                <Card.Body className="">
                                                    <div className="d-flex align-items-center ">
                                                        <div className="me-xxl-4 me-xl-3 me-4 circleIcon">
                                                            <img
                                                                src="images/icon/steering.svg"
                                                                width="36px"
                                                            />
                                                        </div>
                                                        <div>
                                                            <p className="mb-1 text-light-grey">Vacant Vehicles</p>
                                                            <h4>
                                                                <b>
                                                                    {parseInt(dashboardStatistics?.totalVehicle) >
                                                                        0
                                                                        ? parseInt(
                                                                            dashboardStatistics?.totalVehicle
                                                                        ) -
                                                                        parseInt(
                                                                            dashboardStatistics?.totalVehicleOccupied
                                                                        )
                                                                        : 0}
                                                                </b>
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Link>
                                        </Card>
                                    </Col>
                                    <Col xl={3} lg={6} md={6} sm={6} className="mb-4">
                                        <Card className="border-0">
                                            <Link
                                                className="dashboardLink text-black"
                                                to={`${config.baseurl}staffdashboard/vehicleReservation`}
                                            >
                                                <Card.Body className="">
                                                    <div className="d-flex align-items-center ">
                                                        <div className="me-xxl-4 me-xl-3 me-4 circleIcon">
                                                            <img
                                                                src="images/icon/circlecar.svg"
                                                                width="36px"
                                                            />
                                                        </div>
                                                        <div>
                                                            <p className="mb-1 text-light-grey">Occupied Vehicles</p>
                                                            <h4>
                                                                <b>
                                                                    {parseInt(
                                                                        dashboardStatistics?.totalVehicleOccupied
                                                                    ) > 0
                                                                        ? parseInt(
                                                                            dashboardStatistics?.totalVehicleOccupied
                                                                        )
                                                                        : 0}
                                                                </b>
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Link>
                                        </Card>
                                    </Col>
                                    <Col xl={3} lg={6} md={6} sm={6} className="mb-4">
                                        <Card className="border-0">
                                            <Link
                                                className="dashboardLink text-black"
                                                to={`${config.baseurl}staffdashboard/vehicleReservation/cancelled-vehicle`}
                                            >
                                                <Card.Body className="">
                                                    <div className="d-flex align-items-center ">
                                                        <div className="me-xxl-4 me-xl-3 me-4 circleIcon">
                                                            <img
                                                                src="images/icon/cancelletvehicle.svg"
                                                                width="36px"
                                                            />
                                                        </div>
                                                        <div>
                                                            <p className="mb-1 text-light-grey">Cancelled Vehicles</p>
                                                            <h4>
                                                                <b>
                                                                    {parseInt(
                                                                        dashboardStatistics?.totalVehicleCancelled
                                                                    ) > 0
                                                                        ? parseInt(
                                                                            dashboardStatistics?.totalVehicleCancelled
                                                                        )
                                                                        : 0}
                                                                </b>
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Link>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                            <Col xl={12} lg={12} className="mb-4">
                                {/* <div className="graph">
                                    <Card className="border-0">
                                        <Card.Body>
                                            <Row className="mt-2" >
                                                <Col xl={8} lg={12} className="mb-3">
                                                    <div>
                                                        <Row className=" justify-content-between align-items-center mb-3">
                                                            <Col lg={3}>
                                                                <p className="fs-5 text-grey mb-2">Total Revenue</p>
                                                                <h4>$12,500.36</h4>
                                                            </Col>
                                                            <Col lg={9} className="text-end">
                                                                <Row className=" mt-2">

                                                                    <Col lg={5} className="mb-4">
                                                                        <div className="position-relative">
                                                                            <Form.Control
                                                                                type="date"
                                                                                placeholder="Date"
                                                                                aria-label="firstName"
                                                                                name="Date"
                                                                            />
                                                                            <span className="text-light-grey inner-field small">From</span>
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={5} className="mb-4">
                                                                        <div className="position-relative">
                                                                            <Form.Control
                                                                                type="date"
                                                                                placeholder="Date"
                                                                                aria-label="firstName"
                                                                                name="Date"
                                                                            />
                                                                            <span className="text-light-grey inner-field small">To</span>
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={2} className="mb-4 ">
                                                                        <Form.Select aria-label="Default select example">
                                                                            <option>1 Month</option>
                                                                            <option value="1">2 Month</option>
                                                                            <option value="2">3 Month</option>
                                                                            <option value="3">4 Month</option>
                                                                        </Form.Select>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <RevenueChart />
                                                    </div>
                                                </Col>
                                                <Col xl={4} lg={12} className="mb-3">
                                                    <div className="staff_upcoming_booking totalbooking ps-4  position-relative">
                                                        <div className="text-center tboxs card-light-orange d-inline-block px-4 py-3 rounded-3">
                                                            <h6 className="text-primary">Today’s Total Booking</h6>
                                                            <h2>25</h2>
                                                        </div>
                                                    </div>
                                                </Col>

                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </div> */}
                                <Revenue />
                            </Col>
                            <div className="">
                                <Row className="mt-2" >

                                    <Col xl={12} lg={12} className="mb-4">
                                        <div className="staff_upcoming_booking">
                                            <Card className="border-0">
                                                <Card.Body>
                                                    <div className="d-sm-flex align-items-center justify-content-between ">
                                                        <div className="d-flex align-items-end mb-3"><h4 className="mb-0 fw-bolder">Upcoming Bookings </h4>&nbsp;<div>({bookingList.length})</div></div>
                                                        <div className="text-end mb-3">
                                                            <Link
                                                            to={`${config.baseurl + config.loginurl}/vehicleReservation`}
                                                        > <Button variant="default" className="h-auto rounded-5 border-0 text-primary p-0">View all</Button>  </Link></div>
                                                    </div>
                                                    <div>


                                                        <Row className="align-items-center">
                                                            <Col lg={12} xs={12}>
                                                                <div className="">
                                                                    <DataTable
                                                                        columns={columns}
                                                                        data={bookingList}
                                                                        customStyles={customStyles}
                                                                        theme="solarized"
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>

                                                </Card.Body>
                                            </Card>

                                        </div>
                                    </Col>

                                </Row>

                            </div>
                            <div className="testimonial mb-4">
                                <Col lg={12}>
                                    <Card className="border-0 bg-white p-3">
                                        <Card.Header>
                                            <div className="d-flex justify-content-between ">
                                                <div className="d-flex align-items-end"><h4 className="mb-0 fw-bolder">Latest Reviews </h4></div>
                                                <div><Button onClick={() =>
                                                    navigate(
                                                        `${config.baseurl + config.loginurl}/Ratings`,
                                                        { state: "Vehicle" }
                                                    )
                                                } variant="default" className="border-0 p-0 h-auto text-primary">See all</Button></div>
                                            </div>

                                        </Card.Header>
                                        <Card.Body className="pt-0 px-0">
                                            <div className="testimonialSlider">
                                                <Testimonial />
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </div>
                            <div className="services">
                                <Col lg={12}>
                                    <Card className="border-0 bg-white p-3">
                                        <Card.Header>
                                            <div className="d-flex justify-content-between ">
                                                <div className="d-flex align-items-end"><h4 className="mb-0 fw-bolder">Browse Services </h4></div>
                                                <div><Button variant="default" className="border-0 p-0 h-auto text-primary">See all</Button></div>
                                            </div>

                                        </Card.Header>
                                        <Card.Body className="pt-0 px-0">
                                            <div className="">
                                                <Browseservice />
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </section>
        </div>
    );
};

export default Vehicle;
