import React, { useState, useEffect } from "react";
import Header from "../generalPages/directives/header";
import Footer from "../generalPages/directives/footer";
import "./generalCSS/home.css";
import "./generalCSS/generalnavbar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { getMoreSuggestionAction } from "../Action/user.action";
import { Container, Row, Col } from "react-bootstrap";
import config from "../coreFIles/config";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const { id } = useParams();
  const [topDestination, setSomeMoreSuggestion] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getMoreSuggestionAPI();
  }, []);

  const getMoreSuggestionAPI = async () => {
    let res = await getMoreSuggestionAction({ id: id });
    if (res.success) {
      setSomeMoreSuggestion(res.data);
    }
  };

  const handleBack = () => {
    navigate(`${config.baseUrl}`)
  };

  return (
    <>
      <Header />
      <section className="destination-area">
        <Container>
          <Row>
            <Col lg={12} className="mb-4">

              <h5 className=" d-flex align-items-center">
                <button
                  color="#000"
                  onClick={handleBack}
                  className="border-1 rounded-1 border-secondary h-auto arrow_left btn btn-default"
                  sx={{ mr: 1, color: "#000" }}
                >
                  <img
                    src="assets/images/icon/right_arrow.svg"
                    width="7px"
                    style={{ marginTop: "-3px" }}
                  />
                </button>
                &nbsp;
                <b>Back</b>
              </h5>

              <div className="text-center">
                <h2 className="">
                  <b>Suggestion Details</b>
                </h2>
                <div class="section-title-line"> </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} className="mb-3">
              <div
                className="boximage overflow-hidden"
                style={{ height: "500px" }}
              >
                {topDestination[0]?.image ? (
                  <img
                    src={topDestination[0]?.image}
                    width="100%"
                  />
                ) : (
                ""
                )}
                <h4 className="text-uppercase text-white">
                  <b>
                    {topDestination[0]?.name ? topDestination[0]?.name : ""}
                  </b>
                </h4>
              </div>

              <div
                className="mt-4"
                dangerouslySetInnerHTML={{
                  __html: topDestination[0]?.description,
                }}
              />
            </Col>
          </Row>
        </Container>
      </section>

      <Footer />
    </>
  );
};

export default Home;
