import React, { useState, useEffect } from "react";
import Header from "../generalPages/directives/header";
import Footer from "../generalPages/directives/footer";
import "./generalCSS/home.css";
import "./generalCSS/generalnavbar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  getTopDestinationAction,
  getTopDestinationGalleryAction,
} from "../Action/user.action";
import { Container, Row, Col } from "react-bootstrap";
import config from "../coreFIles/config";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const { id } = useParams();
  const [topDestination, setTopDestination] = useState([]);
  const [gallery, setGallery] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getTopDestinationAPI();
    getGallery();
  }, []);

  const getTopDestinationAPI = async () => {
    let res = await getTopDestinationAction({ id: id });
    console.log(res.data[0].description);
    if (res.success) {
      setTopDestination(res.data);
    }
  };
  const getGallery = async () => {
    let res = await getTopDestinationGalleryAction({ destinationId: id });
    console.log(res.data, "...");
    if (res.success) {
      setGallery(res.data);
    }
  };

  const handleBack = () => {
    navigate(`${config.baseUrl}`);
  };

  return (
    <>
      <Header />

      <section style={{ padding: 0 }}>
        <div className="boximage overflow-hidden " style={{ height: "500px" }}>
          {topDestination[0]?.image ? (
            <img src={topDestination[0]?.image} width="100%" className="" />
          ) : (
            ""
          )}
          <div className="overlay"></div>
          <h4 className="text-uppercase text-white  ">
            <b>{topDestination[0]?.name ? topDestination[0]?.name : ""}</b>
          </h4>
        </div>

        <Container className="destination-info mt-5">
          {/* <h2>Exploring New York City on Foot: A Unique Experience</h2> */}
          {/* <p>
            New York City, often referred to as the “city that never sleeps,” is
            a bustling metropolis filled with endless opportunities for
            exploration and adventure. While many cities around the world are
            best experienced by car or public transportation, New York City is a
            place that truly comes alive when explored on foot.
          </p> */}

          {/* <div className="destination-details">
            <h4 className="mt-4 mb-2">
              The Vibrant Neighbourhoods of New York City
            </h4>
            <p>
              One of the most compelling reasons to explore New York City on
              foot is the opportunity to immerse yourself in its diverse
              neighborhoods. From the trendy streets of SoHo to the historic
              brownstones of Brooklyn Heights, each neighbourhood in New York
              has its own unique character and charm waiting to be discovered.
              Walking through these neighbourhoods allows you to experience the
              city like a local, taking in the sights, sounds, and smells that
              make each area special.
            </p>

            <h4 className="mt-4 mb-2">
              The Vibrant Neighbourhoods of New York City
            </h4>
            <p>
              One of the most compelling reasons to explore New York City on
              foot is the opportunity to immerse yourself in its diverse
              neighborhoods. From the trendy streets of SoHo to the historic
              brownstones of Brooklyn Heights, each neighbourhood in New York
              has its own unique character and charm waiting to be discovered.
              Walking through these neighbourhoods allows you to experience the
              city like a local, taking in the sights, sounds, and smells that
              make each area special.
            </p>

            <Row className="d-flex justify-content-center align-items-center mt-4">
              <Col lg={4} sm={4} xs={4} className="mb-3 ">
                <img
                  src={topDestination[0]?.image}
                  width="100%"
                  className="rounded"
                />
              </Col>
              <Col lg={4} sm={4} xs={4} className="mb-3 ">
                <img
                  src={topDestination[0]?.image}
                  width="100%"
                  className="rounded"
                />
              </Col>
              <Col lg={4} sm={4} xs={4} className="mb-3 ">
                <img
                  src={topDestination[0]?.image}
                  width="100%"
                  className="rounded"
                />
              </Col>
              <Col lg={4} sm={4} xs={4} c350pxlassName="mb-3 ">
                <img
                  src={topDestination[0]?.image}
                  width="100%"
                  className="rounded"
                />
              </Col>
              <Col lg={4} sm={4} xs={4} className="mb-3 ">
                <img
                  src={topDestination[0]?.image}
                  width="100%"
                  className="rounded"
                />
              </Col>
              <Col lg={4} sm={4} xs={4} className="mb-3 ">
                <img
                  src={topDestination[0]?.image}
                  width="100%"
                  className="rounded"
                />
              </Col>
            </Row>
            <h4 className="mt-4 mb-2">
              The Vibrant Neighbourhoods of New York City
            </h4>
            <p>
              One of the most compelling reasons to explore New York City on
              foot is the opportunity to immerse yourself in its diverse
              neighborhoods. From the trendy streets of SoHo to the historic
              brownstones of Brooklyn Heights, each neighbourhood in New York
              has its own unique character and charm waiting to be discovered.
              Walking through these neighbourhoods allows you to experience the
              city like a local, taking in the sights, sounds, and smells that
              make each area special.
            </p>
          </div> */}

          {/* <div className="good-to-know">
            <h2>Good To Know</h2>
            <Row
              className="px-4 py-2"
              style={{ borderBottom: "1px solid #EBEBEB" }}
            >
              <Col>
                <h6 className=" fw-bold">Country</h6>
              </Col>
              <Col>
                <h6 className="text-end   fw-bold">United States Of America</h6>
              </Col>
            </Row>
            <Row
              className="px-4 py-2 mt-3"
              style={{ borderBottom: "1px solid #EBEBEB" }}
            >
              <Col>
                <h6 className=" fw-bold">Visa Requirements</h6>
              </Col>
              <Col>
                <h6 className="text-end   fw-bold">Everyone needs a visa</h6>
              </Col>
            </Row>
            <Row
              className="px-4 py-2 mt-3"
              style={{ borderBottom: "1px solid #EBEBEB" }}
            >
              <Col>
                <h6 className=" fw-bold">Languages spoken</h6>
              </Col>
              <Col>
                <h6 className="text-end   fw-bold">English</h6>
              </Col>
            </Row>
            <Row
              className="px-4 py-2 mt-3"
              style={{ borderBottom: "1px solid #EBEBEB" }}
            >
              <Col>
                <h6 className=" fw-bold">Currency used</h6>
              </Col>
              <Col>
                <h6 className="text-end   fw-bold">USD</h6>
              </Col>
            </Row>
          </div> */}

          <div
            dangerouslySetInnerHTML={{
              __html: topDestination[0]?.description,
            }}
          ></div>
          <div className="destination-gallery mt-4">
            <h2 className="">Our Gallery</h2>

            <Row className="destination-div">
              {gallery.length > 0 &&
                gallery.map((item) => (
                  <Col lg={4} md={6} sm={6} xs={6} className="mb-3  ">
                    <img
                      src={item.image}
                      width="100%"
                      className="rounded destination-img"
                    />
                  </Col>
                ))}
            </Row>
          </div>
        </Container>
      </section>
      <Footer />
    </>
  );
};

export default Home;
