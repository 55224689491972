import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import config from "../../../../config";
import Header from "../../../commanComponents/usernavbar";
import "../../css/staffdashboard.css";
import "../../css/finance.css";
import CanvasJSReact from "@canvasjs/react-charts";
import ListMenuButton from "./listMenu";
import UserNameAndTab from "./UserNameAndTab";
import Piechart from "./piechart";
import { getDashboardChartDataAction, } from "../../../../Action/user.action";
import { getExpenseStatics, profitAndLossStatics } from "../../../../Action/propertyOwner.action";
import { Padding } from "@mui/icons-material";



const Financedashboard = () => {
  const CanvasJSChart = CanvasJSReact.CanvasJSChart;
  const navigate = useNavigate();

  useEffect(() => {
    // Add the class to the body tag
    document.body.classList.add("staffbody");

    // Cleanup function to remove the class when the component is unmounted
    return () => {
      document.body.classList.remove("staffbody");
    };
  }, []);

  const navigateRoute = () => {
    setTimeout(() => {
      navigate(`${config.baseurl}${config.loginurl}`, { state: "prop" });
      window.location.reload();
    });
  };

  const [chartData, setChartData] = useState([]);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [revenueYears, setRevenueYears] = useState([2023, 2024]); // Example years, update as needed
  const [expenseData, setExpenseData] = useState([])
  const [profitAndLossYear, setProfitAndLossYear] = useState([currentYear])
  const [expenseYear, setExpenseYear] = useState([currentYear])
  const [profitAndLoss, setProfitAndLoss] = useState([])
  const [totalExpense, setTotalExpense] = useState('')
  const [totalRevenue, setTotalRevenue] = useState(0);


  const options = {
    animationEnabled: true,

    axisX: {
      valueFormatString: "MMM",
      gridColor: "#E8E8E8", // Set the horizontal grid line color
      lineColor: "#E8E8E8", // Optional: Change axis line color
      tickColor: "#EBEBEB", // Change tick line color on the X-axis
    },
    axisY: {
      prefix: "$",
      gridColor: "#E8E8E8", // Set the horizontal grid line color
      lineColor: "#E8E8E8", // Optional: Change axis line color
      tickColor: "#EBEBEB", // Change tick line color on the X-axis
    },
    data: [
      {
        yValueFormatString: "$#,###",
        xValueFormatString: "MMMM",
        type: "spline",
        color: "#E46422",
        dataPoints: expenseData.map((item, index) => ({
          x: new Date(2017, index), // Index corresponds to the month (0 = January, 11 = December)
          y: parseInt(item.total, 10),
        })),
      },
    ],
  };


  useEffect(() => {
    getDashboardChartDataAPI();
    expenseStates()
    profitAndLossStates()
  }, []);



  const expenseStates = async (year) => {
    try {
      let res = await getExpenseStatics({ year: year ? year : currentYear, type: 1 });
      if (res.success) {
        setExpenseData(res.data);

        const totalVariable = res.data.reduce((acc, item) => acc + parseFloat(item.total), 0);
        console.log("Total of all months:", totalVariable);
        setTotalExpense(totalVariable)
      }
    } catch (error) {
      console.error("Error fetching expense statistics:", error);
    }
  };


  const profitAndLossStates = async (year) => {
    try {
      let res = await profitAndLossStatics({ year: year ? year : currentYear, type: 1 })
      if (res.success) {
        setProfitAndLoss(res.data)
      }

    } catch (error) {

    }
  }


  const getDashboardChartDataAPI = async (event) => {
    let res = await getDashboardChartDataAction({
      year: event ? event : currentYear,
    });
    if (res.success) {
      const totalSum = res.data.reduce((sum, item) => parseFloat(sum) + parseFloat(item.total), 0);
      setTotalRevenue(totalSum)

      const defaultColor = "#FFD8C1";
      const hoverColor = "#E46422";

      let optionsArr = {
        animationEnabled: true,
        exportEnabled: true,

        toolTip: {
          shared: true,
          reversed: true,
          contentFormatter: function (e) {
            // Customizing the tooltip content with HTML and inline CSS for text color
            let content = "<span style='color: #000;font-weight: 500;'>" + e.entries[0].dataSeries.name + "</span>" + "<span style='color: #E46422;'>" + ": $" + e.entries[0].dataPoint.y + "</span>";
            return content;
          }
        },
        legend: {
          verticalAlign: "center",
          horizontalAlign: "right",
          reversed: true,
          cursor: "pointer",
        },
        axisX: {
          gridColor: "transparent", // Set the horizontal grid line color
          lineColor: "#EBEBEB", // Optional: Change axis line color
          tickColor: "#EBEBEB", // Change tick line color on the X-axis
        },
        axisY: {
          gridColor: "transparent", // Set the vertical grid line color
          lineColor: "#EBEBEB", // Optional: Change axis line color
          tickColor: "#EBEBEB", // Change tick line color on the X-axis
        },
        data: [
          {
            type: "stackedColumn",
            name: "Booking",
            yValueFormatString: "$,0",
            color: defaultColor, // Set the default light color
            dataPoints: [
              {
                label: "Jan",
                y: res.data[0].total
                  ? parseFloat(res.data[0].total)
                  : parseInt(0),
                cornerRadius: 8
              },
              {
                label: "Feb",
                y: res.data[1].total
                  ? parseFloat(res.data[1].total)
                  : parseInt(0),

                cornerRadius: 8
              },
              {
                label: "Mar",
                y: res.data[2].total
                  ? parseFloat(res.data[2].total)
                  : parseInt(0),
                cornerRadius: 8
              },
              {
                label: "Apr",
                y: res.data[3].total
                  ? parseFloat(res.data[3].total)
                  : parseInt(0),
                cornerRadius: 8
              },
              {
                label: "May",
                y: res.data[4].total
                  ? parseFloat(res.data[4].total)
                  : parseInt(0),
                cornerRadius: 8
              },
              {
                label: "Jun",
                y: res.data[5].total
                  ? parseFloat(res.data[5].total)
                  : parseInt(0),
                cornerRadius: 8
              },
              {
                label: "Jul",

                y: res.data[6].total
                  ? parseFloat(res.data[6].total)
                  : parseInt(0),
                cornerRadius: 8
              },
              {
                label: "Aug",
                y: res.data[7].total
                  ? parseFloat(res.data[7].total)
                  : parseInt(0),
                cornerRadius: 8
              },
              {
                label: "Sept",
                y: res.data[8].total
                  ? parseFloat(res.data[8].total)
                  : parseInt(0),
                cornerRadius: 8
              },
              {
                label: "Oct",
                y: res.data[9].total
                  ? parseFloat(res.data[9].total)
                  : parseInt(0),
                cornerRadius: 8
              },
              {
                label: "Nov",
                y: res.data[10].total
                  ? parseFloat(res.data[10].total)
                  : parseInt(0),
                cornerRadius: 8
              },
              {
                label: "Dec",
                y: res.data[11].total
                  ? parseFloat(res.data[11].total)
                  : parseInt(0),
                cornerRadius: 8
              },

            ],
            mouseover: function (e) {
              e.dataPoint.color = hoverColor; // Change color on hover
              e.chart.render(); // Re-render the chart to apply the color change
            },
            mouseout: function (e) {
              e.dataPoint.color = defaultColor; // Revert to default color on mouse out
              e.chart.render(); // Re-render the chart to apply the color change
            },
          },

        ],
      };
      setChartData(optionsArr);
    }
  };

  const handleYearChange = (event) => {
    const selectedYear = event.target.value;
    setCurrentYear(selectedYear);
    getDashboardChartDataAPI(selectedYear);
  };

  const handleExpenseChange = (event) => {
    const selectedYear = event.target.value;
    setExpenseYear(selectedYear);
    expenseStates(selectedYear);
  };

  const handleProfitAndLossChange = (event) => {
    const selectedYear = event.target.value;
    setProfitAndLossYear(selectedYear);
    profitAndLossStates(selectedYear);
  };

  return (
    <div className="staffdashboard bg-pink">
      <Header />
      <section className="listing pt-5 pb-5 overflow-hidden vh-80">
        <Container>
          <UserNameAndTab />
          <div className="listMenuButton">
            <Col xl={12} lg={12} className="mb-4">
              <Card className="border-0 tablistfinance">
                <Card.Body>
                  <ListMenuButton />
                </Card.Body>
              </Card>
            </Col>
          </div>
          <div className="services">
            <Col lg={12} className="mb-4">
              <Card className="border-0 bg-white p-3 ">
                <Card.Header className="border-0">
                  <div className="d-flex justify-content-between ">
                    <div className="d-flex align-items-end">
                      <h4 className="mb-0 fw-bolder">Shortcuts </h4>
                    </div>
                  </div>
                </Card.Header>
                <Card.Body className="p-0">
                  <div className="shorcutList">
                    <ul className="list-unstyled pl-0">
                      <li className="d-inline-block">
                        <div className="d-flex align-items-center ">
                          <div className="me-3 circleBox">
                            <img src="images/icon/invoice.svg" width="36px" />
                          </div>
                          <div>
                            <Link to={`${config.baseurl}invoicelist`} className="nav-link">
                              <p className="mb-1 text-light-grey">
                                Create Invoice
                              </p>
                            </Link>
                          </div>
                        </div>
                      </li>
                      <li className="d-inline-block">
                        <div className="d-flex align-items-center ">
                          <div className="me-3 circleBox">
                            <img
                              src="images/icon/recordexpenses.svg"
                              width="36px"
                            />
                          </div>
                          <div>
                            <Link to={`${config.baseurl}expenses`} className="nav-link">
                              <p className="mb-1 text-light-grey">
                                Record Expense
                              </p>
                            </Link>
                          </div>
                        </div>
                      </li>
                      <li className="d-inline-block">
                        <div className="d-flex align-items-center ">
                          <div className="me-3 circleBox">
                            <img src="images/icon/bank.svg" width="36px" />
                          </div>
                          <div>
                            <Link to={`${config.baseurl}bankdepositelist`} className="nav-link">
                              <p className="mb-1 text-light-grey">
                                Add Bank Deposit
                              </p>
                            </Link>
                          </div>
                        </div>
                      </li>
                      <li className="d-inline-block">
                        <div className="d-flex align-items-center ">
                          <div className="me-3 circleBox">
                            <img src="images/icon/cheque.svg" width="36px" />
                          </div>
                          <div>
                            <Link to={`${config.baseurl}chequelist`} className="nav-link">
                              <p className="mb-1 text-light-grey">
                                Create Cheque
                              </p>
                            </Link>
                          </div>
                        </div>
                      </li>
                      <li className="d-inline-block">
                        <div className="d-flex align-items-center ">
                          <div className="me-3 circleBox">
                            <img
                              src="images/icon/createestimate.svg"
                              width="36px"
                            />
                          </div>
                          <div>
                            <Link to={`${config.baseurl}estimatelist`} className="nav-link">
                              <p className="mb-1 text-light-grey">
                                Create Estimate
                              </p>
                            </Link>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </div>
          <div className="graph">
            <Row className="mt-2">
              <Col xl={8} lg={12} className="mb-4">
                <div className="graph mt-4 mb-4">
                  <div className="row">
                    <div className="col-md-9">
                      <h5 className="text-primary">
                        <b>Revenue</b>
                      </h5>
                      <h5>${totalRevenue ? parseFloat(totalRevenue).toFixed(2) : 0} </h5>
                    </div>
                    <div className="col-md-3">
                      <select
                        className="form-control"
                        onChange={handleYearChange}
                        value={currentYear}
                      >
                        {revenueYears.map((year, index) => (
                          <option key={index} value={year} data-content={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="mt-4">
                    <CanvasJSChart options={chartData} />
                  </div>
                </div>
              </Col>

              <Col xl={4} lg={12} className="mb-4">
                <div className="line_chart">
                  <Card className="border-0">
                    <Card.Body>
                      <div>
                        <Row className="d-flex align-items-center justify-content-between mb-2">
                          <Col lg={8}>
                            <div>
                              <h4 class="mb-0">
                                <b>Expenses</b>
                              </h4>
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div>
                              <select
                                className="form-control"
                                onChange={handleExpenseChange}
                                value={expenseYear}
                              >
                                {revenueYears.map((year, index) => (
                                  <option key={index} value={year}>
                                    {year}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      {/* <div className="d-flex align-items-center justify-content-between mb-2">
                        <div>
                          <h4 class="mb-0">
                            <b>Expenses</b>
                          </h4>
                        </div>

                        <div>
                          <select
                            className="form-control"
                            onChange={handleExpenseChange}
                            value={expenseYear}
                          >
                            {revenueYears.map((year, index) => (
                              <option key={index} value={year}>
                                {year}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div> */}
                      <div className="mb-3">
                        <p className="fs-5 mb-2" style={{ color: "#C1C1C1" }}>
                          Spending for Year {expenseYear}
                        </p>
                        <h3 className="fw-bold mb-2">${totalExpense || 0}</h3>

                      </div>
                      <div>
                        <CanvasJSChart
                          options={options}
                          containerProps={{ width: "100%", height: "228px" }}
                        />
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </Col>
            </Row>
          </div>
          <div className="">
            <Row className="mt-2">
              <Col xl={8} lg={12} className="mb-3">
                <div>
                  <Card className="border-0">
                    <Card.Body>
                      <Row className="align-items-center justify-content-between mb-2">
                        <Col md={10}>
                          <div>
                            <h4 class="mb-0">
                              <b>Profit & Loss</b>
                            </h4>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div>
                            <select
                              className="form-control"
                              onChange={handleProfitAndLossChange}
                              value={profitAndLossYear}
                            >
                              {revenueYears.map((year, index) => (
                                <option key={index} value={year}>
                                  {year}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Col>
                      </Row>
                      <div>
                        <Row className=" justify-content-between align-items-center mb-3">
                          <Col lg={12} className="mb-3">
                            <p
                              className="fs-5 mb-3"
                              style={{ color: "#C1C1C1" }}
                            >

                              {profitAndLoss?.netProfit > 0 ? "Net Profit" : "Net Loss"}
                            </p>
                            <h3 className="fw-bold">
                              {profitAndLoss?.netProfit < 0
                                ? `-$${Math.abs(profitAndLoss.netProfit)}`
                                : `$${profitAndLoss?.netProfit}`} &nbsp;

                              {/* <span className="text-danger fs-5 fw-normal">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="20"
                                  viewBox="0 0 16 20"
                                  fill="none"
                                  className="me-2"
                                >
                                  <path
                                    d="M8.98771 1.00021V16.5862L14.2807 11.2932C14.373 11.1977 14.4833 11.1215 14.6053 11.0691C14.7273 11.0167 14.8585 10.9891 14.9913 10.988C15.1241 10.9868 15.2558 11.0121 15.3787 11.0624C15.5016 11.1127 15.6132 11.1869 15.7071 11.2808C15.801 11.3747 15.8753 11.4864 15.9255 11.6093C15.9758 11.7322 16.0011 11.8638 16 11.9966C15.9988 12.1294 15.9712 12.2606 15.9188 12.3826C15.8664 12.5046 15.7902 12.615 15.6947 12.7072L8.69471 19.7072C8.50718 19.8947 8.25288 20 7.98771 20C7.72255 20 7.46824 19.8947 7.28071 19.7072L0.280711 12.7072C0.0985527 12.5186 -0.00224113 12.266 3.71933e-05 12.0038C0.00231552 11.7416 0.107485 11.4908 0.292893 11.3054C0.478302 11.12 0.729115 11.0148 0.991311 11.0125C1.25351 11.0103 1.50611 11.1111 1.69471 11.2932L6.98771 16.5862V1.00021C6.98771 0.734997 7.09307 0.480642 7.28061 0.293106C7.46814 0.10557 7.7225 0.000213623 7.98771 0.000213623C8.25293 0.000213623 8.50728 0.10557 8.69482 0.293106C8.88235 0.480642 8.98771 0.734997 8.98771 1.00021Z"
                                    fill="#D62323"
                                  />
                                </svg>
                                Down 50% from the prior month
                              </span> */}
                            </h3>
                          </Col>
                          <Col lg={12} className="mb-3">
                            <Row className="justify-content-between">
                              <Col lg={6} className="">
                                <div className="">
                                  <div className="d-flex align-items-center justify-content-center">
                                    <div className="me-3">
                                      <p className="text-primary fs-5 mb-2">
                                        Income
                                      </p>
                                      <h5>${profitAndLoss?.totalIncome}</h5>
                                    </div>
                                    <div>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="103"
                                        height="45"
                                        viewBox="0 0 103 45"
                                        fill="none"
                                      >
                                        <path
                                          d="M12.4025 28.963L1 16.6941V44.2676H101.875V37.5639L90.7713 23.0264C90.516 22.6922 90.1619 22.447 89.7593 22.3256L78.6345 18.9708L67.385 2.14844L57.6188 10.7493L45.6275 4.42515L35.8614 20.7416L21.7978 22.5123L12.4025 28.963Z"
                                          fill="url(#paint0_linear_211_315)"
                                          fill-opacity="0.25"
                                        />
                                        <path
                                          d="M1 16.6941L12.4025 28.963L21.7978 22.5123L35.8614 20.7416L45.6275 4.42515L57.6188 10.7493L67.385 2.14844L78.6345 18.9708L89.7593 22.3256C90.1619 22.447 90.516 22.6922 90.7713 23.0264L101.875 37.5639"
                                          stroke="#E46422"
                                          stroke-width="2.5"
                                        />
                                        <defs>
                                          <linearGradient
                                            id="paint0_linear_211_315"
                                            x1="51.4377"
                                            y1="2.14844"
                                            x2="51.4377"
                                            y2="44.2676"
                                            gradientUnits="userSpaceOnUse"
                                          >
                                            <stop stop-color="#E46422" />
                                            <stop
                                              offset="1"
                                              stop-color="#E46422"
                                              stop-opacity="0.4"
                                            />
                                          </linearGradient>
                                        </defs>
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                              <Col lg={6} className="text-center">
                                <div className="">
                                  <div className="d-flex align-items-center justify-content-center">
                                    <div className="me-3">
                                      <p className="text-primary fs-5 mb-2">
                                        Expense
                                      </p>
                                      <h5>${profitAndLoss?.totalExpenseAmount}</h5>
                                    </div>
                                    <div>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="103"
                                        height="45"
                                        viewBox="0 0 103 45"
                                        fill="none"
                                      >
                                        <path
                                          d="M12.4025 28.963L1 16.6941V44.2676H101.875V37.5639L90.7713 23.0264C90.516 22.6922 90.1619 22.447 89.7593 22.3256L78.6345 18.9708L67.385 2.14844L57.6188 10.7493L45.6275 4.42515L35.8614 20.7416L21.7978 22.5123L12.4025 28.963Z"
                                          fill="url(#paint0_linear_211_321)"
                                          fill-opacity="0.25"
                                        />
                                        <path
                                          d="M1 16.6941L12.4025 28.963L21.7978 22.5123L35.8614 20.7416L45.6275 4.42515L57.6188 10.7493L67.385 2.14844L78.6345 18.9708L89.7593 22.3256C90.1619 22.447 90.516 22.6922 90.7713 23.0264L101.875 37.5639"
                                          stroke="#D62323"
                                          stroke-width="2.5"
                                        />
                                        <defs>
                                          <linearGradient
                                            id="paint0_linear_211_321"
                                            x1="51.4377"
                                            y1="2.14844"
                                            x2="51.4377"
                                            y2="44.2676"
                                            gradientUnits="userSpaceOnUse"
                                          >
                                            <stop stop-color="#D62323" />
                                            <stop
                                              offset="1"
                                              stop-color="#D62323"
                                              stop-opacity="0.4"
                                            />
                                          </linearGradient>
                                        </defs>
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          {/* <Col lg={12}>
                            <Button
                              variant="default"
                              className="border-0 h-auto text-navy-blue p-0 fs-5"
                            >
                              Bring in transactions automatically
                              <svg
                                className="ms-2"
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="16"
                                viewBox="0 0 20 16"
                                fill="none"
                              >
                                <path
                                  d="M0.999969 7.01229H16.586L11.293 1.71929C11.1975 1.62704 11.1213 1.5167 11.0689 1.39469C11.0165 1.27269 10.9889 1.14147 10.9877 1.00869C10.9866 0.87591 11.0119 0.744231 11.0621 0.621334C11.1124 0.498438 11.1867 0.386786 11.2806 0.292893C11.3745 0.199 11.4861 0.124747 11.609 0.0744663C11.7319 0.0241854 11.8636 -0.00111606 11.9964 3.77571e-05C12.1291 0.00119157 12.2604 0.0287779 12.3824 0.0811869C12.5044 0.133596 12.6147 0.209778 12.707 0.305288L19.707 7.30529C19.8944 7.49282 19.9998 7.74712 19.9998 8.01229C19.9998 8.27745 19.8944 8.53176 19.707 8.71929L12.707 15.7193C12.5184 15.9014 12.2658 16.0022 12.0036 16C11.7414 15.9977 11.4906 15.8925 11.3052 15.7071C11.1197 15.5217 11.0146 15.2709 11.0123 15.0087C11.01 14.7465 11.1108 14.4939 11.293 14.3053L16.586 9.01229H0.999969C0.734753 9.01229 0.480398 8.90693 0.292862 8.71939C0.105326 8.53186 -3.05176e-05 8.2775 -3.05176e-05 8.01229C-3.05176e-05 7.74707 0.105326 7.49272 0.292862 7.30518C0.480398 7.11765 0.734753 7.01229 0.999969 7.01229Z"
                                  fill="#1F93D5"
                                />
                              </svg>
                            </Button>
                          </Col> */}
                        </Row>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </Col>
              <Col xl={4} lg={12} className="mb-3">
                <div className="line_chart">
                  <Card className="border-0">
                    <Card.Body>
                      <div className=" mb-3">
                        <div>
                          <h4 class="mb-0">
                            <b>Income</b>
                          </h4>
                        </div>
                      </div>
                      <div>
                        <Piechart />
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default Financedashboard;
