import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { Swiper, SwiperSlide } from 'swiper/react';
import { FaRegStar, FaStar } from "react-icons/fa";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import '../../css/testimonial.css';

// Import required modules
import { Pagination } from 'swiper/modules';
import { getVehicleOwnersVehicleReviewAction } from "../../../../Action/user.action";

const VihicleTestimonal = () => {
    const [reviews, setReviews] = useState([]);

    useEffect(() => {
        getReviewAPI();
    }, []);

    const getReviewAPI = async () => {
        let res = await getVehicleOwnersVehicleReviewAction();
        if (res.success) {
            setReviews(res.data);
        }
    };

    // Function to render stars based on the rating
    const renderStars = (rating) => {
        const stars = [];
        for (let i = 1; i <= 5; i++) {
            if (i <= rating) {
                stars.push(<FaStar key={i} className='me-1' fill='#FFC107' />);
            } else {
                stars.push(<FaRegStar key={i} className='me-1' fill='#FFC107' />);
            }
        }
        return stars;
    };

    // Function to truncate review text to 10-20 words
    const truncateReview = (text) => {
        const words = text.split(' ');
        if (words.length > 20) {
            return `${words.slice(0, 5).join(' ')}...`;
        }
        return text;
    };

    return (
        <div className=''>
            <Swiper
                slidesPerView={4}
                spaceBetween={20}
                loop={true}
                modules={[Pagination]}
                className="mySwiper"
                pagination={{ clickable: true }} 
            >
                {reviews.map((item, index) => (
                    <SwiperSlide key={index}>
                        <div>
                        <Card className='w-full'>
                            <Card.Body>
                                <div>
                                    <h5 className='small mb-1 fst-italic'>{item.vehicleName}</h5>
                                    <p className='x-small mb-3 text-black'>{truncateReview(item.review)}</p>
                                </div>
                                <div className='d-sm-flex justify-content-between align-items-center'>
                                    <div className='d-flex me-3'>
                                        <div className='me-2'>
                                            <img src="images/t1.svg" className='object-fit-contain' />
                                        </div>
                                        <div>
                                            <h6 className='mb-0 small'>{item.firstName} {item.lastName}</h6>
                                            <div className='text-primary fw-bold xx-small'>
                                                {(() => {
                                                    const date = new Date(item.createdDate);
                                                    const options = { year: "numeric", month: "long", day: "2-digit" };
                                                    const formattedDate = date.toLocaleDateString("en-US", options);
                                                    return formattedDate;
                                                })()}
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        {renderStars(item.rating)}
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default VihicleTestimonal;
