
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import config from '../../config'

import '../../generalPages/generalCSS/thankyou.css'
import { Container, Row, Col, Form, Button, InputGroup, DropdownButton, Dropdown } from 'react-bootstrap';

const LoadingPopup = () => {
    return (
        <div className="loading-popup-container">
            <div className="loading-popup">
                <p>Please wait</p>
                <h1>Loggin in . . .</h1>
            </div>
        </div>
    );
};

const Thankyou = () => {


    return (
        <>

            <section className="thankyou-section pt-5 pb-5 overflow-hidden vh-80">
                <Container>
                    <Row className='justify-content-center '>
                        <Col xl={9} lg={9}>
                            <div className="thankyou">
                                <div className="text-center">
                                    <div className="login-logo mb-5">
                                        <img
                                            src="assets/images/logo/logo.webp"
                                            alt="logo"
                                        />
                                        </div>
                                       
                                        <div className='pt-4 pb-4'>
                                            <h1 className='mb-4'><b>Thank You</b></h1>
                                            <p className='mb-4 text-grey'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                          <Button variant='primary'>Return to Home</Button>
                                        </div>
                                  
                                </div>
                            </div>
                        </Col>
                      
                    </Row>

                </Container>

            </section>
            <footer>
            <div className='banner-bottom'>
                <img src='assets/images/background/bottom_banner.webp' width="100%" />

            </div>
            </footer>

        </>
    )
}

export default Thankyou;
