import React, { useState } from "react";
import Header from "../generalPages/directives/header";
import Footer from "../generalPages/directives/footer";
import "./generalCSS/home.css";
import "./generalCSS/generalnavbar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { FaArrowRight } from "react-icons/fa6";
import { userAccountDeletionAction } from "../Action/propertyOwner.action";
import { toast, Toaster } from "react-hot-toast";
import Maps from "../afterLoggedin/Maps";

const Accountdeletion = () => {
  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const [validatioError, setvalidatioError] = useState({
    emailError: "",
    passError: "",
  });

  const inputHandler = (e) => {
    const { name, value, id } = e.target;
    if (value != "") {
      setvalidatioError((old) => {
        return { ...old, [id]: "" };
      });
    }

    setForm((old) => {
      return { ...old, [name]: value };
    });
  };

  function validate() {
    let emailError = "";
    let passError = "";

    if (form.email === "") {
      emailError = "Email is required.";
    }
    if (form.password === "") {
      passError = "Password is required.";
    }

    if (emailError || passError) {
      setvalidatioError({
        emailError,
        passError,
      });
      return false;
    } else {
      return true;
    }
  }

  const SubmitForm = async (e) => {
    e.preventDefault();
    const isValid = validate();
    if (!isValid) {
    } else {
      let res = await userAccountDeletionAction(form);
      console.log(res, "res");
      if (res.success) {
        toast.success(res.msg);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.error(res.msg);
      }
    }
  };

  const selectedLocation = {
    lat: 6.81316535132013,
    lng: -58.14413406608045,
    location: "Georgetown, Guyana",
  };

  return (
    <>
      <Toaster />
      <section className="destination-area">
        <Container>
          <Row className="justify-content-center mb-5">
            <Col lg={6} md={6} className=" text-center">
              <div className="">
                <div className="text-center">
                  <h2 className="text-black">
                    <b>Account Deletion</b>
                  </h2>
                  <div class="section-title-line"> </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={3}></Col>
            <Col lg={6}>
              <Row>
                <Col lg={12} className=" mb-5">
                  <Form onSubmit={SubmitForm}>
                    <Row>
                      <Col lg={6} className="mb-3">
                        <Form.Group controlId="exampleForm.ControlInput1">
                          <Form.Label className=" fw-bold">Email</Form.Label>
                          <Form.Control
                            onChange={inputHandler}
                            type="email"
                            name="email"
                            placeholder="example@email.com"
                          />
                          <span className="validationErr">
                            {validatioError.emailError}
                          </span>
                        </Form.Group>
                      </Col>
                      <Col lg={6} className="mb-3">
                        <Form.Group controlId="exampleForm.ControlInput1">
                          <Form.Label className=" fw-bold">Password</Form.Label>
                          <Form.Control
                            type="text"
                            onChange={inputHandler}
                            name="password"
                            placeholder="Example123#"
                          />
                          <span className="validationErr">
                            {validatioError.passError}
                          </span>
                        </Form.Group>
                      </Col>
                      &nbsp;
                    </Row>
                    <Button
                      variant="primary"
                      type="submit"
                      className="rounded-5 px-1 btn btn-primary"
                    >
                      Submit&nbsp;&nbsp;
                      <FaArrowRight />
                    </Button>
                  </Form>
                </Col>
              </Row>
            </Col>
            <Col lg={3}></Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Accountdeletion;
