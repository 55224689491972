
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import config from '../../config'

import '../../generalPages/generalCSS/thankyou.css'
import { Container, Row, Col, Form, Button, InputGroup, DropdownButton, Dropdown } from 'react-bootstrap';

const PaymentSuccess = () => {

    return (
        <>

            <section className="thankyou-section pt-5 pb-5 overflow-hidden vh-80">
                <Container>
                    <Row className='justify-content-center '>
                        <Col xl={9} lg={9}>
                            <div className="thankyou">
                                <div className="text-center">
                                    <div className="login-logo mb-5">
                                        <img
                                            src="assets/images/logo/logo.webp"
                                            alt="logo"
                                        />
                                    </div>

                                    <div className='pt-4 pb-4'>
                                        <h3 className='mb-4'><b style={{color:'red'}}>Failed!!</b></h3>
                                        <p className='mb-4 text-grey'>Your payment has been failed.</p>
                                        <Link to={`${config.baseurl}`} >
                                            <Button variant='primary'>Return to Home</Button>
                                        </Link>
                                    </div>

                                </div>
                            </div>
                        </Col>

                    </Row>

                </Container>

            </section>
            <footer>
                <div className='banner-bottom'>
                    <img src='assets/images/background/bottom_banner.webp' width="100%" />

                </div>
            </footer>

        </>
    )
}

export default PaymentSuccess;
