import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Container, Row, Col, Card, Button, Form, Table, Modal } from "react-bootstrap";
import Header from "../../../commanComponents/usernavbar";
import axios from "axios";
import "../../css/createcheque.css";
import config from "../../../../config";
import { addEstimate, getEstimateSerialNo } from "../../../../Action/propertyOwner.action";
import { toast, Toaster } from "react-hot-toast";
import DataTable, { createTheme } from "react-data-table-component";
import { FaPlus, FaArrowLeftLong } from "react-icons/fa6";
import { RiDeleteBin6Line } from "react-icons/ri";



const Createestimate = () => {
  useEffect(() => {
    document.body.classList.add("staffbody");
    return () => {
      document.body.classList.remove("staffbody");
    };
  }, []);

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    customerName: "",
    email: "",
    estimateDate: "",
    expirationDate: "",
    billingAddress: "",
    estimateNo: "",
    message: "",
    memo: '',
    estimateArr: [],
    type: ""
  });

  const [showModal, setShowModal] = useState(false);
  const [estimate, setEstimate] = useState({
    Description: "",
    Qty: "",
    Rate: "",
    Amount: ""
  });

  const [errors, setErrors] = useState({});
  const [estimateError, setEstimateError] = useState({});
  const [serialNo, setSerialNo] = useState({})


  const handleProductChange = (e) => {
    const { name, value } = e.target;
    setEstimate((prevItem) => ({
      ...prevItem,
      [name]: value,
      Amount: name === 'Qty' || name === 'Rate'
        ? (name === 'Qty' ? value : prevItem.Qty) * (name === 'Rate' ? value : prevItem.Rate)
        : prevItem.Amount,
    }));
    setEstimateError({})
  };

  const validateProduct = () => {
    let estimateError = {};
    if (!estimate.Description) estimateError.Description = "Description is required";
    if (!estimate.Qty) estimateError.Qty = "Quantity is required";
    if (!estimate.Rate) estimateError.Rate = "Rate is required";
    //  if (!estimate.Amount) estimateError.Amount = "Amount is required";
    return estimateError;
  };

  useEffect(() => (
    fetchSerial()
  ), [])

  const fetchSerial = async () => {
    let res = await getEstimateSerialNo()
    if (res.success) {
      if (res.data.length != []) {
        setSerialNo(res.data[0])
      } else {
        setSerialNo({ id: 0 })
      }
    }
  }

  const handleAddProduct = () => {
    const productValidationErrors = validateProduct();
    if (Object.keys(productValidationErrors).length === 0) {
      setFormData((prevData) => ({
        ...prevData,
        estimateArr: [...prevData.estimateArr, estimate],
      }));
      setEstimate({
        Description: "",
        Qty: "",
        Rate: "",
        Amount: "",
      });
      setShowModal(false);
      setEstimateError({});
    } else {
      setEstimateError(productValidationErrors);
    }
  };

  const handleDelete = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      estimateArr: prevData.estimateArr.filter((_, i) => i !== index),
    }));
  };



  const calculateTotalAmount = () => {
    return formData.estimateArr.reduce((total, estimate) => {
      return total + parseFloat(estimate.Amount || 0);
    }, 0);
  };

  const validateForm = () => {
    const formErrors = {};
    if (!formData.customerName) formErrors.customerName = "Customer Name is required";
    if (!formData.email) formErrors.email = "Email is required";
    if (!formData.estimateDate) formErrors.estimateDate = "Estimate Date is required";
    if (!formData.expirationDate) formErrors.expirationDate = "Expiration Date is required";
    if (!formData.billingAddress) formErrors.billingAddress = "Billing Address is required";
    // if (!formData.estimateNo) formErrors.estimateNo = "Estimate Number is required";
    if (formData.estimateArr.length === 0) formErrors.estimateArr = "At least one estimate is required";
    return formErrors;
  };

  const handleSubmit = async () => {
    const formValidationErrors = validateForm();
    if (Object.keys(formValidationErrors).length === 0) {
      try {
        let data = {
          ...formData,
          estimateNo: serialNo.id + 1,
          estimateArr: JSON.stringify(formData.estimateArr),
          totalEstimate: calculateTotalAmount().toFixed(2),
          type: 1
        }

        const res = await addEstimate(data)
        if (res.success) {
          toast.success(res.msg);
          setTimeout(() => {
            window.location.href = (`/estimatelist`);
          }, 2000);
        } else {
          toast.error(res.msg);
        }

      } catch (error) {
        console.error("Error adding estimate:", error);
        alert("Something went wrong.");
      }
    } else {
      setErrors(formValidationErrors);
    }
  };

  const customStyles = {
    rows: {
      style: {
        minHeight: '70px', // override the row height

      },
    },
    headCells: {

      style: {
        minHeight: '60px',
        paddingLeft: '20px', // override the cell padding for head cells
        paddingRight: '20px',
        background: '#FFF8F4',

      },
    },
    cells: {
      style: {
        paddingLeft: '20px', // override the cell padding for data cells
        paddingRight: '20px',
      },
    },
  };

  const columns = [
    {
      name: '#',
      selector: (row, index) => index + 1,
      sortable: false,
    },
    {
      name: 'Description',
      selector: row => row.Description,
    },
    {
      name: 'Quantity',
      selector: row => row.Qty,
      // width: "25%"
    },
    {
      name: 'Rate',
      selector: row => row.Rate,
    },
    {
      name: 'Amount',
      selector: row => '$' + row.Amount,

    },
    {
      name: 'Delete',
      selector: row => row.status,
      cell: (row, index) => (
        <Button
          variant="default"
          className="p-0 btn-sm text-primary"
          onClick={() => handleDelete(index)} // Pass the index to the delete handler
        >
          <RiDeleteBin6Line size={`15px`} />
        </Button>
      ),
    },
  ];

  return (
    <div className="staffdashboard bg-pink">
      <Header />
      <Toaster />
      <section className="listing pt-5 pb-5 overflow-hidden vh-80">
        <Container>

          <Row>
            <Col lg={5} sm={12} xs={12} className="mb-4">
              <Button
                variant="default"
                className="p-0 h-auto"
                onClick={() => window.location.href = '/estimatelist'}
              >
                <FaArrowLeftLong size="25px" />
              </Button>
            </Col>

            <Col lg={7} sm={12} xs={12} className="text-right"></Col>
          </Row>
          <div className="">
            <Col lg={12} className="mb-3">
              <Card className="border-0 bg-white p-3">
                <Card.Header className="mb-3 pb-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="mb-2"><h4 className="mb-0 fw-bolder">Estimate No.</h4></div>

                  </div>

                </Card.Header>
                <Card.Body className="p-0">
                  <div className="">
                    <Row>
                      <Col xl={12} lg={12} className="">


                        <Row className="mt-2">

                          <Col lg={6} className="mb-4">
                            <Form.Group className="mb-3">
                              <Form.Label className="text-light-grey mb-1">Customer Name</Form.Label>
                              <Form.Control
                                type="text"
                                name="customerName"
                                value={formData.customerName}
                                onChange={(e) => setFormData({ ...formData, customerName: e.target.value })}
                              />
                              {errors.customerName && <p className="text-danger">{errors.customerName}</p>}
                            </Form.Group>
                          </Col>

                          <Col lg={6} className="mb-4">
                            <Form.Group className="mb-3">
                              <Form.Label className="text-light-grey mb-1">Email</Form.Label>
                              <Form.Control
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                              />
                              {errors.email && <p className="text-danger">{errors.email}</p>}
                            </Form.Group>
                          </Col>

                          <Col lg={6} className="mb-4">
                            <Form.Group className="mb-3">
                              <Form.Label className="text-light-grey mb-1">Estimate Date</Form.Label>
                              <Form.Control
                                type="date"
                                name="estimateDate"
                                value={formData.estimateDate}
                                onChange={(e) => setFormData({ ...formData, estimateDate: e.target.value })}
                              />
                              {errors.estimateDate && <p className="text-danger">{errors.estimateDate}</p>}
                            </Form.Group>
                          </Col>
                          <Col lg={6} className="mb-4">
                            <Form.Group className="mb-3">
                              <Form.Label className="text-light-grey mb-1">Expiration Date</Form.Label>
                              <Form.Control
                                type="date"
                                name="expirationDate"
                                value={formData.expirationDate}
                                onChange={(e) => setFormData({ ...formData, expirationDate: e.target.value })}
                              />
                              {errors.expirationDate && <p className="text-danger">{errors.expirationDate}</p>}
                            </Form.Group>
                          </Col>

                          <Col lg={6} className="mb-4">
                            <Form.Group className="mb-3">
                              <Form.Label className="text-light-grey mb-1">Billing Address</Form.Label>
                              <Form.Control
                                type="text"
                                name="billingAddress"
                                value={formData.billingAddress}
                                onChange={(e) => setFormData({ ...formData, billingAddress: e.target.value })}
                              />
                              {errors.billingAddress && <p className="text-danger">{errors.billingAddress}</p>}
                            </Form.Group>
                          </Col>

                          <Col lg={6} className="mb-4">
                            <Form.Group className="mb-3">
                              <Form.Label className="text-light-grey mb-1">Estimate Number</Form.Label>
                              <Form.Control
                                type="text"
                                name="estimateNo"
                                value={serialNo.id + 1}
                                isInvalid={!!errors.entryNo}
                                readOnly
                              />
                              {/* {errors.estimateNo && <p className="text-danger">{errors.estimateNo}</p>} */}
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>

                      <Col xl={12} lg={12} className="">

                        <Row className="">
                          <Row className="align-items-center">
                            <Col lg={12} className="mb-4">
                              <Form.Group>
                                <Form.Label className="text-light-grey mb-1">Message Displayed on Estimate</Form.Label>
                                <Form.Control
                                  as="textarea"
                                  rows={5}
                                  className="h-auto"
                                  value={formData.message}
                                  onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                                />
                              </Form.Group>

                            </Col>
                            <Col xl={8} lg={7} md={7} className="mb-4">
                            </Col>

                          </Row>

                        </Row>
                      </Col>

                      <Col lg={12}>
                        <hr className="mb-4 mt-0" style={{ color: "#EBEBEB", opacity: "1" }} />
                      </Col>
                      <Col xl={12}>
                        <div className=" mb-4">

                          {/* ----------Balance sheet----------- */}
                          <div className="tableBody p-0" >
                            <Row className="justify-content-center">

                              <Col xl={12} lg={12} className="">
                                <div className="staff_upcoming_booking">
                                  <div className="border-0">
                                    <div className="p-0">

                                      <div className="mb-4">
                                        <Row className="align-items-center">
                                          <Col lg={12} xs={12}>
                                            <div className="pt-2">
                                              <DataTable
                                                columns={columns}
                                                data={formData.estimateArr}
                                                customStyles={customStyles}
                                                theme="solarized"
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                      <div className="">
                                        <Link to={`#`}>
                                          <Button
                                            variant="outline-primary"
                                            className=" rounded-5 me-2 px-4 px-xs-1 mb-4 mobileBtn"
                                            onClick={() => setShowModal(true)}
                                          >
                                            Add lines
                                          </Button>
                                        </Link>
                                        <Link to={`#`}>
                                          <Button
                                            variant="primary"
                                            className="rounded-5 me-2 px-4 px-xs-1 mb-4 mobileBtn"
                                            onClick={() => setFormData({
                                              ...formData,
                                              estimateArr: []
                                            })}
                                          >
                                            Clear All Lines
                                          </Button>

                                        </Link>
                                        {errors.estimateArr && <p className="text-danger">{errors.estimateArr}</p>}


                                      </div>
                                    </div>
                                    <Row className="align-items-center">
                                      <Col lg={6} className="mb-4">
                                        <Form.Group>
                                          <Form.Label className="text-light-grey mb-1">Memo</Form.Label>
                                          <Form.Control
                                            type="text"
                                            placeholder=""
                                            name="memo"
                                            value={formData?.memo}
                                            onChange={(e) => setFormData({ ...formData, memo: e.target.value })}
                                          />
                                        </Form.Group>

                                      </Col>
                                      <Col xl={4} lg={4} md={8} className="mb-4">
                                      </Col>
                                      <Col xl={2} lg={2} md={4} className="mb-4">
                                        <div className="d-flex justify-content-end justify-content-between">
                                          <div><h6 className="mb-0">Total</h6></div>
                                          <h5>${calculateTotalAmount().toFixed(2)}</h5>
                                        </div>
                                      </Col>
                                    </Row>


                                  </div>

                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                      <Col xl={6} lg={6} md={6}>
                        <div className="text-center mx-auto">
                          <div className="mt-3">
                            <Link to={`#`}>
                              <Button
                                variant="primary"
                                className=" rounded-5 me-2 px-5 px-xs-1 mb-4 mobileBtn"
                                onClick={handleSubmit}
                              >
                                Save & Close
                              </Button>
                            </Link>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </div>
        </Container>
      </section>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Estimates</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>

            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                name="Description"
                value={estimate.Description}
                onChange={handleProductChange}
                className="rounded-5"
              />
              {estimateError.Description && (
                <p className="text-danger">{estimateError.Description}</p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Quantity</Form.Label>
              <Form.Control
                type="number"
                name="Qty"
                value={estimate.Qty}
                onChange={handleProductChange}
                className="rounded-5"
              />
              {estimateError.Qty && (
                <p className="text-danger">{estimateError.Qty}</p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Rate</Form.Label>
              <Form.Control
                type="number"
                name="Rate"
                value={estimate.Rate}
                onChange={handleProductChange}
                className="rounded-5"
              />
              {estimateError.Rate && (
                <p className="text-danger">{estimateError.Rate}</p>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="number"
                name="amount"
                value={estimate.Qty * estimate.Rate || 0}
                readOnly
                className="rounded-5"
              />
            </Form.Group>

            <Button
              variant="primary"
              onClick={handleAddProduct}
              className="rounded-5"
            >
              Add Estimate
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Createestimate;
