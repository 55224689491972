import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./generalCSS/login.css";
import "./generalCSS/mobileverification.css";
import "./generalCSS/subscriptionplan.css";
import CryptoJS from 'crypto-js';
import {
  PayPalScriptProvider,
  PayPalButtons,
  FUNDING,
} from "@paypal/react-paypal-js";
import {
  getSubscriptionPlanAction1,
  InsertPaymentDetailsAction,
  checkPlanPurchaseAction
} from "../Action/propertyOwner.action";
import { getFygaroJwt } from "../Action/user.action";

import { Container, Row, Col, Button, Card } from "react-bootstrap";
import config from "../coreFIles/config";
import { toast, Toaster } from "react-hot-toast";
import Cookies from "js-cookie";
import { IoIosArrowDown } from "react-icons/io";
const loginData = !Cookies.get("loginSuccessPropertyOwner")
  ? []
  : JSON.parse(Cookies.get("loginSuccessPropertyOwner"));

const Subscriptionplan = () => {
  const navigate = useNavigate();
  const [planData, setPlanData] = useState([]);
  const [planDataByUser, setPlanDataByUser] = useState([]);
  const [chooseplanDetails, setChoosePlanDetails] = useState({});
  const [clientSecret, setClientSecret] = useState("");
  const [isStep, setisStep] = useState(1);
  const [fygaroToken, setFygaroToken] = useState('');
  const _useLocation = useLocation();

  useEffect(() => {
    getSubscriptionplanByUserId();
  }, []);

  const getSubscriptionplanByUserId = async () => {
    getSubscriptionplan();
  };

  const getSubscriptionplan = async (subcribedData, value) => {
    let res = await getSubscriptionPlanAction1();
    if (res.success) {
      let planData;
      if (value) {
        planData = res.data.filter((item) => item.validity == value);
      } else {
        planData = res.data;
      }

      setPlanData(
        planData
          .filter((item) => item.amount == 0 || item.validity == 1)
          .sort((a, b) => a.amount - b.amount)
      );

    }
  };

  const getSubscriptionplanAPI = async (subcribedData) => {
    let res = await getSubscriptionPlanAction1();
    console.log(res, "2");
    if (res.success) {
      if (subcribedData) {
        setPlanData(res.data.filter((item) => item.validity == subcribedData));
      } else {
        setPlanData(res.data.filter((item) => item.validity == 1));
      }
    }
  };

  function generateRandomString(length) {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    return Array.from({ length }, () =>
      characters.charAt(Math.floor(Math.random() * characters.length))
    ).join("");
  }
  const choosePlan = async (data) => {
    let res = await checkPlanPurchaseAction({ id: data.id });

    if (res.success) {
      if (data.amount > 0) {
        setChoosePlanDetails(data);
        getFygaroPayloadAPI(data)
        setisStep(2);
      } else {
        let arr = {
          subscriptionId: data.id,
          orderId: generateRandomString(16),
          cardNumber: "",
          receiptUrl: "",
          payerId: "",
          paymentMode: 3, // 3 free
        };
        let result = await InsertPaymentDetailsAction(arr);
        if (result.success) {
          toast.success(result.msg);
          setTimeout(() => {
            navigate(`${config.baseUrl}staffdashboard/getstarted`);
          }, 2000);
        } else {
          toast.error(result.msg);
        }
      }
    } else {
      toast.error(res.msg);
    }
  };

  const backBtn = async () => {
    setisStep(1);
  };

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>    PAYPAL PAYMENT  START   >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: chooseplanDetails.description,
            amount: {
              currency_code: "USD",
              value: chooseplanDetails.amount,
            },
          },
        ],
      })
      .then((orderID) => {
        return orderID;
      });
  };

  const onApprove = (data, actions) => {
    return actions.order.capture().then(async function (res) {
      if (res.status == "COMPLETED") {
        let arr = {
          subscriptionId: chooseplanDetails.id,
          orderId: res.id,
          cardNumber: "",
          receiptUrl: res.links[0].href,
          payerId: res.payer.payer_id,
          paymentMode: 1, // 1-Paypal
        };

        let result = await InsertPaymentDetailsAction(arr);
        if (result.success) {
          toast.success(result.msg);
          setTimeout(() => {
            navigate(`${config.baseUrl}staffdashboard/getstarted`);
          }, 2000);
        } else {
          toast.error(result.msg);
        }
      } else {
        toast.error(
          "Something went wrong with your payment. Please try again after some time"
        );
      }
    });
  };

  const onError = (data, actions) => {
    toast.error(
      "Something went wrong with your payment. Please try again after some time"
    );
  };

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>    PAYPAL PAYMENT END  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  const handleBack = () => {
    console.log(_useLocation.state);
    if (_useLocation.state == "profile") {
      navigate(`${config.baseUrl}staffdashboard/profile#subscription`);
    } else {
      navigate(`${config.baseUrl}`);
    }
  };

  const disableStripe = () => {
    setClientSecret("");
  };

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>   Get fygaro JWT token >>>>>>>>>>>>>>>>>>>>>>>>>>>>.

  const getFygaroPayloadAPI = async (data) => {
    const encryptionKey = config.fygaroJWTEncryptionKey;
    let fygaroPayload = {
      subscriptionId: data.id,
      type: 3
    };
    console.log('fygaroPayload', fygaroPayload);
    const encryptedPayload = CryptoJS.AES.encrypt(
      JSON.stringify(fygaroPayload),
      encryptionKey
    ).toString();

    let jwtReceived = await getFygaroJwt({ jwt: encryptedPayload });
    if (jwtReceived.success) {
      setFygaroToken(jwtReceived.data);
    }
  };

  const callFygaro = () => {
    if (fygaroToken) {
      let fygaroString = `https://www.fygaro.com/en/pb/8ef036d5-3406-4b0d-858f-8d814667d469?jwt=${fygaroToken}`;
      window.open(fygaroString, '_blank');
    } else {
      toast.error('Something went wrong please try again after some time.');
    }
  };

  return (
    <>
      <section className="login-section pt-5 pb-5 overflow-hidden vh-80">
        <Container>
          <Toaster />

          {isStep == 1 ? (
            <Row className="justify-content-center ">
              <Col xl={12} lg={12}>
                <div className="login-container">
                  <div className="login-form">
                    <div className="login-logo">
                      <Link to={`${config.baseUrl}`} className="mb-4">
                        <img src="assets/images/logo/logo.webp" alt="logo" />
                      </Link>
                      <div className="py-3">
                        <Row className="align-items-center">
                          <Col xl={2} lg={2} sm={2}>
                            <div className="back-btn mb-4">
                              <button
                                color="#000"
                                onClick={handleBack}
                                className="border-1 rounded-1 border-secondary h-auto arrow_left btn btn-default text-nowrap"
                                sx={{ mr: 1, color: "#000" }}
                              >
                                <img
                                  src="assets/images/icon/right_arrow.svg"
                                  width="7px"
                                />
                              </button>
                              &nbsp;
                              <b>Back</b>
                            </div>
                          </Col>
                          <Col xl={8} lg={8} sm={8}>
                            <div className="text-center">
                              <h3 className="mb-4 position-relative">
                                <b>CHOOSE A SUBSCRIPTION PLAN</b>
                              </h3>
                            </div>
                          </Col>
                          <Col xl={2}>
                            <div className="position-relative mb-4">
                              <select
                                className="form-control"
                                onChange={(e) =>
                                  planDataByUser.length == 0
                                    ? getSubscriptionplanAPI(e.target.value)
                                    : getSubscriptionplan(
                                      planDataByUser,
                                      e.target.value
                                    )
                                }
                              >
                                <option value="1">1 Month</option>
                                <option value="6">6 Months</option>
                                <option value="12">1 Year</option>
                              </select>
                              <IoIosArrowDown
                                className="position-absolute"
                                style={{ top: "14px", right: "12px" }}
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>

              <Col xl={12} lg={12}>
                <div className="subscriptionplan">
                  <Row>
                    {planData.length === 0 ? (
                      <div
                        className="py-5 mt-5"
                        style={{ textAlign: "center" }}
                      >
                        <img
                          src="assets/images/listing_img.svg"
                          width={`220px`}
                        />
                        <h6 className="text-center mt-3">No Data Found!!</h6>
                      </div>
                    ) : (
                      planData.map((item) => (
                        <Col xl={4} lg={4} className="mb-4">
                          <Card>
                            <div className="d-flex align-items-center">
                              <Card.Img
                                variant="top"
                                src="assets/images/plana.png"
                                width="60px"
                                className="me-2"
                              />
                              <h5 className="mb-0">
                                <b>{item.subscriptionType}</b> ({item.name}){" "}
                              </h5>
                            </div>
                            <Card.Body>
                              <p>{item.description}</p>
                              <div className="d-flex align-items-center pb-3">
                                <h2>
                                  <b>${item.amount}</b>
                                </h2>
                                <span>
                                  / {item.validity}{" "}
                                  {item.validity == 1 ? "Month" : "Months"}
                                </span>
                              </div>

                              <Card.Text>
                                <h6>
                                  <b>What’s included</b>
                                </h6>
                                <ul>
                                  {item.IncludedDetails &&
                                    item.IncludedDetails.map((item1) => (
                                      <li>
                                        <img
                                          src="assets/images/icon/check_circle.svg"
                                          className="me-1"
                                        />
                                        {item1?.name}
                                      </li>
                                    ))}
                                </ul>
                              </Card.Text>

                              {loginData?.id ? (
                                <Button
                                  disabled={!item.isSubscribed ? false : true}
                                  variant="primary"
                                  className="w-100 rounded-1 mt-3"
                                  onClick={() => choosePlan(item)}
                                >
                                  {!item.isSubscribed
                                    ? "Get started"
                                    : "Subscribed"}{" "}
                                </Button>
                              ) : (
                                <Link to={`${config.baseUrl}login`}>
                                  <Button
                                    variant="primary"
                                    className="w-100 rounded-1 mt-3"
                                  >
                                    Get started
                                  </Button>
                                </Link>
                              )}
                            </Card.Body>
                          </Card>
                        </Col>
                      ))
                    )}
                  </Row>
                </div>
              </Col>
            </Row>
          ) : (
            <Row className="justify-content-center ">
              <Col xl={12} lg={12}>
                <div className="login-container">
                  <div className="login-form">
                    <div className="login-logo ">
                      <Link to={`${config.baseUrl}`} className="">
                        <img
                          src="assets/images/logo/logo.webp"
                          alt="logo"
                          className=""
                        />
                      </Link>
                      <div className="pt-4">
                        <Row className="align-items-center">
                          <Col xl={2} lg={2} sm={2}>
                            <div className="back-btn mb-4">
                              <button
                                onClick={backBtn}
                                className="border-1 rounded-1 border-secondary h-auto arrow_left btn btn-default"
                              >
                                <img
                                  src="assets/images/icon/right_arrow.svg"
                                  width="7px"
                                />
                              </button>
                              &nbsp;<b>Back</b>
                            </div>
                          </Col>
                          <Col xl={8} lg={8} sm={8}>
                            <div className="text-center">
                              <h3 className="mb-4 position-relative">
                                <b>PLAN DETAILS</b>
                              </h3>
                            </div>
                          </Col>
                          <Col xl={2}></Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xl={12} lg={12}>
                <div className="subscriptionplanDetails">
                  <Row>
                    <Col xl={3} lg={3} className="mb-4"></Col>
                    <Col xl={6} lg={6} className="mb-4">
                      <Card>
                        <div className="d-flex align-items-center">
                          <Card.Img
                            variant="top"
                            src="assets/images/plana.png"
                            width="60px"
                            className="me-2"
                          />
                          <h5 className="mb-0">
                            <b>{chooseplanDetails.subscriptionType}</b> (
                            {chooseplanDetails.name}){" "}
                          </h5>
                        </div>
                        <Card.Body className="pb-0">
                          <p>{chooseplanDetails.description} </p>
                          <div className="d-flex align-items-center pb-3">
                            <h2>
                              <b>${chooseplanDetails.amount}</b>
                            </h2>
                            <span>
                              / {chooseplanDetails.validity}{" "}
                              {chooseplanDetails.validity == 1
                                ? "Month"
                                : "Months"}
                            </span>
                          </div>
                          <Card.Text>
                            <h6>
                              <b>What’s included</b>
                            </h6>
                            <ul>
                              {chooseplanDetails.IncludedDetails &&
                                chooseplanDetails.IncludedDetails.map(
                                  (data) => (
                                    <li>
                                      <img
                                        src="assets/images/icon/check_circle.svg"
                                        className="me-1"
                                      />
                                      {data?.name}
                                    </li>
                                  )
                                )}
                            </ul>
                          </Card.Text>

                          <PayPalScriptProvider
                            options={{ "client-id": config.CLIENT_ID }}
                            className="pglk"
                          >
                            <PayPalButtons
                              style={{ layout: "vertical" }}
                              className="paypal-buttons"
                              onClick={disableStripe}
                              createOrder={createOrder}
                              onApprove={onApprove}
                              onError={onError}
                              fundingSource={FUNDING.PAYPAL}
                            />
                          </PayPalScriptProvider>

                          {/* <button className="btn paypal-button w-100 mt-2" onClick={callFygaro}>Fygaro</button> */}
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col xl={3} lg={3} className="mb-4"></Col>
                  </Row>
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </section>
    </>
  );
};

export default Subscriptionplan;
