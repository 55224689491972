import React, { useState, useEffect } from "react";
import Header from "../generalPages/directives/header";
import Footer from "../generalPages/directives/footer";
import "./generalCSS/home.css";
import "./generalCSS/generalnavbar.css";
import "./generalCSS/blog.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { getBlogDetailAction } from "../Action/user.action";
import moment from "moment";
import configCore from "../coreFIles/config";

import { Link } from "react-router-dom";
import config from "../config";

const Blogdetail = () => {
  const { id } = useParams();

  const [blogDetailsByid, setBlogDetailsByid] = useState([]);
  const [blogDetailsByidRecent, setBlogDetailsByidRecent] = useState([]);

  useEffect(() => {
    getMoreSuggestionAPI();
  }, []);

  const getMoreSuggestionAPI = async () => {
    let res = await getBlogDetailAction({ id: id });
    if (res.success) {
      setBlogDetailsByid(res.data);
      setBlogDetailsByidRecent(res.getRecentBlogsDetails);
    }
  };

  return (
    <>

      <section className="bg-light py-4">
        <Container>
          <Row className="align-items-center">
            <Col lg={12} md={12} className=" text-center">
              <div className="">
                <h1 className=" mb-0 py-4">
                  <b>Blog Detail</b>
                  <div class="section-title-line"> </div>
                </h1>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="blog">
        <Container>
          <Row className="justify-content-center">
            <Row>
              <Col lg={12} className=" mb-5">
                <Row>
                  <Col lg={8} className="mb-5">
                    <Card className="overflow-hidden border-0">
                      <div className="post-image-card overflow-hidden">
                        <Card.Img
                          variant="top"
                          src={blogDetailsByid.image}
                          style={{ width: "720px", height: "405px" }}
                        />
                      </div>
                      <div className="list-inline-icon">
                        <ul>
                          <li>
                            <div className="d-flex align-items-center">
                              <svg
                                aria-hidden="true"
                                class="e-font-icon-svg e-fas-calendar"
                                viewBox="0 0 448 512"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M12 192h424c6.6 0 12 5.4 12 12v260c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V204c0-6.6 5.4-12 12-12zm436-44v-36c0-26.5-21.5-48-48-48h-48V12c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v52H160V12c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v52H48C21.5 64 0 85.5 0 112v36c0 6.6 5.4 12 12 12h424c6.6 0 12-5.4 12-12z"></path>
                              </svg>
                              &nbsp;
                              {moment(blogDetailsByid.dateTime).format(
                                "MMM DD, yyyy"
                              )}
                              {/* November 8, 2023 */}
                            </div>
                          </li>
                          <li>
                            <div className="d-flex align-items-center">
                              <svg
                                aria-hidden="true"
                                class="e-font-icon-svg e-far-clock"
                                viewBox="0 0 512 512"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z"></path>
                              </svg>
                              &nbsp;
                              {moment(blogDetailsByid.dateTime).format(
                                "HH:MM A"
                              )}
                            </div>
                          </li>
                        </ul>
                      </div>

                      <Card.Body>
                        <h5 className="fw-bold">{blogDetailsByid.title}</h5>
                        <Card.Text>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: blogDetailsByid.description,
                            }}
                          ></p>{" "}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col lg={4} className="mb-5">
                    <div
                      class="lh-our-blog aos-init aos-animate"
                      data-aos="fade-up"
                      data-aos-duration="1500"
                    >
                      <div class="lh-our-blog-post">
                        <div class="lh-our-blog-heading">
                          <h4 className="fw-bold">Recent Blogs</h4>
                        </div>
                        {blogDetailsByidRecent.map((item) => {
                          return (
                            <div class="row lh-our-blog-post-pb">
                              <a
                                href={`${config.baseurl}BlogDetail/${item.blogId}`}
                                style={{ textDecoration: "none" }}
                              >
                                <div class="col-12 lh-our-blog-post-inner">
                                  <img
                                    src={item.image}
                                    style={{ width: "80px", height: "80px" }}
                                    alt="blog_post-1"
                                  />
                                  <div class="lh-our-blog-post-contain">
                                    <span className="text-primary small">
                                      {moment(item.dateTime).format(
                                        "MMM DD, yyyy"
                                      )}
                                    </span>
                                    <a href={`${config.baseurl}BlogDetail/${item.blogId}`}>{item.title}</a>
                                  </div>
                                </div>
                              </a>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Row>
        </Container>
      </section>

    </>
  );
};

export default Blogdetail;
