import React, { useState, useEffect, useMemo } from "react";
import Header from "../commanComponents/userheader";
import Footer from "../commanComponents/userfooter";
import config from "../../coreFIles/config";
import { toast, Toaster } from "react-hot-toast";
import CryptoJS from 'crypto-js';
import {
  getVehicleDetailActionbyid,
  insertvehicleBookingDetailsAction,
  getFygaroJwt
} from "../../Action/user.action";
import {
  getSubscriptionPlanAction,
  InsertPaymentDetailsAction,
  checkPlanPurchaseAction,
  paymentIntentAction,
} from "../../Action/propertyOwner.action";
import {
  PayPalScriptProvider,
  PayPalButtons,
  FUNDING,
} from "@paypal/react-paypal-js";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Card,
  Modal,
} from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { Buffer } from "buffer";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../../generalPages/vehicleStripe";
import payment from "../commanComponents/componentCSS/payment.css";
import moment from "moment";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import $ from "jquery";
const stripePromise = loadStripe(config.stripePrivateKey);
const VehiclePayment = () => {
  const navigate = useNavigate();
  // const { id, passenger, checkin, checkout } = useParams();

  const url = window.location.href;
  const params = new URLSearchParams(new URL(url).search);
  const id = params.get('id'); // "116"
  const checkin = params.get('checkin'); // "2024-11-22 12:46:00"
  const checkout = params.get('checkout'); // "2024-11-23 12:46:00"
  const passenger = params.get('passenger'); // "0"

  const [vehicleDetail, setvehicleDetail] = useState({
    PropertyName: "",
    engineArr: [],
    bikeParking: "",
    carParking: "",
    categoryName: "",
    checkIn: "",
    checkOut: "",
    datetime: "",
    description: "",
    documentArr: [],
    email: "",
    guestCount: "",
    id: "",
    imageArr: [],
    isDeleted: "",
    location: "",
    numberOfBathroom: "",
    numberOfBedrooms: "",
    numberOfBeds: "",
    petsAllowed: "",
    price: "",
    propertyName: "",
    propertyPurchaseType: "",
    propertyPurchaseTypeId: "",
    status: "",
    terms: "",
  });
  const [imagesArr, setimagesArr] = useState([]);
  const [engineArr, setengineArr] = useState([]);
  const [totalBookingHour, settotalBookingHour] = useState(0);
  const [paymentType, setpaymentType] = useState(1); //1=Card ,2=Bank Transfer ,3=Paypal,4=Stripe,5=MMG
  const [clientSecret, setClientSecret] = useState("");
  const [chooseplanDetails, setChoosePlanDetails] = useState({
    description: "",
  });
  const [totalAmount, settotalAmount] = useState(0);
  const [orderAmount, setorderAmount] = useState(0);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [fygaroToken, setFygaroToken] = useState('');


  useEffect(() => {
    getListingDetails();
  }, []);


  const getFygaroPayloadAPI = async (data) => {
    const encryptionKey = config.fygaroJWTEncryptionKey;
    const [checkinDate, checkinTime] = checkin.split(" ");

    const [checkoutDate, checkoutTime] = checkout.split(" ");


    let bookingHours;

    if (checkin && checkout) {
      var date1 = new Date(checkin);
      var date2 = new Date(checkout);

      const getHoursDiffBetweenDates = (dateInitial, dateFinal) =>
        (dateFinal - dateInitial) / (1000 * 3600);
      const hour = parseFloat(getHoursDiffBetweenDates(date1, date2)).toFixed(
        0
      );
      bookingHours = hour;
    }

    let amount = data.perHourRate * bookingHours

    let fygaroPayload = {
      vehicleId: id,
      passengers: passenger,
      checkInDate: checkinDate,
      checkInTime: checkinTime,
      checkOutDate: checkoutDate,
      checkOutTime: checkoutTime,
      amount: amount,
      type: 2
    };

    const encryptedPayload = CryptoJS.AES.encrypt(
      JSON.stringify(fygaroPayload),
      encryptionKey
    ).toString();

    let jwtReceived = await getFygaroJwt({ jwt: encryptedPayload });

    if (jwtReceived.success) {
      setFygaroToken(jwtReceived.data);
    }
  };

  const getListingDetails = async () => {
    let res = await getVehicleDetailActionbyid({ id: id });
    if (res.success) {
      setvehicleDetail(res.data);
      setimagesArr(JSON.parse(res.data.imageArr));
      setengineArr(res.data.engineArr);
      getFygaroPayloadAPI(res.data)
    }
    if (checkin && checkout) {
      //Difference in number of days
      var date1 = new Date(checkin);
      var date2 = new Date(checkout);

      const getHoursDiffBetweenDates = (dateInitial, dateFinal) =>
        (dateFinal - dateInitial) / (1000 * 3600);
      const hour = parseFloat(getHoursDiffBetweenDates(date1, date2)).toFixed(
        0
      );
      settotalBookingHour(hour);
    }
  };

  const paymentIntent = async () => {
    let amount =
      parseInt(vehicleDetail.perHourRate) * parseInt(totalBookingHour);

    let res = await paymentIntentAction({ amount: amount });
    if (res.success) {
      setClientSecret({
        clientSecret: Buffer.from(res.client_secret, "base64").toString(
          "ascii"
        ),
      });
    }
  };

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>    PAYPAL PAYMENT  START   >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  const createOrder = (data, actions) => {
    let amount =
      parseInt(vehicleDetail.perHourRate) * parseInt(totalBookingHour);

    return actions.order
      .create({
        purchase_units: [
          {
            description: chooseplanDetails.description,
            amount: {
              currency_code: "USD",
              value: amount,
            },
          },
        ],
      })
      .then((orderID) => {
        return orderID;
      });
  };

  const onApprove = (data, actions) => {
    let amount =
      parseInt(vehicleDetail.perHourRate) * parseInt(totalBookingHour);

    return actions.order.capture().then(async function (res) {
      console.log("66666666", amount, res, vehicleDetail);
      if (res.status == "COMPLETED") {
        let arr = {
          vehicleId: parseInt(id),
          paymentMethodId: 1,
          checkInDate: checkin,
          checkOutDate: checkout,
          passenger: passenger,
          checkInTime:
            ("0" + new Date(checkin).getHours()).slice(-2) +
            ":" +
            ("0" + new Date(checkout).getMinutes()).slice(-2),
          checkOutTime:
            ("0" + new Date(checkout).getHours()).slice(-2) +
            ":" +
            ("0" + new Date(checkout).getMinutes()).slice(-2),
          locationPickup: true,
          amount:
            parseInt(vehicleDetail.perHourRate) * parseInt(totalBookingHour),
          status: 1,
          upiId: "",
          paymentResponse: res,
          orderId: res.id,
        };

        console.log("arr", arr);
        console.log(
          new Date(checkin).getHours() + ":" + new Date(checkout).getMinutes(),
          "checking cjeckin"
        );

        let result = "";
        result = await insertvehicleBookingDetailsAction(arr);
        if (result.success) {
          toast.success(result.msg);

          setTimeout(() => {
            navigate(`${config.baseUrl}userdashboard/vehicleBooking`);
          }, 2000);
        } else {
          toast.error(result.msg);
        }
      } else {
        toast.error(
          "Something went wrong with your payment. Please try again after some time"
        );
      }
    });
  };

  const onError = (data, actions) => {
    toast.error(
      "Something went wrong with your payment. Please try again after some time"
    );
  };

  const disableStripe = () => {
    setClientSecret("");
  };

  useMemo(() => {
    if (paymentType == 2) {
      paymentIntent();
    }
  }, [paymentType]);

  const createPdfFile = () => {
    var HTML_Width = $(".html-content").width();
    var HTML_Height = $(".html-content").height();
    var top_left_margin = 15;
    var PDF_Width = HTML_Width + top_left_margin * 2;
    var PDF_Height = PDF_Width * 1.5 + top_left_margin * 2;
    var canvas_image_width = HTML_Width;
    var canvas_image_height = HTML_Height;

    var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;

    html2canvas($(".html-content")[0]).then(function (canvas) {
      var imgData = canvas.toDataURL("image/jpeg", 1.0);
      var pdf = new jsPDF("p", "pt", [PDF_Width, PDF_Height]);
      pdf.addImage(
        imgData,
        "JPG",
        top_left_margin,
        top_left_margin,
        canvas_image_width,
        canvas_image_height
      );
      for (var i = 1; i <= totalPDFPages; i++) {
        pdf.addPage(PDF_Width, PDF_Height);
        pdf.addImage(
          imgData,
          "JPG",
          top_left_margin,
          -(PDF_Height * i) + top_left_margin * 4,
          canvas_image_width,
          canvas_image_height
        );
      }
      pdf.save("Your_PDF_Name.pdf");
      // $(".html-content").hide();
    });
  };

  const callFygaro = () => {
    if (fygaroToken) {
      let fygaroString = `https://www.fygaro.com/en/pb/8ef036d5-3406-4b0d-858f-8d814667d469?jwt=${fygaroToken}`;
      window.open(fygaroString, '_blank');
    } else {
      toast.error('Something went wrong please try again after some time.');
    }
  };

  return (
    <>
      <Header />
      <Toaster />
      <section className="yourbooking pt-5 pb-5 overflow-hidden vh-80">
        <Container>
          <div className="">
            <Row className=" ">
              <Col lg={5} className="mb-4">
                <div className="heading border-bottom border-secondary pt-2 pb-2">
                  <h5>
                    <b>Payment</b>
                  </h5>
                </div>
                <div className="mt-4">
                  <h6 className="mb-2">
                    <b>Pay With:</b>
                  </h6>
                  <Form>
                    {["radio"].map((type) => (
                      <div key={`inline-${type}`} className="mb-3">
                        {/* <Form.Check
                                                    inline
                                                    label="Card"
                                                    name="group1"
                                                    onChange={e=>setpaymentType(1)}
                                                    type={type}
                                                    id={`inline-${type}-1`}
                                                    className="me-3"
                                                /> */}
                        {/* <Form.Check
                                                    inline
                                                    label="Bank Transfer"
                                                    onChange={e=>setpaymentType(2)}
                                                    name="group1"
                                                    type={type}
                                                    id={`inline-${type}-2`}
                                                    className="me-3"
                                                /> */}
                        <Form.Check
                          inline
                          label="PayPal"
                          onChange={(e) => setpaymentType(1)}
                          checked={paymentType == 1}
                          name="group1"
                          type={type}
                          id={`inline-${type}-3`}
                          className="me-3"
                        />
                        {/* <Form.Check
                          inline
                          label="Stripe"
                          onChange={(e) => setpaymentType(2)}
                          name="group1"
                          checked={paymentType == 2}
                          type={type}
                          id={`inline-${type}-4`}
                          className="me-3"
                        /> */}
                        {/* <Form.Check
                                                    inline
                                                    label="MMG"
                                                    onChange={e=>setpaymentType(5)}
                                                    name="group1"
                                                    type={type}
                                                    id={`inline-${type}-5`}
                                                /> */}
                      </div>
                    ))}
                  </Form>
                </div>
                {/* <div className="mt-4">
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label className="fs-6"><b>Card Number</b></Form.Label>
                                        <Form.Control type="text" placeholder="1234  5678  9101  1121" />
                                    </Form.Group>
                                    <Row>
                                        <Col lg={6}>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label className="fs-6"><b>Expiration Date</b></Form.Label>
                                                <Form.Control type="date" placeholder="MM/YY" />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label className="fs-6"><b>CVV</b></Form.Label>
                                                <Form.Control type="text" placeholder="123" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicCheckbox"
                                    >
                                        <Form.Check
                                            type="checkbox"
                                            label="Save card details"
                                            name="tos"
                                        />
                                    </Form.Group>
                                    <Button variant="primary" className="w-100 rounded-1 mb-3">Pay Now</Button>

                                    <p className="text-grey">Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our privacy policy.</p>
                                </div> */}

                {paymentType == 1 && vehicleDetail.perHourRate > 0 ? (
                  <PayPalScriptProvider
                    options={{ "client-id": config.CLIENT_ID }}
                    className="pglk"
                  >
                    <PayPalButtons
                      style={{ layout: "vertical" }}
                      className="paypal-buttons"
                      onClick={disableStripe}
                      createOrder={createOrder}
                      onApprove={onApprove}
                      onError={onError}
                      fundingSource={FUNDING.PAYPAL}
                    />
                  </PayPalScriptProvider>
                ) : clientSecret && paymentType == 2 ? (
                  <Elements stripe={stripePromise} options={clientSecret}>
                    <div className="text-center" style={{ padding: "15px" }}>
                      <CheckoutForm
                        data={{
                          vehicleId: parseInt(id),
                          paymentMethodId: 1,
                          checkInDate: checkin,
                          checkOutDate: checkout,
                          passenger: passenger,
                          checkInTime:
                            new Date(checkin).getHours() +
                            ":" +
                            new Date(checkout).getMinutes(),
                          checkOutTime:
                            new Date(checkout).getHours() +
                            ":" +
                            new Date(checkout).getMinutes(),
                          locationPickup: true,
                          amount:
                            parseInt(vehicleDetail.perHourRate) *
                            parseInt(totalBookingHour),
                          status: 1,
                          upiId: "",
                        }}
                      />
                    </div>
                  </Elements>
                ) : (
                  ""
                )}

                {/* <button className="btn paypal-button w-100 mt-2" onClick={callFygaro}>Fygaro</button> */}

              </Col>
              <Col lg={2}></Col>
              <Col lg={5} className="mb-4">
                <Card>
                  <div className="paymentImg">
                    {imagesArr.length > 0 ? (
                      <img
                        src={`${imagesArr[0].fileNameImage}`}
                      />
                    ) : (
                      ""
                    )}

                  </div>
                  <div className="px-2">
                    <div>
                      <div className="mt-4">
                        <div className="">
                          <h5 className="mb-2">
                            <b>{vehicleDetail.vehicleName}</b>
                          </h5>
                          <div className="d-flex justify-content-between">
                            <div>
                              <h6>{vehicleDetail.location}</h6>
                            </div>
                            <h5>
                              {" "}
                              <b>
                                $
                                {(parseInt(vehicleDetail.perHourRate) * parseInt(totalBookingHour)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                              </b>
                            </h5>
                          </div>
                        </div>
                      </div>
                      <hr />

                      <div className="">
                        <Row>
                          <Col lg={4} className="">
                            <div>
                              <div>
                                <b>Start Date</b>
                              </div>
                              <p className="text-grey mb-0">{checkin}</p>
                            </div>
                          </Col>
                          <Col lg={4} className="">
                            <div>
                              <div>
                                <b>End Date</b>
                              </div>
                              <p className="text-grey mb-0">{checkout}</p>
                            </div>
                          </Col>
                          <Col lg={4} className="">
                            <div>
                              <div>
                                <b>Hours</b>
                              </div>
                              <p className="text-grey mb-0">
                                {totalBookingHour} Hours
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <hr />
                      <div className="mt-4 mb-4">
                        <Row>
                          <Col lg={6} sm={6} xs={6} className="mb-2">
                            <h6>
                              <td>
                                {" "}
                                ${parseInt(vehicleDetail.perHourRate)} X{" "}
                                {parseInt(totalBookingHour)} hours
                              </td>
                            </h6>
                          </Col>
                          <Col lg={6} sm={6} xs={6} className="mb-2 text-right">
                            <span className="text-grey">
                              $
                              {(parseInt(vehicleDetail.perHourRate) * parseInt(totalBookingHour)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            </span>
                          </Col>
                          <Col lg={6} sm={6} xs={6} className="mb-2">
                            <h6>Tax Fees</h6>
                          </Col>
                          <Col lg={6} sm={6} xs={6} className="mb-2 text-right">
                            <span className="text-grey">$0</span>
                          </Col>
                          <Col lg={6} sm={6} xs={6} className="mb-2 ">
                            <h5>
                              <b>Total Cost:</b>
                            </h5>
                          </Col>
                          <Col lg={6} sm={6} xs={6} className="mb-2 text-right">
                            <h5 className="text-grey">

                              $
                              {(parseInt(vehicleDetail.perHourRate) * parseInt(totalBookingHour)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            </h5>
                          </Col>
                          {/* <Col lg={12} style={{cursor:"pointer"}} onClick={handleShow} className="text-right">
                                                        <a >Download Invoice</a>
                                                    </Col>  */}
                        </Row>
                      </div>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <Footer />
      <Modal show={show} onHide={handleClose} size="md">
        <Modal.Header closeButton>
          <Modal.Title>
            <b>Invoice Details</b>
          </Modal.Title>
          <div style={{ "margin-left": "25px" }}>
            <button
              className="btn btn-primary h-auto btn-sm rounded-1"
              onClick={createPdfFile}
            >
              Download
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="px-2">
            <div>
              <div className="mt-4">
                <div className="">
                  <h5 className="mb-2">
                    <b>{vehicleDetail.vehicleName}</b>
                  </h5>
                  <div className="d-flex justify-content-between">
                    <div>
                      <h6>{vehicleDetail.location}</h6>
                    </div>
                    <h5>
                      {" "}
                      <b>
                        $
                        {parseInt(vehicleDetail.perHourRate) *
                          parseInt(totalBookingHour)}
                      </b>
                    </h5>
                  </div>
                </div>
              </div>
              <hr />

              <div className="">
                <Row>
                  <Col lg={4} className="">
                    <div>
                      <div>
                        <b>Start Date</b>
                      </div>
                      <p className="text-grey mb-0">{checkin}</p>
                    </div>
                  </Col>
                  <Col lg={4} className="">
                    <div>
                      <div>
                        <b>End Date</b>
                      </div>
                      <p className="text-grey mb-0">{checkout}</p>
                    </div>
                  </Col>
                  <Col lg={4} className="">
                    <div>
                      <div>
                        <b>Hours</b>
                      </div>
                      <p className="text-grey mb-0">{totalBookingHour} Hours</p>
                    </div>
                  </Col>
                </Row>
              </div>
              <hr />
              <div className="mt-4 mb-4">
                <Row>
                  <Col lg={6} sm={6} xs={6} className="mb-2">
                    <h6>
                      <td>
                        {" "}
                        ${parseInt(vehicleDetail.perHourRate)} X{" "}
                        {parseInt(totalBookingHour)} hours
                      </td>
                    </h6>
                  </Col>
                  <Col lg={6} sm={6} xs={6} className="mb-2 text-right">
                    <span className="text-grey">
                      $
                      {parseInt(vehicleDetail.perHourRate) *
                        parseInt(totalBookingHour)}
                    </span>
                  </Col>
                  <Col lg={6} sm={6} xs={6} className="mb-2">
                    <h6>Tax Fees</h6>
                  </Col>
                  <Col lg={6} sm={6} xs={6} className="mb-2 text-right">
                    <span className="text-grey">$0</span>
                  </Col>
                  <Col lg={6} sm={6} xs={6} className="mb-2 ">
                    <h5>
                      <b>Total Cost:</b>
                    </h5>
                  </Col>
                  <Col lg={6} sm={6} xs={6} className="mb-2 text-right">
                    <h5 className="text-grey">
                      $
                      {parseInt(vehicleDetail.perHourRate) *
                        parseInt(totalBookingHour)}
                    </h5>
                  </Col>
                  {/* <Col lg={12} style={{cursor:"pointer"}} onClick={handleShow} className="text-right">
                                                        <a >Download Invoice</a>
                                                    </Col>  */}
                </Row>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
    // ----------------
  );
};

export default VehiclePayment;
