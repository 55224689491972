import React, { useState, useEffect, useRef } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    Button,
    Table
} from "react-bootstrap";
import DataTable, { createTheme } from "react-data-table-component";
import { Link } from "react-router-dom";
import config from "../../../config";
import Header from "../../commanComponents/usernavbar";
import "../css/staffdashboard.css";
import "../css/finance.css";
import {
    getEstimateList,
    deleteEstimate,
} from "../../../Action/propertyOwner.action";
import { toast, Toaster } from "react-hot-toast";
import { FaPlus, FaArrowLeftLong } from "react-icons/fa6";



const VehicleEstimateList = () => {
    useEffect(() => {
        // Add the class to the body tag
        document.body.classList.add("staffbody");

        // Cleanup function to remove the class when the component is unmounted
        return () => {
            document.body.classList.remove("staffbody");
        };
    }, []);

    createTheme(
        "solarized",
        {
            text: {
                primary: "#000",
                secondary: "#2aa198",
            },
            background: {
                default: "",
            },
            context: {
                background: "#cb4b16",
                text: "#FFFFFF",
            },
            divider: {
                default: "#EBEBEB",
            },
            action: {
                button: "#E46422",
                hover: "rgba(0,0,0,.08)",
                disabled: "rgba(0,0,0,.12)",
            },
        },
        "dark"
    );

    const customStyles = {
        rows: {
            style: {
                minHeight: "70px", // override the row height
            },
        },
        headCells: {
            style: {
                minHeight: "60px",
                paddingLeft: "20px", // override the cell padding for head cells
                paddingRight: "20px",
                background: "#FFF8F4",
            },
        },
        cells: {
            style: {
                paddingLeft: "20px", // override the cell padding for data cells
                paddingRight: "20px",
            },
        },
    };

    const [bankDeposite, setBankDeposite] = useState([]);



    useEffect(() => {
        fetchBankDeposite();
    }, []);

    const fetchBankDeposite = async () => {
        try {
            let res = await getEstimateList({ type: 2 });
            if (res.success) {
                setBankDeposite(res.data || []);
            }
        } catch (error) { }
    };

    const deleteData = async (id) => {
        try {
            let res = await deleteEstimate({ id: id });
            if (res.success) {
                toast.success(res.msg);
                fetchBankDeposite();
            } else {
                toast.error(res.msg);
            }
        } catch (error) { }
    };

    const columns = [
        {
            name: "#",
            selector: (row, index) => index + 1,
            sortable: true,
            width: "5.6%",
        },
        {
            name: "Customer Name",
            selector: (row) => row.customerName,
            width: "8.6%",
        },
        {
            name: "Email",
            selector: (row) => row.email,
            width: "16.6%",
        },

        {
            name: "Estimate Date",
            selector: (row) => {
                const date = new Date(row.estimateDate);
                const day = String(date.getDate()).padStart(2, "0");
                const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
                const year = date.getFullYear();
                return `${day}/${month}/${year}`;
            },
            width: "10.6%",
        },

        {
            name: "Expiration Date",
            selector: (row) => {
                const date = new Date(row.expirationDate);
                const day = String(date.getDate()).padStart(2, "0");
                const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
                const year = date.getFullYear();
                return `${day}/${month}/${year}`;
            },
            width: "10.6%",
        },

        {
            name: "Estimate Number",
            selector: (row) => row.estimateNo,
            width: "12.6%",
        },
        {
            name: "message",
            selector: (row) => row.message,
            width: "16.6%",
        },

        {
            name: "Action",
            width: "10.6%",
            selector: (row) => (
                <>
                    {/* <Link to={`${config.baseurl}invoicedetail/${row.id}`}>
            <Button variant={`primary`} className="btn-sm h-auto  rounded-5 ">
              &nbsp;View
            </Button>
          </Link>
          &nbsp; */}
                    <Button
                        variant={`primary`}
                        className="btn-sm h-auto  rounded-5 "
                        onClick={() => {
                            deleteData(row.id);
                        }}
                    >
                        &nbsp;Delete
                    </Button>
                </>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    return (
        <div className="staffdashboard bg-pink">
            <Header />
            <Toaster />
            <section className="listing pt-5 pb-5 overflow-hidden vh-80">
                <Container>
                    <Row>
                        <Col lg={5} sm={12} xs={12} className="mb-4">
                            <Link to={`${config.baseurl}financevehicledashboard`}>
                                <Button variant="default" className="p-0 h-auto">
                                    <FaArrowLeftLong size={`25px`} />
                                </Button>
                            </Link>
                        </Col>
                        <Col lg={7} sm={12} xs={12} className="text-right"></Col>
                    </Row>
                    <div className="">
                        <Col lg={12} className="mb-3">
                            <Card className="border-0 bg-white p-3" >
                                <Card.Header className="border-0 mb-0 pb-0">
                                    <div className="d-flex align-items-center justify-content-between mb-4">
                                        <div className="">
                                            <h4 className="mb-0 fw-bolder">Estimates </h4>
                                        </div>
                                        <div className="text-end">
                                            <div>
                                                <div className="d-inline-block">

                                                    <Link to={`${config.baseurl}addvehicleestimate`}>
                                                        <Button variant={`primary`} className="btn-sm h-auto  rounded-4 ">
                                                            &nbsp;ADD
                                                        </Button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Header>
                                <DataTable
                                    columns={columns}
                                    data={bankDeposite}
                                    customStyles={customStyles}
                                    pagination
                                />

                            </Card>
                        </Col>
                    </div>
                </Container>
            </section>
        </div>
    );
};

export default VehicleEstimateList;
