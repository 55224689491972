import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Card } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "../commanComponents/componentCSS/revenue.css";
import { FaStar } from "react-icons/fa";
import config from "../../coreFIles/config";
import config1 from "../../config";
import {
  getVehicleBookingDetailsAction,
  getVehicleDashboardChartDataAction,
  getVehicleOwnersVehicleReviewAction,
} from "../../Action/user.action";
import CanvasJSReact from "@canvasjs/react-charts";
import Cookies from "js-cookie";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;
const Revenue = () => {

  const loginData = !Cookies.get("loginSuccessPropertyOwner")
    ? []
    : JSON.parse(Cookies.get("loginSuccessPropertyOwner"));
  if (!loginData || loginData == "") {
    window.location.href = `${config.baseurl}`;
  }

  const navigate = useNavigate();
  const [completedTrx, setCompletedTrx] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [todayBooking, setTodayBooking] = useState(0);
  const [reviews, setReviews] = useState([]);
  const startYear = 2022;
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const [revenueYears, setRevenueYears] = useState([]);
  const [totalRevenue, setTotalRevenue] = useState(0);

  useEffect(() => {
    // getCompletedTransactions(3);
    getDashboardChartDataAPI();
    getReviewAPI();
  }, []);


  useEffect(() => {
    let tempChartData = [];

    for (let i = 0; i < currentYear - startYear; i++) {
      tempChartData.push(startYear + i + 1);
    }

    setRevenueYears(tempChartData);
  }, [startYear, currentYear]);

  // const getCompletedTransactions = async (type) => {
  //   let res = await getVehicleBookingDetailsAction({ type: type });
  //   if (res.success) {
  //     setCompletedTrx(res.data);
  //   } else {
  //     setCompletedTrx([]);
  //   }
  // };
  const getReviewAPI = async () => {
    let res = await getVehicleOwnersVehicleReviewAction();
    if (res.success) {
      setReviews(res.data);
    }
  };

const getDashboardChartDataAPI = async (event) => {
  let res = await getVehicleDashboardChartDataAction({
    year: event?.target?.value ? event?.target?.value : currentYear,
  });
  
  if (res.success) {
    const totalSum = res.data.getVehicleDashboardChartData.reduce((sum, item) => parseFloat(sum) + parseFloat(item.total), 0);
    setTotalRevenue(totalSum);

    const defaultColor = "#E4A672";
    const hoverColor = "#E46422";
    setTodayBooking(
      res.data.getTotalTodayBooking
        ? res.data.getTotalTodayBooking[0].todayBooking
        : 0
    );

    let optionsArr = {
      animationEnabled: true,
      exportEnabled: true,
      toolTip: {
        shared: true,
        reversed: true,
      },
      legend: {
        verticalAlign: "center",
        horizontalAlign: "right",
        reversed: true,
        cursor: "pointer",
      },
      data: [
        {
          type: "stackedColumn",
          name: "Booking",
          yValueFormatString: "$#,##0", // Format with commas for the tooltip
          color: defaultColor,
          dataPoints: [
            { label: "Jan", y: parseFloat(res.data.getVehicleDashboardChartData[0]?.total || 0) },
            { label: "Feb", y: parseFloat(res.data.getVehicleDashboardChartData[1]?.total || 0) },
            { label: "Mar", y: parseFloat(res.data.getVehicleDashboardChartData[2]?.total || 0) },
            { label: "Apr", y: parseFloat(res.data.getVehicleDashboardChartData[3]?.total || 0) },
            { label: "May", y: parseFloat(res.data.getVehicleDashboardChartData[4]?.total || 0) },
            { label: "Jun", y: parseFloat(res.data.getVehicleDashboardChartData[5]?.total || 0) },
            { label: "Jul", y: parseFloat(res.data.getVehicleDashboardChartData[6]?.total || 0) },
            { label: "Aug", y: parseFloat(res.data.getVehicleDashboardChartData[7]?.total || 0) },
            { label: "Sept", y: parseFloat(res.data.getVehicleDashboardChartData[8]?.total || 0) },
            { label: "Oct", y: parseFloat(res.data.getVehicleDashboardChartData[9]?.total || 0) },
            { label: "Nov", y: parseFloat(res.data.getVehicleDashboardChartData[10]?.total || 0) },
            { label: "Dec", y: parseFloat(res.data.getVehicleDashboardChartData[11]?.total || 0) },
          ],
          mouseover: function (e) {
            e.dataPoint.color = hoverColor;
            e.chart.render();
          },
          mouseout: function (e) {
            e.dataPoint.color = defaultColor;
            e.chart.render();
          },
        },
      ],
    };
    setChartData(optionsArr);
  }
};


  return (
    <div>
      <div className="graph mb-4"
        style={{
          filter: loginData.loginType === 2 ? 'blur(5px)' : 'none',
          pointerEvents: loginData.loginType === 2 ? 'none' : 'auto', // Optional: Prevent interactions when blurred
        }}>

        <Card className="p-3 border-0">
          <Row className=" align-items-center">
            <Col xl={8} lg={8} className="">
              <div className="pb-2">
                <div className="">
                  <Row className="align-items-center">
                    <Col xl={6} lg={6} className="mb-sm-3">
                      <h5 className="text-lighter-grey fw-normal">
                        Total Revenue
                      </h5>
                      <h2 className="fw-medium text-3xl mb-0">${totalRevenue ? parseFloat(totalRevenue).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      }) : 0}</h2>
                    </Col>
                    <Col xl={4} lg={3} className="mb-3">
                    </Col>
                    <Col xl={2} lg={3} className="text-right mb-3">
                      <select
                        className="form-control"
                        onChange={(event) => getDashboardChartDataAPI(event)}
                      >
                        {revenueYears.map((year, index) => (
                          <option
                            key={index}
                            value={year}
                            selected={year === currentYear}
                          >
                            {year}
                          </option>
                        ))}
                      </select>
                    </Col>
                    <Col lg={12}>
                      <hr className="mb-3 mt-0" style={{ color: "#EBEBEB", opacity: "1" }} />
                    </Col>
                  </Row>
                </div>
                <CanvasJSChart options={chartData} />
              </div>
            </Col>
            <Col xl={4} lg={4} className="review mb-3 text-center">
              <Card className="mt-3">
                <div className="card-style2">
                  <p className="mb-0 text-primary fs-5">Total Today's Booking</p>
                  <h2 className="mt-3 mb-3 text-3xl">
                    <b>
                      {parseInt(todayBooking) < 10
                        ? `0${todayBooking}`
                        : todayBooking}
                    </b>
                  </h2>
                </div>
              </Card>
              <br />
            </Col>
          </Row>
        </Card>

      </div>
    </div>
  );
};

export default Revenue;
