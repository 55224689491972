import React, { useRef, useState } from 'react';
import '../../css/listMenu.css';
import "../../../commanComponents/componentCSS/reservation.css"
import { Button } from "react-bootstrap";
import config from '../../../../config';
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa6";
import { FaChevronRight } from "react-icons/fa6";


const ListMenuButton = () => {
    const { pathname } = useLocation();

    const scrollContainerRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    const startDrag = (e) => {
        setIsDragging(true);
        setStartX(e.pageX - scrollContainerRef.current.offsetLeft);
        setScrollLeft(scrollContainerRef.current.scrollLeft);
    };

    const onDrag = (e) => {
        if (!isDragging) return;
        const x = e.pageX - scrollContainerRef.current.offsetLeft;
        const walk = (x - startX) * 2; // Scroll speed
        scrollContainerRef.current.scrollLeft = scrollLeft - walk;
    };

    const stopDrag = () => {
        setIsDragging(false);
    };
    // Scroll the container by a fixed amount when arrow buttons are clicked
    const scrollLeftFunc = () => {
        scrollContainerRef.current.scrollBy({ left: -200, behavior: 'smooth' });
    };

    const scrollRightFunc = () => {
        scrollContainerRef.current.scrollBy({ left: 200, behavior: 'smooth' });
    };

    return (
        <div className='position-relative'>
            <Button variant="outline-primary" className="scroll-arrow left-arrow h-auto rounded-5" onClick={scrollLeftFunc}>
                <FaChevronLeft />
            </Button>
            <div
                className="scroll-container"
                ref={scrollContainerRef}
                onMouseDown={startDrag}
                onMouseMove={onDrag}
                onMouseUp={stopDrag}
                onMouseLeave={stopDrag}
            >
                <button className={`scroll-button ${pathname == '/financedashboard' && 'active'}`} >
                    <Link className="text-decoration-none" to={`${config.baseurl}financedashboard`}>Home</Link>
                </button>

                <button className={`scroll-button ${pathname == '/createaccount' && 'active'}`}>
                    <Link className="text-decoration-none" to={`${config.baseurl}createaccount`}>Chart of Accounts</Link>
                </button>

                <button className={`scroll-button ${pathname == '/expenses' && 'active'}`}>
                    <Link className="text-decoration-none" to={`${config.baseurl}expenses`}>Expenses</Link>
                </button>

                <button className={`scroll-button ${pathname == '/productandservice' && 'active'}`}>
                    <Link className="text-decoration-none" to={`${config.baseurl}productandservice`}>Products and Services </Link>
                </button>

                <button className={`scroll-button ${pathname == '/receivable' && 'active'}`}>
                    <Link className="text-decoration-none" to={`${config.baseurl}receivable`}>Receivable</Link>
                </button>

                <button className={`scroll-button ${pathname == '/supplier' && 'active'}`}>
                    <Link className="text-decoration-none" to={`${config.baseurl}supplier`}>Payable</Link>
                </button>

                <button className={`scroll-button ${pathname == '/billlist' && 'active'}`}>
                    <Link className="text-decoration-none" to={`${config.baseurl}billlist`}>Create Bill</Link>
                </button>

                <button className={`scroll-button ${pathname == '/billpaymentlist' && 'active'}`}>
                    <Link className="text-decoration-none" to={`${config.baseurl}billpaymentlist`}>Bill Payment</Link>
                </button>

                <button className={`scroll-button ${pathname == '/employee' && 'active'}`}>
                    <Link className="text-decoration-none" to={`${config.baseurl}employee`}>Employee</Link>
                </button>

                <button className={`scroll-button ${pathname == '/purchaseorderlist' && 'active'}`}>
                    <Link className="text-decoration-none" to={`${config.baseurl}purchaseorderlist`}>Purchase Order</Link>
                </button>

                <button className={`scroll-button ${pathname == '/journalentry' && 'active'}`}>
                    <Link className="text-decoration-none" to={`${config.baseurl}journalentry`}>Journal Entry
                    </Link>
                </button>
                <button className={`scroll-button ${pathname == '/profitloss' && 'active'}`}>
                    <Link className="text-decoration-none" to={`${config.baseurl}profitloss`}>Profit & Loss
                    </Link>
                </button>

                <button className={`scroll-button ${pathname == '/balancesheet' && 'active'}`}>
                    <Link className="text-decoration-none" to={`${config.baseurl}balancesheet`}>Balance Sheet</Link>
                </button>


            </div>
            <Button variant="outline-primary" className="scroll-arrow right-arrow h-auto rounded-5" onClick={scrollRightFunc}>
                <FaChevronRight />
            </Button>
        </div>
    );
};

export default ListMenuButton;
