import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Table,
  Dropdown
} from "react-bootstrap";
import DataTable, { createTheme } from "react-data-table-component";
import { Link, useParams } from "react-router-dom";
import config from "../../../../../config";
import Header from "../../../../commanComponents/usernavbar";
import "../../../css/staffdashboard.css";
import "../../../css/finance.css";
import {
  getPaymentList,
  updateEmployeePaymentStatus,
} from "../../../../../Action/propertyOwner.action";
import { toast, Toaster } from "react-hot-toast";
import { useReactToPrint } from 'react-to-print';
import { FaPlus, FaArrowLeftLong } from "react-icons/fa6";



const EmployeePaymentList = () => {
  const { id } = useParams();

  useEffect(() => {
    // Add the class to the body tag
    document.body.classList.add("staffbody");

    // Cleanup function to remove the class when the component is unmounted
    return () => {
      document.body.classList.remove("staffbody");
    };
  }, []);

  createTheme(
    "solarized",
    {
      text: {
        primary: "#000",
        secondary: "#2aa198",
      },
      background: {
        default: "",
      },
      context: {
        background: "#cb4b16",
        text: "#FFFFFF",
      },
      divider: {
        default: "#EBEBEB",
      },
      action: {
        button: "#E46422",
        hover: "rgba(0,0,0,.08)",
        disabled: "rgba(0,0,0,.12)",
      },
    },
    "dark"
  );

  const customStyles = {
    rows: {
      style: {
        minHeight: "70px", // override the row height
      },
    },
    headCells: {
      style: {
        minHeight: "60px",
        paddingLeft: "20px", // override the cell padding for head cells
        paddingRight: "20px",
        background: "#FFF8F4",
      },
    },
    cells: {
      style: {
        paddingLeft: "20px", // override the cell padding for data cells
        paddingRight: "20px",
      },
    },
  };

  const [paymentList, setPaymentList] = useState([]);


  useEffect(() => {
    fetchPaymentList();
  }, []);

  const fetchPaymentList = async () => {
    try {
      let res = await getPaymentList({ employeeId: id });
      if (res.success) {
        setPaymentList(res.data || []);
      }
    } catch (error) { }
  };

  const updateStatus = async (data, status) => {
    try {
      let res = await updateEmployeePaymentStatus({ id: data.id, status: status })
      if (res.success) {
        toast.success(res.msg)
        fetchPaymentList()
      } else {
        toast.error(res.msg)
      }
    } catch (error) {

    }
  }

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "12.6%",
    },
    // {
    //   name: "Employess Name",
    //   selector: (row) => row.firstName + ' ' + row.lastName,
    //   width: "16.6%",
    // },
    {
      name: "Amount",
      selector: (row) => row.amount,
      width: "16.6%",
    },
    {
      name: "Payment Date",
      selector: (row) => {
        const date = new Date(row.paymentDate);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
      },
      width: "16.6%",
    },
    {
      name: "Payment Method",
      selector: (row) => {
        switch (row.paymentMode) {
          case 1:
            return "Cash";
          case 2:
            return "Card";
          case 3:
            return "Bank Transfer";
          default:
            return "Unknown";
        }
      },
      width: "16.6%",
    },

    {
      name: "Status",
      selector: (row) => {
        switch (row.status) {
          case 1:
            return "Approve";
          case 2:
            return "Reject";
          default:
            return "Unknown";
        }
      },
      cell: (row) => {
        let statusText;
        let color;

        switch (row.status) {
          case 1:
            statusText = "Approve";
            color = "green";
            break;
          case 2:
            statusText = "Reject";
            color = "red";
            break;
          default:
            statusText = "Unknown";
            color = "grey"; // Optional, for unknown status
        }

        return (
          <span style={{ color: color }}>
            {statusText}
          </span>
        );
      },
      width: "16.6%",
    },

    {
      name: 'Action',
      width: "18.5%",
      cell: (row) => (
        <div className="d-inline-block">
          {row.status === 1 ? (
            <Button
              variant="danger"
              size="sm"
              className="rounded-5"
              onClick={() => updateStatus(row, '2')}>
              Reject
            </Button>
          ) : (
            <Button
              variant="success"
              size="sm"
              className="rounded-5"
              onClick={() => updateStatus(row, '1')}>
              Approve
            </Button>
          )}
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },




  ];

  return (
    <div className="staffdashboard bg-pink">
      <Header />
      <Toaster />
      <section className="listing pt-5 pb-5 overflow-hidden vh-80">
        <Container>
          <Row>
            <Col lg={5} sm={12} xs={12} className="mb-4">
              <Link to={`${config.baseurl}employee`}>
                <Button variant="default" className="p-0 h-auto">
                  <FaArrowLeftLong size={`25px`} />
                </Button>
              </Link>
            </Col>
          </Row>
          <div className="">
            <Col lg={12} className="mb-3">
              <Card className="border-0 bg-white p-3" >
                <Card.Header className="border-0 mb-0 pb-0">
                  <div className="d-flex align-items-center justify-content-between mb-4">
                    <div className="">
                      <h4 className="mb-0 fw-bolder">Payment List </h4>
                    </div>
                    <div className="text-end">
                      <div>
                        <div className="d-inline-block">


                          <Link to={`${config.baseurl}addpayment/${id}`}>
                            <Button variant={`primary`} className="btn-sm h-auto  rounded-4 ">
                              &nbsp;ADD
                            </Button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card.Header>
                <DataTable
                  columns={columns}
                  data={paymentList}
                  customStyles={customStyles}
                  pagination
                />

              </Card>
            </Col>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default EmployeePaymentList;
