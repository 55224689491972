import React, { useState, useEffect } from "react";
import Header from "../generalPages/directives/header";
import Footer from "../generalPages/directives/footer";
import "./generalCSS/home.css";
import "./generalCSS/generalnavbar.css";
import "./generalCSS/blog.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { getPromiseDetailsActionById } from "../Action/user.action";
import moment from "moment";

import configCore from "../coreFIles/config";
import { Link } from "react-router-dom";
import config from "../config";

const PromiseDetail = () => {
  const { id } = useParams();

  const [blogDetailsByid, setBlogDetailsByid] = useState([]);

  useEffect(() => {
    getMoreSuggestionAPI();
  }, []);

  const getMoreSuggestionAPI = async () => {
    let res = await getPromiseDetailsActionById(id);
    if (res.success) {
      setBlogDetailsByid(res.data);
    }
  };

  return (
    <>
      <Header />
      <section className="bg-light py-4">

        <Container>
          <Button
            style={{ marginBottom: "8px" }}
            variant="default"
            className="border-1 rounded-1 border-secondary h-auto arrow_left"
            href={`${config.baseurl}aboutUs`}
          >
            <img
              src="assets/images/icon/right_arrow.svg"
              width="7px"
              style={{ marginTop: "-3px" }}
            />
          </Button>
          <Row className="align-items-center">
            <Col lg={12} md={12} className=" text-center">
              <div className="">
                <h1 className="text-primary mb-0 py-4">
                  <b>Our Promise To Our Users</b>
                </h1>
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="justify-content-center">
            <Col lg={12} className=" mb-5">
              <Card.Body>
                <h3 className="fw-bold">{blogDetailsByid.title}</h3>
                <br />
                <Card.Text>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: blogDetailsByid.description,
                    }}
                  ></p>{" "}
                </Card.Text>
              </Card.Body>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer />
    </>
  );
};

export default PromiseDetail;
