import React, { useState, useEffect } from "react";
import Header from './directives/header';
import Footer from './directives/footer';
import './generalCSS/home.css'
import './generalCSS/generalnavbar.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { getTermsConditionAction } from "../Action/user.action";
import config from "../coreFIles/config";
import { Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
const TermsCondition = () => {
  const navigate = useNavigate();
  const [termsCondition, setTermsCondition] = useState([])

  useEffect(() => {
    getTermsConditionAPI();
  }, []);

  const getTermsConditionAPI = async () => {
    let res = await getTermsConditionAction();
    if (res.success) {
      setTermsCondition(res.data)
    }
  };
  const handleBack = () => {
    navigate(`${config.baseUrl}`)
  };
  return (
    <>
      
      <section className='destination-area'>
        <Container>
          <Row>
            <Col lg={12} className='mb-5'>
      
              <div className='text-center'>
                <h2 className='text-black'><b>Terms & Conditions</b></h2>
                <div class="section-title-line"> </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} className='mb-3'>
              <div className="mt-4">
                {termsCondition ?
                  <>
                    <div dangerouslySetInnerHTML={{ __html: termsCondition?.description }} />
                  </>
                  : 'No data found'}
              </div>
            </Col>
          </Row>
        </Container>

      </section >

   
    </>
  )
}

export default TermsCondition
