
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, Button, Form, Dropdown, Modal } from "react-bootstrap";
import DataTable, { createTheme } from "react-data-table-component";
import { FaStar } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import config from "../../../../config";
import config1 from "../../../../coreFIles/config";
import Header from "../../../commanComponents/usernavbar";
import { RiDeleteBin6Line } from "react-icons/ri";
import "../../css/createcheque.css";
import { toast, Toaster } from "react-hot-toast";
import { getActiveSupplierList, updatebill, getChequeCategoryList, getBillSerialNo, getProductAndServices, getBankAccountList, getBillDetailById } from "../../../../Action/propertyOwner.action";
import { FaPlus, FaArrowLeftLong } from "react-icons/fa6";


const EditBill = () => {
  const { id } = useParams()

  createTheme('solarized', {
    text: {
      primary: '#000',
      secondary: '#2aa198',
    },
    background: {
      default: '',
    },
    context: {
      background: '#cb4b16',
      text: '#FFFFFF',
    },
    divider: {
      default: '#EBEBEB',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  }, 'dark');

  const customStyles = {
    rows: {
      style: {
        minHeight: '70px', // override the row height

      },
    },
    headCells: {

      style: {
        minHeight: '60px',
        paddingLeft: '20px', // override the cell padding for head cells
        paddingRight: '20px',
        background: '#FFF8F4',

      },
    },
    cells: {
      style: {
        paddingLeft: '20px', // override the cell padding for data cells
        paddingRight: '20px',
      },
    },
  };

  const navigate = useNavigate();


  const columns = [
    {
      name: '#',
      selector: (row, index) => index + 1, // Serial number based on the index
      sortable: false,
      width: '10%',
    },
    {
      name: 'Category',
      selector: row => row.categoryName,
      width: "25%"
    },
    {
      name: 'Description',
      selector: row => row.description,
      width: "25%"
    },
    {
      name: 'Amount',
      selector: row => '$' + row.amount,
      width: "30%"
    },
    {
      name: 'Delete',
      selector: row => row.status,
      cell: (row, index) => (
        <Button
          variant="default"
          className="p-0 btn-sm text-primary"
          onClick={() => handleDeleteCategory(index)} // Pass the index to the delete handler
        >
          <RiDeleteBin6Line size={`15px`} />
        </Button>
      ),
    },
  ];

  const columns2 = [
    {
      name: '#',
      selector: (row, index) => index + 1,
      sortable: false,
      width: '10%',
    },
    {
      name: 'Product Service',
      selector: row => row.productService,
      width: "25%"
    },
    {
      name: 'Quantity',
      selector: row => row.quantity,
      width: "25%"
    },
    {
      name: 'Rate',
      selector: row => row.rate,
      width: "15%"
    },
    {
      name: 'Amount',
      selector: row => '$' + row.amount,
      width: "15%"
    },
    {
      name: 'Delete',
      selector: row => row.status,
      cell: (row, index) => (
        <Button
          variant="default"
          className="p-0 btn-sm text-primary"
          onClick={() => handleDeleteItem(index)} // Pass the index to the delete handler
        >
          <RiDeleteBin6Line size={`15px`} />
        </Button>
      ),
    },
  ];


  useEffect(() => {
    // Add the class to the body tag
    document.body.classList.add("staffbody");

    // Cleanup function to remove the class when the component is unmounted
    return () => {
      document.body.classList.remove("staffbody");
    };
  }, []);

  const navigateRoute = () => {
    setTimeout(() => {
      navigate(`${config.baseurl}${config.loginurl}`, { state: "prop" });
      window.location.reload();
    });
  };

  const [supplierList, setSupplierList] = useState([]);
  const [accountList, setAccountList] = useState([])
  const [formData, setFormData] = useState({
    supplierId: '',
    terms: '',
    billDate: '',
    mailingAddress: '',
    dueDate: '',
    tag: '',
    billNo: '',
    categoryMemo: '',
    itemMemo: '',
    categoryArr: [],
    itemArr: [],
    type: ''
  });
  const [errors, setErrors] = useState({});
  const [fileName, setFileName] = useState("");
  const [categoryError, setCategoryError] = useState({});
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [category, setCategory] = useState({
    categoryName: '',
    description: "",
    amount: ""
  });

  const [itemError, setItemError] = useState({});
  const [showItemModal, setShowItemModal] = useState(false);
  const [item, setItem] = useState({
    productService: '',
    quantity: "",
    rate: "",
    amount: ""
  });
  const [chequeCategoryList, setChequeCategoryList] = useState([]);
  const [selectedSupplierEmail, setSelectedSupplierEmail] = useState('');
  const [serialNo, setSerialNo] = useState({})
  const [productService, setProductService] = useState([])
  const [bankAccountList, setBankAccountList] = useState([])

  useEffect(() => {
    fetchSupplierList()
    fetchChequeCategoryList()
    fetchSerial()
    fetchProductAndServices()
    fetchAccountList()
    fetchBillDetails()
  }, [])

  const fetchSerial = async () => {
    let res = await getBillSerialNo()
    if (res.success) {
      if (res.data.length != []) {
        setSerialNo(res.data[0])
      } else {
        setSerialNo({ id: 0 })
      }
    }
  }

  const fetchProductAndServices = async () => {
    let res = await getProductAndServices({ type: 1 });
    if (res.success) {
      setProductService(res.data || []);
    }
  }

  const fetchAccountList = async () => {
    let res = await getBankAccountList();
    if (res.success) {
      setBankAccountList(res.data || []);
    }
  };

  const fetchChequeCategoryList = async () => {
    try {
      let res = await getChequeCategoryList()
      if (res.success) {
        setChequeCategoryList(res.data || [])
      }
    } catch (error) {

    }
  }

  const fetchSupplierList = async () => {
    try {
      let res = await getActiveSupplierList({ type: 1 })
      if (res.success) {
        setSupplierList(res.data || [])
      }
    } catch (error) {

    }
  }


  const fetchBillDetails = async () => {
    try {
      let res = await getBillDetailById({ id: id })
      if (res.success) {
        setFormData(res.data[0] || [])
        setSelectedSupplierEmail(res.data[0].mailingAddress)
      }
    } catch (error) {

    }
  }



  const validateForm = () => {
    const newErrors = {};
    if (!formData?.supplierId) newErrors.supplierId = "Select supplier name.";
    // if (!formData?.terms) newErrors.terms = "Terms is required.";
    if (!formData?.billDate) newErrors.billDate = "Bill date is required.";
    if (!formData?.dueDate) newErrors.dueDate = "Payment date is required.";
    // if (!formData?.mailingAddress) newErrors.mailingAddress = "Mailing address is required.";
    // if (!formData?.tag) newErrors.tag = "Tag is required.";
    // if (!formData?.billNo) newErrors.billNo = "Cheque number is required.";
    // if (formData.categoryArr.length === 0) newErrors.categoryArr = "At least one category is required";
    // if (formData.itemArr.length === 0) newErrors.itemArr = "At least one item is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    try {
      const res = await updatebill({
        ...formData,
        type: 1,
        categoryArr: JSON.stringify(formData.categoryArr),
        itemArr: JSON.stringify(formData.itemArr),
        categoryAmountTotal: calculateCategoryAmount().toFixed(2),
        itemAmountTotal: calculateItemAmount().toFixed(2),
        mailingAddress: selectedSupplierEmail,
        billNo: formData.billNo
      });

      if (res.success) {
        toast.success(res.msg);
        setTimeout(() => {
          navigate(`/billlist`);
        }, 2000);
      } else {
        toast.error(res.msg);
      }
    } catch (error) {
      toast.error('Something went wrong please try again after some time.');
    }
  };


  const inputHandler = (e) => {
    const { name, value } = e.target;
    setFormData((old) => {
      return { ...old, [name]: value };
    });
    if (value.trim() !== "") {
      setErrors((oldErrors) => ({ ...oldErrors, [name]: "" }));
    }

    const selectedSupplier = supplierList.find(item => item.id === parseInt(e.target.value));
    if (selectedSupplier) {
      let fullAddress = selectedSupplier.streetAddress + ', ' + selectedSupplier.city + ', ' + selectedSupplier.postalCode + ', ' + selectedSupplier.country
      setSelectedSupplierEmail(fullAddress);
    }
  };


  //--------------|| CATEGORY MODAL FUNCTION ||--------------------


  const handleCategoryChange = (e) => {
    const { name, value } = e.target;
    setCategory((prevProduct) => ({
      ...prevProduct,
      [name]: value,
    }));
  };

  const validateCategory = () => {
    let categoryError = {};
    if (!category.categoryName) categoryError.categoryName = "Category is required";
    if (!category.description) categoryError.description = "Description is required";
    if (!category.amount) categoryError.amount = "Amount is required";
    return categoryError;
  };

  const handleDeleteCategory = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      categoryArr: prevData.categoryArr.filter((_, i) => i !== index),
    }));
  };

  const handleAddProduct = () => {

    const productValidationErrors = validateCategory();
    if (Object.keys(productValidationErrors).length === 0) {
      setFormData((prevData) => ({
        ...prevData,
        categoryArr: [...(prevData.categoryArr || []), category],
      }));
      setCategory({
        categoryName: '',
        description: '',
        amount: ''
      });
      setShowCategoryModal(false);
      setCategoryError({});
    } else {
      setCategoryError(productValidationErrors);
    }
  };

  const calculateCategoryAmount = () => {
    return formData.categoryArr.reduce((total, category) => {
      return total + parseFloat(category.amount || 0);
    }, 0);
  };

  //--------------|| ITEM MODAL FUNCTION ||--------------------

  const handleItemChange = (e) => {
    const { name, value } = e.target;
    setItem((prevItem) => ({
      ...prevItem,
      [name]: value,
      amount: name === 'quantity' || name === 'rate'
        ? (name === 'quantity' ? value : prevItem.quantity) * (name === 'rate' ? value : prevItem.rate)
        : prevItem.amount,
    }));
  };


  const validateItem = () => {
    let itemError = {};
    if (!item.productService) itemError.productService = "Product service is required";
    if (!item.quantity) itemError.quantity = "Quantity is required";
    if (!item.rate) itemError.rate = "Rate is required";
    if (!item.description) itemError.description = "Description is required";
    // if (!item.amount) itemError.amount = "Amount is required";
    return itemError;
  };

  const handleDeleteItem = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      itemArr: prevData.itemArr.filter((_, i) => i !== index),
    }));
  };

  const handleAddItemDetails = () => {
    const productValidationErrors = validateItem();
    if (Object.keys(productValidationErrors).length === 0) {
      setFormData((prevData) => ({
        ...prevData,
        itemArr: [...(prevData.itemArr || []), item],
      }));
      setItem({
        productService: '',
        quantity: '',
        rate: '',
        amount: ''
      });
      setShowItemModal(false);
      setItemError({});
    } else {
      setItemError(productValidationErrors);
    }
  };

  const calculateItemAmount = () => {
    return formData.itemArr.reduce((total, item) => {
      return total + parseFloat(item.amount || 0);
    }, 0);
  };

  return (
    <div className="staffdashboard bg-pink">
      <Header />
      <Toaster />
      <section className="listing pt-5 pb-5 overflow-hidden vh-80">
        <Container>

          <Row>
            <Col lg={5} sm={12} xs={12} className="mb-4">
              <Link to={`${config.baseurl}billlist`}>
                <Button variant="default" className="p-0 h-auto">
                  <FaArrowLeftLong size={`25px`} />
                </Button>
              </Link>
            </Col>
            <Col lg={7} sm={12} xs={12} className="text-right"></Col>
          </Row>

          <div className="">
            <Col lg={12} className="mb-3">
              <Card className="border-0 bg-white p-3">
                <Card.Header className="mb-3 pb-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="mb-2"><h4 className="mb-0 fw-bolder">Update Bill</h4></div>
                    <div className="mb-2">
                      <h4 className="mb-0 fw-bolder">
                        ${(calculateItemAmount() + calculateCategoryAmount()).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                      </h4>
                    </div>


                  </div>

                </Card.Header>
                <Card.Body className="p-0">
                  <div className="">
                    <Row>
                      <Col xl={12} lg={12} className="">
                        <Row className="mt-2">
                          <Col lg={6} className="mb-4">
                            <Form.Group controlId="formBasicExpense">
                              <Form.Label className="text-light-grey mb-1">Supplier</Form.Label>
                              {formData?.supplierId ? (
                                <Form.Control
                                  type="text"
                                  value={supplierList.find(item => item.id === formData.supplierId)?.supplierName || ''}
                                  readOnly
                                  className="bg-light" // Optional: adds a visual cue for read-only mode
                                />
                              ) : (
                                <Form.Control
                                  as="select"
                                  name="supplierId"
                                  value={formData?.supplierId}
                                  onChange={inputHandler}
                                >
                                  <option value="">Select Supplier</option>
                                  {supplierList.map(item => (
                                    <option key={item.id} value={item.id}>
                                      {item.supplierName}
                                    </option>
                                  ))}
                                </Form.Control>
                              )}
                              {errors.supplierId && <small className="text-danger">{errors.supplierId}</small>}
                            </Form.Group>
                          </Col>

                          <Col lg={6} className="mb-4">
                            <Form.Group className="" controlId="formBasicEmail">
                              <Form.Label className="text-light-grey mb-1">Terms </Form.Label>
                              <Form.Select aria-label="Default select example" onChange={inputHandler} name="terms" value={formData.terms || ''} >
                                <option value="0">Select Terms</option>
                                <option value="15">15</option>
                                <option value="30">30</option>
                                <option value="60">60</option>
                              </Form.Select>
                              {/* {errors.terms && <small className="text-danger">{errors.terms}</small>} */}
                            </Form.Group>
                          </Col>

                          <Col lg={6} className="mb-4">
                            <Form.Group controlId="formMailingAddress">
                              <Form.Label className="text-light-grey mb-1">Mailing Address</Form.Label>
                              <Form.Control
                                type="text"
                                name="mailingAddress"
                                value={selectedSupplierEmail}
                                readOnly
                              />
                              {/* {errors.mailingAddress && <small className="text-danger">{errors.mailingAddress}</small>} */}

                            </Form.Group>
                          </Col>

                          <Col lg={6} className="mb-4">
                            <Form.Group controlId="formPaymentDate">
                              <Form.Label className="text-light-grey mb-1">Bill Date</Form.Label>
                              <Form.Control
                                type="date"
                                name="billDate"
                                value={formData?.billDate}
                                onChange={inputHandler}
                              />
                              {errors.billDate && <small className="text-danger">{errors.billDate}</small>}

                            </Form.Group>
                          </Col>

                          <Col lg={6} className="mb-4">
                            <Form.Group controlId="formPaymentDate">
                              <Form.Label className="text-light-grey mb-1">Due Date</Form.Label>
                              <Form.Control
                                type="date"
                                name="dueDate"
                                value={formData?.dueDate}
                                onChange={inputHandler}
                              />
                              {errors.dueDate && <small className="text-danger">{errors.dueDate}</small>}

                            </Form.Group>
                          </Col>


                          <Col lg={6} className="mb-4">
                            <Form.Group controlId="formTag">
                              <Form.Label className="text-light-grey mb-1">Tags</Form.Label>
                              <Form.Control
                                type="text"
                                name="tag"
                                value={formData?.tag}
                                onChange={inputHandler}
                              />
                              {/* {errors.tag && <small className="text-danger">{errors.tag}</small>} */}

                            </Form.Group>
                          </Col>

                          <Col lg={6} className="mb-4">
                            <Form.Group controlId="formChequeNo">
                              <Form.Label className="text-light-grey mb-1">Bill Number</Form.Label>
                              <Form.Control
                                type="text"
                                name="billNo"
                                value={formData.billNo}
                                readOnly
                              />
                            </Form.Group>
                          </Col>

                        </Row>
                      </Col>
                      <Col lg={12}>
                        <hr className="mb-4 mt-0" style={{ color: "#EBEBEB", opacity: "1" }} />
                      </Col>
                      <Col xl={12} lg={12} className="">
                        <div className="staff_upcoming_booking">
                          <div className="border-0">
                            <div className="p-0">
                              <div className=" mb-3">
                                <div className=""><h4 className="mb-0 fw-bolder">Category Details </h4></div>
                              </div>
                              <div>
                                <Row className="align-items-center">
                                  <Col lg={12} xs={12}>
                                    <div className="pt-2">
                                      <DataTable
                                        columns={columns}
                                        data={formData.categoryArr}
                                        customStyles={customStyles}
                                        theme="solarized"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                              <div className="mt-3">
                                <Link to={`#`}>
                                  <Button
                                    variant="outline-primary"
                                    className=" rounded-5 me-2 px-4 px-xs-1 mb-4"
                                    onClick={() => setShowCategoryModal(true)}
                                  >
                                    Add lines
                                  </Button>
                                </Link>
                                <Link to={`#`}>
                                  <Button
                                    variant="primary"
                                    className=" rounded-5 me-2 px-4 px-xs-1 mb-4"
                                    onClick={() => setFormData({
                                      ...formData,
                                      categoryArr: []
                                    })}
                                  >
                                    Clear All Lines
                                  </Button>
                                </Link>

                              </div>
                              {/* {errors.categoryArr && <small className="text-danger">{errors.categoryArr}</small>} */}

                            </div>
                            <Row className="align-items-center">
                              <Col lg={6} className="mb-4">
                                <Col lg={6} className="mb-4">

                                  <Form.Group>
                                    <Form.Label className="text-light-grey mb-1">Memo</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder=""
                                      name="categoryMemo"
                                      value={formData?.categoryMemo}
                                      onChange={inputHandler}
                                    />
                                  </Form.Group>

                                </Col>

                              </Col>
                              <Col xl={4} lg={4} md={8} className="mb-4">
                              </Col>
                              <Col xl={2} lg={2} md={4} className="mb-4">
                                <div className="d-flex justify-content-end justify-content-between align-items-center">
                                  <div><h6 className="mb-0">Total</h6></div>
                                  <h5 className="mb-0">${calculateCategoryAmount().toFixed(2)}</h5>
                                </div>
                              </Col>
                            </Row>


                          </div>

                        </div>
                      </Col>
                      <Col lg={12}>
                        <hr className="mb-4 mt-0" style={{ color: "#EBEBEB", opacity: "1" }} />
                      </Col>
                      <Col xl={12} lg={12} >
                        <div className="staff_upcoming_booking">
                          <div className="border-0">
                            <div className="p-0">
                              <div className=" mb-3">
                                <div className=""><h4 className="mb-0 fw-bolder">Item Details </h4></div>

                              </div>
                              <div>


                                <Row className="align-items-center">
                                  <Col lg={12} xs={12}>
                                    <div className="pt-2">
                                      <DataTable
                                        columns={columns2}
                                        data={formData.itemArr}
                                        customStyles={customStyles}
                                        theme="solarized"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                              <div className="mt-3">
                                <Link to={`#`}>
                                  <Button
                                    variant="outline-primary"
                                    className=" rounded-5 me-2 px-4 px-xs-1 mb-4"
                                    onClick={() => setShowItemModal(true)}

                                  >
                                    Add lines
                                  </Button>
                                </Link>
                                <Link to={`#`}>
                                  <Button
                                    variant="primary"
                                    className=" rounded-5 me-2 px-4 px-xs-1 mb-4"
                                    onClick={() => setFormData({
                                      ...formData,
                                      itemArr: []
                                    })}
                                  >
                                    Clear All Lines
                                  </Button>
                                </Link>

                              </div>
                              {/* {errors.itemArr && <small className="text-danger">{errors.itemArr}</small>} */}

                            </div>
                          </div>

                        </div>
                      </Col>
                      <Col xl={12}>
                        <div className="">
                          <Row className="align-items-center">
                            <Col lg={6} className="mb-4">
                              <Col lg={6} className="mb-4">
                                <Form.Group>
                                  <Form.Label className="text-light-grey mb-1">Memo</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    name="itemMemo"
                                    value={formData?.itemMemo}
                                    onChange={inputHandler}
                                  />
                                </Form.Group>
                              </Col>

                            </Col>
                            <Col xl={4} lg={4} md={8} className="mb-4">
                            </Col>
                            <Col xl={2} lg={2} md={4} className="mb-4">
                              <div className="d-flex justify-content-end justify-content-between align-items-center">
                                <div><h6 className="mb-0">Total</h6></div>
                                <h5 className="mb-0">${calculateItemAmount().toFixed(2)}</h5>
                              </div>
                            </Col>
                          </Row>
                          {/* ----------Balance sheet----------- */}
                          <div className="tableBody p-0" >
                            <Row className="justify-content-center">
                              <Col xl={4} lg={4} md={6}>
                                {/* ----------Image upload start-------------- */}
                                <div className="text-center py-sm-5 mx-auto">

                                  <div className="mt-5">
                                    <Link to={`${config.baseurl}billlist`}>
                                      <Button
                                        variant="outline-primary"
                                        className=" rounded-5 me-2 px-4 px-xs-1 mb-4 mobileBtn"
                                      >
                                        Cancel
                                      </Button>
                                    </Link>
                                    <Link to={`#`}>
                                      <Button
                                        variant="primary"
                                        className=" rounded-5 me-2 px-4 px-xs-1 mb-4 mobileBtn"
                                        onClick={handleSubmit}
                                      >
                                        Save & Close
                                      </Button>
                                    </Link>
                                  </div>
                                </div>
                                {/* ----------Image upload end-------------- */}
                              </Col>
                            </Row>



                          </div>

                        </div>
                      </Col>
                    </Row>
                  </div>


                </Card.Body>
              </Card>
            </Col>
          </div>
        </Container>
      </section>
      <Modal show={showCategoryModal} onHide={() => setShowCategoryModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Category Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>

            <Form.Group controlId="formBasicExpense" className="mb-3">
              <Form.Label>Account</Form.Label>
              <Form.Control
                as="select"
                name="categoryName"
                className="rounded-5"
                value={category.categoryName}
                onChange={handleCategoryChange}
              >
                <option value="">Select Account</option>
                {bankAccountList.map(item => (
                  <option key={item.id} value={item.accountName}>
                    {item.accountName}
                  </option>
                ))}
              </Form.Control>
              {categoryError.categoryName && <small className="text-danger">{categoryError.categoryName}</small>}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                name="description"
                value={category.description}
                onChange={handleCategoryChange}
                className="rounded-5"
              />
              {categoryError.description && (
                <p className="text-danger">{categoryError.description}</p>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="number"
                name="amount"
                value={category.amount}
                onChange={handleCategoryChange}
                className="rounded-5"
              />
              {categoryError.amount && (
                <p className="text-danger">{categoryError.amount}</p>
              )}
            </Form.Group>
            <Button
              variant="outline-primary"
              onClick={() => { setShowCategoryModal(false) }}
              className="rounded-5"
            >
              Cancel
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button
              variant="primary"
              onClick={handleAddProduct}
              className="rounded-5"
            >
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Item Modal */}

      <Modal show={showItemModal} onHide={() => setShowItemModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Item Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>

            <Form.Group controlId="formBasicExpense" className="mb-3">
              <Form.Label className="">Product/Service</Form.Label>
              <Form.Control
                as="select"
                name="productService"
                value={item.productService}
                onChange={handleItemChange}
                className="rounded-5"
              >
                <option value="">Select</option>
                {productService.map(item => (
                  <option key={item.id} value={item.name}>
                    {item.name}
                  </option>
                ))}
                {categoryError.categoryName && <small className="text-danger">{categoryError.categoryName}</small>}
              </Form.Control>
              {itemError.productService && (
                <p className="text-danger">{itemError.productService}</p>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                name="description"
                value={item.description}
                onChange={handleItemChange}
                className="rounded-5"
              />
              {itemError.description && (
                <p className="text-danger">{itemError.description}</p>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Quantity</Form.Label>
              <Form.Control
                type="number"
                name="quantity"
                value={item.quantity}
                onChange={handleItemChange}
                className="rounded-5"
              />
              {itemError.quantity && (
                <p className="text-danger">{itemError.quantity}</p>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Rate</Form.Label>
              <Form.Control
                type="number"
                name="rate"
                value={item.rate}
                onChange={handleItemChange}
                className="rounded-5"
              />
              {itemError.rate && (
                <p className="text-danger">{itemError.rate}</p>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="number"
                name="amount"
                value={item.quantity * item.rate || 0}
                readOnly
                className="rounded-5"
              />
            </Form.Group>

            <Button
              variant="outline-primary"
              onClick={() => { setShowItemModal(false) }}
              className="rounded-5"
            >
              Cancel
            </Button>
            &nbsp; &nbsp; &nbsp;
            <Button
              variant="primary"
              onClick={handleAddItemDetails}
              className="rounded-5"
            >
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EditBill;
