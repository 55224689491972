import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Button, Form, Modal, Dropdown } from "react-bootstrap";
import DataTable from 'react-data-table-component';
import { toast, Toaster } from "react-hot-toast";
import { getBankAccountList, addBankAccountDetails, updateBankAccountDetails, updateBankAccountStatus, getAccountTypeList, getBankAccountListById } from "../../../../Action/propertyOwner.action";
import ListMenuButton from "./listMenu";
import UserNameAndTab from "./UserNameAndTab";
import { GoPencil } from "react-icons/go";


const customStyles = {
    rows: {
        style: {
            minHeight: '70px',
        },
    },
    headCells: {
        style: {
            minHeight: '60px',
            paddingLeft: '20px',
            paddingRight: '20px',
            background: '#FFF8F4',
        },
    },
    cells: {
        style: {
            paddingLeft: '20px',
            paddingRight: '20px',
        },
    },
};

const CreateAccount = () => {

    useEffect(() => {
        document.body.classList.add("staffbody");
        return () => {
            document.body.classList.remove("staffbody");
        };
    }, []);

    const [accountList, setAccountList] = useState([]);
    const [isModelOpen, setIsModelOpen] = useState(false);
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [accountTypeList, setAccountTypeList] = useState([]);
    const [accountName, setAccountName] = useState([]);
    const [accountDetailsArr, setAccountDetailsArr] = useState([]);

    useEffect(() => {
        fetchAccountList();
        fetchAccountTypeList();
    }, []);

    const fetchAccountList = async () => {
        let res = await getBankAccountList();
        if (res.success) {
            setAccountList(res.data || []);
        }
    };

    const fetchAccountTypeList = async () => {
        let res = await getAccountTypeList();
        if (res.success) {
            setAccountTypeList(res.data || []);
        }
    };

    const fetchBankAccount = async () => {
        try {
            let res = await getBankAccountListById({ accountType: formData.accountType });
            if (res.success) {
                setAccountName(res.data || []);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const inputHandler = (e) => {
        const { name, value, type, checked } = e.target;
        const inputValue = type === "checkbox" ? (checked ? 1 : 0) : value;
        setFormData((old) => ({ ...old, [name]: inputValue }));
        if (typeof inputValue === "string" && inputValue.trim() !== "") {
            setErrors((oldErrors) => ({ ...oldErrors, [name]: "" }));
        }

        if(name == 'accountType'){
            let accountDetail = accountList.filter(data => data.accountType == value);
            setAccountDetailsArr(accountDetail)
        }
    };

    const validateForm = () => {
        let formErrors = {};
        if (!formData.accountName) formErrors.accountName = "Account Name is required.";
        if (!formData.accountType) formErrors.accountType = "Please select an Account Type.";
       // if (!formData.detailType) formErrors.detailType = "Please select an Detail Type.";
    
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const updateStatus = async (data, status) => {
        try {
            let res = await updateBankAccountStatus({ id: data.id, status: status })
            if (res.success) {
                toast.success(res.msg)
                fetchAccountList()
            } else {
                toast.error(res.msg)
            }
        } catch (error) {

        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        try {
            setIsLoading(true);
            let data = {
                ...formData,
                subAccount: formData.subAccount ? formData.subAccount : 0,
            };

            let res;
            if (isEdit) {
                res = await updateBankAccountDetails(data);
            } else {
                res = await addBankAccountDetails(data);
            }

            if (res.success) {
                toast.success(res.msg);
                fetchAccountList();
                modelClose();
            } else {
                toast.error(res.msg);
            }

            setIsLoading(false);
        } catch (error) {
            toast.error('Something went wrong. Please try again later.');
            setIsLoading(false);
        }
    };

    const handleEdit = (accountDetails) => {
        setFormData(accountDetails);
        setIsEdit(true);
        setIsModelOpen(true);
    };

    const modelClose = () => {
        setFormData({
            accountName: '',
            accountType: '',
            detailType: '',
            description: '',
            openingBalance: '',
            asOf: '',
            subAccount: 0,
        });
        setIsEdit(false);
        setIsModelOpen(false);
    };

    const columns = [
        {
            name: "#",
            selector: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: 'Account Name',
            selector: row => row.accountName,
        },
        {
            name: 'Account Type',
            selector: row => row.name,
        },
        {
            name: 'Opening Balance',
            selector: row => `$${row.openingBalance? row.openingBalance : 0}`,
        },
        
        {
            name: 'Status',
            selector: row => (
              <span style={{ color: row.status === 0 ? 'green' : 'red' }}>
                {row.status === 0 ? 'Active' : 'Inactive'}
              </span>
            ),
          },
          
        {
			name: 'Action',
			width: "18.5%",
			selector: row => (
				<>
					{/* <Button variant={`primary`}
                        className="btn-sm h-auto  rounded-5 " onClick={(e) => handleEdit(row)}>
                        <GoPencil />
                        &nbsp;Edit
                    </Button>
					&nbsp;
					<Button variant={`primary`}
						className="btn-sm h-auto  rounded-5 " onClick={() => deleteExpense(row)}>
						&nbsp;Delete
					</Button>
					&nbsp; */}

					<div className="d-inline-block">
						<Dropdown >
							<Dropdown.Toggle variant="outline-primary" id="dropdown-basic" className="btn-sm h-auto rounded-5 box-shadow-none"></Dropdown.Toggle>

							<Dropdown.Menu>
								<Dropdown.Item onClick={(e) => updateStatus(row, row.status === 0 ? 1 : 0)}>{row.status === 0 ? 'Inactive' : 'Active'}</Dropdown.Item>

                                <Dropdown.Item onClick={(e) => handleEdit(row)}>Edit</Dropdown.Item>

							</Dropdown.Menu>
						</Dropdown>

					</div>
				</>
			),
			ignoreRowClick: true,
			allowOverflow: true,
			button: true,
		},
    ];

    return (
        <div className="staffdashboard bg-pink">
            <Toaster />
            <section className="listing pt-5 pb-5 overflow-hidden vh-80">
                <Container>
                    <UserNameAndTab />
                    <div className="listMenuButton">
                        <Col xl={12} lg={12} className="mb-3">
                            <Card className="border-0 tablistfinance">
                                <Card.Body>
                                    <ListMenuButton />
                                </Card.Body>
                            </Card>
                        </Col>
                    </div>
                    <Card className="border-0 bg-white p-3">
                        <div className="d-flex align-items-center justify-content-between">
                            <h4 className="fw-bolder mb-4">Chart of Account</h4>
                            <Button className="rounded-5 px-4 px-xs-1 mb-4 mobileBtn" onClick={() => {
                                setFormData({});
                                setIsEdit(false);
                                setIsModelOpen(true);
                            }}> Add New Account </Button>
                        </div>
                        <Card.Body>
                            <div className="chartofaccount">
                                {accountList.length > 0 ?
                                    <Row>
                                        <Col lg={12}>
                                            <DataTable
                                                columns={columns}
                                                data={accountList}
                                                customStyles={customStyles}
                                                pagination
                                            />
                                        </Col>
                                    </Row>
                                    :
                                    <div className="text-center">
                                        <img src="images/nodatafound.svg" alt="No data found" />
                                        <h5 className="mb-1">Account Report</h5>
                                        <div>This report doesn’t have any data</div>
                                    </div>
                                }
                            </div>
                        </Card.Body>
                    </Card>

                    <Modal show={isModelOpen} onHide={modelClose} dialogClassName="modal-90w modal-xl">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <b>{isEdit ? "Edit Account" : "Add Account"}</b>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form onSubmit={handleSubmit}>
                                <Row className="mt-2">
                                    <Col lg={6} className="mb-4">
                                        <Form.Group>
                                            <Form.Label className="text-light-grey">Account Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="accountName"
                                                value={formData.accountName}
                                                onChange={inputHandler}
                                                isInvalid={!!errors.accountName}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.accountName}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={6} className="mb-4">
                                        <Form.Group controlId="formBasicExpense" className="mb-3">
                                            <Form.Label className="text-light-grey mb-1">Account Type</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="accountType"
                                                value={formData?.accountType}
                                                onChange={inputHandler}
                                                isInvalid={!!errors.accountType}
                                                onClick={fetchBankAccount}
                                            >
                                                <option value="">Select Account Type</option>
                                                {accountTypeList.map(item => (
                                                    <option key={item.id} value={item.id}>
                                                        {item.name}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">{errors.accountType}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={6} className="mb-4">
                                        <Form.Group controlId="formBasicExpense" className="mb-3">
                                            <Form.Label className="text-light-grey mb-1">Detail Type</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="detailType"
                                                value={formData?.detailType}
                                                onChange={inputHandler}
                                                isInvalid={!!errors.detailType}
                                            >
                                                <option value="">Select Detail Type</option>
                                                {accountDetailsArr.map(item => (
                                                    <option key={item.id} value={item.id}>
                                                        {item.accountName}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">{errors.detailType}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={6} className="mb-4">
                                        <Form.Group>
                                            <Form.Label className="text-light-grey">Description</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="description"
                                                value={formData.description}
                                                onChange={inputHandler}
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col lg={6} className="mb-4">
                                        <Form.Group>
                                            <Form.Label className="text-light-grey">Opening Balance</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="openingBalance"
                                                value={formData.openingBalance}
                                                onChange={inputHandler}
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col lg={6} className="mb-4">
                                        <Form.Group>
                                            <Form.Label className="text-light-grey">As Of</Form.Label>
                                            <Form.Control
                                                type="date"
                                                name="asOf"
                                                value={formData.asOf}
                                                onChange={inputHandler}
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col lg={6} className="mb-4">
                                        <Form.Group>
                                            <Form.Check
                                                type="checkbox"
                                                id="subAccount"
                                                name="subAccount"
                                                label="Make this as sub account"
                                                checked={formData.subAccount}
                                                onChange={(e) => inputHandler({ target: { name: 'subAccount', value: e.target.checked ? 1 : 0 } })}
                                                isInvalid={!!errors.subAccount}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Col lg={12} className="text-center">
                                    <div>
                                        {isLoading ?
                                            <button className="rounded-5 me-2 px-4 px-xs-1 mb-3 btn btn-primary" disabled>
                                                Processing...
                                            </button>
                                            :
                                            <button className="rounded-5 me-2 px-4 px-xs-1 mb-3 btn btn-primary" onClick={handleSubmit}>
                                                {isEdit ? 'Update' : 'Save'}
                                            </button>
                                        }
                                        <button className="rounded-5 px-4 px-xs-1 mb-3 btn btn-outline-secondary" onClick={modelClose}>
                                            Cancel
                                        </button>
                                    </div>
                                </Col>


                            </Form>
                        </Modal.Body>
                    </Modal>
                </Container>
            </section>
        </div>
    );
};

export default CreateAccount;
