import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Table,
} from "react-bootstrap";
import { FaArrowLeftLong } from "react-icons/fa6";
import Header from "../../../commanComponents/usernavbar";
import { getInvoiceDetailById } from "../../../../Action/propertyOwner.action";
import "../../css/staffdashboard.css";
import "../../css/finance.css";
import { toast, Toaster } from "react-hot-toast";
import config from "../../../../config";
import { saveAs } from 'file-saver';
import { useReactToPrint } from 'react-to-print';

const VehicleInvoiceDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [invoiceData, setInvoiceData] = useState(null);
  const printComponentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
  });

  useEffect(() => {
    document.body.classList.add("staffbody");
    return () => {
      document.body.classList.remove("staffbody");
    };
  }, []);

  useEffect(() => {
    const fetchInvoiceDetails = async () => {
      try {
        const res = await getInvoiceDetailById({ id: id });
        if (res.success) {
          const parsedProductArr = res.data[0].productArr;
          setInvoiceData({ ...res.data[0], productArr: parsedProductArr });
        } else {
          toast.error(res.msg);
        }
      } catch (error) {
        console.error("Error fetching invoice details:", error);
        toast.error("Failed to fetch invoice details.");
      }
    };

    fetchInvoiceDetails();
  }, [id, navigate]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const calculateTotalAmount = () => {
    return invoiceData.productArr.reduce((total, item) => {
      return total + parseFloat(item.Amount);
    }, 0).toFixed(2); // Two decimal places for currency
  };

  return (
    <div className="staffdashboard bg-pink">
      <Header />
      <Toaster />

      <section className="listing pt-5 pb-5 overflow-hidden vh-80">
        <Container>
          <Row>
            <Col lg={5} sm={12} xs={12} className="mb-4">
              <Link to={`${config.baseurl}invoicevehiclelist`}>
                <Button variant="default" className="p-0 h-auto">
                  <FaArrowLeftLong size={`25px`} />
                </Button>
              </Link>
            </Col>
            <Col lg={7} sm={12} xs={12} className="text-right">
              <Button
                variant="default"
                className="me-4 p-0 h-auto"
                onClick={handlePrint}

              >

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M17.0703 5.03906H16.5234V2.92969C16.5234 1.31426 15.2092 0 13.5938 0H6.40625C4.79082 0 3.47656 1.31426 3.47656 2.92969V5.03906H2.92969C1.31426 5.03906 0 6.35332 0 7.96875V12.6562C0 14.2717 1.31426 15.5859 2.92969 15.5859H3.47656V18.2422C3.47656 19.2114 4.26512 20 5.23438 20H14.7656C15.7349 20 16.5234 19.2114 16.5234 18.2422V15.5859H17.0703C18.6857 15.5859 20 14.2717 20 12.6562V7.96875C20 6.35332 18.6857 5.03906 17.0703 5.03906ZM4.64844 2.92969C4.64844 1.96043 5.43699 1.17188 6.40625 1.17188H13.5938C14.563 1.17188 15.3516 1.96043 15.3516 2.92969V5.03906H4.64844V2.92969ZM15.3516 18.2422C15.3516 18.5653 15.0887 18.8281 14.7656 18.8281H5.23438C4.91129 18.8281 4.64844 18.5653 4.64844 18.2422V12.4609H15.3516V18.2422ZM18.8281 12.6562C18.8281 13.6255 18.0396 14.4141 17.0703 14.4141H16.5234V12.4609H16.875C17.1986 12.4609 17.4609 12.1986 17.4609 11.875C17.4609 11.5514 17.1986 11.2891 16.875 11.2891H3.125C2.80141 11.2891 2.53906 11.5514 2.53906 11.875C2.53906 12.1986 2.80141 12.4609 3.125 12.4609H3.47656V14.4141H2.92969C1.96043 14.4141 1.17188 13.6255 1.17188 12.6562V7.96875C1.17188 6.99949 1.96043 6.21094 2.92969 6.21094H17.0703C18.0396 6.21094 18.8281 6.99949 18.8281 7.96875V12.6562Z"
                    fill="black"
                    fillOpacity="0.6"
                  />
                  <path
                    d="M14.7656 2.92969H5.23438C4.91129 2.92969 4.64844 3.19254 4.64844 3.51562V4.70703C4.64844 5.03012 4.91129 5.29297 5.23438 5.29297H14.7656C15.0887 5.29297 15.3516 5.03012 15.3516 4.70703V3.51562C15.3516 3.19254 15.0887 2.92969 14.7656 2.92969ZM14.1797 4.12109H5.82031V4.10254H14.1797V4.12109Z"
                    fill="black"
                    fillOpacity="0.6"
                  />
                  <path
                    d="M14.7656 16.9922H5.23438C4.91129 16.9922 4.64844 17.255 4.64844 17.5781V18.2422C4.64844 18.5653 4.91129 18.8281 5.23438 18.8281H14.7656C15.0887 18.8281 15.3516 18.5653 15.3516 18.2422V17.5781C15.3516 17.255 15.0887 16.9922 14.7656 16.9922ZM14.1797 17.5781H5.82031V17.5596H14.1797V17.5781Z"
                    fill="black"
                    fillOpacity="0.6"
                  />
                </svg>
              </Button>
            </Col>
          </Row>

          <Col lg={12} className="mb-3">
            <Card className="border-0 bg-white p-3 pt-sm-4 px-sm-5">
              <Card.Header className="border-0 mb-0 px-sm-5">
                <h4 className="mb-0 fw-bolder">Invoice Details</h4>
              </Card.Header>

              <div ref={printComponentRef}>
                {invoiceData ? (
                  <Card.Body className="p-0 px-sm-5">
                    <Row className="mt-2">
                      <Col lg={6} className="mb-4">
                        <strong>Customer Name:</strong> {invoiceData.customerName}
                      </Col>
                      <Col lg={6} className="mb-4">
                        <strong>Email:</strong> {invoiceData.email}
                      </Col>
                      <Col lg={6} className="mb-4">
                        <strong>Invoice Date:</strong> {formatDate(invoiceData.InvoiceDate)}
                      </Col>
                      <Col lg={6} className="mb-4">
                        <strong>Due Date:</strong> {formatDate(invoiceData.dueDate)}
                      </Col>
                      <Col lg={6} className="mb-4">
                        <strong>Terms:</strong> {invoiceData.terms}
                      </Col>
                      <Col lg={6} className="mb-4">
                        <strong>Invoice Number:</strong> {invoiceData.invoiceNumber}
                      </Col>
                      <Col lg={6} className="mb-4">
                        <strong>Billing Address:</strong> {invoiceData.billingAddress}
                      </Col>
                      <Col lg={6} className="mb-4">
                        <strong>Tags:</strong> {invoiceData.tags}
                      </Col>
                      <Col lg={6} className="mb-4">
                        <strong>Message on Invoice:</strong> {invoiceData.messageOnInvolve}
                      </Col>
                      <Col lg={6} className="mb-4">
                        <strong>Message on Statements:</strong> {invoiceData.messageOnStatements}
                      </Col>
                    </Row>

                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Product Service</th>
                          <th>Description</th>
                          <th>Qty</th>
                          <th>Rate</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {invoiceData.productArr.map((item, index) => (
                          <tr key={index}>
                            <td>{item.ProductService}</td>
                            <td>{item.Description}</td>
                            <td>{item.Qty}</td>
                            <td>${item.Rate}</td>
                            <td>${item.Amount}</td>
                          </tr>
                        ))}
                        <tr>
                          <td colSpan="4" className="text-right"><strong>Total Amount</strong></td>
                          <td><strong>${calculateTotalAmount()}</strong></td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                ) : (
                  <Card.Body className="p-0 px-sm-5">
                    <p>Loading...</p>
                  </Card.Body>
                )}
              </div>
            </Card>
          </Col>
        </Container>
      </section>
    </div>
  );
};

export default VehicleInvoiceDetail;
