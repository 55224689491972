import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Container, Row, Col, Card, Button, Form, Table, Modal, Dropdown } from "react-bootstrap";
import Header from "../../../commanComponents/usernavbar";
import "../../css/createcheque.css";
import { addBillPayment, getActiveSupplierList, getOverdueExpenseList, getBankCashAccountListById, getTotalAmountPaid } from "../../../../Action/propertyOwner.action";
import { toast, Toaster } from "react-hot-toast";
import DataTable, { createTheme } from "react-data-table-component";
import { FaPlus, FaArrowLeftLong } from "react-icons/fa6";
import config from "../../../../config";


const BillPayment = () => {
    useEffect(() => {
        document.body.classList.add("staffbody");
        return () => {
            document.body.classList.remove("staffbody");
        };
    }, []);

    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        supplierId: "",
        paymentDate: "",
        mailingAddress: "",
        referenceNo: "",
        bankCreditAccount: "",
        type: ""
    });

    const [errors, setErrors] = useState({});
    const [supplierList, setSupplierList] = useState([]);
    const [expenseList, setExpense] = useState([])
    const [bankCashAccountList, setBankCashAccountList] = useState([])
    const [billFrom, setBillFrom] = useState('');
    const [billTo, setBillTo] = useState('');
    const [overdueOnly, setOverdueOnly] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [totalAmt, setTotalAmt] = useState('')


    useEffect(() => {
        fetchSupplierList()
        //  fetchExpenseList()
        fetchBankCashAccountList()
    }, [])


    const validateForm = () => {
        const formErrors = {};
        if (!formData.supplierId) formErrors.supplierId = "Please select supplier";
        if (!formData.mailingAddress) formErrors.mailingAddress = "Mailing address is required";
        if (!formData.paymentDate) formErrors.paymentDate = "Payment Date is required";
        if (!formData.bankCreditAccount) formErrors.bankCreditAccount = "Bank credit account is required";
        if (!formData.referenceNo) formErrors.referenceNo = "Reference Number is required";
        return formErrors;
    };

    const handleSelectedRowsChange = (state) => {
        const selectedIds = state.selectedRows.map((row) => row.id);
        setSelectedRows(selectedIds);
    };

    const fetchSupplierList = async () => {
        try {
            let res = await getActiveSupplierList({ type: 1 })
            if (res.success) {
                setSupplierList(res.data || [])
            }
        } catch (error) {

        }
    }

    const fetchTotalAmount = async () => {
        try {
            let res = await getTotalAmountPaid({ supplierId: formData.supplierId})
            if (res.success) {
                setTotalAmt(res.data || 0)
            }
        } catch (error) {

        }
    }
    

    const fetchExpenseList = async () => {
        try {
            let res = await getOverdueExpenseList({ type: 1, supplierId: formData.supplierId, paymentStatus: null });
            if (res.success) {
                setExpense(res.data || [])
            }
        } catch (error) {

        }
    }

    const filterData = async () => {
        try {
            let paymentStatus = null;
            if (overdueOnly) {
                paymentStatus = 3;
            }

            let res = await getOverdueExpenseList({ type: 1, supplierId: formData.supplierId, paymentStatus: paymentStatus });

            if (res.success) {
                setExpense(res.data);
                let expenses = res.data || [];
                if (billFrom !== '' && billTo !== '') {
                    const startDate = new Date(billFrom);
                    const endDate = new Date(billTo);
                    endDate.setHours(23, 59, 59, 999);

                    const filteredExpenses = expenses.filter(expense => {
                        const expenseDate = new Date(expense.dateTime);
                        return expenseDate >= startDate && expenseDate <= endDate;
                    });

                    setExpense(filteredExpenses);
                }
            }
        } catch (error) {
            console.error('Error fetching expense list:', error);
        }
    };


    console.log('expenseList', expenseList)


    const fetchBankCashAccountList = async () => {
        try {
            let res = await getBankCashAccountListById()
            if (res.success) {
                setBankCashAccountList(res.data || [])
            }
        } catch (error) {

        }
    }

    const inputHandler = (e) => {
        const { name, value } = e.target;
        setFormData((old) => {
            return { ...old, [name]: value };
        });
        if (value.trim() !== "") {
            setErrors((oldErrors) => ({ ...oldErrors, [name]: "" }));
        }
    };


    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === 'checkbox') {
            setOverdueOnly(checked);
        } else {
            if (name === 'billfrom') setBillFrom(value);
            if (name === 'billto') setBillTo(value);
        }
    };

    const handleSubmit = async () => {
        const formValidationErrors = validateForm();
        if (Object.keys(formValidationErrors).length === 0) {
            try {
                let data = {
                    ...formData,
                    expensePaymentIds: selectedRows,
                    type: 1,
                }

                const res = await addBillPayment(data)
                if (res.success) {
                    toast.success(res.msg);
                    setTimeout(() => {
                        navigate(`/billpaymentlist`);
                    }, 2000);
                } else {
                    toast.error(res.msg);
                }

            } catch (error) {
                console.error("Error adding estimate:", error);
                alert("Something went wrong.");
            }
        } else {
            setErrors(formValidationErrors);
        }
    };

    const customStyles = {
        rows: {
            style: {
                minHeight: '70px', // override the row height

            },
        },
        headCells: {

            style: {
                minHeight: '60px',
                paddingLeft: '20px', // override the cell padding for head cells
                paddingRight: '20px',
                background: '#FFF8F4',

            },
        },
        cells: {
            style: {
                paddingLeft: '20px', // override the cell padding for data cells
                paddingRight: '20px',
            },
        },
    };

    const columns = [
        {
            name: '#',
            selector: (row, index) => index + 1,
            sortable: false,
        },
        {
            name: 'Description',
            selector: row => row.description,
        },
        {
            name: 'Due Date',
            // width: "9%",
            selector: row => {
                const date = new Date(row.paymentDate);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                const year = date.getFullYear();
                return `${day}/${month}/${year}`;
            },
        },
        {
            name: 'Original Amount',
            selector: row => `$${row.expenseAmount + row.taxAmount}`,
        }


    ];

    const calculateAmount = () => {
        return expenseList.reduce((total, expense) => {
            return total + parseFloat(expense.expenseAmount + expense.taxAmount || 0);
        }, 0);
    };

    return (
        <div className="staffdashboard bg-pink">
            <Header />
            <Toaster />
            <section className="listing pt-5 pb-5 overflow-hidden vh-80">
                <Container>
                    <Row>
                        <Col lg={5} sm={12} xs={12} className="mb-4">
                            <Link to={`${config.baseurl}billpaymentlist`}>
                                <Button variant="default" className="p-0 h-auto">
                                    <FaArrowLeftLong size={`25px`} />
                                </Button>
                            </Link>
                        </Col>
                        <Col lg={7} sm={12} xs={12} className="text-right"></Col>
                    </Row>
                    <div className="">
                        <Col lg={12} className="mb-3">
                            <Card className="border-0 bg-white p-3">
                                <Card.Header className="mb-3 pb-3">
                                    <div className="d-md-flex justify-content-between align-items-center">
                                        <div className="mb-2"><h4 className="mb-0 fw-bolder">Bill Payment</h4></div>
                                        <div className="mb-2 text-end"><h5 className="mb-0 fw-bolder">Amount Paid<span className="ms-3">${totalAmt ? totalAmt : '00'}</span></h5></div>

                                    </div>

                                </Card.Header>
                                <Card.Body className="p-0">
                                    <div className="">
                                        <Row>
                                            <Col xl={12} lg={12} className="">


                                                <Row className="">

                                                    <Col lg={6} className="mb-4">
                                                        <Form.Group controlId="formBasicExpense" className="mb-3">
                                                            <Form.Label className="text-light-grey mb-1">Supplier</Form.Label>
                                                            <Form.Control
                                                                as="select"
                                                                name="supplierId"
                                                                value={formData?.supplierId}
                                                                onChange={inputHandler}
                                                                onClick={() => {
                                                                    fetchExpenseList();
                                                                    fetchTotalAmount();
                                                                }}
                                                            >
                                                                <option value="">Select Supplier</option>
                                                                {supplierList.map(item => (
                                                                    <option key={item.id} value={item.id}>
                                                                        {item.supplierName}
                                                                    </option>
                                                                ))}
                                                            </Form.Control>
                                                            {errors.supplierId && <small className="text-danger">{errors.supplierId}</small>}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={2} className="mb-md-4">
                                                    </Col>
                                                    <Col lg={4} className="mb-4">
                                                        <Form.Group className="mb-3">
                                                            <Form.Label className="text-light-grey mb-1">Payment Date</Form.Label>
                                                            <Form.Control
                                                                type="date"
                                                                name="paymentDate"
                                                                value={formData.paymentDate}
                                                                onChange={inputHandler}
                                                            />
                                                            {errors.paymentDate && <p className="text-danger">{errors.paymentDate}</p>}
                                                        </Form.Group>
                                                    </Col>

                                                    <Col lg={6} className="mb-4">
                                                        <Form.Group className="mb-3">
                                                            <Form.Label className="text-light-grey mb-1">Mailing Address</Form.Label>
                                                            <Form.Control
                                                                type="email"
                                                                name="mailingAddress"
                                                                value={formData.mailingAddress}
                                                                onChange={inputHandler}
                                                            />
                                                            {errors.mailingAddress && <p className="text-danger">{errors.mailingAddress}</p>}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={2} className="mb-md-4">
                                                    </Col>

                                                    <Col lg={4} className="mb-4">
                                                        <Form.Group className="mb-3">
                                                            <Form.Label className="text-light-grey mb-1">Reference No</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="referenceNo"
                                                                value={formData.referenceNo}
                                                                onChange={inputHandler}
                                                            />
                                                            {errors.referenceNo && <p className="text-danger">{errors.referenceNo}</p>}
                                                        </Form.Group>
                                                    </Col>

                                                    <Col lg={6} className="mb-4">
                                                        <Form.Group controlId="formBasicExpense" className="mb-3">
                                                            <Form.Label className="text-light-grey mb-1">Bank / Credit Account</Form.Label>
                                                            <Form.Control
                                                                as="select"
                                                                name="bankCreditAccount"
                                                                value={formData?.bankCreditAccount}
                                                                onChange={inputHandler}
                                                            >
                                                                <option value="">Select Account</option>
                                                                {bankCashAccountList.map(item => (
                                                                    <option key={item.id} value={item.id}>
                                                                        {item.accountName}
                                                                    </option>
                                                                ))}
                                                            </Form.Control>
                                                            {errors.bankCreditAccount && <small className="text-danger">{errors.bankCreditAccount}</small>}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={2} className="mb-md-4">
                                                    </Col>

                                                    <Col lg={4} className="mb-4">
                                                        <Form.Group className="mb-3">
                                                            <Form.Label className="text-light-grey">Filter</Form.Label>
                                                            <div className="customDropdown">
                                                                <Dropdown>
                                                                    <Dropdown.Toggle variant="default" id="dropdown-basic" className="w-100 text-start">
                                                                        Sort
                                                                    </Dropdown.Toggle>

                                                                    <Dropdown.Menu className="mt-2">
                                                                        <Card className="shadow-sm border-0 p-3">
                                                                            <Row>
                                                                                <Col lg={6} className="mb-3">
                                                                                    <Form.Group>
                                                                                        <Form.Label className="text-black mb-1 x-small">Bills from</Form.Label>
                                                                                        <Form.Control
                                                                                            type="date"
                                                                                            name="billfrom"
                                                                                            value={billFrom}
                                                                                            onChange={handleInputChange}
                                                                                        />
                                                                                    </Form.Group>
                                                                                </Col>
                                                                                <Col lg={6} className="mb-3">
                                                                                    <Form.Group>
                                                                                        <Form.Label className="text-black mb-1 x-small">Bills to</Form.Label>
                                                                                        <Form.Control
                                                                                            type="date"
                                                                                            name="billto"
                                                                                            value={billTo}
                                                                                            onChange={handleInputChange}
                                                                                        />
                                                                                    </Form.Group>
                                                                                </Col>
                                                                                <Col lg={12}>
                                                                                    <Form.Group>
                                                                                        <Form.Check
                                                                                            label="Overdue bills only"
                                                                                            name="group1"
                                                                                            className="x-small"
                                                                                            type="checkbox"
                                                                                            id="reverse-checkbox-1"
                                                                                            checked={overdueOnly}
                                                                                            onChange={handleInputChange}
                                                                                        />
                                                                                    </Form.Group>
                                                                                </Col>
                                                                                <Col lg={12}>
                                                                                    <div className="d-flex">

                                                                                        <Button
                                                                                            variant="outline-primary"
                                                                                            className=" rounded-5 px-4 me-3 w-100 px-xs-1 h-auto"
                                                                                            onClick={() => {

                                                                                                setBillFrom('');
                                                                                                setBillTo('');
                                                                                                setOverdueOnly(false);
                                                                                                fetchExpenseList();
                                                                                            }}
                                                                                        >
                                                                                            Reset
                                                                                        </Button>

                                                                                        <Button
                                                                                            variant="primary"
                                                                                            className=" rounded-5  px-4 px-xs-1 w-100 h-auto"
                                                                                            onClick={filterData}
                                                                                        >
                                                                                            Apply
                                                                                        </Button>

                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </Card>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>

                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={7} className="mb-4">
                                                        <Form.Group className="mb-3 d-md-flex align-items-center justify-content-end">
                                                            <Form.Label className="text-light-grey me-3 text-right">Balance Due</Form.Label>

                                                            <Form.Control
                                                                type="text"
                                                                name="1,139,800.000"
                                                                value={calculateAmount().toFixed(2)}
                                                                className="w-auto"
                                                            />



                                                        </Form.Group>
                                                    </Col>


                                                </Row>
                                            </Col>


                                            <Col lg={12}>
                                                <hr className="mb-4 mt-0" style={{ color: "#EBEBEB", opacity: "1" }} />
                                            </Col>
                                            <h4>Details</h4>
                                            <Col xl={12}>
                                                <div className=" mb-4">

                                                    {/* ----------Balance sheet----------- */}
                                                    <div className="tableBody p-0" >
                                                        <Row className="justify-content-center">

                                                            <Col xl={12} lg={12} className="">
                                                                <div className="staff_upcoming_booking">
                                                                    <div className="border-0">
                                                                        <div className="p-0">

                                                                            <div className="mb-4">
                                                                                <Row className="align-items-center">
                                                                                    <Col lg={12} xs={12}>
                                                                                        <div className="pt-2">
                                                                                            <DataTable
                                                                                                columns={columns}
                                                                                                data={expenseList}
                                                                                                selectableRows
                                                                                                onSelectedRowsChange={handleSelectedRowsChange}
                                                                                                customStyles={customStyles}
                                                                                                theme="solarized"
                                                                                            />
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>

                                                                        </div>

                                                                    </div>

                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xl={12} lg={12} md={12}>
                                                <div className="text-center mx-auto">
                                                    <div className="mt-3">
                                                    <Link to={`#`}>
                                                            <Button
                                                                variant="outline-primary"
                                                                className=" rounded-5 me-2 px-5 px-xs-1 mb-4 mobileBtn"
                                                            >
                                                                Cancel
                                                            </Button>
                                                        </Link>
                                                        <Link to={`#`}>
                                                            <Button
                                                                variant="primary"
                                                                className=" rounded-5 me-2 px-5 px-xs-1 mb-4 mobileBtn"
                                                                onClick={handleSubmit}
                                                            >
                                                                Save & Close
                                                            </Button>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </div>
                </Container>
            </section>
        </div>
    );
};

export default BillPayment;
