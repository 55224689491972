import * as Yup from 'yup';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]?$/
const nameRegExp = /^[A-Za-z]+$/;
export const SignupSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(nameRegExp, 'Only alphabet characters are allowed')
    .min(2, 'Too Short Name!')
    .max(50, 'Too Long Name!')
    .required('First name is required.'),
  lastName: Yup.string()
    .matches(nameRegExp, 'Only alphabet characters are allowed')
    .min(2, 'Too Short Name!')
    .max(50, 'Too Long Name!')
    .required('Last name is required.'),
  email: Yup.string().email('Invalid email').required('Email is required.'),
  mobileNumber: Yup.string()
    .matches(phoneRegExp, ' only Numeric values allowed')
    .max(10, 'Phone number is too long - should be maximum 10 digits.')
    .required('Phone number is required.'),
  password: Yup.string()
    .required('Password is required.')
    .matches(/\b([A-Z])/g, 'Password requires first letter Capital')
    .max(18, 'Password is too long - should be maximum 20 chars.')
    .min(7, 'Password is too short - should be 8 chars minimum.')
    .matches(/[0-9]/, 'Password requires a number')
    .matches(/[a-z]/, 'Password requires a lowercase letter')
    .matches(/[A-Z]/, 'Password requires an uppercase letter')
    .matches(/[^\w]/, 'Password requires a special chars'),
  confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Password and confirm password does not match').required('Confirm password is required.'),
  tos: Yup.bool().oneOf([true], 'You need to accept the terms and conditions'),
});


export const SigninSchema = Yup.object().shape({
  mobileNumber: Yup.string()
    // .matches(phoneRegExp, 'Phone number is not valid')
    .max(10, 'Phone number is too long - should be maximum 10 digits.')
    .required('Phone number is required'),
  password: Yup.string()
    .required('Password is required')
});


export const ForgetPasswordSchema = Yup.object().shape({
  emailormobile: Yup.string()
    .matches(phoneRegExp, 'only Numeric Values Allowed')
    .max(10, 'Phone number is too long - should be maximum 10 digits.')
    .required('Phone number is required')
});

export const ForgetPasswordOTPSchema = Yup.object().shape({
  otp1: Yup.string()
    .required('OTP required'),
  otp2: Yup.string()
    .required('OTP required'),
  otp3: Yup.string()
    .required('OTP required'),
  otp4: Yup.string()
    .required('OTP required'),
  otp5: Yup.string()
    .required('OTP required'),
});


export const ResetPasswordOTPSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password required')
    .matches(/\b([A-Z])/g, 'Password requires first letter Capital')
    .max(18, 'Password is too long - should be maximum 20 chars.')
    .min(7, 'Password is too short - should be 8 chars minimum.')
    .matches(/[0-9]/, 'Password requires a number')
    .matches(/[a-z]/, 'Password requires a lowercase letter')
    .matches(/[A-Z]/, 'Password requires an uppercase letter')
    .matches(/[^\w]/, 'Password requires a special chars'),
  confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Password and confirm password does not match').required('Confirm password is required'),
});

export const RegisterSchema = Yup.object().shape({
  fullname: Yup.string()
    // .matches(nameRegExp, 'Only alphabet characters are allowed')
    .min(2, 'Too Short Name!')
    .max(50, 'Too Long Name!')
    .required('Full name is required.'),
  email: Yup.string().email('Invalid email').required('Email is required.'),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, ' only Numeric values allowed')
    .required('Phone number is required.'),
  location: Yup.string()
    .required('Location is required.'),
  // numberOfProperty: Yup.string()
  //   .required('Number of property is required.'),
  // numberOfVehicle: Yup.string()
  //   .required('Number of vehicle is required.'),
  // typesOfProperty: Yup.string()
  //   .required('Types of property is required.'),
  // typesOfVehicle: Yup.string()
  //   .required('Types of vehicle is required.'),
  description: Yup.string()
    .required('Description is required.'),
});
