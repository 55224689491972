
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, Button, Form, Dropdown, Modal } from "react-bootstrap";
import DataTable, { createTheme } from "react-data-table-component";
import { FaStar } from "react-icons/fa";
import { Link } from "react-router-dom";
import config from "../../../config";
import config1 from "../../../coreFIles/config";
import Header from "../../commanComponents/usernavbar";
import { RiDeleteBin6Line } from "react-icons/ri";
import "../css/createcheque.css";
import { toast, Toaster } from "react-hot-toast";
import { getActiveSupplierList, getBankAccountList, addChequeDetails, getChequeCategoryList } from "../../../Action/propertyOwner.action";
import { FaPlus, FaArrowLeftLong } from "react-icons/fa6";



const AddVehicleCheque = () => {
  createTheme('solarized', {
    text: {
      primary: '#000',
      secondary: '#2aa198',
    },
    background: {
      default: '',
    },
    context: {
      background: '#cb4b16',
      text: '#FFFFFF',
    },
    divider: {
      default: '#EBEBEB',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  }, 'dark');

  const customStyles = {
    rows: {
      style: {
        minHeight: '70px', // override the row height

      },
    },
    headCells: {

      style: {
        minHeight: '60px',
        paddingLeft: '20px', // override the cell padding for head cells
        paddingRight: '20px',
        background: '#FFF8F4',

      },
    },
    cells: {
      style: {
        paddingLeft: '20px', // override the cell padding for data cells
        paddingRight: '20px',
      },
    },
  };

  const navigate = useNavigate();


  const columns = [
    {
      name: '#',
      selector: (row, index) => index + 1, // Serial number based on the index
      sortable: false,
      width: '10%',
    },
    {
      name: 'Category',
      selector: row => row.categoryName,
      width: "25%"
    },
    {
      name: 'Description',
      selector: row => row.description,
      width: "25%"
    },
    {
      name: 'Amount',
      selector: row => '$' + row.amount,
      width: "30%"
    },
    {
      name: 'Delete',
      selector: row => row.status,
      cell: (row, index) => (
        <Button
          variant="default"
          className="p-0 btn-sm text-primary"
          onClick={() => handleDeleteCategory(index)} // Pass the index to the delete handler
        >
          <RiDeleteBin6Line size={`15px`} />
        </Button>
      ),
    },
  ];

  const columns2 = [
    {
      name: '#',
      selector: (row, index) => index + 1,
      sortable: false,
      width: '10%',
    },
    {
      name: 'Product Service',
      selector: row => row.productService,
      width: "25%"
    },
    {
      name: 'Quantity',
      selector: row => row.quantity,
      width: "25%"
    },
    {
      name: 'Rate',
      selector: row => row.rate,
      width: "15%"
    },
    {
      name: 'Amount',
      selector: row => '$' + row.amount,
      width: "15%"
    },
    {
      name: 'Delete',
      selector: row => row.status,
      cell: (row, index) => (
        <Button
          variant="default"
          className="p-0 btn-sm text-primary"
          onClick={() => handleDeleteItem(index)} // Pass the index to the delete handler
        >
          <RiDeleteBin6Line size={`15px`} />
        </Button>
      ),
    },
  ];


  useEffect(() => {
    // Add the class to the body tag
    document.body.classList.add("staffbody");

    // Cleanup function to remove the class when the component is unmounted
    return () => {
      document.body.classList.remove("staffbody");
    };
  }, []);

  const navigateRoute = () => {
    setTimeout(() => {
      navigate(`${config.baseurl}${config.loginurl}`, { state: "prop" });
      window.location.reload();
    });
  };

  const [supplierList, setSupplierList] = useState([]);
  const [accountList, setAccountList] = useState([])
  const [formData, setFormData] = useState({
    supplierId: '',
    bankAccountId: '',
    mailingAddress: '',
    balance: '',
    paymentDate: '',
    tag: '',
    attachment: '',
    chequeNo: '',
    categoryMemo: '',
    itemMemo: '',
    categoryArr: [],
    itemArr: [],
    categoryAmountTotal: '',
    itemAmountTotal: '',
    type: ''
  });
  const [errors, setErrors] = useState({});
  const [fileName, setFileName] = useState("");
  const [categoryError, setCategoryError] = useState({});
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [category, setCategory] = useState({
    categoryName: '',
    description: "",
    amount: ""
  });

  const [itemError, setItemError] = useState({});
  const [showItemModal, setShowItemModal] = useState(false);
  const [item, setItem] = useState({
    productService: '',
    quantity: "",
    rate: "",
    amount: ""
  });
  const [chequeCategoryList, setChequeCategoryList] = useState([]);



  useEffect(() => {
    fetchSupplierList()
    fetchAccountList()
    fetchChequeCategoryList()
  }, [])

  const fetchChequeCategoryList = async () => {
    try {
      let res = await getChequeCategoryList()
      if (res.success) {
        setChequeCategoryList(res.data || [])
      }
    } catch (error) {

    }
  }

  const fetchSupplierList = async () => {
    try {
      let res = await getActiveSupplierList({ type: 2 })
      if (res.success) {
        setSupplierList(res.data || [])
      }
    } catch (error) {

    }
  }

  const fetchAccountList = async () => {
    try {
      let res = await getBankAccountList()
      if (res.success) {
        setAccountList(res.data || [])
      }
    } catch (error) {

    }
  }

  const validateForm = () => {
    const newErrors = {};
    if (!formData?.supplierId) newErrors.supplierId = "Select supplier name.";
    if (!formData?.bankAccountId) newErrors.bankAccountId = "Please select bank.";
    if (!formData?.balance) newErrors.balance = "Balance is required.";
    if (!formData?.paymentDate) newErrors.paymentDate = "Payment date is required.";
    if (!formData?.mailingAddress) newErrors.mailingAddress = "Mailing address is required.";
    if (!formData?.tag) newErrors.tag = "Tag is required.";
    if (!formData?.chequeNo) newErrors.chequeNo = "Cheque number is required.";
    if (formData.categoryArr.length === 0) newErrors.categoryArr = "At least one category is required";
    if (formData.itemArr.length === 0) newErrors.itemArr = "At least one item is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;
    try {
      const res = await addChequeDetails({
        ...formData,
        type: 2,
        categoryArr: JSON.stringify(formData.categoryArr),
        itemArr: JSON.stringify(formData.itemArr),
        categoryAmountTotal: calculateCategoryAmount().toFixed(2),
        itemAmountTotal: calculateItemAmount().toFixed(2)
      });

      if (res.success) {
        toast.success(res.msg);
        setTimeout(() => {
          navigate(`/vehiclechequelist`);
        }, 2000);
      } else {
        toast.error(res.msg);
      }
    } catch (error) {
      toast.error('Something went wrong please try again after some time.');
    }
  };

  const handleDeleteCategory = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      categoryArr: prevData.categoryArr.filter((_, i) => i !== index),
    }));
  };

  const inputHandler = (e) => {
    const { name, value } = e.target;
    setFormData((old) => {
      return { ...old, [name]: value };
    });
    if (value.trim() !== "") {
      setErrors((oldErrors) => ({ ...oldErrors, [name]: "" }));
    }
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, attachment: e.target.files[0] });
    setFileName(e.target.files ? e.target.files[0].name : "");
  };

  //--------------|| CATEGORY MODAL FUNCTION ||--------------------


  const handleCategoryChange = (e) => {
    const { name, value } = e.target;
    setCategory((prevProduct) => ({
      ...prevProduct,
      [name]: value,
    }));
  };

  const validateCategory = () => {
    let categoryError = {};
    if (!category.categoryName) categoryError.categoryName = "Category is required";
    if (!category.description) categoryError.description = "Description is required";
    if (!category.amount) categoryError.amount = "Amount is required";
    return categoryError;
  };

  const handleAddProduct = () => {
    const productValidationErrors = validateCategory();
    if (Object.keys(productValidationErrors).length === 0) {
      setFormData((prevData) => ({
        ...prevData,
        categoryArr: [...(prevData.categoryArr || []), category],
      }));
      console.log('formData', formData)
      setCategory({
        categoryName: '',
        description: '',
        amount: ''
      });
      setShowCategoryModal(false);
      setCategoryError({});
    } else {
      setCategoryError(productValidationErrors);
    }
  };

  const calculateCategoryAmount = () => {
    return formData.categoryArr.reduce((total, category) => {
      return total + parseFloat(category.amount || 0);
    }, 0);
  };

  //--------------|| ITEM MODAL FUNCTION ||--------------------

  const handleItemChange = (e) => {
    const { name, value } = e.target;
      setItem((prevItem) => ({
      ...prevItem,
      [name]: value,
      amount: name === 'quantity' || name === 'rate'
        ? (name === 'quantity' ? value : prevItem.quantity) * (name === 'rate' ? value : prevItem.rate)
        : prevItem.amount,
    }));
  };

  const validateItem = () => {
    let itemError = {};
    if (!item.productService) itemError.productService = "Product service is required";
    if (!item.quantity) itemError.quantity = "Quantity is required";
    if (!item.rate) itemError.rate = "Rate is required";
  //  if (!item.amount) itemError.amount = "Amount is required";
    return itemError;
  };

  const handleAddItemDetails = () => {
    const productValidationErrors = validateItem();
    if (Object.keys(productValidationErrors).length === 0) {
      setFormData((prevData) => ({
        ...prevData,
        itemArr: [...(prevData.itemArr || []), item],
      }));
      console.log('formData', formData)
      setItem({
        productService: '',
        quantity: '',
        rate: '',
        amount: ''
      });
      setShowItemModal(false);
      setItemError({});
    } else {
      setItemError(productValidationErrors);
    }
  };

  const handleDeleteItem = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      itemArr: prevData.itemArr.filter((_, i) => i !== index),
    }));
  };

  const calculateItemAmount = () => {
    return formData.itemArr.reduce((total, item) => {
      return total + parseFloat(item.amount || 0);
    }, 0);
  };

  return (
    <div className="staffdashboard bg-pink">
      <Header />
      <Toaster />
      <section className="listing pt-5 pb-5 overflow-hidden vh-80">
        <Container>
        <Row>
            <Col lg={5} sm={12} xs={12} className="mb-4">
              <Link to={`${config.baseurl}vehiclechequelist`}>
                <Button variant="default" className="p-0 h-auto">
                  <FaArrowLeftLong size={`25px`} />
                </Button>
              </Link>
            </Col>
            <Col lg={7} sm={12} xs={12} className="text-right"></Col>
          </Row>
          <div className="">
            <Col lg={12} className="mb-3">
              <Card className="border-0 bg-white p-3">
                <Card.Header className="mb-3 pb-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="mb-2"><h4 className="mb-0 fw-bolder">Cheque No.1</h4></div>
                   
                  </div>

                </Card.Header>
                <Card.Body className="p-0">
                  <div className="">
                    <Row>
                      <Col xl={12} lg={12} className="">
                        <Row className="mt-2">
                          <Col lg={6} className="mb-4">
                            <Form.Group controlId="formBasicExpense">
                              <Form.Label className="text-light-grey mb-1">Supplier</Form.Label>
                              <Form.Control
                                as="select"
                                name="supplierId"
                                value={formData?.supplierId}
                                onChange={inputHandler}
                              >
                                <option value="">Select Supplier</option>
                                {supplierList.map(item => (
                                  <option key={item.id} value={item.id}>
                                    {item.supplierName}
                                  </option>
                                ))}
                              </Form.Control>
                              {errors.supplierId && <small className="text-danger">{errors.supplierId}</small>}
                            </Form.Group>
                          </Col>

                          {/* <Col lg={4} className="mb-4">
                            <Form.Group>
                              <Form.Label className="text-light-grey">Bank Account</Form.Label>
                              <div className="customDropdown">
                              <Dropdown>
                                <Dropdown.Toggle variant="default" id="dropdown-basic" className="w-100 text-start">
                                   Cash & Cash Equivalents
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Button variant="default" as="button"  className="text-dark-blue w-100 text-start">
                                     +Add New
                                  </Button>
                                  <Dropdown.Item href="#/action-2">Cash & Cash Equivalent </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                              </div>
                             
                            </Form.Group>
                          </Col> */}

                          <Col lg={6} className="mb-4">
                            <Form.Group controlId="formBasicExpense">
                              <Form.Label className="text-light-grey mb-1">Bank Account</Form.Label>
                              <Form.Control
                                as="select"
                                name="bankAccountId"
                                value={formData?.bankAccountId}
                                onChange={inputHandler}
                              >
                                <option value="">Select bank account</option>
                                {accountList.map(item => (
                                  <option key={item.id} value={item.id}>
                                    {item.accountName}
                                  </option>
                                ))}
                              </Form.Control>
                              {errors.bankAccountId && <small className="text-danger">{errors.bankAccountId}</small>}
                            </Form.Group>
                            <Link to={`${config.baseurl}createaccount`}>
                            <Button
                              variant="link"
                              className="p-0"
                            >
                              Add New
                            </Button>
                            </Link>
                          </Col>

                          <Col lg={4} className="mb-4">
                            <Form.Group>
                              <Form.Label className="text-light-grey">Balance</Form.Label>
                              <Form.Control
                                type="number"
                                name="balance"
                                value={formData?.balance}
                                onChange={inputHandler}
                              />
                              {errors.balance && <small className="text-danger">{errors.balance}</small>}

                            </Form.Group>
                          </Col>

                          <Col lg={6} className="mb-4">
                            <Form.Group controlId="formMailingAddress">
                              <Form.Label className="text-light-grey mb-1">Mailing Address</Form.Label>
                              <Form.Control
                                type="text"
                                name="mailingAddress"
                                value={formData?.mailingAddress}
                                onChange={inputHandler}
                              />
                              {errors.mailingAddress && <small className="text-danger">{errors.mailingAddress}</small>}

                            </Form.Group>
                          </Col>

                          <Col lg={6} className="mb-4">
                            <Form.Group controlId="formPaymentDate">
                              <Form.Label className="text-light-grey mb-1">Payment Date</Form.Label>
                              <Form.Control
                                type="date"
                                name="paymentDate"
                                value={formData?.paymentDate}
                                onChange={inputHandler}
                              />
                              {errors.paymentDate && <small className="text-danger">{errors.paymentDate}</small>}

                            </Form.Group>
                          </Col>


                          <Col lg={6} className="mb-4">
                            <Form.Group controlId="formTag">
                              <Form.Label className="text-light-grey mb-1">Tags</Form.Label>
                              <Form.Control
                                type="text"
                                name="tag"
                                value={formData?.tag}
                                onChange={inputHandler}
                              />
                              {errors.tag && <small className="text-danger">{errors.tag}</small>}

                            </Form.Group>
                          </Col>

                          <Col lg={6} className="mb-4">
                            <Form.Group controlId="formChequeNo">
                              <Form.Label className="text-light-grey mb-1">Cheque No</Form.Label>
                              <Form.Control
                                type="text"
                                name="chequeNo"
                                value={formData?.chequeNo || ''}
                                onChange={inputHandler}
                              />
                              {errors.chequeNo && <small className="text-danger">{errors.chequeNo}</small>}

                            </Form.Group>
                          </Col>

                        </Row>
                      </Col>
                      <Col lg={12}>
                        <hr className="mb-4 mt-0" style={{ color: "#EBEBEB", opacity: "1" }} />
                      </Col>
                      <Col xl={12} lg={12} className="">
                        <div className="staff_upcoming_booking">
                          <div className="border-0">
                            <div className="p-0">
                              <div className=" mb-3">
                                <div className=""><h4 className="mb-0 fw-bolder">Category Details </h4></div>
                              </div>
                              <div>
                                <Row className="align-items-center">
                                  <Col lg={12} xs={12}>
                                    <div className="pt-2">
                                      <DataTable
                                        columns={columns}
                                        data={formData.categoryArr}
                                        customStyles={customStyles}
                                        theme="solarized"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                              <div className="mt-3">
                                <Link to={`#`}>
                                  <Button
                                    variant="outline-primary"
                                    className=" rounded-5 me-2 px-4 px-xs-1 mb-4"
                                    onClick={() => setShowCategoryModal(true)}
                                  >
                                    Add lines
                                  </Button>
                                </Link>
                                <Link to={`#`}>
                                  <Button
                                    variant="primary"
                                    className=" rounded-5 me-2 px-4 px-xs-1 mb-4"
                                    onClick={() => setFormData({
                                      ...formData,
                                      categoryArr: []
                                    })}
                                  >
                                    Clear All Lines
                                  </Button>
                                </Link>
                              </div>
                              {errors.categoryArr && <small className="text-danger">{errors.categoryArr}</small>}

                            </div>
                            <Row className="align-items-center">
                            <Col lg={6} className="mb-4">
                            <Form.Group>
                                  <Form.Label className="text-light-grey mb-1">Memo</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    name="categoryMemo"
                                    value={formData.categoryMemo}
                                    onChange={inputHandler}
                                  />
                                </Form.Group>

                            </Col>
                            <Col xl={4} lg={4} md={8} className="mb-4">
                            </Col>
                            <Col xl={2} lg={2} md={4} className="mb-4">
                              <div className="d-flex justify-content-end justify-content-between">
                                <div><h6 className="mb-0">Total</h6></div>
                                <h5>${calculateCategoryAmount().toFixed(2)}</h5>
                              </div>
                            </Col>
                          </Row>


                          </div>

                        </div>
                      </Col>
                      <Col lg={12}>
                        <hr className="mb-4 mt-0" style={{ color: "#EBEBEB", opacity: "1" }} />
                      </Col>
                      <Col xl={12} lg={12} >
                        <div className="staff_upcoming_booking">
                          <div className="border-0">
                            <div className="p-0">
                              <div className=" mb-3">
                                <div className=""><h4 className="mb-0 fw-bolder">Item Details </h4></div>

                              </div>
                              <div>


                                <Row className="align-items-center">
                                  <Col lg={12} xs={12}>
                                    <div className="pt-2">
                                      <DataTable
                                        columns={columns2}
                                        data={formData.itemArr}
                                        customStyles={customStyles}
                                        theme="solarized"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                              <div className="mt-3">
                                <Link to={`#`}>
                                  <Button
                                    variant="outline-primary"
                                    className=" rounded-5 me-2 px-4 px-xs-1 mb-4"
                                    onClick={() => setShowItemModal(true)}

                                  >
                                    Add lines
                                  </Button>
                                </Link>
                                <Link to={`#`}>
                                  <Button
                                    variant="primary"
                                    className=" rounded-5 me-2 px-4 px-xs-1 mb-4"
                                    onClick={() => setFormData({
                                      ...formData,
                                      itemArr: []
                                    })}
                                  >
                                    Clear All Lines
                                  </Button>
                                </Link>
                              </div>
                              {errors.itemArr && <small className="text-danger">{errors.itemArr}</small>}
                            </div>
                          </div>
                          
                        </div>
                      </Col>
                      <Col xl={12}>
                        <div className="">
                          <Row className="align-items-center">
                            <Col lg={6} className="mb-4">
                            <Form.Group>
                                <Form.Label className="text-light-grey mb-1">Memo</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder=""
                                  name="itemMemo"
                                  value={formData.itemMemo}
                                  onChange={inputHandler}
                                />
                              </Form.Group>

                            </Col>
                            <Col xl={4} lg={4} md={8} className="mb-4">
                            </Col>
                            <Col xl={2} lg={2} md={4} className="mb-4">
                              <div className="d-flex justify-content-end justify-content-between">
                                <div><h6 className="mb-0">Total</h6></div>
                                <h5>${calculateItemAmount().toFixed(2)}</h5>
                              </div>
                            </Col>
                          </Row>
                          {/* ----------Balance sheet----------- */}
                          <div className="tableBody p-0" >
                            <Row className="justify-content-center">
                              <Col xl={4} lg={4} md={6}>
                                {/* ----------Image upload start-------------- */}
                                <div className="text-center py-sm-5 mx-auto">
                                  <div className="fileUpload ">
                                    <Form.Control type="file" id="base-input" class="form-input form-style-base"
                                      onChange={handleFileChange}
                                    />

                                    <div className="text-center">
                                      <i className="svgIcon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="40" viewBox="0 0 32 40" fill="none">
                                          <path d="M31.7099 33.75C31.7099 37.1964 28.9062 40 25.4599 40H6.25C2.80365 40 0 37.1964 0 33.75V9.29688C0 5.85052 2.80365 3.04688 6.25 3.04688H6.54694C7.21344 1.26892 8.92975 0 10.9375 0C13.5223 0 15.625 2.10266 15.625 4.6875V15.7031C15.625 18.288 13.5223 20.3906 10.9375 20.3906C8.35266 20.3906 6.25 18.288 6.25 15.7031V10.8594C6.25 9.99634 6.94946 9.29688 7.8125 9.29688C8.67554 9.29688 9.375 9.99634 9.375 10.8594V15.7031C9.375 16.5646 10.076 17.2656 10.9375 17.2656C11.799 17.2656 12.5 16.5646 12.5 15.7031V4.6875C12.5 3.82599 11.799 3.125 10.9375 3.125C10.2069 3.125 9.59381 3.62976 9.42352 4.30817C9.44244 4.40582 9.45312 4.50623 9.45312 4.60938C9.45312 5.06073 9.26056 5.46631 8.95447 5.75134C8.66943 6.05743 8.26385 6.25 7.8125 6.25C7.64252 6.25 7.47955 6.22192 7.32635 6.17188H6.25C4.52698 6.17188 3.125 7.57385 3.125 9.29688V33.75C3.125 35.473 4.52698 36.875 6.25 36.875H25.4602C27.1832 36.875 28.5852 35.473 28.5852 33.75C28.5852 32.887 29.2847 32.1875 30.1477 32.1875C31.0104 32.1875 31.7099 32.887 31.7099 33.75ZM7.73438 29.8438C6.87134 29.8438 6.17188 30.5432 6.17188 31.4062C6.17188 32.2693 6.87134 32.9688 7.73438 32.9688H19.1406C20.0037 32.9688 20.7031 32.2693 20.7031 31.4062C20.7031 30.5432 20.0037 29.8438 19.1406 29.8438H7.73438ZM25.3906 25.0781C25.3906 24.2151 24.6912 23.5156 23.8281 23.5156H7.73438C6.87134 23.5156 6.17188 24.2151 6.17188 25.0781C6.17188 25.9412 6.87134 26.6406 7.73438 26.6406H23.8281C24.6912 26.6406 25.3906 25.9412 25.3906 25.0781ZM25.4599 3.04688H20.2344C19.3713 3.04688 18.6719 3.74634 18.6719 4.60938C18.6719 5.47241 19.3713 6.17188 20.2344 6.17188H25.4599C27.1832 6.17188 28.5849 7.57385 28.5849 9.29688V25.9375C28.5849 26.8005 29.2847 27.5 30.1474 27.5C31.0104 27.5 31.7099 26.8005 31.7099 25.9375V9.29688C31.7099 5.85052 28.9062 3.04688 25.4599 3.04688Z" fill="#E46422" />
                                        </svg>
                                      </i>
                                      <h6 className="text-primary mt-1">Attachments</h6>
                                      {fileName && <p className="mt-2">{fileName}</p>}
                                    </div>
                                  </div>
                                  <div className="mt-5">
                                    <Link to={`#`}>
                                      <Button
                                        variant="outline-primary"
                                        className=" rounded-5 me-2 px-4 px-xs-1 mb-4 mobileBtn"
                                      >
                                        Cancel
                                      </Button>
                                    </Link>
                                    <Link to={`#`}>
                                      <Button
                                        variant="primary"
                                        className=" rounded-5 me-2 px-4 px-xs-1 mb-4 mobileBtn"
                                        onClick={handleSubmit}
                                      >
                                        Save & Close
                                      </Button>
                                    </Link>
                                  </div>
                                </div>
                                {/* ----------Image upload end-------------- */}
                              </Col>
                            </Row>



                          </div>

                        </div>
                      </Col>
                    </Row>
                  </div>


                </Card.Body>
              </Card>
            </Col>
          </div>
        </Container>
      </section>
      <Modal show={showCategoryModal} onHide={() => setShowCategoryModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Category Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
          <Form.Group controlId="formBasicExpense">
              <Form.Label className="text-light-grey mb-1">Category</Form.Label>
              <Form.Control
                as="select"
                name="categoryName"
                value={category.categoryName}
                onChange={handleCategoryChange}
              >
                <option value="">Select Category</option>
                {chequeCategoryList.map(item => (
                  <option key={item.id} value={item.categoryName}>
                    {item.categoryName}
                  </option>
                ))}
              </Form.Control>
              {categoryError.categoryName && <small className="text-danger">{categoryError.categoryName}</small>}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                name="description"
                value={category.description}
                onChange={handleCategoryChange}
                className="rounded-5"
              />
              {categoryError.description && (
                <p className="text-danger">{categoryError.description}</p>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="number"
                name="amount"
                value={category.amount}
                onChange={handleCategoryChange}
                className="rounded-5"
              />
              {categoryError.amount && (
                <p className="text-danger">{categoryError.amount}</p>
              )}
            </Form.Group>
            <Button
              variant="primary"
              onClick={handleAddProduct}
              className="rounded-5"
            >
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Item Modal */}

      <Modal show={showItemModal} onHide={() => setShowItemModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Item Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Product Service</Form.Label>
              <Form.Control
                type="text"
                name="productService"
                value={item.productService}
                onChange={handleItemChange}
                className="rounded-5"
              />
              {itemError.productService && (
                <p className="text-danger">{itemError.productService}</p>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Quantity</Form.Label>
              <Form.Control
                type="number"
                name="quantity"
                value={item.quantity}
                onChange={handleItemChange}
                className="rounded-5"
              />
              {itemError.quantity && (
                <p className="text-danger">{itemError.quantity}</p>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Rate</Form.Label>
              <Form.Control
                type="number"
                name="rate"
                value={item.rate}
                onChange={handleItemChange}
                className="rounded-5"
              />
              {itemError.rate && (
                <p className="text-danger">{itemError.rate}</p>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="number"
                name="amount"
                value={item.quantity * item.rate || 0}
                readOnly 
                className="rounded-5"
              />
            </Form.Group>

            <Button
              variant="primary"
              onClick={handleAddItemDetails}
              className="rounded-5"
            >
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddVehicleCheque;
