
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import config from '../config'

import './generalCSS/login.css';
import './generalCSS/generalnavbar.css';
import { Container, Row, Col, Form, Button, InputGroup, DropdownButton, Dropdown } from 'react-bootstrap';

const LoadingPopup = () => {
    return (
        <div className="loading-popup-container">
            <div className="loading-popup">
                <p>Please wait</p>
                <h1>Loggin in . . .</h1>
            </div>
        </div>
    );
};

const Resetpassword = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleLogin = (e) => {
        e.preventDefault();
        setIsLoading(true);
        console.log('Logged in with username:', username, 'and password:', password);
        setTimeout(() => {
            navigate(`${config.baseurl}${config.loginurl}/`);
        }, 3000);
    };

    return (
        <>

            <section className="login-section pt-5 pb-5 overflow-hidden vh-80">
                <Container>
                    <Row className='justify-content-center '>
                        <Col xl={6} lg={6}>
                            <div className="login-container">
                                <div className="login-form">
                                    <div className="login-logo">
                                        <img
                                            src="assets/images/logo/logo.webp"
                                            alt="logo"
                                        />
                                        <div className='pt-4 pb-4'>
                                            <h3 className='mb-3'><b>RESET PASSWORD</b></h3>
                                            <p>Enter new password</p>
                                        </div>
                                    </div>
                                    <Form>
                                        <Row>

                                            <Col xl={12} lg={12}>
                                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                                    <Form.Control type="password" placeholder="NEW PASSWORD" autocomplete="off" />
                                                </Form.Group>

                                            </Col>

                                            <Col xl={12} lg={12}>
                                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                                    <Form.Control type="password" placeholder="CONFIRM PASSWORD" autocomplete="off" />
                                                </Form.Group>
                                            </Col>

                                           

                                            <Col xl={12} lg={12}>
                                                <Button variant="primary" type="submit" className='w-100'>
                                                   RESET PASSWORD
                                                </Button>
                                            </Col>

                                        </Row>

                                    </Form>
                                </div>
                            </div>
                        </Col>
                      
                    </Row>

                </Container>

            </section>
            <footer>
            <div className='banner-bottom'>
                <img src='assets/images/background/bottom_banner.webp' width="100%" />

            </div>
            </footer>
            {isLoading && <LoadingPopup />}

        </>
    )
}

export default Resetpassword;
