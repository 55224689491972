import { useState } from 'react';
import './generalCSS/home.css'
import './generalCSS/generalnavbar.css'
import './generalCSS/seolanding.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { MultiSelect } from "react-multi-select-component";
import { Row, Col, Button, Form, Modal } from 'react-bootstrap';
import { Formik } from "formik";
import { RegisterSchema } from "../validations/signup";
import { registerSEOAction } from "../Action/propertyOwner.action";
import { toast, Toaster } from "react-hot-toast";
import  config  from '../coreFIles/config';

const options = [
    { label: "Sedan", value: "1" },
    { label: "SUV", value: "2" },
    { label: "Motorcycle", value: "3" },
    { label: "Bus", value: "4" },
    { label: "Coupe", value: "5" },
    { label: "Pickup", value: "6" },
    { label: "Boats", value: "7" },
    { label: "Hatchback", value: "8" },
    { label: "Minibus", value: "9" }
]

const options1 = [
    { label: "Apartment", value: "5" },
    { label: "House", value: "1" },
    { label: "Villa", value: "4", },
    { label: "Cottage", value: "2", },
    { label: "Hotel", value: "3", },
    { label: "Resort", value: "6", },
]

let numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];

const Seolanding = () => {
    const [typesOfProperty, setTypesOfProperty] = useState([]);
    const [typesOfVehicle, setTypesOfVehicle] = useState([]);
    const [typesOfVehicleError, setTypesOfVehicleError] = useState('');
    const [typesOfPropertyError, setTypesOfPropertyError] = useState('');
    const [isPopup, setisPopup] = useState(0);

    const handleSignup = async (values) => {

        if (typesOfProperty.length == 0 && typesOfVehicle.length == 0) {
            toast.error('Please select property type or vehicle.');
            return
            // setTypesOfPropertyError('Please select property type or vehicle');
        } else {
            // setTypesOfPropertyError('');
        }
        // if (typesOfVehicle.length == 0) {
        //     setTypesOfVehicleError('Vehicle type required');
        // } else {
        //     setTypesOfVehicleError('');
        // }

        // if (typesOfProperty.length > 0) {
        //     if (values.numberOfProperty == 0) {
        //         toast.error('Please select number of property.');
        //         return
        //     }
        // }

        // if (typesOfVehicle.length > 0) {
        //     if (values.numberOfVehicle == 0) {
        //         toast.error('Please select number of vehicle.');
        //         return
        //     }
        // }        

        values.typesOfProperty = typesOfProperty
        values.typesOfVehicle = typesOfVehicle
        const res = await registerSEOAction(values);
        if (res.success) {
            // toast.success(res.msg);
            // setisPopup(1)
            // setTimeout(() => {
            //     window.location.reload();
            // }, 5000);
            window.location.href = `${config.baseUrl}thank-you`
        } else {
            toast.error(res.msg);
        }
    };

    return (
        <>
            <h5 className='mb-4'><b>Register Yourself To Get Listed With Us!</b></h5>
            <Toaster />
            <Formik
                initialValues={{
                    'fullname': '',
                    'email': '',
                    'phoneNumber': "",
                    'location': "",
                    'description': "",
                    'typesOfProperty': '',
                    'typesOfVehicle': '',
                    'numberOfProperty': '',
                    'numberOfVehicle': ''
                }}
                validationSchema={RegisterSchema}
                onSubmit={(values) => {
                    handleSignup(values);
                }}
            >
                {({
                    errors,
                    handleSubmit,
                    handleBlur,
                    handleChange,
                    touched,
                    values,
                }) => (
                    <>
                        <Form onSubmit={handleSubmit} autoComplete="off">
                            <Row>
                                <Col lg={6} >
                                    <Form.Group
                                        className="mb-3 "
                                    >
                                        <Form.Label className=''>Full Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            onKeyPress={(event) => { if (!/^[A-Za-z ]+$/.test(event.key)) { event.preventDefault(); } }}
                                            placeholder="Enter Full Name"
                                            aria-label="fullname"
                                            name="fullname"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.fullname}
                                        />
                                        <Form.Text className="text-danger">
                                            {errors.fullname && touched.fullname && (
                                                <div>
                                                    {errors.fullname}
                                                </div>
                                            )}
                                        </Form.Text>
                                    </Form.Group>
                                </Col>
                                <Col lg={6} >
                                    <Form.Group
                                        className="mb-3 "
                                    >
                                        <Form.Label className=''>Email Id</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Email"
                                            aria-label="email"
                                            name="email"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.email}
                                        />
                                        <Form.Text className="text-danger">
                                            {errors.email && touched.email && (
                                                <div>
                                                    {errors.email}
                                                </div>
                                            )}
                                        </Form.Text>
                                    </Form.Group>
                                </Col>
                                <Col lg={6} >
                                    <Form.Group
                                        className="mb-3 "
                                    >
                                        <Form.Label className=''>Phone Number</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Phone Number"
                                            aria-label="phoneNumber"
                                            name="phoneNumber"
                                            onBlur={handleBlur}
                                            onKeyPress={(event) => { if (!/^\d*[]?\d{0,1}$/.test(event.key)) { event.preventDefault(); } }}
                                            onChange={handleChange}
                                            value={values.phoneNumber}
                                        />
                                        <Form.Text className="text-danger">
                                            {errors.phoneNumber && touched.phoneNumber && (
                                                <div>
                                                    {errors.phoneNumber}
                                                </div>
                                            )}
                                        </Form.Text>
                                    </Form.Group>

                                </Col>
                                <Col lg={6} >
                                    <Form.Group
                                        className="mb-3 "
                                    >
                                        <Form.Label className=''>Location</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Location"
                                            aria-label="location"
                                            name="location"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.location}
                                        />
                                        <Form.Text className="text-danger">
                                            {errors.location && touched.location && (
                                                <div>
                                                    {errors.location}
                                                </div>
                                            )}
                                        </Form.Text>
                                    </Form.Group>

                                </Col>
                                <Col lg={6} >
                                    <Form.Group className="mb-3 " >
                                        <Form.Label className=''>Types of property</Form.Label>
                                        <MultiSelect
                                            aria-label="typesOfProperty"
                                            name="typesOfProperty"
                                            onBlur={handleBlur}
                                            options={options1}
                                            value={typesOfProperty}
                                            onChange={setTypesOfProperty}
                                            labelledBy="Select"
                                        />
                                        <Form.Text className="text-danger">
                                            {typesOfPropertyError ?
                                                <div>
                                                    {typesOfPropertyError}
                                                </div>
                                                : ""}
                                        </Form.Text>
                                    </Form.Group>

                                </Col>
                                {/* <Col lg={6} >
                                    <Form.Group className="mb-3 " >
                                        <Form.Label className=''>Number of property</Form.Label>
                                        <Form.Select
                                            aria-label="numberOfProperty"
                                            name="numberOfProperty"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            className='form_select'>
                                            {numbers.map(i => (
                                                <option value={i}>{i}</option>
                                            ))}
                                        </Form.Select>
                                        <Form.Text className="text-danger">
                                            {errors.numberOfProperty && touched.numberOfProperty && (
                                                <div>
                                                    {errors.numberOfProperty}
                                                </div>
                                            )}
                                        </Form.Text>
                                    </Form.Group>
                                </Col> */}
                                <Col lg={6} >
                                    <Form.Group
                                        className="mb-3 "
                                    >
                                        <Form.Label className=''>Types of vehicle</Form.Label>
                                        <MultiSelect
                                            aria-label="typesOfVehicle"
                                            name="typesOfVehicle"
                                            onBlur={handleBlur}
                                            options={options}
                                            value={typesOfVehicle}
                                            onChange={setTypesOfVehicle}
                                            labelledBy="Select"
                                        />
                                        <Form.Text className="text-danger">
                                            {typesOfVehicleError ?
                                                <div>
                                                    {typesOfVehicleError}
                                                </div>
                                                : ""}
                                        </Form.Text>
                                    </Form.Group>

                                </Col>
                                {/* <Col lg={6} >
                                    <Form.Group
                                        className="mb-3 "
                                    >
                                        <Form.Label className=''>Number of vehicle</Form.Label>
                                        <Form.Select
                                            aria-label="numberOfVehicle"
                                            name="numberOfVehicle"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            className='form_select'>
                                            {numbers.map(i => (
                                                <option value={i}>{i}</option>
                                            ))}
                                        </Form.Select>
                                        <Form.Text className="text-danger">
                                            {errors.numberOfVehicle && touched.numberOfVehicle && (
                                                <div>
                                                    {errors.numberOfVehicle}
                                                </div>
                                            )}
                                        </Form.Text>
                                    </Form.Group>

                                </Col> */}
                                <Col lg={12} >
                                    <Form.Group className="mb-3" >
                                        <Form.Label className=''>Description</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            className='h-auto'
                                            placeholder="Enter Description"
                                            aria-label="description"
                                            name="description"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.description}
                                        />
                                        <Form.Text className="text-danger">
                                            {errors.description && touched.description && (
                                                <div>
                                                    {errors.description}
                                                </div>
                                            )}
                                        </Form.Text>
                                    </Form.Group>

                                </Col>

                            </Row>
                            <Button type='submit' variant='primary' className='px-3'>
                                Submit
                            </Button>

                        </Form>
                    </>
                )}
            </Formik>

            <Modal show={isPopup} className="d-toaster-modal" size="md">
                <Modal.Body>
                    <div className="d-flex pt-2 pb-2">
                        <div className="me-3">
                            <img src="assets/images/success.png" width="18px" />
                        </div>
                        <div>
                            <h5 className="mb-0">Thank you!</h5>
                            <p className="text-muted mb-0">Your property has been successfully registered with us.</p>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default Seolanding